import { configureStore } from '@reduxjs/toolkit'
import registerSlice from "../features/auth/registerSlice";
import loginSlice from "../features/auth/loginSlice";
import logoutSlice from "../features/auth/logoutSlice";
import authSlice from "../features/auth/authSlice";
import farmSlice from "../features/farm/farmSlice";
import plotSlice from "../features/plot/plotSlice";
import fileSlice from "../features/file/fileSlice";
import gradeSlice from "../features/grade/gradeSlice";
import cropSlice from "../features/crop/cropSlice";
import storeSlice from "../features/store/storeSlice";
import productSlice from "../features/product/productSlice";
import catalogSlice from "../features/catalog/catalogSlice";
import categorySlice from "../features/category/categorySlice";
import demandSlice from "../features/demand/demandSlice";
import userSlice from "../features/user/userSlice";
import demandApplicationSlice from "../features/demand-application/demandApplicationSlice";
import orderSlice from "../features/order/orderSlice";
import orderDetailSlice from "../features/order-detail/orderDetailSlice";
import orderFarmerSlice from "../features/order-farmer/orderFarmerSlice";
import urustaniIntegrateionSlice from "../features/urustani-integration/urustaniIntegrationSlice";
import forgotPasswordSlice from "../features/auth/forgotPasswordSlice";
import newPasswordSlice from "../features/auth/newPasswordSlice";
import catalogPlotsSlice from "../features/catalog/plots/catalogPlotsSlice";
import advanceBookingSlice from "../features/advance-booking/advanceBookingSlice";
import orderFarmerDeliverySlice from "../features/order-farmer-delivery/orderFarmerDeliverySlice";
import settingDepositSlice from 'features/settingDeposit/settingDepositSlice';
import roomChatSlice from 'features/room-chat/roomChatSlice';
import varietySlice from 'features/variety/varietySlice';
import unitOfMeasureSlice from 'features/unit-of-measure/unitOfMeasureSlice';
import masterCropSlice from 'features/master-crop/masterCropSlice';
import companyCropSlice from 'features/company-crop/companyCropSlice';
import gradeProductSlice from 'features/grade-product/gradeProductSlice';
import delyvaIntegrationSlice from 'features/delyva-intgrations/delyvaIntegrationsSlice';
import cartSlice from 'features/cart/cartSlice';
import auctionBiddingSlice from 'features/auction-bidding/auctionBiddingSlice';
import companySlice from 'features/company/companySlice';
import companyRatingSlice from 'features/company-rating/companyRatingSlice';
import followerSlice from 'features/follower/followerSlice';
import billingSlice from 'features/billing/billingSlice';
import packageSlice from 'features/package-subscription/packageSlice';
import creditManagementSlice from 'features/credit-management/creditManagementSlice';
import creditManagementHistorySlice from 'features/credit-management-history/creditManagementHistorySlice';

export const store = configureStore({
    reducer: {
        register: registerSlice.reducer,
        login: loginSlice.reducer,
        logout: logoutSlice.reducer,
        auth: authSlice.reducer,
        user: userSlice.reducer,
        farm: farmSlice.reducer,
        file: fileSlice.reducer,
        plot: plotSlice.reducer,
        store: storeSlice.reducer,
        crop: cropSlice.reducer,
        grade: gradeSlice.reducer,
        product: productSlice.reducer,
        catalog: catalogSlice.reducer,
        category: categorySlice.reducer,
        demand: demandSlice.reducer,
        demandApplication: demandApplicationSlice.reducer,
        order: orderSlice.reducer,
        orderDetail: orderDetailSlice.reducer,
        orderFarmer: orderFarmerSlice.reducer,
        urustaniIntegrateion: urustaniIntegrateionSlice.reducer,
        forgotPassword: forgotPasswordSlice.reducer,
        newPassword: newPasswordSlice.reducer,
        catalogPlotsSlice: catalogPlotsSlice.reducer,
        advanceBooking: advanceBookingSlice.reducer,
        auctionBidding: auctionBiddingSlice.reducer,
        orderFarmerDelivery: orderFarmerDeliverySlice.reducer,
        setting: settingDepositSlice.reducer,
        roomChat: roomChatSlice.reducer,
        variety: varietySlice.reducer,
        unitOfMeasure: unitOfMeasureSlice.reducer,
        masterCrop: masterCropSlice.reducer,
        companyCrop: companyCropSlice.reducer,
        gradeProduct: gradeProductSlice.reducer,
        delyvaIntegration: delyvaIntegrationSlice.reducer,
        cart: cartSlice.reducer,
        company: companySlice.reducer,
        companyRating: companyRatingSlice.reducer,
        follower: followerSlice.reducer,
        billing: billingSlice.reducer,
        package: packageSlice.reducer,
        creditManagement: creditManagementSlice.reducer,
        creditManagementHistory: creditManagementHistorySlice.reducer,
        devTools: true,
    }
}
)