import { useNavigate } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";

const ProfileMenuSuperAdmin = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="unborderedButton"
        style={!window.location.pathname.includes(routesPaths.USER_DETAIL) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
        onClick={() => {
          navigate(routesPaths.USER_DETAIL_SHOW);
        }}
      >
        Manage User
      </div>
      <div
        className="unborderedButton"
        style={!window.location.pathname.includes(routesPaths.COMPANY_DETAIL) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
        onClick={() => {
          navigate(routesPaths.COMPANY_DETAIL_SHOW);
        }}
      >
        Manage Company
      </div>
      <div
        className="unborderedButton"
        style={!window.location.pathname.includes(routesPaths.PACKAGE_DETAIL) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
        onClick={() => {
          navigate(routesPaths.PACKAGE_DETAIL_SHOW);
        }}
      >
        Manage Packages
      </div>
      <div
        className="unborderedButton"
        style={!window.location.pathname.includes(routesPaths.MANAGE_BILLING) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
        onClick={() => {
          navigate(routesPaths.MANAGE_BILLING_SHOW);
        }}
      >
        Billing Management
      </div>
      <div
        className="unborderedButton"
        style={!window.location.pathname.includes(routesPaths.MANAGE_CREDIT) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
        onClick={() => {
          navigate(routesPaths.MANAGE_CREDIT_SHOW);
        }}
      >
        Credit Management
      </div>
      <div
        className="unborderedButton"
        style={!window.location.pathname.includes(routesPaths.MANAGE_ORDER) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
        onClick={() => {
          navigate(routesPaths.MANAGE_ORDER_SHOW);
        }}
      >
        Manage Order
      </div>
      <div
        className="unborderedButton"
        style={!window.location.pathname.includes(routesPaths.SETTING_PAGE) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
        onClick={() => {
          navigate(routesPaths.SETTING_PAGE);
        }}
      >
        Setting
      </div>
    </>
  );
};

export default ProfileMenuSuperAdmin;
