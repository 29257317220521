import CartProgress from "components/cart/CartProgress";
import CartSidebar from "components/cart/CartSidebar";
import Footer from "components/footer/footer";
import CartProductList from "components/cart/CartProductList";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import TermsOfUse from "components/home/TermsOfUse";
import md5 from 'md5';
import cartStyle from "./CartSidebar.module.scss";
import clsx from "clsx";
import { getCourier } from "features/order-farmer-delivery/orderFarmerDeliverySlice";
import { cartIndex } from "features/cart/cartSlice";
import { orderDetailStore } from "features/order-detail/orderDetailSlice";

const CartPanel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const snackbarCtx = useContext(SnackbarContext);
  const { updating } = useSelector((state) => state.cart);
  const [paymentType, setPaymentType] = useState(null);
  const [agreeTermCondition, setAgreeTermCondition] = useState(0);
  const [showTerms, setTermsModal] = useState(false);
  const [vcode, setVCode] = useState(null);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [selectedOrderDetail, setSelectedOrderDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [courierList, setCourierList] = useState([]);
  const [shippingCharge, setShippingCharge] = useState(0.00);
  const [totalAmount, setTotalAmount] = useState(0.00);
  const [orderNumber, setOrderNumber] = useState("");
  const [productPrice, setProductPrice] = useState(0.00);
  const [cartsArr, setCartsArr] = useState([])

  const loadCart = () => {
    dispatch(
      cartIndex({
        status: 'pending'
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        console.log(response?.payload)
      }
    });
  }

  const loadCourier = (cart, farmer, index) => {
    let checkbox = document.getElementsByName(farmer + '-main');
    let checkboxes = document.getElementsByName(farmer);

    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked === true && index === i) {
        checkbox[0].checked = true;
        setCartsArr([...cartsArr, cart]);
        setSelectedOrderDetail(cart);
        setProductPrice(productPrice + cart.total_price)
        setTotalAmount(totalAmount + shippingCharge + cart.total_price)
        setIsLoading(true);
        dispatch(
          getCourier({
            sender_address: {
              address: cart?.farmer?.user_address?.address?.address,
              city: cart?.farmer?.user_address?.address?.city,
              state: cart?.farmer?.user_address?.address?.state,
              postcode: cart?.farmer?.user_address?.address?.postcode,
              country: "MY",
              lat: cart?.farmer?.user_address?.address?.lat,
              lon: cart?.farmer?.user_address?.address?.lon
            },
            receiver_address: {
              address: cart?.buyer?.user_address?.address?.address,
              city: cart?.buyer?.user_address?.address?.city,
              state: cart?.buyer?.user_address?.address?.state,
              postcode: cart?.buyer?.user_address?.address?.postcode,
              country: "MY",
              lat: cart?.buyer?.user_address?.address?.lat,
              lon: cart?.buyer?.user_address?.address?.lon
            },
            weight: {
              unit: "kg",
              value: cart?.quantity
            }
          })
        ).then(function (response) {
          setIsLoading(false);
          if (response.error && response.error.message == "Rejected") {
          } else {
            let result = response.payload;
            setCourierList(result);
          }
        });
      } else {
        checkbox[0].checked = false;

        if (cart?.total_price) {
          setProductPrice(parseFloat(productPrice.toFixed(2)) - parseFloat(cart?.total_price.toFixed(2)));
          setTotalAmount(parseFloat(totalAmount.toFixed(2)) + parseFloat(shippingCharge.toFixed(2)) - parseFloat(cart.total_price).toFixed(2))
        } else {
          setProductPrice(0.00);
          setTotalAmount(0.00);
          setShippingCharge(0.00);
        }
      }
    }


  };

  const handleClickProceed = () => {
    if (paymentType) {
      if (agreeTermCondition) {
        if (paymentType == 0) {
          navigate(`/cart/offline-payment-process?paymentType=${paymentType}`);
        }
        else {
          if (window.confirm("Are you sure want to pay total amount for this order?")) {
            dispatch(
              orderDetailStore({
                amount_delivery: shippingCharge,
                price: selectedOrderDetail.total_price,
                product_id: selectedOrderDetail?.product?.id,
                quantity: selectedOrderDetail?.quantity,
                unit: selectedOrderDetail?.grade?.unit?.name,
                grade_id: selectedOrderDetail?.grade?.id,
                cart_id: selectedOrderDetail.id
              })
            ).then(function (response) {
              setIsLoading(false);
              if (response.error && response.error.message == "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
              } else {
                setOrderNumber(response?.payload?.data?.order_detail?.order?.order_number);
                setVCode(md5(totalAmount + process.env.REACT_APP_RAZER_MERCHANT_ID + "ORDER_" + response?.payload?.data?.order_detail?.order?.order_number + process.env.REACT_APP_RAZER_VERIFY_KEY));

                setTimeout(() => {
                  document.getElementById("razerForm").submit();
                }, 5000);
              }
            });
          }
        }
      }
      else {
        alert('Please agree with terms & conditions');
      }
    } else {
      alert('Please select payment method');
    }
  };

  useEffect(() => {
    loadCart();
    // setVCode(md5(totalAmount + process.env.REACT_APP_RAZER_MERCHANT_ID + "ORDER_" + reduxOrderDetail.order?.order_number + process.env.REACT_APP_RAZER_VERIFY_KEY));
  }, []);

  return (
    <div className="bg-gray-50 flex w-full flex-wrap justify-between h-screen" style={{ height: '90vh' }}>
      <div className="lg:w-4/5 w-full flex flex-col lg:space-y-6 space-y-3 px-3 py-6">
        <CartProgress step={step} />
        <CartProductList selectedCourier={selectedCourier} isLoading={isLoading} courierList={courierList} loadCourier={loadCourier} setSelectedCourier={setSelectedCourier} setShippingCharge={setShippingCharge} setTotalAmount={setTotalAmount} cartsArr={cartsArr} totalAmount={totalAmount} shippingCharge={shippingCharge} productPrice={productPrice} setProductPrice={setProductPrice} />
      </div>
      <div className="lg:w-1/5 w-full lg:px-3">
        <div data-testid="cart-sidebar" className="lg:mt-6 bg-white flex flex-col h-fit lg:p-3 p-6 mb-14 lg:mb-0 gap-4 rounded-lg shadow">
          <CartSidebar shippingCharge={shippingCharge} selectedOrderDetail={selectedOrderDetail} totalAmount={totalAmount} productPrice={productPrice} />
          <section>
            <select className={totalAmount > 0 ? "input-search" : "input-search bg-gray-300 !border-gray-400"} disabled={totalAmount === 0} defaultValue={null} value={paymentType} onChange={({ target }) => { setPaymentType(target.value); }}>
              <option key={"x-1"} value={null}>
                Select Payment Method
              </option>
              {/* <option key={"paymentMethod0"} value={0}>Offline</option> */}
              <option key={"paymentMethod1"} value={1}>Online</option>
            </select>
          </section>
          <section>
            <div className="flex sm:items-center lg:items-start">
              <input
                type="checkbox"
                name="agree_term_condition"
                className={totalAmount === 0 ? "!border-gray-400 mr-3 lg:mt-1" : "mr-3 lg:mt-1"}
                value={agreeTermCondition}
                disabled={totalAmount === 0}
                onChange={({ target }) => {
                  setAgreeTermCondition((agreeTermCondition == 0) ? 1 : 0);
                }}
              />
              <label className="text-sm">I've read and agree to <span className="text-green" style={{ cursor: "pointer" }}
                onClick={() => setTermsModal(true)}>Terms & Conditions</span> </label>
            </div>
          </section>
          <button disabled={totalAmount === 0} type="button" className={totalAmount === 0 ? "btn-gray" : "btn-green"} onClick={(event) => {
            event.preventDefault();
            handleClickProceed();
          }}>{updating.updating ? 'Processing, please wait..' : "Pay"}
          </button>
        </div>
      </div>
      <Footer />
      {showTerms && (
        <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
          <section data-testid="popup-layout" className="absolute bg-white w-4/5 h-3/4 flex flex-col rounded-xl shadow pb-4">
            <div className="bg-white w-full h-full flex flex-col rounded-t-xl px-8 py-4 overflow-y-auto">
              <TermsOfUse />
            </div>
            <div className="w-full flex gap-4 justify-center mt-2">
              <button className="borderedButton w-2/5" onClick={() => setTermsModal(false)}>
                Close
              </button>
            </div>
          </section>
        </div>
      )}
      <form id="razerForm" action={process.env.REACT_APP_RAZER_PAYMENT_URL} method="POST">
        <input type="hidden" name="merchant_id" value={process.env.REACT_APP_RAZER_MERCHANT_ID} />
        <input type="hidden" name="amount" value={totalAmount} />
        <input type="hidden" name="orderid" value={'ORDER_' + orderNumber} />
        <input type="hidden" name="bill_name" value={selectedOrderDetail?.buyer?.name} />
        <input type="hidden" name="bill_email" value={selectedOrderDetail?.buyer?.email} />
        <input type="hidden" name="bill_mobile" value={selectedOrderDetail?.buyer?.phone_number} />
        <input type="hidden" name="bill_desc" value={'ORDER_' + orderNumber} />
        <input type="hidden" name="returnurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-normal-order-return"} />
        <input type="hidden" name="callbackurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-normal-order-callback"} />
        <input type="hidden" name="vcode" value={vcode} />
        <input type="submit" className={clsx(cartStyle.hideVisibility)} />
      </form>
    </div>
  );
};

export default CartPanel;