import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    orderFarmerGetByOrderNumber, uploadPaymentReceipt, downloadInvoice
} from "../../../../features/order-farmer/orderFarmerSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { Col, Row, Badge } from "react-bootstrap";
import PaymentProgress from "./PaymentProgress";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleExclamation, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
// const FileDownload = require('js-file-download');

// import { useSearchParams } from "react-router-dom";
// import {Route, Link, Routes, useParams} from 'react-router-dom';

const PaymentOffline = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const snackbarCtx = useContext(SnackbarContext);
    const [order, setOrder] = useState(null);
    const [orderFarmer, setOrderFarmer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);


    const downloadInvoiceHandler = () => {
        setIsLoading(true);
        dispatch(
            downloadInvoice({
                order_number: params.order_number
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
            } else {
                let result = response.payload;
                const fileURL = URL.createObjectURL(result);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                snackbarCtx.displayMsg("Invoice has been generated.", "success");
                setIsLoading(false);
            }
        });
    };

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const uploadPaymentReceiptHandler = () => {
        if (selectedFile == null) {
            alert("Please choose payment proof.");
        } else {
            if (window.confirm("Are you sure want to pay this order?")) {
                setIsLoading(true);
                const formData = new FormData();
                formData.append("file", selectedFile, selectedFile.name);
                formData.append("order_number", params.order_number);

                dispatch(uploadPaymentReceipt(formData)).then(function (response) {
                    setIsLoading(false);
                    if (response.error && response.error.message === "Rejected") {
                        alert(response.payload);
                    } else {
                        let result = response.payload.data.order_farmer;
                        setIsLoading(false);
                        snackbarCtx.displayMsg(response.payload.message, "success");
                        navigate(`/customer/order-farmers/${result.id}/tracking`);
                    }
                });
            }
        }
    }

    const loadDetail = () => {
        setIsLoading(true);
        dispatch(
            orderFarmerGetByOrderNumber({
                order_number: params.order_number
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                alert(response.payload);
            } else {
                let order_farmer = response.payload.data.order_farmer;
                setOrderFarmer(order_farmer);
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        loadDetail();
    }, []);

    return (
        <Row className="container">
            <Col md={3}></Col>
            <Col md={6}>
                <div className="flex flex-col items-center mt-4">
                    <div style={{ minWidth: "80%" }} >
                        <PaymentProgress />
                    </div>
                </div>
                <Row className="mt-3">
                    <Col md={12}>
                        <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
                            <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                                <div className="w-full max-w">
                                    <center><label className="text-center mt-1"><b>Please transfer the remaining balance to this account:</b></label></center>
                                    <Row className="mt-2">
                                        <label className="col-md-4">Bank Name</label>
                                        <Col md={8}> : {orderFarmer?.farmer?.bank_detail?.bank_name}</Col>
                                    </Row>
                                    <Row>
                                        <label className="col-md-4">Account Name</label>
                                        <Col md={8}> : {orderFarmer?.farmer?.bank_detail?.account_name}</Col>
                                    </Row>
                                    <Row>
                                        <label className="col-md-4">Account Number</label>
                                        <Col md={8}> : {orderFarmer?.farmer?.bank_detail?.account_number}</Col>
                                    </Row>
                                    <Row>
                                        <label className="col-md-4">Total Remaining Balance</label>
                                        <Col md={8}> : RM {orderFarmer?.order?.remaining_balance}</Col>
                                    </Row>
                                    <Row hidden={(orderFarmer?.order?.status === 4) ? false : true}>
                                        <label className="col-md-4">Status</label>
                                        <Col md={8}>
                                            : <Badge bg="warning" className="text-black">Awaiting Farmer Confirmation</Badge>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}></Col>
                                        <Col md={8}>
                                            <button type="button" className="btn-green laptop:w-3/5 wide:w-3/5 mt-3 mb-3" onClick={() => {
                                                downloadInvoiceHandler()
                                            }}>
                                                {(isLoading) ? "Processing, please wait.." : "Download Invoice"}
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4 mb-2">
                                        <label className="col-md-12">Already transfer? Please upload payment proof for confirmation.</label>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            {/* <label className="input-group-text" for="inputGroupFile" style={{ marginTop: "1px" }}>{(selectedFile) ? "File Selected" : "Select File"}</label> */}
                                            <input
                                                id="inputGroupFile"
                                                // className={"hidden"}
                                                className="form-control"
                                                type="file"
                                                accept="image/png, image/jpeg, image/jpg"
                                                onChange={handleChangeFile}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <button type="button" className="btn-green laptop:w-5/5 wide:w-5/5" onClick={(event) => {
                                                event.preventDefault();
                                                uploadPaymentReceiptHandler();
                                            }}>
                                                {(isLoading) ? "Processing, please wait.." : "Upload"}
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={3}></Col>
        </Row>
    );
};

export default PaymentOffline;
