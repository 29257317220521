
import { routesPaths } from "constants/routes.constants";
import { useNavigate } from "react-router-dom";
import "./CompanyDetails.css";

const CompanyDetailsProducts = ({ company }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="mx-4 mb-2 text-2xl font-extrabold text-greenGray">All Products</div>
            <div className={"w-full grid place-items-center tablet:grid-cols-3 mb-3 gap-4 laptop:grid-cols-6 wide:grid-cols-7"}>
                {
                    company?.products && company?.products.map((product, key) => <div
                        key={key}
                        className="cursor-pointer relative w-full h-full rounded-lg shadow-lg border-2"
                        onClick={() => {
                            navigate(`${routesPaths.CATALOG}/${product.catalog.id}/show`);
                        }}
                    >
                        <img
                            src={product?.photo?.length > 0 ? product?.photo[0]?.photo : product?.photo[0]?.photo_default}
                            alt="photo of the product"
                            className="object-cover w-full h-full aspect-square rounded-lg"
                        />
                        <section className="absolute bg-black/50 text-white left-0 bottom-0 rounded-b-lg flex flex-col w-full h-1/4 p-2 box-img-card">
                            <label className="cursor-pointer font-in-box">{product?.name ? product.name : "Product Name"}</label>
                            <label className="cursor-pointer font-in-box">{product?.scientific_name && <span>( {product?.scientific_name} )</span>} </label>
                        </section>
                    </div>)
                }
            </div>

        </>
    );
};

export default CompanyDetailsProducts;


