import AccountLayout from "pages/AccountLayout";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { addPackage } from "features/auth/packageSubscriptionSlice";
import { useTranslation } from "react-i18next";
import { InputGroup } from "react-bootstrap";
import styles from "../account/Account.module.scss";
import clsx from "clsx";

const buttonStyle = {
  marginTop: "0px",
  height: "35px",
};

const PackageAdd = () => {
  const { t } = useTranslation();

  const { updating } = useSelector((state) => state.auth);

  const [packageName, setPackageName] = useState("")
  const [subscriptionFee, setSubscriptionFee] = useState("")
  const [serviceCharge, setServiceCharge] = useState()
  const [serviceChargeType, setServiceChargeType] = useState(0)
  const [packageDetails, setPackageDetails] = useState("")
  const [packagePeriod, setPackagePeriod] = useState("")
  const [selectedPeriodDate, setSelectedPeriodDate] = useState(0);
  const [packageAvailStatus, setPackageAvailStatus] = useState(1)
  const [selectedAccountType, setSelectedAccountType] = useState(""); // State to track the selected account type
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const snackbarCtx = useContext(SnackbarContext);

  const handleAccountType = id => {
    let type;

    switch (id) {
      case 'Farmer/Buyer':
        type = 1;
        break;

      case "Buyer":
        type = 2;
        break;

      default:
        type = 1;
        break;
    }

    return type;
  }


  const submitHandler = (event) => {
    dispatch(
      addPackage({
        package_name: packageName,
        subscription_fee: subscriptionFee,
        service_charge: serviceCharge,
        service_charge_type: parseInt(serviceChargeType),
        package_details: packageDetails,
        package_avail_status: 1,
        package_period: packagePeriod,
        package_period_format: selectedPeriodDate,
        role_id: handleAccountType(selectedAccountType)
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        navigate(`/package-management/show`);
      }
    });

    event.preventDefault();
  };

  useEffect(() => {
  }, []);

  const handlePeriodDateChange = (event) => {
    setSelectedPeriodDate(event.target.value);
  };

  // Account type

  const handleAccountTypeChange = (event) => {
    setSelectedAccountType(event.target.value);
  };

  return (
    <AccountLayout>
      <div className="flex flex-col items-start gap-2 p-4">
        <label className="cursor-pointer head-bar-nav font-regular font-semibold text-sm" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
          {" Go Back"}
        </label>
        <h1 className="text-3xl font-regular font font-semibold pb-2" style={{ color: "#395B50" }}>Add Package</h1>
        <div className="w-full max-w bg-white flex flex-col items-start rounded-lg shadow gap-4 p-4">
          <h1 className="text-xl font-regular font font-semibold" style={{ color: "#395B50" }}>Package Details</h1>
          <div className="row font-regular text-sm" style={{ width: "100%" }}>
            <form className="self-start flex flex-col gap-4 col-md-5" onSubmit={submitHandler}>
              <div className="flex flex-col space-y-6">
                <section className="flex flex-col space-y-1">
                  <label>Package Name:</label>
                  <input
                    type="text"
                    className={"input-search"}
                    placeholder={"Name"}
                    onChange={({ target }) => setPackageName(target.value)}
                  />
                </section>
                <section className="flex flex-col space-y-1">
                  <label>User Type:</label>
                  <select
                    value={selectedAccountType}
                    className="input-search"
                    onChange={handleAccountTypeChange}
                  >
                    <option value="DEFAULT">
                      {"Select Account Type"}
                    </option>
                    <option value={t('farmerBuyer')}>{t('farmerBuyer')}</option>
                    <option value={t('buyer')}>{t('buyer')}</option>
                  </select>
                </section>
                <section className="flex flex-col space-y-1">
                  <label>Subscription Fee (PER MONTH):</label>
                  <input
                    type="number"
                    className={"input-search"}
                    placeholder={"Subscription / month"}
                    onChange={({ target }) => setSubscriptionFee(target.value || "")}
                  />
                </section>
                <section className="flex flex-col space-y-1">
                  <label>Service Charge (PER TRANSACTION):</label>
                  <div className="flex items-center col-span-2 gap-5">
                    {serviceChargeType === 1 && <div className="flex w-full">
                      <InputGroup.Text id="basic-addon1" className="rounded-r-none">RM</InputGroup.Text>
                      <input
                        type="number"
                        className={clsx(styles.removeLeftBorder, "input-search")}
                        placeholder={"Service Charge %"}
                        value={parseInt(serviceCharge)}
                        onChange={({ target }) => { setServiceCharge(target.value || "") }}
                      />
                    </div>}
                    {serviceChargeType === 0 &&
                      <input
                        type="number"
                        className="input-search w-full"
                        placeholder={"Service Charge %"}
                        value={parseInt(serviceCharge)}
                        onChange={({ target }) => setServiceCharge(target.value || "")}
                      />}
                    <select
                      value={serviceChargeType}
                      className="input-search"
                      onChange={({ target }) => setServiceChargeType(parseInt(target.value))}
                    >
                      <option value="" disabled>
                        {"Select Service Type"}
                      </option>
                      <option value={0}>Percentage</option>
                      <option value={1}>Fixed</option>
                    </select>
                  </div>
                </section>
                <section className="flex flex-col space-y-1">
                  <label>Package Details:</label>
                  <input
                    type="text"
                    className={"input-search"}
                    placeholder={"Package Details"}
                    onChange={({ target }) => setPackageDetails(target.value)}
                  />
                </section>
                <section className="flex flex-col space-y-1">
                  <label>Package Availability Status:</label>
                  <div className="flex items-center col-span-2 gap-5">
                    <button
                      type="button"
                      onClick={() => setPackageAvailStatus(1)}
                      className={`border-none borderedwhiteButton btn-add-carts w-full desktop:w-1/2 text-center ${packageAvailStatus === 1 ? "borderedActiveButton" : ""}`} style={{ marginTop: "0px", height: "35px" }}>
                      Active
                    </button>
                    <button
                      type="button"
                      onClick={() => setPackageAvailStatus(0)}
                      className={`border-none borderedwhiteButton btn-add-carts w-full desktop:w-1/2 text-center ${packageAvailStatus === 0 ? "borderedInactiveButton" : ""}`} style={{ marginTop: "0px", height: "35px" }}>
                      Inactive
                    </button>
                  </div>
                </section>
                <section className="flex flex-col space-y-1">
                  <div className="flex flex-col">
                    <label>Package Period:</label>
                    <div className="flex col-span-2  gap-4">
                      <div className="w-full">
                        <input
                          className="input-search"
                          type="number"
                          placeholder="Period Date"
                          onChange={({ target }) => {
                            setPackagePeriod(parseInt(target.value))
                          }}
                        />
                      </div>
                      <div className="w-full">
                        <select
                          value={selectedPeriodDate}
                          className="input-search"
                          onChange={handlePeriodDateChange}
                        >
                          <option value={t('months')}>{t('months')}</option>
                          <option value={t('years')}>{t('years')}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="row">
                  <div className="col-md-4">
                    <button
                      type="submit"
                      className="saveButton bg-green-600 text-white"
                    >
                      {updating ? "Processing, please wait.." : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};

export default PackageAdd;
