import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./../Account.module.scss";
import clsx from "clsx";
import { getCurrentUserInfo } from "features/auth/authSlice";
import Billing from "./Billing";

const AccountBill = () => {
    const { currentCompany } = useSelector((state) => state.auth);
    const [currentBills, setCurrentBills] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCurrentUserInfo());
        setCurrentBills(currentCompany?.bills?.data);

    }, [dispatch]);

    return (
        <div id="billing" data-testid="order-list" className="w-full flex flex-col items-start">
            <div className={clsx(styles.menuMobile, "justify-between items-center py-2 w-full gap-y-2")}>
                <div className="flex items-center justify-end tablet:flex">
                </div>
                <div className="flex items-center tablet:flex justify-start desktop:w-fit">
                    <form className="tablet:flex justify-center w-full desktop:w-fit ">
                        <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 dark:text-white laptop:hidden desktop:hidden tablet:hidden">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                            <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                        </div>
                    </form>
                </div>
            </div>
            <div className={clsx(styles.scroll, "w-full mt-2 shadow rounded-lg")}>
                <table className="rounded-t-lg" style={{ width: "100%" }}>
                    <tr className="text-center p-2 text-sm rounded-t-lg" style={{ backgroundColor: "#00AF54", color: "white" }}>
                        <th className="p-2 rounded-tl-lg">
                            Plan Subscribed
                        </th>
                        <th className="p-2">
                            Billing No
                        </th>
                        <th className="p-2">
                            Billing Date & Time
                        </th>
                        <th className="p-2">
                            Billing Amount (RM)
                        </th>
                        <th className="p-2">
                            Status
                        </th>
                        <th className="p-2">
                            Payment Method
                        </th>
                        <th className="p-2 rounded-tr-lg w-[250px]">
                            Options
                        </th>
                    </tr>
                    {currentBills.length === 0 && <tr className="text-center text-md bg-white rounded-b-lg" style={{ borderBottomWidth: "1px" }}>No bill is available</tr>}
                    <Billing />
                </table>
            </div>
        </div>
    );
};

export default AccountBill;
