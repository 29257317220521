import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  farmService from "./farmService";

export const farmIndex = createAsyncThunk(
    "farms/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await farmService.farmIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const farmStore = createAsyncThunk(
    "farms/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await farmService.farmStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const farmUpdate = createAsyncThunk(
    "farms/update",
    async (args, thunkAPI) => {
        try {
            return await farmService.farmUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const farmUploadPhoto = createAsyncThunk(
    "farms/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await farmService.farmUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const farmShow = createAsyncThunk(
    "farms/:id/show",
    async (args, thunkAPI) => {
        try {
            return await farmService.farmShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const farmDelete = createAsyncThunk(
    "farms/:id/delete",
    async (args, thunkAPI) => {
        try {
            return await farmService.farmDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    farm: {
        owner:{}
    },
    farms: {
        data:[]
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const farmSlice = createSlice({
    name: 'farm',
    initialState,
    reducers: {
    },
    extraReducers : {
        [farmStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [farmStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.farm = payload.data.farm;
            state.farm.owner = payload.data.owner;
            state.loading=false;
            state.isSuccess = true;
        },
        [farmStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [farmUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [farmUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [farmUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [farmShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [farmShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.farm = payload.data.farm;
            state.farm.owner = payload.data.owner;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [farmShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [farmIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [farmIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.farms = payload.data.farms;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [farmIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = farmSlice.actions

export default farmSlice;