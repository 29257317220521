import styles from "./Account.module.scss";
import clsx from "clsx";

const AccountControls = ({ page, pageHandler }) => {
  return (
    <div className={clsx(styles.scroll, "flex gap-x-4 w-full overflow-x-scroll")}>
      <a
        href="#personal"
        className={page === "#personal" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
          "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("#personal")}
      >
        Personal Details
      </a>
      <a
        href="#company"
        className={page === "#company" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
          "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("#company")}
      >
        Company Details
      </a>
      <a
        href="#address"
        className={page === "#address" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
          "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("#address")}
      >
        Delivery Address
      </a>
      <a
        href="#package"
        className={page === "#package" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
          "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("#package")}
      >
        Package Subscription
      </a>
      <a
        href="#bank"
        className={page === "#bank" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
          "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("#bank")}
      >
        Bank Details
      </a>
      <a
        href="#billing"
        className={page === "#billing" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
          "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("#billing")}
      >
        Billing
      </a>
    </div>
  );
};

export default AccountControls;
