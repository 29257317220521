import React, { useContext } from "react";
import ReactDOM from "react-dom";

import SnackbarContext from "_helpers/snackbar-context";

import styles from "./Snackbar.module.css";
import { Col } from "react-bootstrap";

const SnackbarNotif = ({ title, body, setIsShowNotif }) => {
    const snackbarCtx = useContext(SnackbarContext);

    return (
        <div className={`${styles.snackbarContainer} ${styles['success']}`}>
            <Col>
                <div style={{ fontSize: "15px", color: "white", fontWeight: "bold" }}>{title}</div>
                <div style={{ fontSize: "13px", color: "white" }}>{body}</div>
            </Col>
            <button className={styles.snackbarDismiss} onClick={() => setIsShowNotif(false)}>
                &times;
            </button>
        </div>)
};

export default SnackbarNotif;