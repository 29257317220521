const ReturnRefundPolicy = () => {
    return (
        <div className="flex justify-start flex-col ">
            <div className="p-2">
                <label className="italic font-bold text-sm p-2">
                    TaniExchage Refund and Return Policy
                </label>
                <label className="text-xs p-2">
                    At TaniExchange, we strive to provide our customers with top-quality agricultural products and services.
                    Due to the sensitive nature of these products, we have a specific Refund and Return Policy in place to
                    ensure your satisfaction.
                </label>
            </div>
            <div className="text-xs px-5 flex flex-col">
                <div>
                    <label className="font-bold">
                        <span className="mr-2">
                            1. 
                        </span>
                        <span>
                            Inspection Upon Delivery
                        </span>
                    </label>
                    <div className="ml-4">
                        <label className="flex flex-row mt-1">
                            <span className="mr-2">
                                1.1
                            </span>
                            <span>
                                We highly recommend inspecting all perishable items immediately upon delivery to ensure
                                the deliverables meet your expectations, including whether the items are of acceptable
                                quality and match the description that has been provided to you. 
                            </span>
                        </label>
                        <label className="flex flex-row  mt-2">
                            <span className="mr-2">
                                1.2
                            </span>
                            <span>
                                If you notice any issues with the quality or condition of the products, please contact us
                                within 24 hours of delivery to report the concern.
                            </span>
                        </label>
                    </div>
                </div>

                <div className="mt-3">
                    <label className="font-bold">
                        <span className="mr-2">
                            2. 
                        </span>
                        <span>
                            Perishable Items are Non-Returnable
                        </span>
                    </label>
                    <div className="ml-4">
                        <label className="flex flex-row  mt-1">
                            <span className="mr-2">
                                2.1
                            </span>
                            <span>
                                Kindly note that all perishable items are non-returnable. Given the nature of these
                                products, which include fresh produce and other perishable goods, we are unable to
                                process returns for them. 
                            </span>
                        </label>
                        <label className="flex flex-row mt-2">
                            <span className="mr-2">
                                2.2
                            </span>
                            <span>
                                This policy is designed to maintain the integrity and freshness of the items we provide.
                            </span>
                        </label>
                    </div>
                </div>

                <div className="mt-3">
                    <label className="font-bold">
                        <span className="mr-2">
                            3. 
                        </span>
                        <span>
                            Quality Concerns and Order Inaccuracy
                        </span>
                    </label>
                    <div className="ml-4">
                        <label className="flex flex-row mt-1">
                            <span className="mr-2">
                                3.1
                            </span>
                            <span>
                                We strive to fulfil your orders accurately. In the rare event that you receive incomplete,
                                incorrect, or damaged items, please contact us immediately.
                            </span>
                        </label>
                        <label className="flex flex-row mt-2">
                            <span className="mr-2">
                                3.2
                            </span>
                            <span>
                                Please provide photographic evidence of the issue along with proof of purchase and
                                contact us within 24 hours of delivery. We will investigate the matter and address your
                                concerns promptly.
                            </span>
                        </label>
                        <label className="flex flex-row mt-2">
                            <span className="mr-2">
                                3.3
                            </span>
                            <span>
                                For incomplete order, refund will be issued to you via your original payment method. For
                                Advanced Booking and Auction/ Bidding orders, the amount eligible for refund will be
                                based on the amount or percentage of incompleteness on the date of the refund request.
                            </span>
                        </label>
                        <label className="flex flex-row mt-2">
                            <span className="mr-2">
                                3.4
                            </span>
                            <span>
                                <span>
                                If a product you've ordered is not in stock, you may contact the vendor and will be 
                                presented with either one of the following solutions:
                                </span>
                            </span>
                        </label>
                        <label className="flex flex-row mt-2 ml-6">
                            <span className="mr-2">
                                3.4.1
                            </span>
                            <span className="flex flex-col italic">
                                <span>
                                    Modify your order by substituting it with an alternative product of equivalent value,
                                </span>
                                <span>
                                    OR
                                </span>
                            </span>
                        </label>
                        <label className="flex flex-row mt-2 ml-6">
                            <span className="mr-2">
                                3.4.2
                            </span>
                            <span className="italic">
                                Proceed with a complete reimbursement for the product that is unavailable but was originally ordered.
                            </span>
                        </label>
                    </div>
                </div>

                <div className="mt-3">
                    <label className="font-bold">
                        <span className="mr-2">
                            4. 
                        </span>
                        <span>
                            Contact Information
                        </span>
                    </label>
                    <div className="ml-4">
                        <label className="flex flex-row mt-1">
                            <span className="mr-2">
                                4.1
                            </span>
                            <span>
                                <span>
                                    For refund or return requests, or if you have any questions regarding our Refunds and
                                    Return Policy for perishable items, please contact our team at
                                </span>
                                <label className="underline ml-1">
                                    admin.taniexchange
                                </label>
                                <label className="underline">
                                @singularityaerotech.asia
                                </label>
                            </span>
                        </label>
                        <label className="flex flex-row mt-2">
                            <span className="mr-2">
                                4.2
                            </span>
                            <span>
                                This policy is designed to maintain the integrity and freshness of the items we provide.
                            </span>
                        </label>
                    </div>
                </div>
                <label className="mt-3 font-bold text-sm">
                    Note: This Refund and Return Policy for perishable items is subject to change, and any
                    updates will be posted on our website. We recommend checking this policy periodically to
                    stay informed about any modifications.
                </label>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            Revision 1.0. 
                        </span>
                        <span>
                            Date 21/08/2023
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                        © SINGULARITY AEROTECH ASIA SDN BHD 2020. ALL RIGHTS RESERVED. CONFIDENTIAL AND PROPRIETARY DOCUMENT.
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReturnRefundPolicy;