import api from "../../../support/support_api";

const plotDetail = async (params) => {
    const response  = await api.axiosShow(`/plots/${params.plot_id}`, params);

    return response.data;
}

const bookingPlot = async (params) => {
    const response  = await api.axiosPost(`/booking-plots`, params);

    return response.data;
}

const aucBidPlot = async (params) => {
    const response  = await api.axiosPost(`/auction-plots`, params);

    return response.data;
}

const forefeitBid = async (params) => {
    const response  = await api.axiosDelete(`/auction-plots/${params.plot_id}/forfeitByUser`);

    return response.data;
}

const getBookingPlotByUser = async (params) => {
    const response  = await api.axiosGet(`/booking-plots/${params.plot_id}/getByUser/${params.user_id}`);

    return response.data;
}

const getBidPriceLast = async (params) => {
    const response  = await api.axiosGet(`/auction-plots/${params.plot_id}/last`);

    return response.data;
}

const getDataBidders = async (params) => {
    const response  = await api.axiosGet(`/auction-plots/${params.plot_id}/getDataBidders`);

    return response.data;
}

const getBidStatusUser = async (params) => {
    const response  = await api.axiosGet(`/auction-plots/${params.plot_id}/lastByUser/${params.user_id}`);

    return response.data;
}

const getStatesList = async (params) => {
    const response  = await api.axiosGet(`/states/list`);

    return response.data;
}

const getAddress = async (params) => {
    const response  = await api.axiosGet(`/users/get/userAddress`, params);

    return response.data;
}


const catalogPlotsService = {
    plotDetail,
    bookingPlot,
    getBookingPlotByUser,
    getStatesList,
    getAddress,
    getBidPriceLast,
    getBidStatusUser,
    aucBidPlot,
    forefeitBid,
    getDataBidders
}

export default catalogPlotsService;