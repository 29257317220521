import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  settingDepositService from "./settingDepositService";

export const settingDepositGet = createAsyncThunk(
    "setting-deposit",
    async (args, thunkAPI) => {
        try {
            return await settingDepositService.settingDepositGet(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const settingDepositSave = createAsyncThunk(
    "setting-deposit/save",
    async (args, thunkAPI) => {
        try {
            return await settingDepositService.settingDepositSave(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const settingRegistrationLimit= createAsyncThunk(
    "setting-registration/limit",
    async (args, thunkAPI) => {
        try {
            return await settingDepositService.settingRegistrationLimit(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)


const initialState = {
    data: {},
    setting: {},
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const settingDepositSlice = createSlice({
    name: 'settingDeposit',
    initialState,
    reducers: {
    },
    extraReducers : {
        [settingDepositSave.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [settingDepositSave.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.setting = payload.data.setting;
            state.loading=false;
            state.isSuccess = true;
        },
        [settingDepositSave.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        

        [settingRegistrationLimit.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [settingRegistrationLimit.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.setting.data = payload.data.setting;
            state.loading=false;
            state.isSuccess = true;
        },
        [settingRegistrationLimit.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [settingDepositGet.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [settingDepositGet.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.setting = payload.data.setting;
            state.loading=false;
            state.isSuccess = true;
        },
        [settingDepositGet.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        
        
    }
})

// export const {  } = orderFarmerSlice.actions

export default settingDepositSlice;