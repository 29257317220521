import { useDispatch } from "react-redux";
import { login } from "../../features/auth/loginSlice";
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/auth/logoutSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { useContext } from "react";
import { routesPaths } from "constants/routes.constants";

const ProfileEtc = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbarCtx = useContext(SnackbarContext)

  const submitHandler = () => {
    dispatch(logout()).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        localStorage.clear();
        window.location = "/login";
        // navigate("/login");
      }
    });

    // navigate("/account");
  };

  return (
    <>
      <div

        className="unborderedButton"
        style={{ backgroundColor: "white", color: "black" }}
        onClick={() => submitHandler()}
      >
        Logout
      </div>
      {/* <div
          
          className="unborderedButton"
          style={{backgroundColor:"white", color:"black"}}
          onClick={() => {
              // navigate(routesPaths.PRODUCTS);
          }}
      >
          Help
      </div> */}
      {/* <button type="button" className="borderedButton hover:bg-green-500 hover:text-white hover:border-green-500" onClick={() => submitHandler()}>
        Logout
      </button>
      <button type="button" className="borderedButton hover:bg-green-500 hover:text-white hover:border-green-500">
        Help
      </button> */}
    </>
  );
};

export default ProfileEtc;
