import ProfileEtc from "./ProfileEtc";
import ProfileInfo from "./ProfileInfo";
import ProfileManagement from "./ProfileManagement";
import ProfileRating from "./ProfileRating";
import { getCurrentUserInfo, authRoleActiveChange } from "../../features/auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import ProfileInfoAdmin from "./ProfileInfoAdmin";


const ProfileSidebar = () => {
    const dispatch = useDispatch();

    const { isLoggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getCurrentUserInfo());
    }, []);

    const { currentUser } = useSelector((state) => state.auth);
  return (
        <>
          {isLoggedIn &&
              <div className="bg-white justify-self-center col-start-1 text-sm flex flex-col p-3 py-4 gap-4 rounded-lg shadow tablet:h-full laptop:justify-self-end w-full h-full desktop:h-full">
                  {/* <ProfileInfo /> */}
                  {/* {currentUser && currentUser.role_active == "super admin" ? <ProfileInfo /> : <ProfileManagement />} */}
                  {/* {currentUser && currentUser.role_active == "farmer" && <ProfileMenuFarmer />} */}
                  {/* <ProfileRating /> */}
                  {/* <ProfileEtc /> */}
                  {currentUser && currentUser.role_active === "super-admin" ? (<ProfileInfoAdmin />) : (
                      <ProfileInfo />)}
                  <ProfileManagement/>
              </div>
          }
        </>
  );
};

export default ProfileSidebar;
