import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  plotService from "./plotService";

export const plotIndex = createAsyncThunk(
    "plots/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await plotService.plotIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const plotStore = createAsyncThunk(
    "plots/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await plotService.plotStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const plotUpdate = createAsyncThunk(
    "plots/update",
    async (args, thunkAPI) => {
        try {
            return await plotService.plotUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const plotUploadPhoto = createAsyncThunk(
    "plots/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await plotService.plotUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const plotShow = createAsyncThunk(
    "plots/:id/show",
    async (args, thunkAPI) => {
        try {
            return await plotService.plotShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const plotDelete = createAsyncThunk(
    "plots/:id/delete",
    async (args, thunkAPI) => {
        try {
            return await plotService.plotDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const plotUpdatePublishStatus = createAsyncThunk(
    "plots/:id/updatePublishStatus",
    async (args, thunkAPI) => {
        try {
            return await plotService.plotUpdatePublishStatus(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const plotSaveAdvancedBooking = createAsyncThunk(
    "plots/store/advancedBookingSetting",
    async (args, thunkAPI) => {
        try {
            return await plotService.plotSaveAdvancedBooking(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const plotSaveAuctionBidding = createAsyncThunk(
    "plots/store/auctionBiddingSetting",
    async (args, thunkAPI) => {
        try {
            return await plotService.plotSaveAuctionBidding(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const plotCancelAdvancedBooking = createAsyncThunk(
    "plots/store/cancelAdvancedBooking",
    async (args, thunkAPI) => {
        try {
            return await plotService.plotCancelAdvancedBooking(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getCompanyPackage = createAsyncThunk(
    "plots/getCompanyPackage",
    async (args, thunkAPI) => {
        try {
            return await plotService.getCompanyPackage(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getAucPlot = createAsyncThunk(
    "plots/getAucPlot",
    async (args, thunkAPI) => {
        try {
            return await plotService.getAucPlot(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getGradeList = createAsyncThunk(
    "grades/getByCropId/:crop_id",
    async (args, thunkAPI) => {
        try {
            return await plotService.getGradeList(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const plotUpdateViewOnlyPubStatus = createAsyncThunk(
    "plots/:id/updateViewOnlyPubStatus",
    async (args, thunkAPI) => {
        try {
            return await plotService.plotUpdateViewOnlyPubStatus(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    plot: {},
    plots: [],
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const plotSlice = createSlice({
    name: 'plot',
    initialState,
    reducers: {
    },
    extraReducers : {
        [plotStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [plotStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.plot = payload.data.plot;
            state.loading=false;
            state.isSuccess = true;
        },
        [plotStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [plotUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [plotUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [plotUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [plotShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [plotShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.plot = payload.data.plot;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [plotShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [plotIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [plotIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.plots = payload.data.plots;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [plotIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [plotDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [plotDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.plots = {};//payload.data.plots;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [plotDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [plotSaveAdvancedBooking.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [plotSaveAdvancedBooking.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.plots = {};//payload.data.plots;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [plotSaveAdvancedBooking.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [getGradeList.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [getGradeList.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.plots = {};//payload.data.plots;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [getGradeList.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [plotCancelAdvancedBooking.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [plotCancelAdvancedBooking.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.plots = {};//payload.data.plots;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [plotCancelAdvancedBooking.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = plotSlice.actions

export default plotSlice;