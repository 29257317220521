import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fileUpdate, fileShow } from "../../features/file/fileSlice";
import SnackbarContext from "_helpers/snackbar-context";

import AccountLayout from "pages/AccountLayout";
import { productUpdate } from "../../features/product/productSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import "./PickMapLocation.css";
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

const MultipleMarkerMapLocation = ({ listLocation }) => {
    const LATITUDE_DEFAULT = 1.966773;
    const LONGITUDE_DEFAULT = 102.881449;
    const [listLocationGlobal, setListLocationGlobal] = useState([{ lat: 1.966773, lng: 102.881449, name: "Farm" }]);

    useEffect(() => {
        setListLocationGlobal(listLocation);
    }, [listLocation]);

    function ChangeView({ center, zoom }) {
        const map = useMap();
        map.setView(center, zoom);
        return null;
    }


    const DraggableMarker = ({ listMarker }) => {
        return (
            <>
                {listMarker.map((markerPosi, index) => (
                    <>
                        <Marker
                            draggable={false}
                            position={markerPosi}
                        >
                            <Popup minWidth={90}>
                                <div className="text-center">
                                    <div className="mb-2">
                                        {markerPosi.name}
                                    </div>
                                    <span>
                                        {markerPosi.lat}, {markerPosi.lng}
                                    </span>
                                </div>

                            </Popup>
                        </Marker>
                    </>
                ))}

            </>

        );
    }


    return (
        <>
            <MapContainer center={[(((listLocation[0].lat == 0) || (listLocation[0].lat == null) || (listLocation[0].lat == undefined)) ? LATITUDE_DEFAULT : listLocation[0].lat), (((listLocation[0].lng == 0) || (listLocation[0].lng == null) || (listLocation[0].lng == undefined)) ? LONGITUDE_DEFAULT : listLocation[0].lng)]} zoom={13} scrollWheelZoom={false}>
                <ChangeView center={[(((listLocationGlobal[0].lat == 0) || (listLocationGlobal[0].lat == null) || (listLocationGlobal[0].lat == undefined)) ? LATITUDE_DEFAULT : listLocationGlobal[0].lat), (((listLocationGlobal[0].lng == 0) || (listLocationGlobal[0].lng == null) || (listLocationGlobal[0].lng == undefined)) ? LONGITUDE_DEFAULT : listLocationGlobal[0].lng)]}  zoom={13} />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <DraggableMarker listMarker={listLocationGlobal} />
            </MapContainer>
        </>
    );
};

export default MultipleMarkerMapLocation;
