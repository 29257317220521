import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import PageLayout from "pages/AccountLayout";
import Demand from "./Demand";
import MessageEmpty from "../ui/message/empty/MessageEmpty"

import BananaPhoto from "../../images/BananaPhoto.png";
import { productIndex } from "../../features/product/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { demandIndex } from "../../features/demand/demandSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSortAmountAsc } from "@fortawesome/free-solid-svg-icons";

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const DemandList = () => {
  const navigate = useNavigate();

  const [demands, setDemands] = useState([
    {
      info: {
        id: 1,
        photo: "/assets/images/products/BananasPhoto.svg",
        product_id: 1,
        farm_id: 1,
        grade_id: 1,
        name: "Bananas",
        description: "Open",
        frequency: "Delivery Every Thursday",
        quantity_number: 10,
        quantity_target: 150,
        unit: "KG",
        timeframe: "Week",
      },
    },
    {
      info: {
        id: 2,
        photo: "/assets/images/products/OrangesPhoto.svg",
        product_id: 1,
        farm_id: 1,
        grade_id: 1,
        name: "",
        description: "Open",
        frequency: "Once",
        quantity_number: 10,
        quantity_target: 150,
        unit: "KG",
        timeframe: "Week",
      },
    },
    {
      info: {
        id: 3,
        photo: "",
        product_id: 1,
        farm_id: 1,
        grade_id: 1,
        name: "",
        description: "",
        frequency: "",
        quantity_number: 0,
        quantity_target: 0,
        unit: "",
        timeframe: "",
      },
    },
  ]);

  const dispatch = useDispatch();

  const reduxDemand = useSelector((state) => state.demand);
  const snackbarCtx = useContext(SnackbarContext);
  const [demandList, setDemandList] = useState({
    data: []
  })
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initial value, you can change this
  const [currentPage, setCurrentPage] = useState(1);
  const loadDemands = (pageNumber, itemsPerPage) => {
    dispatch(
      demandIndex({
        limit: itemsPerPage,
        page: pageNumber,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        let demands = response.payload.data.demands;
        setDemandList(demands);
      }
    });
  };

  const handlePageChange = (newPageNumber, newItemsPerPage) => {
    setCurrentPage(newPageNumber);
    setItemsPerPage(newItemsPerPage); // Assuming you have an itemsPerPage state variable

    // Now you can call your data loading function with the updated values
    loadDemands(newPageNumber, newItemsPerPage);
  };

  const handleChangePage = (event, newPageNumber) => {
    handlePageChange(newPageNumber, itemsPerPage); // Call the common function
  };

  const handleLimitPage = (newItemsPerPage) => {
    setCurrentPage(1); // Reset the current page to 1
    setItemsPerPage(newItemsPerPage); // Update the itemsPerPage state variable
    loadDemands(1, newItemsPerPage);
  };

  useEffect(() => {
    loadDemands();
  }, []);

  return (
    <>
      <PageLayout>
        <div data-testid="order-list" className="flex flex-col items-start gap-6 p-4 mb-5">
          <h1 className="text-2xl text-left font-bold" style={{ color: "#395B50" }}>My Demands</h1>
          <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
            <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
              <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
                Sort
              </div>
              <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/4">
                <select className="select-custom mr-2">
                  <option value="DATE" selected>
                    Date
                  </option>
                  <option value="DATE" selected>
                    Price
                  </option>
                </select>
                <FontAwesomeIcon icon={faSortAmountAsc} />
              </div>
              <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
                Filter
              </div>
              <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
                <select className="select-custom ">
                  <option value="DATE" selected>
                    Farm
                  </option>
                  <option value="DATE" selected>
                    Product
                  </option>
                </select>
              </div>

              <div className="flex items-center tablet:flex justify-start w-full desktop:w-fit">
                <form className="tablet:flex justify-start w-full desktop:w-fit items-center">
                  <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 mr-5 dark:text-white laptop:hidden desktop:hidden">Search</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </div>
                    <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                  </div>
                </form>
              </div>
              <div className="flex items-center tablet:flex justify-start desktop:w-2/3 justify-end">
                <button
                  type="button"
                  onClick={() => navigate("/demands/create")}
                  className="border-none saveButton btn-add-carts  w-full desktop:w-1/2 text-center" style={{ marginTop: "0px", height: "35px" }}>
                  <FontAwesomeIcon icon={faPlus} className="mr-1" />
                  Add New Demand
                </button>
              </div>
            </div>
            {/*<button type="button" className="borderedButton h-[9rem]" onClick={addHandler}>*/}
            {/*<label className="cursor-pointer text-2xl">Add New Order</label>*/}
            {/*</button>*/}
            <div className="p-4 w-full max-w">
              <div className="hidden laptop:flex flex-row items-center justify-center pb-3" style={{ borderBottomWidth: "1px" }}>
                <div className="desktop:basis-1/4 laptop:basis-1/3 flex items-center justify-center font-medium">Demands</div>
                <div className="desktop:basis-1/4 laptop:basis-1/3 flex items-center justify-center font-medium">Details</div>
                <div className="desktop:basis-1/4 laptop:basis-1/3 flex items-center justify-center font-medium">Status</div>
                <div className="desktop:basis-1/4 flex items-center justify-center font-medium"></div>
              </div>
              {reduxDemand.demands.data &&
                reduxDemand.demands.data.map((demand, index) => {
                  return <Demand demand={demand} key={index} />;
                })}
            </div>
          </div>
          <div className="grid sm:grid-rows-2 md:grid-cols-2 w-full">
            <div className="flex items-center tablet:flex lg:justify-start justify-center w-full">
              <span>
                <select className="select-custom" onChange={(e) => handleLimitPage(e.target.value)}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={1000}>All</option>
                </select>
              </span>
              <span className="ml-2 text-sm">
                <label>entries per page</label>
              </span>
            </div>
            <div className="flex lg:justify-end justify-center">
              <Stack spacing={2} >
                <Pagination count={demandList.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={currentPage} />
              </Stack>
            </div>
          </div>
          <MessageEmpty loading={reduxDemand.loading} length={reduxDemand.demands.data.length} message={reduxDemand.data.message} />
        </div>

        {/* <div data-testid="demand-list" className="items-center flex flex-col rounded-md space-y-6">
          <h1 className="text-3xl">My Demands</h1>
          <div className="w-full max-w-[60rem] bg-formLight flex flex-col items-center shadow rounded-lg p-4 space-y-4">
            <button type="button" className="borderedButton h-[9rem]" onClick={() => navigate("/demands/create")}>
              <label className="cursor-pointer text-2xl">Add Demand</label>
            </button>
            {reduxDemand.demands.data &&
              reduxDemand.demands.data.map((demand, index) => {
                return <Demand demand={demand} key={index} />;
              })}
          </div>

          <MessageEmpty loading={reduxDemand.loading} length={reduxDemand.demands.data.length} message={reduxDemand.data.message} />
        </div> */}
      </PageLayout>
    </>
  );
};

export default DemandList;
