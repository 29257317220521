import { useNavigate } from "react-router-dom";
import { productDelete } from "features/product/productSlice";
import { useDispatch } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { useContext, useState } from "react";
import { catalogDestroy, catalogStore } from "../../features/catalog/catalogSlice";
import PopupConfirmation from "components/ui/popup/PopupConfirmation";

const Product = ({ product, loadProducts }) => {
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const [isDelete, setDelete] = useState(false);
  const navigate = useNavigate();
  const editHandler = () => {
    navigate(`/products/${product.id}/edit`);
  };
  const deletetableProduct = product.status_text === 'Draft' ? true : false;

  const deleteProduct = (event) => {
    let productId = product.id;
    if (window.confirm("Are you sure you want to delete this Product?")) {
      dispatch(
        productDelete({
          product_id: productId,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure")
        } else {
          snackbarCtx.displayMsg(response.payload.message, "success")
          loadProducts();
        }
      });
    }
  }

  const doDelete = () => {
    let productId = product.id;
    dispatch(
      productDelete({
        product_id: productId,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        loadProducts();
        setDelete(false);
      }
    });

  }
  const productSaveToCatalog = () => {
    let productId = product.id;

    dispatch(
      catalogStore({
        product_id: productId,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
        loadProducts();
      }
    });
  };

  const productRemoveFromCatalog = () => {
    dispatch(
      catalogDestroy({
        catalog_id: product.catalog.id,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        loadProducts();
      }
    });
  };

  return (
    <>
      {isDelete && <PopupConfirmation title="Are you sure want to delete this product?" successButtonTitle="Delete" cancelButtonTitle="Cancel" onSuccess={doDelete} onCancel={setDelete} />}
      <div
        data-testid="product"
        className="bg-white w-full flex flex-col justify-between items-center lg:p-4"
        style={{ borderBottomWidth: "1px" }}
      >
        <div className=" w-full flex flex-row p-2 gap-4 items-center justify-between ">
          <section className="flex items-center gap-x-8">
            <img
              src={product.photo.length > 0 ? product.photo[0].photo : "/assets/images/products/WheatIcon.svg"}
              alt={product.name}
              className="w-20 h-20 object-cover rounded-full"
            />

            <div className="flex flex-col justify-start items-start gap-x-2">
              <label className="text-xl font-semibold text-center">
                {product.name}

                {product.scientific_name && <span> ( {product.scientific_name} ) </span>}
              </label>
              {/* <label>Status:</label> */}
              <label className=" text-center">Status: {product.status_text}</label>
            </div>
          </section>

          <section className="w-1/3 p-4 hidden md:flex flex-row space-y-4 items-end justify-end gap-2">

            <button type="button" className="btn-border-green lg:w-1/3 rounded-md" onClick={() => editHandler()}>
              Edit
            </button>
            {deletetableProduct ? (<>
              <button type="button" className="btn-border-red lg:w-1/3 rounded-md" onClick={() => setDelete(true)}>
                Delete
              </button>
              <button type="button" className="btn-border-green lg:w-1/3 rounded-md" onClick={() => productSaveToCatalog()}>
                Publish
              </button>
            </>) : (<button type="button" className="btn-border-red lg:w-2/5 rounded-md" onClick={() => productRemoveFromCatalog()}>
              Unpublish
            </button>)}
          </section>
        </div>
        <section className="productLarge:hidden flex flex-row w-full space-y-4 items-end justify-between gap-2">
          <button type="button" className="btn-border-green w-[120px] rounded-md" onClick={() => editHandler()}>
            Edit
          </button>
          {deletetableProduct ? (<>
            <button type="button" className="btn-border-red w-1/3 rounded-md" onClick={() => setDelete(true)}>
              Delete
            </button>
            <button type="button" className="btn-border-green w-[120px] rounded-md" onClick={() => productSaveToCatalog()}>
              Publish
            </button></>
          ) : (<button type="button" className="btn-border-red w-[120px] rounded-md" onClick={() => productRemoveFromCatalog()}>
            Unpublish
          </button>)}
        </section>
      </div>
    </>
  );
};

export default Product;
