
import { useState } from "react";
import { routesPaths } from "constants/routes.constants";
import { useNavigate } from "react-router-dom";
import "./CompanyDetails.css";

const CompanyDetailsFarms = ({ company }) => {
    const navigate = useNavigate();
    const [tab, setTab] = useState(Object.keys(company?.farms).length !== 0 ? Object.keys(company?.farms)[0] : {});
    const [count, setCount] = useState(0);

    return (
        <>
            <div className="mx-4 mb-2 text-2xl font-extrabold text-greenGray">Farms</div>
            <div className="flex gap-12">
                {
                    Object.keys(company?.farms).map((farm, index) => <div>
                        <label
                            key={index}
                            className={
                                tab === farm
                                    ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new mb-2"
                                    : "cursor-pointer font-medium border-b-[.2rem] border-b-black text-black tab-not-active-new mb-2"
                            }
                            onClick={() => { setTab(farm); setCount(index) }}
                        >
                            {farm}
                        </label>

                    </div>)
                }
            </div>
            <div className={"w-full grid tablet:grid-cols-3 mb-3 gap-4 laptop:grid-cols-6 wide:grid-cols-7"}>
                {Object.keys(company?.farms).length !== 0 ? Object.values(company?.farms)[count].map((plot, key) => {
                    return plot?.farm?.name == tab ? (<div
                        key={key}
                        className="cursor-pointer relative w-fit h-fit rounded-lg shadow-lg border-2"
                        onClick={() => {
                            navigate(`${routesPaths.CATALOG_PLOT_DETAIL}/${plot.id}`);
                        }}
                    >
                        <img
                            src={plot?.farm?.photo_default}
                            alt="photo of the plot"
                            className="object-cover w-full h-full aspect-square rounded-lg"
                        />
                        <section className="absolute bg-black/50 text-white left-0 bottom-0 rounded-b-lg flex flex-col w-full h-1/4 p-2 box-img-card">
                            <label className="cursor-pointer font-in-box">{plot?.name}</label>
                            <label className="cursor-pointer font-in-box">{plot?.farm && plot?.farm?.name}</label>
                            <label className="cursor-pointer font-in-box">{plot.crop_name}</label>
                            <label className={(plot) ? (plot.harvest_count_down == 'Harvest date has passed') ? "cursor-pointer font-in-box text-red" : "cursor-pointer font-in-box text-green" : "cursor-pointer font-in-box"}>{plot?.harvest_count_down ? plot.harvest_count_down : "Harvest Count Down"}</label>
                        </section>
                    </div>) : <div className="hidden" />
                }) : 'No available farm under this company'}

            </div>
        </>
    );
};

export default CompanyDetailsFarms;


