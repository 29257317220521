import SnackbarContext from "_helpers/snackbar-context";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { advanceBookingDetail, advanceBookingDetailByBookingNumber } from "../../../features/advance-booking/advanceBookingSlice";

import Footer from "components/footer/footer";
import { Col, Row } from "react-bootstrap";
import DepositProgress from "./DepositProgress";

// import { useSearchParams } from "react-router-dom";
// import {Route, Link, Routes, useParams} from 'react-router-dom';

const DepositResultPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const snackbarCtx = useContext(SnackbarContext);
  const [bookingPlot, setBookingPlot] = useState(null);
  const [paymentMessage, setPaymentMessage] = useState(null);

  const loadDetail = () => {
    dispatch(
      advanceBookingDetailByBookingNumber({
        booking_number: params.booking_number,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
      } else {
        let result = response.payload.data.booking_plot;
        setBookingPlot(result);
      }
    });
  };

  const backToBooking = () => {
    navigate(`/manage-advance-booking/detail/${bookingPlot.id}`);
  };

  useEffect(() => {
    loadDetail();
  }, []);

  useEffect(() => {
    // Check for special case where the status is "Awaiting Deposit" but the payment is still being processed
    if (bookingPlot) {
      if (bookingPlot.status === 3) {
        setPaymentMessage(<>
          <center><FontAwesomeIcon icon={faCircleCheck} color="green" size="6x" /></center><br />
          <p className="text-center mt-1">You have successfully paid for the deposit of Booking <strong>#{(bookingPlot) ? bookingPlot.booking_number : ''}</strong>.</p>
          <p className="text-center mt-1">Full payment is required upon the confirmation of harvest quantity.</p>
          <p className="text-center mt-1">Thank You!</p>
        </>);
      } else if (bookingPlot.status === 1) {
        if (bookingPlot.payment_history?.status == '11') { // Failed
          setPaymentMessage(<>
            <center><FontAwesomeIcon icon={faCircleXmark} color="red" size="6x" /></center><br />
            <p className="text-center mt-1">We're sorry, but it seems that there was an issue processing your payment for the deposit of Booking <strong>#{(bookingPlot) ? bookingPlot.booking_number : ''}</strong>.</p>
            <p className="text-center mt-1">Please double-check the payment details you've entered, including card information and billing address, and try again. If the issue persists, you might want to contact our customer support for assistance.</p>
            <p className="text-center mt-1">We appreciate your patience!</p>
          </>);
        } else if (bookingPlot.payment_history?.status == '22') { // Pending
          setPaymentMessage(<>
            <center><FontAwesomeIcon icon={faCircleExclamation} color="#cc9a06" size="6x" /></center><br />
            <p className="text-center mt-1">Your payment for the deposit of Booking <strong>#{(bookingPlot) ? bookingPlot.booking_number : ''}</strong> is currently pending confirmation by our payment processing partner.</p>
            <p className="text-center mt-1">This may take some time as we wait for approval from the acquirer. We appreciate your patience during this process.</p>
            <p className="text-center mt-1">Should you have any questions or need assistance, please don't hesitate to reach out to our support team.</p>
          </>);
        }
      }
    }
  }, [bookingPlot]);

  return (
    <Row className="container mb-20">
      <Col md={3}></Col>
      <Col md={6}>
        <div className="flex flex-col items-center mt-4">
          <div style={{ minWidth: "80%" }} >
            <DepositProgress bookingPlot={bookingPlot} />
          </div>
        </div>
        <Row className="mt-3">
          <Col md={12}>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
              <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                <div className="w-full max-w">
                  {paymentMessage}
                  <br />
                  <br />
                  <button type="button" className="btn-green" onClick={backToBooking}>
                    Back To My Orders
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={3}>
        <div className="flex flex-col items-center mt-4" >
          <div style={{ minWidth: "80%", height: "17px" }} >
            {/* <DepositProgress/> */}
          </div>
        </div>
        <Row className="mt-3">
          <Col md={12}>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
              <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                <div className="p-4 w-full max-w">
                  <label className="desktop:mr-5">Total Deposit</label>
                  <label>
                    : RM {(bookingPlot) ? bookingPlot.deposit_amount : ''} 20000
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Footer />
    </Row>
  );
};

export default DepositResultPage;
