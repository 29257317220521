import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/support_api";
import  loginService from "./loginService";

export const login = createAsyncThunk(
    "auth/users/login",
    async (credentials, thunkAPI) => {
        try {
            return await loginService.login(credentials);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const loginFB = createAsyncThunk(
    "auth/users/verify-fb-login",
    async (token, thunkAPI) => {
        try {
            return await loginService.FBlogin(token);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const loginGoogle = createAsyncThunk(
    "auth/users/verify-google-login",
    async (token, thunkAPI) => {
        try {
            return await loginService.Googlelogin(token);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

//
// export const currentUser = createAsyncThunk(
//     "auth/users/login",
//     async (credentials, thunkAPI) => {
//         try {
//             return await loginService.login(credentials);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )

const initialState = {
    data: {},
    loading: false,
    isSuccess: false,
    currentUser: {},
    currentCompany: {},
    token: 'asdasd'
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        getCurrentUserInfo: (state) => {
            let user = JSON.parse(localStorage.getItem("user"));

            state.token = user.data.token;
            state.currentUser = user.data.user;
            state.currentCompany = user.data.company;
        },

    },
    extraReducers : {
        [login.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [login.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.token = payload?.data?.token;
            state.currentUser = payload?.data?.user;
            state.currentCompany = payload?.data?.company;
            state.loading=false;
            state.isSuccess = true;
        },
        [login.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

export const { getCurrentUserInfo } = loginSlice.actions

export default loginSlice;