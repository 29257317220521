import AuthLayout from "pages/AuthLayout";
import LoginForm from "../../components/login/LoginForm"
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const LoginPage = () => {
  const { state } = useLocation();
  const { from } = state || {};
  useEffect(() => {
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      // Send a message to the service worker to get the variable
      navigator.serviceWorker.controller.postMessage({ type: 'clearNotif' });
    }
  }, []);
  return (
    <AuthLayout>
      <div className="flex flex-col items-center">
        <LoginForm from={from} />
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
