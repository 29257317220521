import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auctionBiddingDetailBuyer } from "../../../features/auction-bidding/auctionBiddingSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import AccountLayout from "pages/AccountLayout";
import { Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { bidAucPlot } from "features/catalog/plots/catalogPlotsSlice";
import styles from "../../advance-booking/pay-deposit/AdvanceBookingDeposit.module.scss";
import clsx from "clsx";
import { routesPaths } from "constants/routes.constants";

const AuctionBiddingDetailBuyer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const snackbarCtx = useContext(SnackbarContext);
  const [auctionPLot, setAuctionPlot] = useState(null);
  const id = params.auction_id;
  const { currentUser } = useSelector((state) => state.auth);

  // modal auction bidding
  const [modalPlaceBid, setModalPlaceBid] = useState(false);
  const popupCloseModalPlaceBid = () => setModalPlaceBid(false);
  const popupShowModalPlaceBid = () => setModalPlaceBid(true);

  const [msgErrorInModal, setMsgErrorInModal] = useState("");
  const [countDownCurrent, setCountDownCurrent] = useState(null);
  const [bidPriceCurrent, setBidPriceCurent] = useState(0);
  const [bidPriceYou, setBidPriceYou] = useState(0);
  const [totalbidPriceYou, setTotalBidPriceYou] = useState(0);
  const [availableQty, setAvailableQty] = useState(0);

  const loadDetail = async (isSubmit) => {
    setIsLoading(true);
    dispatch(
      auctionBiddingDetailBuyer({
        id: id,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        if (isSubmit) {
          popupCloseModalPlaceBid();
        }
        setIsLoading(false);
      } else {
        let result = response.payload.data.auction_plot;
        setCountDownCurrent(result.time_left);
        setBidPriceCurent(result.high_bid_price);
        setAvailableQty(result.available_quantity);
        setBidPriceYou(parseFloat(result.bid_price_you));
        setAuctionPlot(result);
        if (isSubmit) {
          popupCloseModalPlaceBid();
        }
        setIsLoading(false);
      }
    });
  };

  const submitBidAucPlot = async (event) => {
    setIsLoading(true);
    dispatch(
      bidAucPlot({
        plot_id: (auctionPLot != null) ? auctionPLot.plot_id : null,
        user_id: currentUser.id,
        company_id: currentUser.company.id,
        bid_price: bidPriceYou

      })
    ).then(async function (response) {
      if (response.error && response.error.message == "Rejected") {
        setMsgErrorInModal(response.payload);
        await loadDetail(false);
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
        await loadDetail(true);
      }
    });

    event.preventDefault();
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    if (availableQty != null && bidPriceYou != null) {
      setTotalBidPriceYou(bidPriceYou * availableQty);
    }

  }, [bidPriceYou, availableQty]);

  useEffect(() => {
    if (currentUser) {
      loadDetail();
    }
  }, [currentUser]);

  return (
    <AccountLayout>
      <div className="w-full flex flex-col p-3 laptop:p-6 wide:p-6 mb-20">
        <Row className="mb-2">
          <Col md={6} sm={12}>

            <div className="title-content mb-3">
              Auction : {(auctionPLot) ? (auctionPLot) ? auctionPLot.auction_id : '' : ''}
            </div>
          </Col>
        </Row>
        <div className="w-full max-w bg-white flex flex-col shadow rounded-lg p-4 space-y-4">
          <div className="font-medium" style={{ color: "#395B50", cursor: "pointer" }} onClick={() => navigate(-1)}>
            {"< Go back to list"}
          </div>
          <section className="relative flex flex-col justify-center items-center">
            <img
              src="/assets/images/QRIcon.svg"
              alt="QR icon"
              className={clsx(styles.qrCode)}
            />
            {/* Info */}
            <div className="flex max-w-[100rem] w-full">



              <div className="bg-buttonText text-white w-full flex flex-col p-4 gap-2 rounded-l-lg shadow tablet:h-fit desktop:h-[12rem]">
                <label className="md:text-3xl xs:text-lg font-bold">Farmer</label>
                <label className={clsx(styles.mobileText)}>{(auctionPLot) ? (auctionPLot) ? auctionPLot.plot.farm.name : '' : ''}</label>
                <label className={clsx(styles.mobileText)}>{(auctionPLot) ? (auctionPLot) ? auctionPLot.plot.farm.address : '' : ''}</label>
              </div>

              {/* Customer Information */}
              <div className="bg-formSemiDark text-white w-full flex flex-col p-4 gap-2 rounded-r-lg shadow tablet:h-fit desktop:h-[12rem]">

                <label className="md:text-3xl xs:text-lg font-bold">Buyer</label>
                <label className={clsx(styles.mobileText)}>{(auctionPLot) ? (auctionPLot.buyer) ? auctionPLot.buyer.name : '' : ''}</label>
                <label className={clsx(styles.mobileText)}>{(auctionPLot) ? (auctionPLot.buyer.user_address) ? `${auctionPLot.buyer.user_address.address.address} ${auctionPLot.buyer.user_address.address.city}` : '' : ''}</label>
              </div>
            </div>



            {/* Status */}
            <div className="flex max-w-[100rem] w-full">
              {/* Farmer Status */}
              <div className="w-full h-[12rem] flex flex-col md:p-4 gap-4 items-center mt-4">
                <label className="text-3xl text-center">Status</label>
                <img
                  src={
                    "/assets/images/OrderClock.svg"
                  }
                  alt="status icon"
                  className="w-[4rem] h-[4rem]"
                />
                <label className="text-lg text-gray-600 text-center">
                  {(auctionPLot) ? (auctionPLot) ? auctionPLot.status_farmer_current : '' : ''}
                </label>
              </div>
              {/* Customer Status */}
              <div className="w-full h-fit flex flex-col md:p-4 gap-4 items-center mt-4">
                <label className="text-3xl text-center">Status</label>
                <img
                  src={
                    "/assets/images/OrderClock.svg"
                  }
                  alt="status icon"
                  className="w-[4rem] h-[4rem]"
                />
                <label className="text-lg text-gray-600 text-center">
                  {(auctionPLot) ? (auctionPLot) ? auctionPLot.status_current : '' : ''}
                </label>
              </div>
            </div>
          </section>
          <Row>
            <Col md={4}></Col>
            <Col md={5}>
              {(auctionPLot) ? (auctionPLot.harvest_count_down_full == 'Harvest date has passed') ?
                <>
                  <h4 className="mt-2 text-red text-center"><b>{auctionPLot.harvest_count_down_full}</b></h4>
                </>
                :
                <>
                  <center>
                    <div style={{ display: "inline" }} className="mr-4 text-center lg:float-left">
                      <h4 className="mt-2"><b>Countdown to <br />Harvest</b></h4>
                    </div>
                    <div style={{ display: "inline" }} className="lg:float-left">
                      <table>
                        <tr>
                          <td className="text-center">DAY</td>
                          <td className="pl-2 pr-2"></td>
                          <td className="text-center">HOUR</td>
                          <td className="pl-2 pr-2"></td>
                          <td className="text-center">MIN</td>
                        </tr>
                        <tr>
                          <td className="text-center text-green">{(auctionPLot) ? (auctionPLot.harvest_count_down_full) ? auctionPLot.harvest_count_down_full.days : '' : ''}</td>
                          <td className="pl-2 pr-2 text-green">:</td>
                          <td className="text-center text-green">{(auctionPLot) ? (auctionPLot.harvest_count_down_full) ? auctionPLot.harvest_count_down_full.hours : '' : ''}</td>
                          <td className="pl-2 pr-2 text-green">:</td>
                          <td className="text-center text-green">{(auctionPLot) ? (auctionPLot.harvest_count_down_full) ? auctionPLot.harvest_count_down_full.minutes : '' : ''}</td>
                        </tr>
                      </table>
                    </div>
                  </center>

                </>
                :
                ""
              }
            </Col>
            <Col md={3}></Col>
          </Row>
          <h1 className="text-2xl">Auction Details</h1>
          <ol>
            {auctionPLot && (<>
              <li className={"mt-2 gap-y-2"} >
                <div className="flex tablet:flex-row flex-col gap-x-3 items-center">
                  <img
                    src={auctionPLot.plot.photo_default}
                    alt=""
                    className="  orderLarge:inline min-w-[7rem] max-w-[7rem] min-h-[7rem] max-h-[7rem] object-cover aspect-square rounded-full"
                  />
                  <div className="flex flex-col ml-2">
                    <span><b>Plot Name:</b> {auctionPLot.plot.name}</span>  <span><b>Quantity:</b> {`${auctionPLot.available_quantity} ${auctionPLot.available_quantity_unit}`} </span> <span><b>Bid Price:</b> RM {auctionPLot.bid_price}</span> <span><b>Current Highest Bid:</b> RM {auctionPLot.high_bid_price}</span>
                  </div>
                </div>
              </li>
            </>)}
          </ol>
          <div className="text-center">
            {(auctionPLot) && (
              <>
                {(auctionPLot.status_current == "Bid Placed") && (
                  <>
                    <span style={{ fontSize: 15, color: "red" }}>
                      A bid has been placed. You are currently the bidder with the highest bid
                    </span>
                  </>
                )}
                {(auctionPLot.status_current == "Outbid") && (
                  <>
                    <button
                      className="btn-green w-[210px] mr-auto ml-auto mb-3 p-3"
                      onClick={popupShowModalPlaceBid}
                    >Place a bid</button>
                  </>
                )}

                {(auctionPLot.status_current == "Bid Lost") && (
                  <>
                    <span style={{ fontSize: 15, color: "red" }}>
                      You have lost the bid. No further action required.
                    </span>
                  </>
                )}

                {(auctionPLot.status_current == "Bid Won") && (
                  <>
                    <span style={{ fontSize: 15, color: "red" }}>
                      You have won the auction. Awaiting farmer’s confirmation.
                    </span>
                  </>
                )}
                {(auctionPLot.status_current == "Booking Created") && (
                  <>
                    <span style={{ fontSize: 15, color: "red" }}>
                      Auction has been transferred to ‘Advanced Booking’
                    </span>
                    <center>
                      <button type="button" className="text-center mt-4 btn-green w-fit" onClick={() => { navigate(routesPaths.MANAGE_ADVANCE_BOOKING) }}>
                        Go to Advanced Booking section
                      </button>
                    </center>
                  </>
                )}
              </>
            )}

          </div>
        </div>
      </div>
      <Modal className="modal-large-custom" show={modalPlaceBid} onHide={popupCloseModalPlaceBid}>
        <Modal.Body>
          <Row className="mt-2 mr-4 ml-4">
            <div>
              <label className="cursor-pointer head-bar-nav" onClick={() => {
                popupCloseModalPlaceBid();
              }}>
                <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }}

                />
                {" Go Back"}
              </label>
            </div>
            <div style={{ color: "#0E5934", fontSize: 25, fontWeight: "bold" }}>
              Place your bid
            </div>
            {(msgErrorInModal != "") && (
              <>
                <div style={{ color: "red", fontSize: 15, fontWeight: "bold" }} className="mt-2">
                  {msgErrorInModal}
                </div>
              </>
            )}
            {(countDownCurrent != null) && (
              <>
                <Row className="mt-2">
                  <Col md={12}>
                    <table style={{ width: "40%" }}>
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <div style={{ fontSize: 12 }}>
                            DAY
                          </div>
                          <div style={{ fontSize: 35, fontWeight: "bold" }}>
                            {countDownCurrent.days}
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div style={{ fontSize: 35, fontWeight: "bold" }}>
                            :
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div style={{ fontSize: 12 }}>
                            HOUR
                          </div>
                          <div style={{ fontSize: 35, fontWeight: "bold" }}>
                            {countDownCurrent.hours}
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div style={{ fontSize: 35, fontWeight: "bold" }}>
                            :
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div style={{ fontSize: 12 }}>
                            MIN
                          </div>
                          <div style={{ fontSize: 35, fontWeight: "bold" }}>
                            {countDownCurrent.minutes}
                          </div>
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <div style={{ fontSize: 15, fontWeight: "bold" }}>
                            left
                          </div>
                        </td>

                      </tr>
                    </table>
                  </Col>
                </Row>
              </>
            )}

            <Row className="mt-2">
              <Col md={12}>
                <label style={{ fontSize: 20, marginRight: 5, fontWeight: "bold" }}>Current Bid (MYR): </label>
                <label style={{ color: "#395B50" }}> <span style={{ fontSize: 25 }}> RM  {bidPriceCurrent}</span> <span style={{ fontSize: 10 }}> / {(auctionPLot) ? auctionPLot.start_bidding_price_unit : ''}</span></label>
              </Col>
            </Row>

            {(auctionPLot) ? ((auctionPLot.is_allow_min_bidd_inc == 1) ? (
              <>
                <div>
                  <label style={{ fontSize: 15, marginRight: 3 }}>Minimum Increment Bid (MYR) : </label>
                  <label style={{ fontSize: 15, color: "#0E5934", fontWeight: "bold" }}>{(auctionPLot) ? auctionPLot.mini_bidding_price : ''}</label>
                </div>
              </>
            ) : "") : ""}


            <div>
              <label style={{ fontSize: 20, marginRight: 3, fontWeight: "bold" }}>Your bid </label>
            </div>
            <div>
              <Row style={{ alignItems: "center" }}>
                <Col xs={4}>
                  MYR (RM):
                </Col>
                <Col xs={6} className="ml-0 mr-0 pl-0 pr-0">
                  <InputGroup>
                    {(auctionPLot) ? ((auctionPLot.is_allow_min_bidd_inc == 1) ? (
                      <>
                        <InputGroup.Text id="basic-addon1"><div style={{ cursor: "pointer" }}
                          onClick={() => setBidPriceYou(bidPriceYou - parseFloat(auctionPLot.mini_bidding_price))}
                        >-</div></InputGroup.Text>
                      </>
                    ) : "") : ""}

                    <Form.Control

                      type="number input-search"
                      min="0"
                      style={{ borderRadius: "8px", border: "1px solid #00AF54" }}
                      onChange={({ target }) => {
                        setBidPriceYou(target.value);
                      }}
                      placeholder=""
                      value={bidPriceYou}
                      disabled={(auctionPLot) ? ((auctionPLot.is_allow_min_bidd_inc == 1) ? true : false) : false}
                    />
                    {(auctionPLot) ? ((auctionPLot.is_allow_min_bidd_inc == 1) ? (
                      <>
                        <InputGroup.Text id="basic-addon2"><div style={{ cursor: "pointer" }}
                          onClick={() => setBidPriceYou(bidPriceYou + parseFloat(auctionPLot.mini_bidding_price))}
                        >+</div></InputGroup.Text>
                      </>
                    ) : "") : ""}

                  </InputGroup>
                </Col>
                <Col md={2}>
                  {/* <span style={{ fontSize: 10, verticalAlign: "bottom" }}>
                                        /{plot.available_quantity_unit}
                                    </span> */}
                </Col>
              </Row>
            </div>
            <div className="mt-3">
              <Row>
                <Col md={8} xs={12}>
                  <table>
                    <tr>
                      <td>
                        <div className="hidden tablet:flex">
                          <label style={{ fontSize: 20, marginRight: 3, fontWeight: "bold" }}>Total Payment (MYR): </label>
                        </div>
                        <div className="hidden tablet:flex" style={{ fontSize: 11 }}>
                          (Available Quantity x Your Bid)
                        </div>
                      </td>
                      <td>
                        <label className={clsx(styles.menuMobile)} style={{ fontSize: 20, marginRight: 3, fontWeight: "bold" }}>Total Payment (MYR): </label>
                        <label className={clsx(styles.menuMobile)} style={{ fontSize: 11 }}>(Available Quantity x Your Bid)</label>
                        <label style={{ fontSize: 30, marginLeft: 3, fontWeight: "bold" }} className="text-green">{numberWithCommas(Number(totalbidPriceYou).toFixed(2))}</label>
                      </td>
                    </tr>
                  </table>
                </Col>
                <Col md={4}>
                  <button disabled={isLoading} type="button" style={{ height: 40 }} className="btn-border-green" onClick={() => {
                    if (!isLoading) {
                      submitBidAucPlot();
                    }
                  }}>
                    <label className="cursor-pointer text-lg">
                      {isLoading ? "Loading.." : "Bid"}
                    </label>
                  </button>
                </Col>
              </Row>

            </div>

          </Row>
        </Modal.Body>

      </Modal>
    </AccountLayout>
  );
};

export default AuctionBiddingDetailBuyer;
