import { useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import * as statusConst from 'constants/advanceBooking.constants';

const AdvanceBooking = ({ bookingPlots, currentUser }) => {
    const navigate = useNavigate();

    const detailHandler = (id) => {
        navigate(`/manage-advance-booking/detail/${id}`);
    };

    const getStatusBadge = (bookingPlot) => {
        let bookingStatus = statusConst.bookingPlotStatus[bookingPlot.status];

        // Check for special case where the status is "Awaiting Deposit" but the payment is still being processed
        if (bookingPlot.status === 1 && bookingPlot.payment_history?.status == '22') {
            bookingStatus = statusConst.bookingPlotStatus[13];
        }

        return bookingStatus;
    }

    return (
        <div style={{ overflow: "auto" }}>
            <table className="hidden lg:table table">
                <thead>
                    <tr className="text-center">
                        <td><b>Booking ID</b></td>
                        <td><b>Quantity</b></td>
                        <td><b>Price</b></td>
                        <td><b>Amount Paid</b></td>
                        <td><b>Status</b></td>
                        <td><b>Order ID</b></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {bookingPlots ? bookingPlots.data ? bookingPlots.data.map((bookingPlot, index) => {
                        let bookingStatus = getStatusBadge(bookingPlot);
                        return <tr key={'trBooking' + index}>
                            <td>
                                <div className="flex space-x-4">
                                    <img src={bookingPlot.plot.farm ? bookingPlot.plot.farm.photo_default : "/assets/images/products/WheatIcon.svg"} alt={bookingPlot.plot.farm ? bookingPlot.plot.farm.name : "-"}
                                        className="min-w-[8rem] max-w-[8rem] min-h-[8rem] max-h-[8rem] object-cover aspect-square rounded-full"
                                    />
                                    <div>
                                        <p className="mt-4"><b>{(bookingPlot) ? bookingPlot.booking_number : ''}</b></p>
                                        <p><b> {(bookingPlot) ? bookingPlot.plot.name : ''}</b></p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p className="mt-4 text-center">{(bookingPlot) ? bookingPlot.quantity + ' ' + bookingPlot.quantity_unit : ''}</p>
                            </td>
                            <td>
                                <p className="mt-4 text-center">{(bookingPlot) ? 'RM ' + bookingPlot.total_price : ''}</p>
                            </td>
                            <td>
                                <p className="mt-4 text-center">{(bookingPlot) ? (bookingPlot.status == 3 || bookingPlot.status == 4) ? 'RM ' + bookingPlot.deposit_amount : 'N/A' : 'N/A'}</p>
                            </td>
                            <td>
                                <center>
                                    <Badge className="mt-4 text-center" bg={bookingStatus.badge}>{bookingStatus.label}</Badge>
                                </center>
                            </td>
                            <td>
                                <p className="mt-4 text-center">{(bookingPlot) ? (bookingPlot.status == 4) ? bookingPlot.order.order_number : 'N/A' : 'N/A'}</p>
                            </td>
                            <td>
                                <button className="btn-border-green w-[150px] mr-auto ml-auto mb-3 mt-4" onClick={() => { detailHandler(bookingPlot.id) }}>{(bookingPlot.status == 0 && currentUser.role_active == 'farmer') ? 'Confirm Booking' : (bookingPlot.status == 1 && currentUser.role_active == 'customer') ? 'Pay Deposit' : 'Details'}</button>
                            </td>
                        </tr>
                    }) : '' : ''}
                </tbody>
            </table>
            <div data-testid="order-card" className="lg:hidden hover:ring-2 hover:ring-formDark bg-white rounded-lg w-full overflow-hidden" >
                <div className=" w-full flex flex-col p-2 gap-4 items-center">
                    {bookingPlots && bookingPlots.data ? (bookingPlots.data.map((bookingPlot, index) => {
                        let bookingStatus = getStatusBadge(bookingPlot);
                        return (
                            <div key={'trBooking' + index} style={{ borderBottomWidth: "1px" }}>
                                <div className="lg:hidden w-full flex flex-row p-2 gap-4 items-center justify-center">
                                    <section className="basis-1/4 items-center gap-x-8 ">
                                        <img
                                            // src={orderDetail.product.photo ? info.photo : "/assets/images/products/WheatIcon.svg"}
                                            src={bookingPlot.plot.farm ? bookingPlot.plot.farm.photo_default : "/assets/images/products/WheatIcon.svg"}
                                            alt={bookingPlot.plot.farm ? bookingPlot.plot.farm.name : "-"}
                                            className="min-w-[4rem]  min-h-[4rem]  object-cover aspect-square rounded-full "
                                        />
                                        <label className="lg:hidden text-md font-semibold mt-2">{(bookingPlot) ? bookingPlot.booking_number : ''} </label>
                                        <label className="lg:hidden text-sm font-semibold text-center ">{(bookingPlot) ? bookingPlot.plot.name : ''} </label>
                                    </section>
                                    <section className="flex flex-col gap-2 items-center text-center">

                                        <label className="lg:hidden">
                                            <span className="text-sm font-semibold">Quantity:</span>
                                            <label className="ml-2">{(bookingPlot) ? bookingPlot.quantity + ' ' + bookingPlot.quantity_unit : ''}</label> </label>
                                        <label className="lg:hidden" style={{ textAlign: "center" }}>
                                            <span className="text-sm font-semibold">Price:</span>
                                            <span className="ml-2">{(bookingPlot) ? 'RM ' + bookingPlot.total_price : ''}</span> </label>
                                        <label className="lg:hidden" style={{ textAlign: "center" }}>
                                            <span className="text-sm font-semibold">Amount Paid:</span>
                                            <label className="">{(bookingPlot) ? (bookingPlot.status == 3 || bookingPlot.status == 4) ? 'RM ' + bookingPlot.deposit_amount : 'RM 10000.00' : '-'}</label> </label>
                                        <label className="lg:hidden" style={{ textAlign: "center" }}>
                                            <span className="text-sm font-semibold">Status:</span></label>
                                        <Badge className="mt-[-0.5rem] text-center" style={{ whiteSpace: "normal" }} bg={bookingStatus.badge}>{bookingStatus.label}</Badge>
                                        <label className="lg:hidden" style={{ textAlign: "center" }}>
                                            <span className="text-sm font-semibold">Order ID:</span> </label>
                                        <label className="mt-[-1rem]">{(bookingPlot) ? (bookingPlot.status == 4) ? bookingPlot.order.order_number : 'WGQOEC5JAP99' : '-'}</label>
                                    </section>

                                </div>
                                <button className="lg:hidden btn-border-green w-[150px] mr-auto ml-auto mb-3 flex justify-center" onClick={() => { detailHandler(bookingPlot.id) }}>
                                    {(bookingPlot.status == 0 && currentUser.role_active == 'farmer') ? 'Confirm Booking' : (bookingPlot.status == 1 && currentUser.role_active == 'customer') ? 'Pay Deposit' : 'Details'}</button>
                            </div>
                        );
                    })) : (<></>)}
                </div>
            </div>
        </div>
    );
};

export default AdvanceBooking;
