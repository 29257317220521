import api from "../../support/support_api";

const settingDepositGet = async (params) => {
    const response  = await  api.axiosGet('/setting-deposit', params);

    return response.data;
}

const settingDepositSave = async (params) => {
    const response  = await  api.axiosPost('/setting-deposit/save', params);

    return response.data;
}

const settingRegistrationLimit= async (params) => {
    const response  = await  api.axiosPost('/setting-registration/limit', params);

    return response.data;
}

const settingDepositService = {
    settingDepositSave,
    settingDepositGet,
    settingRegistrationLimit
}


export default settingDepositService;