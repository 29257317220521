import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import advanceBookingService from "./advanceBookingService";

export const advanceBookingList = createAsyncThunk(
    "booking-plots/getData",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.advanceBookingList(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const advanceBookingGeneralList = createAsyncThunk(
    "booking-plots/generalList",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.advanceBookingGeneralList(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const advanceBookingDetail = createAsyncThunk(
    "booking-plots/:id",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.advanceBookingDetail(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const advanceBookingDetailByBookingNumber = createAsyncThunk(
    "booking-plots/getByBookingNumber/:order_number",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.advanceBookingDetailByBookingNumber(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const advanceBookingReject = createAsyncThunk(
    "booking-plots/:id/reject",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.advanceBookingReject(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const advanceBookingApprove = createAsyncThunk(
    "booking-plots/:id/approve",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.advanceBookingApprove(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const advanceBookingPayDeposit = createAsyncThunk(
    "booking-plots/:id/payDeposit",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.advanceBookingPayDeposit(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const advanceBookingCreateOrder = createAsyncThunk(
    "booking-plots/createOrder",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.advanceBookingCreateOrder(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const confirmOfflinePaymentDeposit = createAsyncThunk(
    "booking-plots/confirmOfflinePayment",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.confirmOfflinePaymentDeposit(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const downloadInvoice = createAsyncThunk(
    "booking-plots/download-invoice/:id",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.downloadInvoice(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const uploadPaymentReceipt = createAsyncThunk(
    "booking-plots/uploadPaymentReceipt",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.uploadPaymentReceipt(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const generateInvoiceNumber = createAsyncThunk(
    "booking-plots/:id/generateInvoiceNumber",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.generateInvoiceNumber(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const advanceBookingStatistic = createAsyncThunk(
    "booking-plots/statistic",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.advanceBookingStatistic(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const advanceBookingDelete = createAsyncThunk(
    "booking-plots/delete",
    async (args, thunkAPI) => {
        try {
            return await advanceBookingService.advanceBookingDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)


const initialState = {
    data: {},
    booking_plot: {},
    booking_plots: {
        data: []
    },
    bookingStatistic: {},
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const advanceBookingSlice = createSlice({
    name: 'advanceBooking',
    initialState,
    reducers: {
    },
    extraReducers: {
        [advanceBookingList.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [advanceBookingList.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.booking_plots = payload.data.booking_plots;
            state.loading = false;
            state.isSuccess = true;
        },
        [advanceBookingList.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [advanceBookingGeneralList.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [advanceBookingGeneralList.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.booking_plots = payload.data.booking_plots;
            state.loading = false;
            state.isSuccess = true;
        },
        [advanceBookingGeneralList.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [advanceBookingDetail.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [advanceBookingDetail.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.booking_plot = payload.data.booking_plot;
            state.booking_plots = payload.data.booking_plots;
            state.loading = false;
            state.isSuccess = true;
        },
        [advanceBookingDetail.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [advanceBookingReject.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [advanceBookingReject.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.booking_plots = payload.data.booking_plots;
            state.loading = false;
            state.isSuccess = true;
        },
        [advanceBookingReject.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [advanceBookingApprove.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [advanceBookingApprove.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.booking_plots = payload.data.booking_plots;
            state.loading = false;
            state.isSuccess = true;
        },
        [advanceBookingApprove.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [advanceBookingPayDeposit.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [advanceBookingPayDeposit.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.booking_plots = payload.data.booking_plots;
            state.loading = false;
            state.isSuccess = true;
        },
        [advanceBookingPayDeposit.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [advanceBookingCreateOrder.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [advanceBookingCreateOrder.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.booking_plots = payload.data.booking_plots;
            state.loading = false;
            state.isSuccess = true;
        },
        [advanceBookingCreateOrder.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [confirmOfflinePaymentDeposit.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [confirmOfflinePaymentDeposit.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [confirmOfflinePaymentDeposit.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [downloadInvoice.pending]: (state, { payload }) => {
            // state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [downloadInvoice.fulfilled]: (state, { payload }) => {
            // state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [downloadInvoice.rejected]: (state, { payload }) => {
            // state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [uploadPaymentReceipt.pending]: (state, { payload }) => {
            // state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [uploadPaymentReceipt.fulfilled]: (state, { payload }) => {
            // state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [uploadPaymentReceipt.rejected]: (state, { payload }) => {
            // state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [generateInvoiceNumber.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [generateInvoiceNumber.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [generateInvoiceNumber.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [advanceBookingStatistic.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [advanceBookingStatistic.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.bookingStatistic = payload.data;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [advanceBookingStatistic.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [advanceBookingDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [advanceBookingDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [advanceBookingDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = orderFarmerSlice.actions

export default advanceBookingSlice;