import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import {
  getCurrentUserInfo,
  authUpdate,
  authUpdatePhoto,
  // setCurrentPhoto,
} from "../../features/auth/authSlice";
// import { fileDelete } from "features/file/fileSlice";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccountPersonal = () => {
  //const isRunned = useRef(false);
  const { currentUser, updating } = useSelector((state) => state.auth);
  const [username, setUsername] = useState(currentUser?.username);
  const [firstname, setFirstName] = useState(currentUser?.first_name);
  const [lastname, setLastName] = useState(currentUser?.last_name);
  const [phone, setPhone] = useState(currentUser?.phone_number);
  const [email, setEmail] = useState(currentUser?.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [inputElement, setInputElement] = useState("");
  const snackbarCtx = useContext(SnackbarContext);

  // const [photo, setPhoto] = useState(`${process.env.REACT_APP_BACKEND_ROOT}/assets/images/ProfileIcon.png`);

  const dispatch = useDispatch();

  const submitHandler = (event) => {
    dispatch(
      authUpdate({
        username: username,
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone_number: phone,
        password: password,
        password_confirmation: confirmPassword,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });

    event.preventDefault();
  };

  const onFileChange = (event) => {
    let selectedFile = event.target.files[0];

    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);
    formData.append("store", "single");
    formData.append("model", "user");
    formData.append("type", "photo");
    formData.append("user_id", currentUser.id);

    dispatch(authUpdatePhoto(formData)).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
      }
    });
  };

  // const itemDelete = (photo) => {
  //   if (window.confirm("Are you sure you want to delete this Photo?")) {
  //     dispatch(
  //       fileDelete({
  //         file_id: photo.id,
  //       })
  //     ).then(function (response) {
  //       if (response.error && response.error.message === "Rejected") {
  //       } else {
  //         let message = response.payload.message;
  //         dispatch(authGetUser())
  //       }
  //     });
  //   }
  //   else {
  //   }
  // }

  const userPhotoSrc = typeof currentUser.photo === 'object' ? currentUser.photo.src
    : currentUser.photo;

  // const deletablePhoto = typeof currentUser.photo === 'object' ? true : false;

  useEffect(() => {
    dispatch(getCurrentUserInfo());

    const reloadUser = () => {
      setUsername(currentUser.username);
      setPhone(currentUser.phone_number);
      setEmail(currentUser.email);
    };
    reloadUser();

  }, [currentUser.username]);

  return (
    <form className="self-start flex flex-col gap-4 col-md-8 col-lg-5" onSubmit={submitHandler}>
      <div className="flex items-start ">
        <img
          src={userPhotoSrc}
          alt="profile"
          onClick={() => inputElement.click()}
          className="bg-white w-25 h-25 cursor-pointer object-cover aspect-square border-[.01rem] border-green-400 rounded-md"
        />
        {/* <button type="button" className="w-3/5 space-x-4 hover:bg-green-500 hover:text-white ml-2">
          <img src={uploadIcon} alt="upload button" className="w-6 h-6" onClick={() => inputElement.click()} />
          <label className="cursor-pointer text-sm" onClick={() => inputElement.click()}>
            Upload Image
          </label>
          
        </button> */}
        <input className={"hidden"} type="file" value="" onChange={onFileChange} ref={(input) => setInputElement(input)} accept="image/png, image/jpeg" />
        {/* <button className={`ml-3 ${deletablePhoto ? '' : 'hidden'} `} onClick={(e) => {
          e.preventDefault();
          itemDelete(currentUser.photo)
        }}>
          <FontAwesomeIcon icon={faTrashCan} className={"text-red-400"} />
        </button> */}
      </div>
      <div className="flex flex-col space-y-6">
        <section className="flex flex-col space-y-1">
          <label>Username:</label>
          <input
            type="text"
            className="input-search"
            placeholder={currentUser.username}
            value={username}
            onChange={({ target }) => setUsername(target.value)}
          />
        </section>
        <section className="flex justify-between content-center">
          <div className="mr-2">
            <label>First Name:</label>
            <input
              type="text"
              className="input-search"
              placeholder={currentUser.first_name}
              value={firstname}
              onChange={({ target }) => setFirstName(target.value)}
            />
          </div>
          <div className="ml-2">
            <label>Last Name:</label>
            <input
              type="text"
              className="input-search"
              placeholder={currentUser.last_name}
              value={lastname}
              onChange={({ target }) => setLastName(target.value)}
            />
          </div>
        </section>
        <section className="flex flex-col space-y-1">
          <label>Phone Number:</label>
          <input
            type="text"
            className="input-search"
            placeholder={currentUser.phone_number}
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
          />
        </section>
        <section className="flex flex-col space-y-1">
          <label>Email Address: </label>
          <input
            type="email"
            className="input-search"
            placeholder={currentUser.email}
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
        </section>
        <section className="flex flex-col space-y-1">
          <label>Password: </label>
          <input
            type="password"
            className="input-search"
            placeholder={password}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
          <small className={"text-danger"}>(Leave empty to update the rest of the fields.)</small>
        </section>
        <section className="flex flex-col space-y-1">
          <label>Confirm Password:  </label>
          <input
            type="password"
            className="input-search"
            placeholder={confirmPassword}
            value={confirmPassword}
            onChange={({ target }) => setConfirmPassword(target.value)}
          />
        </section>
        <div className="row">
          <div className="col-md-4">
            <button type="submit" className="saveButton">
              {updating ? "Processing, please wait.." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccountPersonal;
