import AuthLayout from "pages/AuthLayout";
import RegisterProgress from "components/register/RegisterProgress";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./register.module.scss";
import clsx from "clsx";

const RegisterPage = () => {
  const location = useLocation();

  let progress = 1;
  if (location.pathname === "/register/details") {
    progress = 1;
  } else if (location.pathname === "/register/company") {
    progress = 2;
  } else if (location.pathname === "/register/type") {
    progress = 3;
  } else if (location.pathname === "/register/subscribe") {
    progress = 4;
  } else {
    progress = 5;
  }

  return (
    <AuthLayout>
      <div className="flex flex-col items-center">
        <div className={clsx(styles.minWidthProgress, "flex", "flex-col")} >
          {location.pathname !== "/register/closed" && <RegisterProgress />}
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div data-testid="register" className={clsx((progress === 1 || progress === 2 || progress === 5)  ? styles.bgFormRegister : "", "flex", "flex-col", progress === 4 ? "" : "p-8", "space-y-6")} >
          <Outlet />
        </div>
      </div>

    </AuthLayout>
  );
};

export default RegisterPage;
