import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "pages/AccountLayout";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { billingShow, billingUploadPaymentReceipt, downloadBill } from "features/billing/billingSlice";
import { Spinner } from 'react-bootstrap';
import md5 from 'md5';
import clsx from "clsx";
import cartStyle from "../../cart/CartSidebar.module.scss";
import TermsOfUse from "components/home/TermsOfUse";

const BillingDetail = () => {
  const params = useParams();
  const { bill } = useSelector((state) => state.billing);
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState(1);
  const [agreeTermCondition, setAgreeTermCondition] = useState(0);
  const [showTerms, setTermsModal] = useState(false);
  const [vcode, setVCode] = useState(null);

  const loadBill = () => {
    setLoading(true);
    dispatch(
      billingShow({
        id: params.id,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        dispatch(
          downloadBill({
            id: response?.payload?.data?.bill.invoice_number
          })
        ).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
          } else {
            let result = response.payload;
            const file = result;
            const fileURL = URL.createObjectURL(file);
            setFileUrl(fileURL);
          }
        });
      }
    });
    setLoading(false);
  }

  const downloadBillHandler = () => {
    setLoading(true);
    dispatch(
      downloadBill({
        id: bill.invoice_number
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
      } else {
        let result = response.payload;
        const file = result;
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      }
    });

    setLoading(false);
  };

  const handleClickProceed = () => {
    setLoading(true);
    if (paymentType) {
      if (agreeTermCondition) {
        if (paymentType == 0) {
          navigate(`'bill/${bill?.id}/offline-payment`);
        }
        else {
          setVCode(md5((parseFloat(bill.total_amount)) + process.env.REACT_APP_RAZER_MERCHANT_ID + "BILLING_" + bill?.invoice_number + process.env.REACT_APP_RAZER_VERIFY_KEY));

          setTimeout(() => {
            if (window.confirm("Are you sure want to pay for this bill?")) {
              document.getElementById("razerForm").submit();
            }
          }, 1000);
        }
      }
      else {
        alert('Please agree with terms & conditions');
      }
    } else {
      alert('Please select payment method');
    }

    setLoading(false);
  };

  useEffect(() => {
    loadBill();
  }, []);

  return (
    <>
      <PageLayout>
        <div data-testid="order-list" className="flex flex-col items-start gap-6 p-4">
          <label className="cursor-pointer head-bar-nav font-regular font-semibold text-sm" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
            {" Go Back"}
          </label>
          <h1 className="text-3xl text-left font-semibold font-regular" style={{ color: "#395B50" }}>Billing Detail</h1>
          <div className="flex justify-end w-full">
            <button onClick={() => downloadBillHandler()} className="saveButton w-fit"><FontAwesomeIcon icon={faPrint} className="mr-2" />Print</button>
          </div>
          <div className="flex w-full space-x-4">
            <div className="w-full max-w bg-white items-center border-2 p-8 rounded-lg font-regular space-y-6">
              {isLoading ? <section><Spinner animation="border" variant="light" size="lg" >
                <span className='visually-hidden'>Loading...</span>
              </Spinner><span className="mt-2" style={{ fontSize: "15px", color: "white" }}>
                  Loading...
                </span>
              </section> :
                <div>
                  <embed src={fileUrl} type="application/pdf" width="100%" height="600px" />
                </div>}
            </div>
            {bill?.status === 1 && <div className="w-1/4 h-fit bg-white items-center border-2 p-8 rounded-lg font-regular space-y-6">
              <div className="text-lg">Subtotal: {bill?.current_charge}</div>
              <div className="text-lg">Tax total: 0</div>
              <label className="underline" />
              <div className="text-xl font-bold">Tax total: {bill?.current_charge}</div>
              <section>
                <select className="input-search" defaultValue={1} value={paymentType} onChange={({ target }) => { setPaymentType(target.value); }}>
                  <option key={"paymentMethod-1"} value={null}>
                    Select Payment Method
                  </option>
                  {/* <option key={"paymentMethod0"} value={0}>Offline</option> */}
                  <option key={"paymentMethod1"} value={1}>Online</option>
                </select>
              </section>
              <section>
                <div className="flex sm:items-center lg:items-start">
                  <input
                    type="checkbox"
                    name="agree_term_condition"
                    className="mr-3 lg:mt-1"
                    value={agreeTermCondition}
                    onChange={({ target }) => {
                      setAgreeTermCondition((agreeTermCondition == 0) ? 1 : 0);
                    }}
                  />
                  <label className="text-sm">I've read and agree to <span className="text-green" style={{ cursor: "pointer" }}
                    onClick={() => setTermsModal(true)}>Terms & Conditions</span> </label>
                </div>
              </section>
              <button type="button" className="btn-green" onClick={(event) => {
                event.preventDefault();
                handleClickProceed();
              }}>{isLoading ? 'Processing, please wait..' : "Pay bill"}
              </button>
            </div>}
          </div>
        </div>
      </PageLayout>
      <form id="razerForm" action={process.env.REACT_APP_RAZER_PAYMENT_URL} method="POST">
        <input type="hidden" name="merchant_id" value={process.env.REACT_APP_RAZER_MERCHANT_ID} />
        <input type="hidden" name="amount" value={parseFloat(bill.current_charge)} />
        <input type="hidden" name="orderid" value={'BILLING_' + bill?.invoice_number} />
        <input type="hidden" name="bill_name" value={currentUser?.first_name} />
        <input type="hidden" name="bill_email" value={currentUser?.email} />
        <input type="hidden" name="bill_mobile" value={currentUser?.phone_number} />
        <input type="hidden" name="bill_desc" value={'BILLING_' + bill?.invoice_number} />
        <input type="hidden" name="returnurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-billing-return"} />
        <input type="hidden" name="callbackurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-billing-callback"} />
        <input type="hidden" name="vcode" value={vcode} />
        <input type="submit" className={clsx(cartStyle.hideVisibility)} />
      </form>
      {showTerms && (
        <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
          <section data-testid="popup-layout" className="absolute bg-white w-4/5 h-3/4 flex flex-col rounded-xl shadow pb-4">
            <div className="bg-white w-full h-full flex flex-col rounded-t-xl px-8 py-4 overflow-y-auto">
              <TermsOfUse />
            </div>
            <div className="w-full flex gap-4 justify-center mt-2">
              <button className="borderedButton w-2/5" onClick={() => setTermsModal(false)}>
                Close
              </button>
            </div>
          </section>
        </div>
      )}
    </>
  );
};
export default BillingDetail;
