import React from 'react';
import { useState } from 'react';
import styles from './CustomSliderDemand.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import clsx from 'clsx';

export default function CustomImageDemands(props) {

    const [imageIndex, setImageIndex] = useState(1);

    const nextSlide = () => {
        if (imageIndex !== props.photos.data.length) {
            setImageIndex(imageIndex + 1)
        } else if (imageIndex === props.photos.data.length) {
            setImageIndex(1)
        }
    }

    const prevSlide = () => {
        if (imageIndex !== 1) {
            setImageIndex(imageIndex - 1)
        } else if (imageIndex === 1) {
            setImageIndex(props.photos.data.length)
        }
    }

    const changeImage = (i) => {
        setImageIndex(i);
    }

    return (
        <>
            <Row>
                <Col md={12} className="title-content mb-4">
                    {props.photos.data.length > 0 && (
                        <>
                            <img
                                src={props.photos.data[0].src}
                                alt="product"
                                className={styles.sliderImageNew}
                            />
                        </>
                    )}

                </Col>

            </Row>
            <Row>

                {props.photos.data.length > 0 && props.photos.data.map((obj, index) => {
                    if (index > 0 && index <= 3) {
                        return (
                            <>
                                <Col md={3} className="title-content">
                                    <div
                                        onClick={() => {
                                            if ((index == 3)) {
                                                props.setIsShowPopupImage(true);
                                            }

                                        }}
                                        className={clsx((index == 3) ? styles.bgColorImageNewRel : "")}>
                                        <img
                                            src={obj.src}
                                            alt="product"
                                            className={styles.sliderImageSubNew}
                                        />
                                        <div className={clsx((index == 3) ? (styles.bgColorImageNewAbs) : "")}>
                                            {(index == 3) && (
                                                <>
                                                    <div style={{ display: "table", height: "100%", width: "100%", overflow: "hidden" }}>
                                                        <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                                                            <div style={{ fontSize: "35px", color: "#FFFFFF" }}>
                                                                +{props.photos.data.length - 4}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "20px" }}>

                                    </div>
                                </Col>
                            </>

                        )
                    }

                })}
            </Row>
        </>
    )
}

