import React from 'react';
import styles from './PhotoModal.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose} from '@fortawesome/free-solid-svg-icons';


function PhotoModal(props) {
 
  const clickedImage = props.imageSet.data[props.photoIndex];
 
  return (
    <div className= {styles.overlay} >
        <div className = {styles.modal} >
        <img src={clickedImage.url} alt = "farm"/>
        <FontAwesomeIcon icon= {faClose}  className = {styles.close} onClick = {props.closePhotoModal} />
        </div>
    </div>
  )
}

export default PhotoModal;