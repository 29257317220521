import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fileUpdate, fileShow } from "../../features/file/fileSlice";
import SnackbarContext from "_helpers/snackbar-context";

import AccountLayout from "pages/AccountLayout";
import { productUpdate } from "../../features/product/productSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import "./PickMapLocation.css";
import { LayersControl, MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

const PickMapLocation = ({ curentLatMap, curentLngMap, curentLatMark, curentLngMark, saveLat, saveLng }) => {
    const LATITUDE_DEFAULT = 1.966773;
    const LONGITUDE_DEFAULT = 102.881449;

    const [latitude, setLatitude] = useState(LATITUDE_DEFAULT);
    const [longitude, setLongitude] = useState(LONGITUDE_DEFAULT);
    const [markerLatitude, setMarkerLatitude] = useState(LATITUDE_DEFAULT);
    const [markerlongitude, setMarkerLongitude] = useState(LONGITUDE_DEFAULT);

    const SearchField = () => {
        const provider = new OpenStreetMapProvider();
        // @ts-ignore
        const searchControl = new GeoSearchControl({
            provider: provider,
            showMarker: false,
        });

        const map = useMap();
        useEffect(() => {
            map.addControl(searchControl);
            map.invalidateSize(true);
            return () => map.removeControl(searchControl);
        }, []);

        return null;
    };

    const DraggableMarker = ({ centerLocationPick }) => {
        const [draggable, setDraggable] = useState(true);
        const [position, setPosition] = useState(centerLocationPick);
        const markerRef = useRef(null);
        const eventHandlers = useMemo(
            () => ({
                dragend() {
                    const marker = markerRef.current
                    if (marker != null) {
                        var locati = null;
                        locati = marker.getLatLng();
                        setMarkerLatitude(locati.lat.toFixed(6));
                        setMarkerLongitude(locati.lng.toFixed(6));
                        saveLat(locati.lat.toFixed(6));
                        saveLng(locati.lng.toFixed(6));
                        setPosition({ lat: locati.lat.toFixed(6), lng: locati.lng.toFixed(6) });

                    }
                },
            }),
            [],
        );

        return (
            <Marker
                draggable={draggable}
                eventHandlers={eventHandlers}
                position={position}
                ref={markerRef}>
                <Popup minWidth={90}>
                    <span>
                        {position.lat}, {position.lng}
                    </span>
                </Popup>
            </Marker>
        );
    }

    const MapEvents = () => {
        useMapEvents({
            click(e) {
                // setState your coords here
                // coords exist in "e.latlng.lat" and "e.latlng.lng"
                setMarkerLatitude(e.latlng.lat.toFixed(6));
                setMarkerLongitude(e.latlng.lng.toFixed(6));
                saveLat(e.latlng.lat.toFixed(6));
                saveLng(e.latlng.lng.toFixed(6));
            },
        });
    }

    useEffect(() => {
        setLatitude(curentLatMap);
        setLongitude(curentLngMap);
        setMarkerLatitude(curentLatMark);
        setMarkerLongitude(curentLngMark);
    }, [curentLatMap, curentLngMap, curentLatMark, curentLngMark]);

    return (
        <>
            <MapContainer center={[(((curentLatMap == 0) || (curentLatMap == null) || (curentLatMap == undefined)) ? LATITUDE_DEFAULT : curentLatMap), (((curentLngMap == 0) || (curentLngMap == null) || (curentLngMap == undefined)) ? LONGITUDE_DEFAULT : curentLngMap)]} zoom={13} scrollWheelZoom={false}>
                <SearchField />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <DraggableMarker centerLocationPick={{
                    lat: markerLatitude,
                    lng: markerlongitude,
                }} />
                <MapEvents />
                <LayersControl position="topright">
                    <LayersControl.Overlay name="Satellite">
                        <TileLayer
                            url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                            maxZoom={20}
                            subdomains={['mt1', 'mt2', 'mt3']}
                        />
                    </LayersControl.Overlay>
                </LayersControl>
            </MapContainer>
        </>
    );
};

export default PickMapLocation;
