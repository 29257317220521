import api from "../../support/support_api";

const companyRatingStore = async (params) => {
    const response  = await  api.axiosPost('/company-rating', params);

    return response.data;
}

const companyRatingUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/company-rating/${params.id}`, params);

    return response.data;
}

const companyRatingDelete = async (params) => {
    const response  = await  api.axiosDelete(`/company-rating/${params.id}`, params);

    return response.data;
}

const companyRatingGetAvg= async (params) => {
    const response  = await api.axiosGet(`/company-rating/${params.company_id}`, params);

    return response.data;
}


const companyRatingService = {
    companyRatingStore,
    companyRatingUpdate,
    companyRatingDelete,
    companyRatingGetAvg
}

export default companyRatingService;