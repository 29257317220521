const PopupLayout = ({ children }) => {
  return (
    <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
      <section
        data-testid="popup-layout"
        className="absolute bg-formLight w-[30rem] flex flex-col rounded-xl shadow"
      >
        <div className="bg-green-500 w-full flex flex-col items-center rounded-t-xl p-8">
          <img src="/assets/images/CheckmarkIcon.svg" alt="checkmark icon" className="w-[7rem] h-[7rem]" />
        </div>
        <div className="self-center w-3/4 flex flex-col p-8 gap-8 text-center items-center">{children}</div>
      </section>
    </div>
  );
};

export default PopupLayout;
