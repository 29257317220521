import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  varietyService from "./varietyService";

export const varietyIndex = createAsyncThunk(
    "variety/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await varietyService.varietyIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const varietyStore = createAsyncThunk(
    "variety/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await varietyService.varietyStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const varietyDelete = createAsyncThunk(
    "variety/delete",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await varietyService.varietyDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const varietyUpdate = createAsyncThunk(
    "variety/update",
    async (args, thunkAPI) => {
        try {
            return await varietyService.varietyUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const varietyUploadPhoto = createAsyncThunk(
    "variety/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await varietyService.varietyUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const varietyShow = createAsyncThunk(
    "variety/:id/show",
    async (args, thunkAPI) => {
        try {
            return await varietyService.varietyShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    variety: {},
    varieties: {
        data : [

        ]
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const varietySlice = createSlice({
    name: 'variety',
    initialState,
    reducers: {
        varietySet: (state, newVariety) => {
            console.log(newVariety);
            state.variety = newVariety.payload;
        },
    },
    extraReducers : {
        [varietyStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [varietyStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.variety = payload.data.variety;
            state.loading=false;
            state.isSuccess = true;
        },
        [varietyStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [varietyUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [varietyUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [varietyUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [varietyShow.pending]:(state, {payload}) => {
            state.data = {};
            state.variety = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [varietyShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.varieties = payload.data.varieties;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [varietyShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [varietyIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [varietyIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.variety = payload.data.variety;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [varietyIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export const { varietySet } = varietySlice.actions

export default varietySlice;