import React, { useEffect, useState, useContext } from "react";
import "./HomeForm.css";
import taniExchangeLogo from "images/home/taniexchange.png";
import { useTranslation } from "react-i18next";
import { height } from "@mui/system";
import styles from "./Intro.module.scss";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { getPrivacyPolicy, getReturnRefund, getTermUse } from "features/asset/assetSlice";
import SnackbarContext from "_helpers/snackbar-context";
import TermsOfUse from "./TermsOfUse";
import ReturnRefundPolicy from "./ReturnRefundPolicy";
import PrivacyPolicy from "./PrivacyPolicy";

const Sitemap = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const [privacyPolicyURL, setPrivacyPolicyURL] = useState("")
  const [refundReturnURL, setRefundReturnURL] = useState("")
  const [termUseURL, setTermUseURLL] = useState("")

  const loadPDF = () => {
    dispatch(
      getPrivacyPolicy()
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        setPrivacyPolicyURL(response.payload.url)
      }
    });

    dispatch(
      getReturnRefund()
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        setRefundReturnURL(response.payload.url)
      }
    });

    dispatch(
      getTermUse()
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        setTermUseURLL(response.payload.url)
      }
    });
  }
  const [isDelete, setDelete] = useState(false);
  function openPopup(url) {
    const width = 800; // Set the desired width of the popup window
    const height = 600; // Set the desired height of the popup window
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
  
    // Specify the window features
    const popupFeatures = `
      width=${width},
      height=${height},
      left=${left},
      top=${top},
      resizable=yes,
      scrollbars=yes,
      status=yes,
      toolbar=yes,
      menubar=no,
      location=no`;
  
    // Open the popup window with the specified features
    window.open(url, 'Popup', popupFeatures);
  }
  
  const [showTerms, setTermsModal] = useState(false);
  const [showRefund, setRefundModal] = useState(false);
  const [showPrivacy, setPrivacyModal] = useState(false);
  
  useEffect(() => {
    loadPDF();
  }, []);

  return (
    <>
    {showTerms && (
        <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
          <section data-testid="popup-layout" className="absolute bg-white w-4/5 h-3/4 flex flex-col rounded-xl shadow pb-4">
            <div className="bg-white w-full h-full flex flex-col rounded-t-xl px-8 py-4 overflow-y-auto">
              <TermsOfUse />
            </div>
            <div className="w-full flex gap-4 justify-center mt-2">
              <button className="borderedButton w-2/5"  onClick={() => setTermsModal(false)}>
                Close
              </button>
            </div>
          </section>
        </div>
      )}
    {showRefund && (
      <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
        <section data-testid="popup-layout" className="absolute bg-white w-4/5 h-3/4 flex flex-col rounded-xl shadow pb-4">
          <div className="bg-white w-full h-full flex flex-col rounded-t-xl px-8 py-4 overflow-y-auto">
          {/* <iframe
              src={termUseURL} // Replace with the actual path to your PDF file
              title="PDF Viewer"
              width="100%"
              height="100%"
            /> */}
            <ReturnRefundPolicy />
          </div>
          <div className="w-full flex gap-4 justify-center mt-2">
            <button className="borderedButton w-2/5"  onClick={() => setRefundModal(false)}>
              Close
            </button>
          </div>
        </section>
      </div>
    )}
    {showPrivacy && (
      <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
        <section data-testid="popup-layout" className="absolute bg-white w-4/5 h-3/4 flex flex-col rounded-xl shadow pb-4">
          <div className="bg-white w-full h-full flex flex-col rounded-t-xl px-8 py-4 overflow-y-auto">
            <PrivacyPolicy />
          </div>
          <div className="w-full flex gap-4 justify-center mt-2">
            <button className="borderedButton w-2/5"  onClick={() => setPrivacyModal(false)}>
              Close
            </button>
          </div>
        </section>
      </div>
    )}
    <div className="flex justify-center items-center flex-col bg-white m-11">
      <img src={taniExchangeLogo} className={clsx(styles.FooterLogo)} />
      <div className="font-bold text-lg mt-3 ml-14" style={{ color: "#395B50" }}>{t('harvestConnectThrive')}</div>
      <div className={clsx("flex justify-around font-semibold mt-10", styles.FooterDetails)} style={{ color: "#395B50" }}>
        <div className="cursor-pointer mx-2"><a href="https://singularityaerotech.asia/about/" target="_blank">{t('aboutUs')}</a></div>
        {/* <div className="cursor-pointer mx-2"><a href={termUseURL} target="_blank">{t('termsOfUse')}</a></div> */}
        {/* <div className="cursor-pointer mx-2"><a href="#" onClick={() => openPopup(termUseURL)} target="_blank">{t('termsOfUse')}</a></div> */}
        <div className="cursor-pointer mx-2" onClick={() => setTermsModal(true)}>{t('termsOfUse')}</div>
        <div className="cursor-pointer mx-2" onClick={() => setRefundModal(true)}>{t('returnRefund')}</div>
        <div className="cursor-pointer mx-2" onClick={() => setPrivacyModal(true)}>{t('privacyPolicy')}</div>
        <div className="cursor-pointer mx-2"><a href="https://singularityaerotech.asia/contact/" target="_blank">{t('contact')}</a></div>
      </div>
      <div className="text-xl font-medium mt-10" style={{ color: "#395B50" }}>{t('trademark')}</div>
      <div className="text-sm font-medium mt-1" style={{ color: "#395B50" }}>{"Version 1.0.0"}</div>
      
    </div>
    </>);
};

export default Sitemap;
