import React, { useState, useContext } from "react";
import { useEffect } from "react";
import AccountLayout from "pages/AccountLayout";
import { productStore } from "../../features/product/productSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, InputGroup, Form } from "react-bootstrap";
import { unitOfMeasureIndex } from "features/unit-of-measure/unitOfMeasureSlice";
import clsx from "clsx";
import { companyCropIndex } from "features/company-crop/companyCropSlice";
import { gradeProductStore } from "features/grade-product/gradeProductSlice";
import { fileUpload } from "features/file/fileSlice";
import { catalogStore } from "features/catalog/catalogSlice";

const ProductCreate = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);
    const [name, setName] = useState("");
    const [includeDeliveryFee, setIncludeDeliveryFee] = useState(1);
    const [deliveryChargeType, setDeliveryChargeType] = useState(0);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const [showInProduce, setShowInProduce] = useState(0);
    const [isEnablePriceQuote, setEnablePriceQuote] = useState(0)
    const [grades, setGrade] = useState([{
        unit_id: "",
        base_price: "",
        selling_price: "",
        available_qty: "",
        photo: null,
        image: null,
        is_thumbnail: 0,
        name: "",
    }]);
    const { updating } = useSelector((state) => state.product);
    const { currentCompany } = useSelector((state) => state.auth);
    const { unitOfMeasures } = useSelector((state) => state.unitOfMeasure);
    const { companyCrops } = useSelector((state) => state.companyCrop);
    const [inputElement, setInputElement] = useState("");
    const [cropId, setCropId] = useState("");
    const [includeFixedPercentage, setIncludeFixedPercentage] = useState(1);

    let companyPackage = currentCompany?.company_packages?.data.find(companyPackage => companyPackage.status === 1);

    const save = (event) => {
        dispatch(
            productStore({
                farm_id: null,
                name: name,
                company_crop_id: cropId,
                delivery_charge: parseFloat(deliveryCharge),
                delivery_charge_type: deliveryChargeType,
                show_in_produce: showInProduce,
                enable_price_quote: isEnablePriceQuote,
                include_delivery_charge: includeDeliveryFee
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                let product = response.payload.data.product;

                if (showInProduce === 1) {
                    productSaveToCatalog(product.id);
                }

                for (let index = 0; index < grades.length; index++) {
                    dispatch(
                        gradeProductStore({
                            product_id: product.id,
                            unit_id: parseInt(grades[index].unit_id),
                            base_price: grades[index].base_price,
                            selling_price: grades[index].selling_price,
                            available_qty: grades[index].available_qty,
                            image: null,
                            is_thumbnail: grades[index].is_thumbnail,
                            name: grades[index].name,
                        })
                    ).then(function (response) {
                        if (response.error && response.error.message === "Rejected") {
                            snackbarCtx.displayMsg(response.payload, "failure")
                        } else {
                            if (grades[index].photo !== null) {
                                uploadPhoto(grades[index].image, response?.payload?.data?.gradeProduct)
                            }

                            if (grades.length === index + 1) {
                                navigate(`/products`);
                            }
                        }
                    });
                }
            }
        });

        event.preventDefault();
    };

    const loadCompanyCrops = () => {
        dispatch(
            companyCropIndex({
                page: 1,
                limit: 100,
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            }
        });
    }

    const loadUnitOfMeasure = () => {
        dispatch(
            unitOfMeasureIndex()
        )
    }

    const addGrade = () => {
        setGrade([...grades, {
            unit_id: "",
            base_price: "",
            selling_price: "",
            available_qty: "",
            photo: null,
            image: null,
            is_thumbnail: 0,
            name: "",
        }])
    }

    const deleteGradeArray = (index) => {
        let filteredArray = grades.filter((item, i) => i !== index)
        setGrade(filteredArray);
    }

    const updateGradeArray = (index) => (e) => {
        if (companyPackage) {
            const newArray = grades.map((item, i) => {
                if (index === i) {
                    if (e.target.name === 'base_price') {
                        let base_price = parseFloat(e.target.value).toFixed(2);
                        let selling_price = (parseFloat(e.target.value) * parseFloat(companyPackage.service_charge) / 100) + parseFloat(e.target.value);
                        return { ...item, selling_price: parseFloat(selling_price.toFixed(2)), base_price: base_price, [e.target.name]: e.target.value }
                    }
                    if (e.target.name === 'is_thumbnail') {
                        let new_is_thumbnail = (parseInt(item.is_thumbnail) === 0) ? 1 : 0;
                        return { ...item, is_thumbnail: new_is_thumbnail }
                    }
                    if (e.target.name === 'photo') {
                        let selectedFile = e.target.files[0];
                        let imageUrl = URL.createObjectURL(e.target.files[0]);
                        return { ...item, image: selectedFile, photo: imageUrl }
                    } else {
                        return { ...item, [e.target.name]: e.target.value };
                    }
                }
                else {
                    return item;
                }
            });

            setGrade(newArray);
        } else {
            alert("You haven't purchased a subscription package yet, please subscribe to take this action");
        }
    };

    const uploadPhoto = (selectedFile, grade) => {
        const formData = new FormData();

        formData.append("file", selectedFile, selectedFile.name);
        formData.append("store", "single");
        formData.append("model", "gradeProduct");
        formData.append("type", "photo");
        formData.append("model_id", grade.id);

        dispatch(fileUpload(formData)).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                alert(response.payload);
            }
        });
    };

    const productSaveToCatalog = productId => {
        dispatch(
            catalogStore({
                product_id: productId,
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            }
        });
    };

    useEffect(() => {
        loadCompanyCrops();
        loadUnitOfMeasure();
    }, []);

    return (
        <AccountLayout>
            <div data-testid="product-create" className="flex flex-col h-full max-h items-start gap-6 p-4 mb-5" >
                <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
                    {" Go Back"}
                </label>
                {/* Title */}
                <h1 className="text-center text-2xl font-bold" style={{ color: "#395B50", marginTop: -20 }}>Add Product</h1>
                {/* Widget */}
                <div className="w-full max-w bg-white p-4 rounded-lg shadow min-h-[30rem]">
                    <div className="grid laptop:grid-cols-4 desktop:grid-cols-5 gap-4">
                        <form className="w-full max-w place-self-center flex flex-col space-y-5 col-span-6" onSubmit={save}>
                            <section className="w-full flex items-center">
                                <div className="lg:w-auto lg:mr-4 w-full flex items-center flex-wrap">
                                    <label className="lg:w-auto lg:mr-1 w-full">Product Name:</label>
                                    <input type="text" placeholder={name} onChange={({ target }) => { setName(target.value) }} className={"lg:w-[18rem] w-full input-search"} />
                                </div>
                                <div className="lg:flex w-1/3 items-center hidden">
                                    <input
                                        type="checkbox"
                                        name="show_in_produce"
                                        value={showInProduce}
                                        onChange={() => {
                                            setShowInProduce((showInProduce == 0) ? 1 : 0);
                                        }}
                                    />
                                    &nbsp;&nbsp;
                                    <label>Show in Produce</label>
                                </div>
                            </section>
                            <section className="w-full flex items-center flex-wrap">
                                <label className="lg:w-auto lg:mr-1">Crop & Variety:</label>
                                {
                                    (
                                        companyCrops?.data && companyCrops?.data.length > 0 ?
                                            (<select
                                                className="lg:w-[18rem] w-full input-search"
                                                defaultValue={""}
                                                value={cropId}
                                                onChange={({ target }) => {
                                                    setCropId(target.value)
                                                }} >
                                                <option value="">
                                                    Select Crop
                                                </option>
                                                {companyCrops?.data.length > 0 && companyCrops?.data.map((crop, index) => {
                                                    return (
                                                        <option value={crop?.id} key={index}>{crop.master_crop?.name} - {crop.variety}</option>
                                                    )
                                                })}
                                            </select>)
                                            :
                                            <span>No crops available.</span>
                                    )
                                }
                            </section>
                            <div className="lg:hidden w-full items-center">
                                <input
                                    type="checkbox"
                                    name="show_in_produce"
                                    value={showInProduce}
                                    onChange={() => {
                                        setShowInProduce((showInProduce == 0) ? 1 : 0);
                                    }}
                                />
                                &nbsp;&nbsp;
                                <label>Show in Produce</label>
                            </div>
                            <section>
                                <label className="w-1/6">Grades: </label>
                                <div className="overflow-y-scroll desktop:h-[22rem] tablet:max-h-[70rem] w-full px-3 pt-2 pb-4">
                                    {grades.length > 0 && grades.map((grade, index) => <div className={clsx("bg-lightGray p-4 rounded-lg shadow border-main desktop:min-h-[20rem]", index !== 0 ? "mt-4" : "")} key={index}>
                                        <div className="flex justify-between">
                                            <div>Grade {index + 1}</div>
                                            {index !== 0 && <FontAwesomeIcon icon={faTrashCan} className="text-lg text-greenGray" onClick={() => deleteGradeArray(index)} />}
                                        </div>
                                        <div className="flex flex-wrap w-full mt-2">
                                            <div className="desktop:w-2/12 w-full">
                                                {grade.photo === null ?
                                                    <button
                                                        type="button"
                                                        accept="image/png, image/jpeg"
                                                        className="w-full w-m h-full flex flex-col justify-center items-center bg-white rounded-md border-[.01rem] border-green-500 space-y-2"
                                                        onClick={() => inputElement.click()}
                                                    > :
                                                        <FontAwesomeIcon icon={faPlus} className={"change-icon-color-green"} />
                                                    </button> : <img
                                                        src={grade.photo}
                                                        alt="profile"
                                                        onClick={() => inputElement.click()}
                                                        className="w-full w-m h-auto flex flex-col cursor-pointer object-cover aspect-square border-[.01rem] border-green-400 rounded-md"
                                                    />}
                                                <input className={"hidden"} type="file" name="photo" onChange={updateGradeArray(index)} ref={(input) => setInputElement(input)} accept="image/png, image/jpeg" />
                                                <div className="flex items-center mt-3"><input
                                                    type="checkbox"
                                                    value={grade.is_thumbnail}
                                                    name="is_thumbnail"
                                                    onChange={updateGradeArray(index)}
                                                />
                                                    &nbsp;&nbsp;
                                                    <label className="text-sm">Use this as thumbnail</label>
                                                </div>
                                            </div>
                                            <div className="desktop:w-5/12 desktop:pl-7 mt-14 desktop:mt-0 w-full">
                                                <div className="text-greenGray">Grade Name</div>
                                                <input className="w-full input-search" value={grade.name} onChange={updateGradeArray(index)} name="name" />
                                                <div className="text-greenGray mt-3 small-input">Base Price</div>
                                                <input className="w-full input-search" type="number" step="0.01" value={grade.base_price} onChange={updateGradeArray(index)} name="base_price" />
                                                <div className="text-greenGray mt-3">Available Quantity</div>
                                                <input className="w-full input-search" value={grade.available_qty} onChange={updateGradeArray(index)} name="available_qty" />
                                            </div>
                                            <div className="desktop:w-5/12 desktop:pl-7 md:w-full sm:w-full">
                                                <div className="text-greenGray">Unit</div>
                                                <div className="text-greenGray"><select className="input-search w-full" defaultValue={"DEFAULT"} value={grade.unit} name="unit_id" onChange={updateGradeArray(index)}>
                                                    <option value="DEFAULT" disabled>
                                                        Select Unit
                                                    </option>
                                                    {unitOfMeasures.data.map((unit, index) => (
                                                        <option value={unit.id} key={index}>{unit.name}</option>
                                                    ))}
                                                </select></div>
                                                <div className="text-greenGray mt-3 small-input">Selling Price (Include {companyPackage?.service_charge}%)</div>
                                                <input className="w-full input-search bg-gray-200 text-gray-600" type="number" step="0.01" value={grade.selling_price} name="selling_price" onChange={updateGradeArray(index)} disabled />
                                            </div>

                                        </div>
                                    </div>)}
                                </div>
                                <div className="flex justify-end mr-2">
                                    <button type="button" className="saveButton rounded-md lg:w-1/4" onClick={() => addGrade()}>
                                        <FontAwesomeIcon icon={faPlus} className="mr-3" />
                                        Add Grade
                                    </button>
                                </div>
                            </section>
                            <label>Include Delivery Charges?</label>
                            <label className="text-green italic" style={{ fontSize: "15px" }}>*If you choose to include delivery charges you will be required to pay for delivery fee</label>
                            <Row className="mt-2 mb-2">
                                <Col md={3} style={{ width: '3cm' }}>
                                    <input
                                        type="radio"
                                        name="include_delivery_fee"
                                        value={1}
                                        checked={includeDeliveryFee == 1}
                                        onChange={({ target }) => { setIncludeDeliveryFee(target.value); }}
                                    /> Yes
                                </Col>
                                <Col md={3} style={{ width: '3cm' }}>
                                    <input
                                        type="radio"
                                        name="include_delivery_fee"
                                        value={0}
                                        checked={includeDeliveryFee == 0}
                                        onChange={({ target }) => { setIncludeDeliveryFee(target.value); }}
                                    /> No
                                </Col>
                            </Row>
                            {(includeDeliveryFee == 0 && currentCompany?.delyva_integration) && (<div className="flex w-full mt-1">
                                <input
                                    type="checkbox"
                                    name="show_in_produce"
                                    className="mt-1 text-sm"
                                    value={isEnablePriceQuote}
                                    onChange={() => {
                                        setEnablePriceQuote((isEnablePriceQuote == 0) ? 1 : 0);
                                        if (isEnablePriceQuote == 0) {
                                            setIncludeFixedPercentage(0);
                                        } else {
                                            setIncludeFixedPercentage(1);
                                        }
                                    }}
                                />
                                &nbsp;&nbsp;
                                <label className="text-sm mt-1">Enable buyer to quote delivery charge</label>
                            </div>)}
                            {(includeDeliveryFee == 0 && includeFixedPercentage == 1) && (
                                <>
                                    <label>Delivery Charge:</label>
                                    <Row className="mt-2 mb-2">
                                        <Col md={6} xs={7}>
                                            <InputGroup>
                                                {(deliveryChargeType != 1) && (
                                                    <>
                                                        <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setDeliveryCharge(deliveryCharge - 1)}>-</div></InputGroup.Text>
                                                        <Form.Control
                                                            type="number"
                                                            min="0"
                                                            placeholder=""
                                                            className="text-center text-green input-group-plot"
                                                            value={deliveryCharge}
                                                            onChange={({ target }) => setDeliveryCharge(parseInt(target.value))}
                                                        />
                                                    </>
                                                )}
                                                {(deliveryChargeType == 1) && (
                                                    <>
                                                        <InputGroup.Text id="basic-addon1">RM</InputGroup.Text>
                                                        <Form.Control
                                                            type="number"
                                                            min="0"
                                                            placeholder=""
                                                            className="input-group-custom form-control"
                                                            value={deliveryCharge}
                                                            onChange={({ target }) => setDeliveryCharge(parseInt(target.value))}
                                                        />
                                                    </>
                                                )}
                                                {(deliveryChargeType != 1) && (
                                                    <>
                                                        <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setDeliveryCharge(deliveryCharge + 1)}>+</div></InputGroup.Text>
                                                    </>
                                                )}
                                            </InputGroup>
                                        </Col>
                                        <Col md={6} xs={5}>
                                            <select className="input-search text-green" defaultValue={"DEFAULT"} value={deliveryChargeType} onChange={({ target }) => setDeliveryChargeType(target.value)}>
                                                <option value="DEFAULT">
                                                    Select Type..
                                                </option>
                                                <option value="1">Fix Amount</option>
                                                <option value="2">%</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <div className="flex">
                                <button type="submit" className="saveButton rounded-md w-1/6">
                                    {updating ? "Processing, please wait.." : "Save"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AccountLayout>
    );
};

export default ProductCreate;
