import AccountLayout from "pages/AccountLayout";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams, } from "react-router-dom";
import { orderShow, orderUpdate } from "features/order/orderSlice";
import * as statusConst from "constants/normalOrder.constants";
import * as bookStatusConst from "constants/advanceBooking.constants";
import { advanceBookingDetail } from "features/advance-booking/advanceBookingSlice";
import { auctionBiddingDetail } from "features/auction-bidding/auctionBiddingSlice";
import { DateTime } from "luxon";

const ManageOrdersEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const { updating } = useSelector((state) => state.auth);
  const { order } = useSelector((state) => state.order);
  const { booking_plot } = useSelector((state) => state.advanceBooking);
  const { auction_plot } = useSelector((state) => state.auctionBidding);
  const [paymentMethod, setPaymentMethod] = useState(order?.payment_method || "");
  const [auctionPlotBidders, setAuctionPlotBidders] = useState(null);
  const params = useParams();

  const loadOrder = () => {
    dispatch(
      orderShow({
        order_id: params?.id
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      }
    })
  }

  const loadBooking = () => {
    dispatch(
      advanceBookingDetail({
        id: params?.id,
      })
    );
  }

  const loadAuction = () => {
    dispatch(
      auctionBiddingDetail({
        id: params?.id,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
      } else {
        let result = response.payload.data.auction_plot;
        setAuctionPlotBidders(result);
      }
    });
  }

  const update = (event) => {
    dispatch(
      orderUpdate({
        order_id: params?.id,
        payment_method: paymentMethod
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });

    event.preventDefault();
  };

  useEffect(() => {
    if (params?.type === 'Incoming') {
      loadOrder();
    } else if (params?.type === 'Advanced') {
      loadBooking();
    } else {
      loadAuction();
    }

  }, []);

  return (
    <AccountLayout>
      <div className="flex flex-col items-start gap-2 p-4">
        <h1 className="pb-2 text-3xl font-regular font font-semibold" style={{ color: "#395B50" }}>Order - {params?.type === 'Incoming' ? 'Incoming Order' : params?.type === 'Advanced' ? 'Advanced Booking' : 'Auction Bidding'}</h1>
        <div className="w-full max-w bg-white flex flex-col items-start rounded-lg shadow gap-4 p-4">
          <label className="cursor-pointer head-bar-nav font-regular font-semibold text-sm" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
            {" Go Back"}
          </label>
          <h1 className="text-xl font-regular font font-semibold" style={{ color: "#395B50" }}>Buyer's Detail</h1>
          <u></u>
          <div className="row font-regular text-sm" style={{ width: "100%" }}>
            <form className="self-start flex flex-col gap-4 col-md-12" onSubmit={update}>
              <div className="flex sm:flex-wrap laptop:flex-nowrap justify-between col-md-12 space-x-2">
                <div className="col-md-7">
                  <section className="flex flex-col space-y-1">
                    <label>Buyer's Name:</label>
                    <input
                      type="text"
                      className="input-search bg-gray-400"
                      placeholder={order?.customer?.name || booking_plot?.buyer?.name || auction_plot?.buyer?.name}
                      value={order?.customer?.name || booking_plot?.buyer?.name || auction_plot?.buyer?.name}
                      disable
                    />
                  </section>
                  {params?.type !== 'Auction' && <section className="flex flex-col space-y-1 mt-3">
                    <label>Payment Type:</label>
                    <input
                      type="text"
                      className="input-search bg-gray-400"
                      placeholder={params?.type === 'Advanced' ? 'N/A' : order?.payment_history === 0 ? 'Offline' : 'Online'}
                      value={params?.type === 'Advanced' ? 'N/A' : order?.payment_history === 0 ? 'Offline' : 'Online'}
                      disabled
                    />
                  </section>}
                  {params?.type === 'Incoming' && <section className="flex flex-col space-y-1 mt-3">
                    <label>Payment Status:</label>
                    <select
                      value={paymentMethod}
                      className="input-search"
                      onChange={({ target }) => setPaymentMethod(parseInt(target.value))}
                    >
                      <option value="Select Payment Status" disabled>
                        {"Select Payment Status"}
                      </option>
                      <option value={0}>Unpaid</option>
                      <option value={1}>Paid</option>
                    </select>
                  </section>}
                </div>
                {params?.type === 'Incoming' && <div className="col-md-3 self-end">
                  <button type="submit" className="saveButton">
                    {updating ? "Processing, please wait.." : "Save"}
                  </button>
                </div>}
              </div>
            </form>
          </div>
          <h1 className="text-l font-regular font font-semibold" style={{ color: "#395B50" }}>Order Detail</h1>
          <u></u>
          {params?.type === 'Advanced' && <section className="flex flex-col space-y-1">
            <label>Farmer's Name:</label>
            <input
              type="text"
              className="input-search bg-gray-400"
              placeholder={booking_plot?.plot?.farmer?.name}
              value={booking_plot?.plot?.farmer?.name}
              disabled
            />
          </section>}
          <div className="w-full">
            {params?.type !== 'Auction' && <div className="w-full max-w bg-main flex rounded-t-lg justify-between border-y-[.1rem]">
              <div className="basis-1/6 flex items-center justify-center font-medium">Item</div>
              <div className="basis-1/6 flex items-center justify-center font-medium">Product Name</div>
              <div className="basis-1/6 flex items-center justify-center font-medium">Farmer's Name</div>
              <div className="basis-1/6 flex items-center justify-center font-medium">Quantity</div>
              <div className="basis-1/6 flex items-center justify-center font-medium">Total Price (RM)</div>
              <div className="basis-1/6 flex items-center justify-center font-medium">Status</div>
            </div>}
            {params?.type === 'Auction' && <div className="w-full max-w bg-main flex rounded-t-lg justify-between border-y-[.1rem]">
              <div className="basis-1/6 flex items-center justify-center font-medium">Ranking</div>
              <div className="basis-1/6 flex items-center justify-center font-medium">Bid Price (MYR)</div>
              <div className="basis-1/6 flex items-center justify-center font-medium">Total Bid Price (MYR)</div>
              <div className="basis-1/6 flex items-center justify-center font-medium">Bid Time</div>
              <div className="basis-1/6 flex items-center justify-center font-medium">Auction ID</div>
              <div className="basis-1/6 flex items-center justify-center font-medium">Bidder</div>
            </div>}
            {params?.type === 'Incoming' && order?.details?.data.length > 0 && order?.details?.data.map((orderDetail, index) => (<div className="w-full max-w bg-white flex justify-between border-y-2">
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{index + 1}</div>
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{orderDetail?.product?.name}</div>
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{orderDetail?.farmer?.name}</div>
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{orderDetail?.quantity}</div>
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{orderDetail?.total_price_formatted}</div>
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem] px-2">{statusConst.normalOrderStatus[order.status]?.label_for_order_management}</div>
            </div>))}
            {params?.type === 'Advanced' && <div className="w-full max-w bg-white flex justify-between border-y-2">
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">1</div>
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{booking_plot?.plot?.product?.name}</div>
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{booking_plot?.plot?.farmer?.name}</div>
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{booking_plot?.quantity}</div>
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{booking_plot?.total_price}</div>
              <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem] px-2">{bookStatusConst.bookingPlotStatus[booking_plot.status]?.label}</div>
            </div>}
            {params?.type === 'Auction' && auctionPlotBidders?.auction_bid_plots.map((listValue, index) => {
              return (<div key={index} className="w-full max-w bg-white flex justify-between border-y-2">
                <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{index + 1}</div>
                <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{listValue?.bid_price}</div>
                <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{listValue?.total_bid_price}</div>
                <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{DateTime.fromSQL(listValue?.bid_time).toFormat('dd/LL/yyyy hh:mm a')}</div>
                <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem]">{listValue?.auction_id}</div>
                <div className="basis-1/6 flex items-center justify-center font-medium border-x-[.1rem] px-2">{listValue?.user?.name}</div>
              </div>);
            })}
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};

export default ManageOrdersEdit;
