import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "pages/AccountLayout";
import SnackbarContext from "_helpers/snackbar-context";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { companyShow } from "features/company/companySlice";

const CompanySubscriptionList = () => {
  const params = useParams();
  const { company } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const navigate = useNavigate();

  const loadCompany = () => {
    dispatch(
      companyShow({
        company_id: params?.id
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      }
    });
  }

  useEffect(() => {
    loadCompany();
  }, []);

  return (
    <>
      <PageLayout>
        <div data-testid="order-list" className="flex flex-col items-start gap-6 p-4">
          <label className="cursor-pointer head-bar-nav font-regular font-semibold text-sm" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
            {" Go Back"}
          </label>
          <h1 className="text-3xl text-left font-semibold font-regular" style={{ color: "#395B50" }}>Subscription History</h1>
          <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "0px", width: "100%" }}>
            <div className="flex w-full justify-end">
              <form className="tablet:flex justify-start w-full desktop:w-fit">
                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 laptop:hidden desktop:hidden">Search</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                  </div>
                  <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500" placeholder="Search" onChange={({ target }) => { loadCompany(target.value) }} />
                </div>
              </form>
            </div>
          </div>
          <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg font-regular">
            <div className="p-2 w-full max-w rounded-t-lg" style={{ backgroundColor: "#00AF54", color: "white" }}>
              <div className="hidden laptop:flex flex-row items-center justify-center text-sm text-semibold font-regular">
                <div className="flex items-center justify-center font-medium"></div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Plan Subscribed</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Subscription Start Date</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Subscription End Date</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Subscription Period</div>
                {/* <div className="basis-1/4 flex items-center justify-center font-medium">Action</div> */}
              </div>
            </div>
            <div className="p-2 w-full max-w mt-1">
              {company?.company_packages?.data.length > 0 && company?.company_packages?.data.map(item => {
                return <div className="hidden laptop:flex flex-row items-center justify-center pb-2 font-light text-sm" key={item.id}>
                  <div className="basis-1/4 flex items-center justify-center font-medium">{item.package?.package_name}</div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">{DateTime.fromSQL(item?.subs_start_date).toFormat('dd/LL/yyyy')}</div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">{DateTime.fromSQL(item?.subs_end_date).toFormat('dd/LL/yyyy')}</div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">{item?.package?.package_period_format === 'Months' ? 'Monthly' : 'Yearly'}</div>
                  {/* <div className="basis-1/4 flex items-center justify-center font-medium space-x-3">
                    <FontAwesomeIcon icon={faFileLines} className="text-xl text-main" />
                  </div> */}
                </div>
              })}
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};
export default CompanySubscriptionList;
