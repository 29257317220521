import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  gradeService from "./gradeService";

export const gradeIndex = createAsyncThunk(
    "grades/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await gradeService.gradeIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const gradeStore = createAsyncThunk(
    "grades/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await gradeService.gradeStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const gradeUpdate = createAsyncThunk(
    "grades/update",
    async (args, thunkAPI) => {
        try {
            return await gradeService.gradeUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const gradeUploadPhoto = createAsyncThunk(
    "grades/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await gradeService.gradeUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const gradeShow = createAsyncThunk(
    "grades/:id/show",
    async (args, thunkAPI) => {
        try {
            return await gradeService.gradeShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const gradeDelete = createAsyncThunk(
    "grades/:id/delete",
    async (args, thunkAPI) => {
        try {
            return await gradeService.gradeDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    grade: {},
    grades: [],
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}


export const gradeSlice = createSlice({
    name: 'grade',
    initialState,
    reducers: {
        gradeClear: (state) => {
            state.grade = {}
        },
    },
    extraReducers : {
        [gradeStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [gradeStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.grade = payload.data.grade;
            state.loading=false;
            state.isSuccess = true;
        },
        [gradeStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [gradeUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [gradeUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [gradeUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [gradeShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [gradeShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.grade = payload.data.grade;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [gradeShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [gradeDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [gradeDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.grade = payload.data.grade;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [gradeDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [gradeIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [gradeIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.grades = payload.data.grades;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [gradeIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export const {  gradeClear } = gradeSlice.actions

export default gradeSlice;