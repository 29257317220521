
export function parse_query_string (query)  {
    var vars = query.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        var key = decodeURIComponent(pair.shift());
        var value = decodeURIComponent(pair.join("="));
        // If first entry with this name
        if (typeof query_string[key] === "undefined") {
            query_string[key] = value;
            // query_string[key] = value;
            // If second entry with this name
        } else if (typeof query_string[key] === "string") {
            var arr = [query_string[key], value];
            query_string[key] = arr;
            // If third or later entry with this name
        } else {
            query_string[key].push(value);
        }
    }
    return query_string;
}

export function get_current_query_string() {
     let query = window.location.search.substring(1);
     let qs =  parse_query_string(query);

     return qs;
}

export function set_current_query_string_filter(query) {
    const params = new URLSearchParams();

    params.append("searchBy", query.searchBy);
    params.append("searchPlotBy", query.searchPlotBy);
    params.append("filter", query.selectedFilter);
    params.append("search", query.input);

    return params;
 }