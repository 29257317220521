import { useState, useEffect } from "react";

import AccountLayout from "pages/AccountLayout";
import Application from "./Application";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { demandFarmerApplications } from "../../../features/demand/demandSlice";
import MessageEmpty from "../../ui/message/empty/MessageEmpty"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountAsc, faSortDown, faSearch } from '@fortawesome/free-solid-svg-icons';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const ApplicationList = () => {
  const [dummyApplications, setDummyApplications] = useState([
    {
      product_photo: "/assets/images/products/BananasPhoto.svg",
      customer_photo: "/assets/images/StockGuy1.jpg",
      demand_number: 1,
      name: "Bananas",
      grade: "Grade A",
      weight: 150,
      unit: "KG",
      timeframe: "Week",
      location: "California",
      schedule: "Delivery Every Thursday",
      status: "Accepted",
    },
    {
      product_photo: "/assets/images/products/OrangesPhoto.svg",
      customer_photo: "/assets/images/StockGuy2.svg",
      demand_number: 2,
      name: "Oranges",
      grade: "U.S. No.1",
      weight: 100,
      unit: "LBS",
      timeframe: "One Off",
      location: "Florida",
      schedule: "Once",
      status: "Pending",
    },
    {
      product_photo: "",
      customer_photo: "",
      demand_number: 3,
      name: "Spinach",
      grade: "",
      weight: 0,
      unit: "",
      timeframe: "",
      location: "",
      schedule: "",
      status: "",
    },
    {
      product_photo: "/assets/images/products/DuriansPhoto.svg",
      customer_photo: "/assets/images/StockGuy4.jpg",
      demand_number: 4,
      name: "Durians",
      grade: "U.S. No.2",
      weight: 65,
      unit: "KG",
      timeframe: "Month",
      location: "Texas",
      schedule: "Delivery Every 1st Monday",
      status: "Ongoing",
    },
  ]);


  const reduxDemand = useSelector((state) => state.demand);


  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [demandList, setDemandList] = useState({
    data: []
  })
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initial value, you can change this
  const [currentPage, setCurrentPage] = useState(1);
  const loadDemandFarmerApplications = (pageNumber, itemsPerPage) => {
    dispatch(
      demandFarmerApplications({
        limit: itemsPerPage,
        page: pageNumber,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        alert(response.payload);
      } else {
        let demands = response.payload.data.demands;
        setDemandList(demands);
      }
    });
  }

  const handlePageChange = (newPageNumber, newItemsPerPage) => {
    setCurrentPage(newPageNumber);
    setItemsPerPage(newItemsPerPage); // Assuming you have an itemsPerPage state variable

    // Now you can call your data loading function with the updated values
    loadDemandFarmerApplications(newPageNumber, newItemsPerPage);
  };

  const handleChangePage = (event, newPageNumber) => {
    handlePageChange(newPageNumber, itemsPerPage); // Call the common function
  };

  const handleLimitPage = (newItemsPerPage) => {
    setCurrentPage(1); // Reset the current page to 1
    setItemsPerPage(newItemsPerPage); // Update the itemsPerPage state variable
    loadDemandFarmerApplications(1, newItemsPerPage);
  };

  useEffect(() => {
    loadDemandFarmerApplications()
  }, []);

  // only run if the farmId was updated
  // useEffect(() => {
  //     if(farmId) {
  //         setGradeId("")
  //
  //         loadGrades()
  //     }
  // }, [farmId]);

  return (
    <AccountLayout>
      <div data-testid="application-list" className="flex flex-col items-start gap-6 p-4 mb-5">
        <h1 className="text-2xl font-bold" style={{ color: "#395B50" }}>My Applications</h1>
        <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
          <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
            <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
              Sort
            </div>
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
              <select className="select-custom mr-2">
                <option value="DATE" selected>
                  Date
                </option>
                <option value="DATE" selected>
                  Price
                </option>
              </select>
              <FontAwesomeIcon icon={faSortAmountAsc} />
            </div>
            <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
              Filter
            </div>
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
              <select className="select-custom ">
                <option value="DATE" selected>
                  Farm
                </option>
                <option value="DATE" selected>
                  Product
                </option>
              </select>
            </div>

            <div className="flex items-center tablet:flex justify-start w-full desktop:w-fit">
              <form className="tablet:flex justify-start w-full desktop:w-fit items-center">
                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 dark:text-white laptop:hidden desktop:hidden mr-5">Search</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                  </div>
                  <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                </div>
              </form>
            </div>

          </div>
          <div className="p-4 w-full max-w">
            <div className="hidden laptop:flex flex-row items-center justify-center pb-3" style={{ borderBottomWidth: "1px" }}>
              <div className="basis-1/4 flex items-center justify-center font-medium">Demands</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Details</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Status</div>
              <div className="basis-1/4 flex items-center justify-center font-medium"></div>
            </div>
            {reduxDemand.demands && reduxDemand.demands.data.map((demand, index) => (
              <Application loadDemandFarmerApplications={loadDemandFarmerApplications} demand={demand} key={index} />
            ))}
          </div>

        </div>
        <div className="grid sm:grid-rows-2 md:grid-cols-2 w-full">
          <div className="flex items-center tablet:flex lg:justify-start justify-center w-full">
            <span>
              <select className="select-custom" onChange={(e) => handleLimitPage(e.target.value)}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={1000}>All</option>
              </select>
            </span>
            <span className="ml-2 text-sm">
              <label>entries per page</label>
            </span>
          </div>
          <div className="flex lg:justify-end justify-center">
            <Stack spacing={2} >
              <Pagination count={demandList.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={currentPage} />
            </Stack>
          </div>
        </div>
        <MessageEmpty loading={reduxDemand.loading} length={reduxDemand.demands.data.length} message={reduxDemand.data.message} />

      </div>
    </AccountLayout>
  );
};

export default ApplicationList;
