
const getAuth = async () => {
    return JSON.parse(localStorage.getItem("user"));
}


/**
 *  import  storage from "../../support/storage";
 *  let token = await storage.getToken();
 *
 * @param params
 * @returns {Promise<string|*|executor|CancelToken|string|any>}
 */
const getToken = async (params) => {
    let user = await JSON.parse(localStorage.getItem("user"));

    return user.data.token
}

const setUser = async (params) => {
    // let user = await JSON.parse(localStorage.getItem("user"));
    localStorage.setItem("user", JSON.stringify(params));

    const user = await JSON.parse(localStorage.getItem("user"));

    return user;
}

const setUserClear = async (params) => {
    localStorage.setItem("user", null);

    const user = await localStorage.getItem("user");

    return user;
}


const getConfig = async (params) => {
    let user = await JSON.parse(localStorage.getItem("user"));

    let token = null;

    if(user != null) {
        token = user.data.token;
    }

    let config = {
        headers: {
            'Authorization': 'Bearer ' +  token
        }
    }

    return config;
}

const support_storage = {
    getToken, getAuth, getConfig, setUser, setUserClear
}

export default support_storage;