import { useState } from "react";
import { routesPaths } from "constants/routes.constants";
import { useNavigate } from "react-router-dom";
import ProductPlotWidget from "./ProductPlotWidget";
import { useEffect } from "react";

const ProductPlot = () => {
  const navigate = useNavigate();
  const [plot, setPlot] = useState("DEFAULT");

  const dummyPlots = [
    { name: "Plot 1" },
    { name: "Plot 2" },
    { name: "Plot 3" },
    { name: "Plot 4" },
    { name: "Plot 5" },
  ];

  const plots = dummyPlots;

  useEffect(() => {
  }, [plot]);

  const dummyPlotInfo = {
    product_id: 1,
    plot_id: 1,
    crop_name: "Bananas",
    crop_season: "Summer",
    planting_start: "",
    harvest_start: "",
    harvest_end: "",
    target_field: "",
    photos: [
      {
        photo: "/assets/images/plots/PlotPhoto1.svg",
        date: { day: 25, month: 5, year: 2022 },
        text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic error est fugit culpa nostrum, impedit temporibusdignissimos, consectetur modi quis fugiat obcaecati magni esse incidunt aspernatur officiis accusamus totam cum.",
      },
      {
        photo: "/assets/images/plots/PlotPhoto2.svg",
        date: { day: 2, month: 7, year: 2022 },
        text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic error est fugit culpa nostrum, impedit temporibusdignissimos, consectetur modi quis fugiat obcaecati magni esse incidunt aspernatur officiis accusamus totam cum.",
      },
      {
        photo: "/assets/images/plots/PlotPhoto3.svg",
        date: { day: 17, month: 3, year: 2022 },
        text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic error est fugit culpa nostrum, impedit temporibusdignissimos, consectetur modi quis fugiat obcaecati magni esse incidunt aspernatur officiis accusamus totam cum.",
      },
    ],
  };

  const info = dummyPlotInfo;

  return (
    <div className="bg-gray-50 h-screen w-screen self-center flex flex-col p-4">
      {/* Flex Column */}
      <div className="w-full max-w-[60rem] self-center flex flex-col gap-4">
        {/* Navigation */}
        <label
          className="cursor-pointer font-medium"
          onClick={() => navigate(`${routesPaths.CATALOG}/${info.product_id}/show`)}
        >{`< Go Back To Product`}</label>
        <div className="flex flex-col items-center gap-4">
          {/* Plot Details */}
          <section className="bg-formDark w-full flex flex-col p-4 gap-4 rounded-lg shadow">
            <label className="text-2xl text-center">Plot {info.plot_id} Details</label>
            <div className="flex flex-col tablet:flex-row justify-center items-center gap-4 text-center">
              <label className="indentedText max-w-[20rem] w-full">
                {info.crop_name ? info.crop_name : "Crop Name"}
              </label>
              <label className="indentedText max-w-[20rem] w-full">
                {info.harvest_start ? info.harvest_start : "Start of Harvest"}
              </label>
            </div>
            <div className="flex flex-col tablet:flex-row justify-center items-center gap-4 text-center">
              <label className="indentedText max-w-[20rem] w-full">
                {info.crop_season ? info.crop_season : "Crop Season"}
              </label>
              <label className="indentedText max-w-[20rem] w-full">
                {info.harvest_end ? info.harvest_end : "End of Harvest"}
              </label>
            </div>
            <div className="flex flex-col tablet:flex-row justify-center items-center gap-4 text-center">
              <label className="indentedText max-w-[20rem] w-full">
                {info.planting_start ? info.planting_start : "Start Planting"}
              </label>
              <label className="indentedText max-w-[20rem] w-full">
                {info.target_field ? info.target_field : "Target Field"}
              </label>
            </div>
          </section>
          <select
            className="max-w-[20rem] w-full self-start"
            value={plot}
            onChange={({ target }) => setPlot(target.value)}
          >
            <option value="DEFAULT" disabled>
              Choose Plot
            </option>
            {plots.map((plot, index) => (
              <option value={plot.name} key={index}>
                {plot.name}
              </option>
            ))}
          </select>
          <div className="grid gap-4 justify-items-center grid-cols-1 productPlotWidgetSmall:grid-cols-2 productPlotWidgetLarge:grid-cols-3">
            {info.photos.map((photo, index) => (
              <ProductPlotWidget info={photo} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPlot;
