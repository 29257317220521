import { useLocation, useNavigate } from "react-router-dom";

const FarmControls = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex gap-4">
      {location.pathname !== "/farms/widgets" && (
        <button type="button" className="max-w-[12rem] btn-border-green" onClick={() => navigate("/farms/widgets")}>
          Widget View
        </button>
      )}
      {location.pathname !== "/farms/map" && (
        <>
          {location.pathname !== "/farms/create" && (
            <button type="button" className="max-w-[12rem] btn-border-green" onClick={() => {
              window.location = `/farms/map`;
              // navigate("/farms/map")
            }}>
              Map View
            </button>
          )}

          {/* <button type="button" className="max-w-[12rem] btn-border-green">
            Crop & Stock
          </button> */}
        </>

      )}
      {/* {location.pathname !== "/farms/crop-stock" && (
          <button
            type="button"
            className="max-w-[15rem] saveButton"
            onClick={() => navigate("/farms/crop-stock")}
          >
            Crop & Stock
          </button>
        )} */}
    </div>
  );
};

export default FarmControls;
