import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "pages/AccountLayout";
import SnackbarContext from "_helpers/snackbar-context";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { creditManagementShow, creditManagementUpdate } from "features/credit-management/creditManagementSlice";
import { creditManagementHistoryStore } from "features/credit-management-history/creditManagementHistorySlice";

const CreditManagementEdit = () => {
  const params = useParams();
  const { creditManagement } = useSelector((state) => state.creditManagement);
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [autoAddCredit, setAutoAddCredit] = useState(creditManagement?.is_auto_add_credit || 0.00);
  const [autoAddCreditAmount, setAutoAddCreditAmount] = useState(creditManagement?.auto_add_credit_amount || 0.00);
  const [autoAddCreditStartDate, setAutoAddCreditStartDate] = useState("");
  const [autoAddCreditEndDate, setAutoAddCreditEndDate] = useState("");
  const [autoDeductCredit, setAutoDeductCredit] = useState(creditManagement?.is_auto_deduct_credit || 0.00);
  const [autoDeductCreditAmount, setAutoDeductCreditAmount] = useState(creditManagement?.auto_deduct_credit_amount || 0.00);
  const [autoDeductCreditStartDate, setAutoDeductCreditStartDate] = useState(creditManagement?.deduct_credit_start_date || "");
  const [autoDeductCreditEndDate, setAutoDeductCreditEndDate] = useState(creditManagement?.deduct_credit_end_date || "");
  const [autoBilling, setAutoBilling] = useState(creditManagement?.is_auto_billing || 0);
  const [autoBillingStartDate, setAutoBillingStartDate] = useState("");
  const [autoBillingEndDate, setAutoBillingEndDate] = useState("");
  const [addedCredit, setAddedCredit] = useState(0);
  const [deductedCredit, setDeductedCredit] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0.00)

  const loadCreditManagement = () => {
    dispatch(
      creditManagementShow({
        id: params?.id
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      }
    });
  }

  const saveCreditManagement = () => {
    dispatch(
      creditManagementUpdate({
        id: creditManagement?.id,
        company_id: creditManagement?.company?.id,
        is_auto_add_credit: autoAddCredit,
        auto_add_credit_amount: parseFloat(autoAddCreditAmount),
        add_credit_start_date: autoAddCreditStartDate,
        add_credit_end_date: autoAddCreditEndDate,
        is_auto_deduct_credit: autoDeductCredit,
        auto_deduct_credit_amount: parseFloat(autoDeductCreditAmount),
        deduct_credit_start_date: autoDeductCreditStartDate,
        deduct_credit_end_date: autoDeductCreditEndDate,
        is_auto_billing: autoBilling,
      }),
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        if (addedCredit > 0 || deductedCredit > 0) {
          let transactionDate = DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss')

          dispatch(
            creditManagementHistoryStore({
              credit_management_id: creditManagement?.id,
              transaction_date_time: transactionDate,
              added_credit: parseFloat(addedCredit),
              deducted_credit: parseFloat(deductedCredit),
            }),
          ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
              snackbarCtx.displayMsg(response.payload, "failure");
            } else {
              snackbarCtx.displayMsg(response.payload.message, "success");
              navigate(-1);
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    loadCreditManagement();
  }, []);

  return (
    <>
      <PageLayout>
        <div data-testid="order-list" className="flex flex-col items-start gap-6 p-4">
          <label className="cursor-pointer head-bar-nav font-regular font-semibold text-sm" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
            {" Go Back"}
          </label>
          <h1 className="text-3xl text-left font-semibold font-regular" style={{ color: "#395B50" }}>Edit Credit Management</h1>
          <div className="w-full max-w bg-white items-center border-2 p-8 rounded-lg font-regular space-y-6">
            <div className="space-y-2">
              <label className="font-bold">Company Name:</label>
              <input
                type="text"
                className={"bg-gray-300 input-search"}
                placeholder={creditManagement?.company?.name}
                disabled
              />
            </div>
            <div className="space-y-2">
              <label className="font-bold">Plan Subscribed:</label>
              <input
                type="text"
                className={"bg-gray-300 input-search"}
                placeholder={creditManagement?.company?.selected_package?.data[0]?.package?.package_name || 'N/A'}
                disabled
              />
            </div>
            <div className="space-y-2">
              <label className="font-bold">Credit Balance:</label>
              <input
                type="text"
                className={"bg-gray-300 input-search"}
                placeholder={creditManagement?.credit_balance}
              />
            </div>
            <div className="space-y-2">
              <label className="font-bold">Add/Remove Credit:</label>
              <div className="flex space-x-2 w-full">
                <input
                  type="text"
                  className={"input-search w-full"}
                  placeholder={creditAmount}
                  onChange={({ target }) => setCreditAmount(target.value)}
                />
                <button className="w-1/12 bg-main items-center borderedButton" onClick={() => setAddedCredit(creditAmount)} >
                  <FontAwesomeIcon icon={faPlus} className="text-xl" />
                </button>
                <button className="w-1/12 bg-red-700 items-center borderedButton border-red-700 ring-red-700 hover:bg-red-700 hover:border-red-800 hover:ring-red-700" onClick={() => setDeductedCredit(creditAmount)}>
                  <FontAwesomeIcon icon={faMinus} className="text-xl" />
                </button>
              </div>
            </div>
            <div className="space-y-2 w-full">
              <div className="flex justify-evenly space-x-4">
                <div className="w-full space-y-2">
                  <label className="font-bold">Auto Add Credit (Monthly):</label>
                  <div className="flex">
                    <button className={autoAddCredit === 0 ? "bg-gray-400 hover:bg-gray-400 hover:text-white hover:border-gray-400 hover:ring-2 hover:ring-gray-400 text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow w-[100px] border-r-0 rounded-r-none" : "" + "borderedActiveButton w-[100px] border-r-0 rounded-r-none text-white"} onClick={() => setAutoAddCredit(1)}>Active</button>
                    <button className={autoAddCredit === 1 ? "bg-gray-400 hover:bg-gray-400 hover:text-white hover:border-gray-400 hover:ring-2 hover:ring-gray-400 text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow w-[100px] border-l-0 rounded-l-none" : "" + "borderedInactiveButton w-[100px] border-l-0 rounded-l-none text-white"} onClick={() => { setAutoAddCredit(0); setAutoAddCreditAmount(0); setAutoAddCreditStartDate(""); setAutoAddCreditEndDate("") }}>Inactive</button>
                  </div>
                </div>
                <div className="w-full space-y-2">
                  <label>End Date:</label>
                  <input
                    className={autoAddCredit === 0 ? "input-search bg-gray-300" : "input-search"}
                    disabled={autoAddCredit === 0}
                    type="date"
                    value={autoAddCreditStartDate}
                    onChange={({ target }) => setAutoAddCreditStartDate(new Date(target?.value).toISOString().substr(0, 10))}
                  />
                </div>
                <div className="w-full space-y-2">
                  <label>End Date:</label>
                  <input
                    className={autoAddCredit === 0 ? "input-search bg-gray-300" : "input-search"}
                    disabled={autoAddCredit === 0}
                    type="date"
                    value={autoAddCreditEndDate}
                    onChange={({ target }) => setAutoAddCreditEndDate(new Date(target?.value).toISOString().substr(0, 10))}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label className="font-bold">Auto Add Credit Amount (Monthly):</label>
                <input
                  type="text"
                  className={autoAddCredit === 0 ? "input-search bg-gray-300" : "input-search"}
                  disabled={autoAddCredit === 0}
                  placeholder={autoAddCreditAmount}
                  onChange={({ target }) => setAutoAddCreditAmount(target?.value)}
                />
              </div>
            </div>
            <div className="space-y-2 w-full">
              <div className="flex justify-evenly space-x-4">
                <div className="w-full space-y-2">
                  <label className="font-bold">Auto Deduct Credit (Monthly):</label>
                  <div className="flex">
                    <button className={autoDeductCredit === 0 ? "bg-gray-400 hover:bg-gray-400 hover:text-white hover:border-gray-400 hover:ring-2 hover:ring-gray-400 text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow w-[100px] border-r-0 rounded-r-none" : "" + "borderedActiveButton w-[100px] border-r-0 rounded-r-none text-white"} onClick={() => setAutoDeductCredit(1)}>Active</button>
                    <button className={autoDeductCredit === 1 ? "bg-gray-400 hover:bg-gray-400 hover:text-white hover:border-gray-400 hover:ring-2 hover:ring-gray-400 text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow w-[100px] border-l-0 rounded-l-none" : "" + "borderedInactiveButton w-[100px] border-l-0 rounded-l-none text-white"} onClick={() => { setAutoDeductCredit(0); setAutoDeductCreditAmount(0); setAutoDeductCreditStartDate(""); setAutoDeductCreditEndDate("") }}>Inactive</button>
                  </div>
                </div>
                <div className="w-full space-y-2">
                  <label>Start Date:</label>
                  <input
                    className={autoDeductCredit === 0 ? "input-search bg-gray-300" : "input-search"}
                    type="date"
                    disabled={autoDeductCredit === 0}
                    value={autoDeductCreditStartDate}
                    onChange={({ target }) => setAutoDeductCreditStartDate(new Date(target?.value).toISOString().substr(0, 10))}
                  />
                </div>
                <div className="w-full space-y-2">
                  <label>End Date:</label>
                  <input
                    className={autoDeductCredit === 0 ? "input-search bg-gray-300" : "input-search"}
                    disabled={autoDeductCredit === 0}
                    type="date"
                    value={autoDeductCreditEndDate}
                    onChange={({ target }) => setAutoDeductCreditEndDate(new Date(target?.value).toISOString().substr(0, 10))}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label className="font-bold">Auto Deduct Credit Amount (Monthly):</label>
                <input
                  className={autoDeductCredit === 0 ? "input-search bg-gray-300" : "input-search"}
                  disabled={autoDeductCredit === 0}
                  type="text"
                  value={autoDeductCreditAmount}
                  onChange={({ target }) => setAutoDeductCreditAmount(target?.value)}
                />
              </div>
            </div>
            <div className="space-y-2 w-full">
              <div className="flex justify-evenly space-x-4">
                <div className="w-full space-y-2">
                  <label className="font-bold">Auto Billing (Monthly):</label>
                  <div className="flex">
                    <button className={autoBilling === 0 ? "bg-gray-400 hover:bg-gray-400 hover:text-white hover:border-gray-400 hover:ring-2 hover:ring-gray-400 text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow w-[100px] border-r-0 rounded-r-none" : "" + "borderedActiveButton w-[100px] border-r-0 rounded-r-none text-white"} onClick={() => setAutoBilling(1)}>Active</button>
                    <button className={autoBilling === 1 ? "bg-gray-400 hover:bg-gray-400 hover:text-white hover:border-gray-400 hover:ring-2 hover:ring-gray-400 text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow w-[100px] border-l-0 rounded-l-none" : "" + "borderedInactiveButton w-[100px] border-l-0 rounded-l-none text-white"} onClick={() => { setAutoBilling(0); setAutoBillingStartDate(""); setAutoBillingEndDate(""); }}>Inactive</button>
                  </div>
                </div>
                <div className="w-full space-y-2">
                  <label>Start Date:</label>
                  <input
                    className={autoBilling === 0 ? "input-search bg-gray-300" : "input-search"}
                    type="date"
                    disabled={autoBilling === 0}
                    value={autoBillingStartDate}
                    onChange={({ target }) => setAutoBillingStartDate(new Date(target?.value).toISOString().substr(0, 10))}
                  />
                </div>
                <div className="w-full space-y-2">
                  <label>End Date:</label>
                  <input
                    className={autoBilling === 0 ? "input-search bg-gray-300" : "input-search"}
                    type="date"
                    disabled={autoBilling === 0}
                    value={autoBillingEndDate}
                    onChange={({ target }) => setAutoBillingEndDate(new Date(target?.value).toISOString().substr(0, 10))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full max-w bg-white items-center border-2 p-8 rounded-lg font-regular space-y-6">
            <label className="font-bold">Transaction History</label>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg font-regular">
              <div className="p-2 w-full max-w rounded-t-lg">
                <div className="hidden laptop:flex flex-row items-center justify-center font-bold text-black">
                  <div className="flex items-center justify-center font-medium"></div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">#</div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">Transaction Date & Time</div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">Added Credit</div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">Deducted Credit</div>
                </div>
              </div>
              <div className="p-2 w-full max-w mt-1">
                {creditManagement?.history?.data && creditManagement?.history?.data.map((item, key) => {
                  return <div className="hidden laptop:flex flex-row items-center justify-center pb-2 font-light text-sm" key={item.id}>
                    <div className="basis-1/4 flex items-center justify-center font-medium">{key + 1}</div>
                    <div className="basis-1/4 flex items-center justify-center font-medium">{item?.transaction_date_time}</div>
                    <div className="basis-1/4 flex items-center justify-center font-medium">{item?.added_credit}</div>
                    <div className="basis-1/4 flex items-center justify-center font-medium">{item?.deducted_credit}</div>
                  </div>
                })}
              </div>
            </div>
            <div className="flex justify-end space-x-2">
              <button className="borderedButton w-fit" onClick={() => navigate(-1)}>Cancel</button>
              <button className="saveButton w-fit" onClick={() => saveCreditManagement()}>Save</button>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};
export default CreditManagementEdit;
