import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';
import HomePage from "pages/home/HomePage";
import RegisterPage from "pages/auth/RegisterPage";
import LoginPage from "pages/auth/LoginPage";
import RegisterDetail from "components/register/RegisterDetail";
import RegisterType from "components/register/RegisterType";
import RegisterCompany from "components/register/RegisterCompany";
import RegisterSubscription from "components/register/RegisterSubscription";
import RegisterComplete from "components/register/RegisterComplete";
import BlankPage from "pages/BlankPage";
import AccountDetails from "components/account/AccountDetails";
import FarmWidgetList from "components/farm/widget-view/FarmWidgetList";
import FarmMap from "components/farm/map-view/FarmMap";
import FarmCropStock from "components/farm/crop-stock/FarmCropStock";
import ProductList from "components/products/ProductList";
import FarmEdit from "components/farm/FarmEdit";
import FarmCreate from "components/farm/FarmCreate";
import PlotEdit from "components/farm/plots/PlotEdit";
import PlotCreate from "components/farm/plots/PlotCreate";
import PlotShow from "components/plots/PlotShow";
import ProductCreate from "components/products/ProductCreate";
import ProductEdit from "components/products/ProductEdit";
import StoreEdit from "components/farm/stores/StoreEdit";
import CatalogPage from "pages/CatalogPage";
import ApplicationList from "components/demands/applications/ApplicationsList";
import DemandList from "components/demands/DemandList";
import DemandCreate from "components/demands/DemandCreate";
import DemandEdit from "components/demands/DemandEdit";
import DemandMarketPage from "pages/DemandMarketPage";
import DemandMarketApply from "components/demands/market/DemandMarketApply";
import DemandShow from "components/demands/DemandShow";
import CatalogShow from "components/catalogs/CatalogShow";
import ProductFarm from "components/catalogs/ProductFarm";
import CartProductList from "components/cart/CartProductList";
import CartDeliveryPayment from "components/cart/CartDeliveryPayment";
import CartDone from "components/cart/CartDone";
import FileEdit from "components/files/FileEdit";
import OrderFarmerList from "components/order-farmers/OrderFarmerList";
import OrderFarmerTracking from "components/order-farmers/OrderFarmerTracking";
import CustomerOrderFarmerList from "components/customer/order-farmers/OrderFarmerList";
import CustomerOrderFarmerTracking from "components/customer/order-farmers/OrderFarmerTracking";
import ProductPlot from "components/catalogs/ProductPlot";
import DemandFarmMap from "components/demands/farms/DemandFarmMap";
import DemandFarmWidgets from "components/demands/farms/DemandFarmWidgets";
import DemandFarmShow from "components/demands/farms/DemandFarmShow";
import FarmShow from "components/farm/FarmShow"
import FarmViewWidgets from "components/farm/view/FarmViewWidgets"
import FarmViewMap from "components/farm/view/FarmViewMap"
import ForgotPasswordPage from "pages/auth/ForgotPasswordPage";
import NewPasswordPage from "pages/auth/NewPasswordPage";
import PlotsDetailPage from "pages/catalogs/plots/PlotsDetailPage";
import AdvanceBookingList from "components/advance-booking/AdvanceBookingList";
import AdvanceBookingDetail from "components/advance-booking/detail/AdvanceBookingDetail";
import AdvanceBookingDeposit from "components/advance-booking/pay-deposit/AdvanceBookingDeposit";
import SuccessPaidDepositPage from "components/advance-booking/pay-deposit/SuccessPaidDepositPage";
import DepositResultPage from "components/advance-booking/pay-deposit/DepositResultPage";
import EditUserDetail from "components/manage-user/UserDetailEdit"
import UserDetailList from "components/manage-user/UserDetailList";
import EditCompanyDetail from "components/manage-company/CompanyDetailEdit"
import CompanyList from "components/manage-company/CompanyList";
import PackageList from "components/manage-package/PackageList"
import PackageEdit from "components/manage-package/PackageEdit";
import PackageAdd from "components/manage-package/PackageAdd";
import AccountDetailsAdmin from "components/account/AccountDetailsAdmin";
import OrderFarmerDelivery from "components/order-farmers/OrderFarmerDelivery";
import AuctionBiddingList from "components/auction-bidding/AuctionBiddingList";
import AuctionBiddingDetail from "components/auction-bidding/detail/AuctionBiddingDetail";
import AuctionBiddingDetailBuyer from "components/auction-bidding/detail/AuctionBiddingDetailBuyer";
import OrderFarmerPayment from "components/customer/order-farmers/payment/OrderFarmerPayment";
import PaymentResultPage from "components/customer/order-farmers/payment/PaymentResultPage";
import DepositOffline from "components/advance-booking/pay-deposit/DepositOffline";
import PaymentOffline from "components/customer/order-farmers/payment/PaymentOffline";
import SettingPage from "components/setting/SettingPage";
import RegisterClosed from "components/register/RegisterClosed";
import NotificationList from "components/notification-user/NotificationList";
import ChatRoom from "components/chat-room/ChatRoom";
import ChatRoomList from "components/chat-room/list/ChatRoomList";
import { useSelector } from "react-redux";
import CartPanel from "components/cart/CartPanel";
import CartOfflinePaymentProcess from "components/cart/CartOfflinePaymentProcess";
import CartOfflinePayment from "components/cart/CartOfflinePayment";
import ManageOrdersList from "components/manage-orders/ManageOrdersList";
import ManageOrdersEdit from "components/manage-orders/ManageOrdersEdit";
import CompanyDetails from "components/company-details/CompanyDetails";
import BillingList from "components/manage-billing/BillingList";
import CompanyBillingList from "components/manage-billing/CompanyBillingList";
import CompanySubscriptionList from "components/manage-billing/CompanySubscriptionList";
import CompanyPlan from "components/manage-billing/CompanyPlan";
import CreditManagementList from "components/manage-credit/CreditManagementList";
import CreditManagementEdit from "components/manage-credit/CreditManagementEdit";
import BillingDetail from "components/account/billing/BillingDetail";
import BillingOfflinePayment from "components/account/billing/BillingOfflinePayment";
import UserCreditManagementList from "components/manage-user-credit/UserCreditManagementList";

const AppRoutes = () => {
  const { currentUser, token } = useSelector((state) => state.auth);
  let user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    let isLoggedIn = false;

    let newPasswordUrl = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
    if (user && user.data.token) {
      isLoggedIn = true;
    }
    if (window.location.pathname === '/') {
      // public access is allowed
    }
    else if (isLoggedIn) {
      if (window.location.pathname === '/login' ||
        window.location.pathname === '/register/details'
      ) {
        window.location = "/account/edit#personal";
      }
    }
    else {
      if (window.location.pathname === '/login' ||
        window.location.pathname === '/demands/market' ||
        window.location.pathname === '/products/catalogs' ||
        window.location.pathname === '/register/details' ||
        window.location.pathname === '/register/closed' ||
        window.location.pathname === '/forgot-password' ||
        newPasswordUrl === '/new-password'
      ) {
        // public access is allowed
      }
      else if (window.location.pathname !== '/login' || window.location.pathname !== '/register/details' || window.location.pathname === '/register/closed') {
        window.location = "/login";
      }
    }
  }, []);
  return (
    <>
      <Routes>
        {/* Any Route That Doesn't Exist */}
        <Route exact path="*" element={<BlankPage />} />

        {/* Home Route */}
        <Route exact path="/" element={<HomePage />} />

        {/* Login Route */}
        <Route exact path="/login" element={<LoginPage />} />

        {/* Forgot Password Route */}
        <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />

        {/* New Password Route */}
        <Route exact path="/new-password/:token" element={<NewPasswordPage />} />

        {/* Registration Routes */}
        <Route exact path="/register" element={<RegisterPage />}>
          <Route exact path="details" element={<RegisterDetail />} />
          <Route exact path="company" element={<RegisterCompany />} />
          <Route exact path="type" element={<RegisterType />} />
          <Route exact path="subscribe" element={<RegisterSubscription />} />
          <Route exact path="complete" element={<RegisterComplete />} />
          <Route exact path="closed" element={<RegisterClosed />} />
        </Route>

        {/* Subscription Package Routes
          <Route exact path="/register/subscription" element={<RegisterSubscription />} /> */}

        {/* Account Routes */}
        <Route exact path="/account" element={<BlankPage />} />
        <Route exact path="/account">
          <Route exact path="edit" element={<AccountDetails />} />
          <Route exact path="edit/:edit_type" element={<AccountDetails />} />
          <Route exact path="admin" element={<AccountDetailsAdmin />} />
          <Route exact path="bill/:id/edit" element={<BillingDetail />} />
          <Route exact path="bill/:id/offline-payment" element={<BillingOfflinePayment />} />
        </Route>

        {/* Farm Routes */}
        <Route exact path="/farms" element={<BlankPage />} />
        <Route exact path="/farms">
          <Route exact path="widgets" element={<FarmWidgetList />} />
          <Route exact path="map" element={<FarmMap />} />

          <Route exact path=":farm_id/" element={<FarmShow />} />    {/*Other people can view farm by farm_id*/}

          <Route exact path=":farm_id/crop-stock" element={<FarmCropStock />} />
          <Route exact path=":farm_id/stores/:store_id/edit" element={<StoreEdit />} />
          <Route exact path="create" element={<FarmCreate />} />
          <Route exact path=":farm_id" element={<BlankPage />} />

          <Route exact path=":farm_id">
            <Route exact path="edit" element={<FarmEdit />} />
            <Route exact path="show" />
            <Route exact path="plots" element={<BlankPage />} />
            <Route exact path="plots">
              <Route exact path="create" element={<PlotCreate />} />
              <Route exact path=":plot_id" element={<BlankPage />} />
              <Route exact path=":plot_id">
                <Route exact path="edit" element={<PlotEdit />} />
                <Route exact path="show" />
              </Route>
            </Route>
          </Route>

          {/*Other people can view farms by farmer_id*/}
          <Route exact path="view">
            <Route exact path="farmer">
              <Route exact path=":farmer_id">
                <Route exact path="map" element={<FarmViewMap />} />
                <Route exact path="widgets" element={<FarmViewWidgets />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route exact path="/manage-crop" element={<StoreEdit />} />


        <Route exact path="plots">
          {/*<Route exact path="" element={<PlotCreate />} />*/}
          <Route exact path=":plot_id" element={<PlotShow />} />
          {/*<Route exact path=":plot_id">*/}
          {/*<Route exact path="edit" element={<PlotEdit />} />*/}
          {/*<Route exact path="show" />*/}
          {/*</Route>*/}
        </Route>
        <Route exact path="/files">
          <Route exact path=":file_id">
            <Route exact path="edit" element={<FileEdit />} />
          </Route>

          <Route exact path="view">
            <Route exact path="farmer">
              <Route exact path=":farmer_id">
                <Route exact path="map" element={<FarmViewMap />} />
                <Route exact path="widgets" element={<FarmViewWidgets />} />
              </Route>
            </Route>
          </Route>
        </Route>




        {/* Product Routes */}
        <Route exact path="/products" element={<ProductList />} />
        <Route exact path="/products">
          <Route exact path="create" element={<ProductCreate />} />

          <Route exact path="catalogs" element={<CatalogPage />} />

          <Route exact path="catalogs">
            <Route exact path=":catalog_id" element={<BlankPage />} />
            <Route exact path=":catalog_id">
              <Route exact path="show" element={<CatalogShow />} />
              <Route exact path="farm" element={<ProductFarm />} />
              <Route exact path="farm">
                <Route exact path="plot" element={<BlankPage />} />
                <Route exact path="plot">
                  <Route exact path=":plot_id" element={<ProductPlot />} />
                </Route>
              </Route>
            </Route>

            <Route exact path="plot">
              <Route exact path=":plot_id" element={<PlotsDetailPage />} />
            </Route>
          </Route>

          <Route exact path=":product_id" element={<BlankPage />} />
          <Route exact path=":product_id">
            <Route exact path="edit" element={<ProductEdit />} />
            <Route exact path="show" element={<ProductFarm />} />
          </Route>
        </Route>

        {/* Demand Routes */}
        <Route exact path="/demands" element={<DemandList />} />
        <Route exact path="/demands">
          <Route exact path="applications" element={<ApplicationList />} />

          <Route exact path="create" element={<DemandCreate />} />
          <Route exact path=":demand_id" element={<BlankPage />} />
          <Route exact path=":demand_id">
            <Route exact path="edit" element={<DemandEdit />} />
            <Route exact path="show" element={<DemandShow />} />
            <Route exact path="show">
              <Route exact path="map" element={<DemandFarmMap />} />
              <Route exact path="widgets" element={<DemandFarmWidgets />} />
              <Route exact path="widgets">
                <Route exact path=":id">
                  <Route exact path="show" element={<DemandFarmShow />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route exact path="market" element={<DemandMarketPage />} />
          <Route exact path="market">
            <Route exact path="apply" element={<BlankPage />} />
            <Route exact path="apply">
              <Route exact path=":demand_id" element={<DemandMarketApply />} />
            </Route>
          </Route>
        </Route>

        {/* Application Routes: NOTE. applications routes are not being used at the moment */}
        <Route exact path="/applications">
          <Route exact path="create" element={<BlankPage />} />
          <Route exact path=":application_id" element={<BlankPage />} />
          <Route exact path=":application_id">
            <Route exact path="edit" element={<BlankPage />} />
          </Route>
        </Route>

        {/* Application Routes: NOTE. applications routes are not being used at the moment */}

        {/* Order Routes */}


        {/*<Route exact path="/orders" element={<OrderList />} />*/}
        {/*<Route exact path="/orders">*/}
        {/*<Route exact path="create" element={<BlankPage />} />*/}
        {/*<Route exact path=":order_id" element={<BlankPage />} />*/}
        {/*<Route exact path=":order_id">*/}
        {/*<Route exact path="tracking" element={<OrderTracking />} />*/}
        {/*</Route>*/}
        {/*</Route>*/}

        <Route exact path="/order-farmers" element={<OrderFarmerList />} />
        <Route exact path="/order-farmers">
          <Route exact path="create" element={<BlankPage />} />
          <Route exact path=":order_farmer_id" element={<BlankPage />} />
          <Route exact path=":order_farmer_id">
            <Route exact path="tracking" element={<OrderFarmerTracking />} />
          </Route>
          <Route exact path=":order_farmer_id">
            <Route exact path="delivery" element={<OrderFarmerDelivery />} />
          </Route>
        </Route>

        <Route exact path="/customer/order-farmers" element={<CustomerOrderFarmerList />} />

        <Route exact path="/customer/order-farmers">
          <Route exact path="create" element={<BlankPage />} />
          <Route exact path=":order_farmer_id" element={<BlankPage />} />
          <Route exact path=":order_farmer_id">
            <Route exact path="tracking" element={<CustomerOrderFarmerTracking />} />
          </Route>
          <Route exact path=":order_number">
            <Route exact path="payment" element={<OrderFarmerPayment />} />
          </Route>
          <Route exact path=":order_number">
            <Route exact path="payment-offline" element={<PaymentOffline />} />
          </Route>
          <Route exact path=":order_number">
            <Route exact path="payment-result" element={<PaymentResultPage />} />
          </Route>
        </Route>

        {/*<Route exact path="/customer/orders" element={<CustomerOrderList />} />*/}
        {/*<Route exact path="/customer/orders">*/}
        {/*<Route exact path="create" element={<BlankPage />} />*/}
        {/*<Route exact path=":order_id" element={<BlankPage />} />*/}
        {/*<Route exact path=":order_id">*/}
        {/*<Route exact path="tracking" element={<CustomerOrderTracking />} />*/}
        {/*</Route>*/}
        {/*</Route>*/}

        {/*<Route exact path="/order-details" element={<OrderDetailList />} />*/}
        {/*<Route exact path="/order-details">*/}
        {/*<Route exact path="create" element={<BlankPage />} />*/}
        {/*<Route exact path=":order_detail_id" element={<BlankPage />} />*/}
        {/*<Route exact path=":order_detail_id">*/}
        {/*<Route exact path="tracking" element={<OrderDetailTracking />} />*/}
        {/*</Route>*/}
        {/*</Route>*/}

        {/*<Route exact path="/customer/order-details" element={<CustomerOrderDetailList />} />*/}
        {/*<Route exact path="/customer/order-details">*/}
        {/*<Route exact path="create" element={<BlankPage />} />*/}
        {/*<Route exact path=":order_detail_id" element={<BlankPage />} />*/}
        {/*<Route exact path=":order_detail_id">*/}
        {/*<Route exact path="tracking" element={<CustomerOrderDetailTracking />} />*/}
        {/*</Route>*/}
        {/*</Route>*/}


        {/* Cart Routes */}
        <Route exact path="/cart" element={<BlankPage />} />
        <Route exact path="/cart">
          <Route exact path="products" element={<CartProductList />} />
          <Route exact path="delivery-payment" element={<CartDeliveryPayment />} />
          <Route exact path="done" element={<CartDone />} />
          <Route exact path="products-new" element={<CartPanel />} />
          <Route exact path="offline-payment-process" element={<CartOfflinePaymentProcess />} />
          <Route exact path="offline-payment">
            <Route exact path=":order_id" element={<CartOfflinePayment />} />
          </Route>
        </Route>

        {/* User's Detail Routes */}
        <Route exact path="/user-detail" element={<BlankPage />} />
        <Route exact path="/user-detail">
          <Route exact path="show" element={<UserDetailList />} />
          {/* <Route exact path=":product_id" element={<BlankPage />} />
            <Route exact path=":product_id"> */}
          {/* <Route exact path="edit" element={<EditUserDetail />} /> */}
          <Route exact path=":user_id" element={<BlankPage />} />
          <Route exact path=":user_id">
            <Route exact path="edit" element={<EditUserDetail />} />
          </Route>
        </Route>

        {/* Company Detail Routes */}
        <Route exact path="/company-detail" element={<BlankPage />} />
        <Route exact path="/company-detail">
          <Route exact path="show" element={<CompanyList />} />
          <Route exact path=":company_id" element={<BlankPage />} />
          <Route exact path=":company_id">
            <Route exact path="edit" element={<EditCompanyDetail />} />
          </Route>
        </Route>

        {/* Package Management Routes */}
        <Route exact path="/package-management" element={<BlankPage />} />
        <Route exact path="/package-management">
          <Route exact path="show" element={<PackageList />} />
          <Route exact path=":id/edit" element={<PackageEdit />} />
          <Route exact path="add" element={<PackageAdd />} />
        </Route>

        {/* Manage Setting */}
        <Route exact path="/setting" element={<SettingPage />} />

        {/* Manage Advance Booking Plots */}
        <Route exact path="/manage-advance-booking" element={<AdvanceBookingList />} />
        <Route exact path="/manage-advance-booking">
          <Route exact path="detail">
            <Route exact path=":booking_id" element={<AdvanceBookingDetail />} />
          </Route>
          <Route exact path="deposit">
            <Route exact path=":booking_id" element={<AdvanceBookingDeposit />} />
          </Route>
          <Route exact path="deposit-paid">
            <Route exact path=":booking_id" element={<SuccessPaidDepositPage />} />
          </Route>
          <Route exact path="deposit-offline">
            <Route exact path=":booking_id" element={<DepositOffline />} />
          </Route>
          <Route exact path="deposit-result">
            <Route exact path=":booking_number" element={<DepositResultPage />} />
          </Route>
        </Route>

        {/* Manage Order Routes */}
        <Route exact path="/manage-orders" element={<BlankPage />} />
        <Route exact path="/manage-orders">
          <Route exact path="show" element={<ManageOrdersList />} />
          <Route exact path=":id/edit/:type" element={<ManageOrdersEdit />} />
        </Route>

        {/* Auction Bidding Plots */}
        <Route exact path="/manage-auction-bidding" element={<AuctionBiddingList />} />
        <Route exact path="/manage-auction-bidding">
          <Route exact path="detail">
            <Route exact path=":auction_id" element={<AuctionBiddingDetail />} />
          </Route>
          <Route exact path="detail-buyer">
            <Route exact path=":auction_id" element={<AuctionBiddingDetailBuyer />} />
          </Route>
        </Route>

        {/* notification */}
        <Route exact path="/notification">
          <Route exact path="show" element={<NotificationList />} />
        </Route>

        {/* room chat */}
        <Route exact path="/room-chat">
          <Route exact path="page" element={<ChatRoomList />} />
          <Route exact path="live/:to_id" element={<ChatRoom />} />
          <Route exact path="live/:to_id/:type/:id_type/:id_catalog" element={<ChatRoom />} />
        </Route>

        {/* manage billing */}
        <Route exact path="/manage-billing" element={<BlankPage />} />
        <Route exact path="/manage-billing">
          <Route exact path="show" element={<BillingList />} />
          <Route exact path=":id" element={<CompanyBillingList />} />
          <Route exact path=":id/billing/:bill_id" element={<BillingDetail />} />
          <Route exact path=":id/company-plan" element={<CompanyPlan />} />
          <Route exact path=":id/subscription" element={<CompanySubscriptionList />} />
        </Route>

        {/* manage credit */}
        <Route exact path="/manage-credit" element={<BlankPage />} />
        <Route exact path="/manage-credit">
          <Route exact path="show" element={<CreditManagementList />} />
          <Route exact path=":id/edit" element={<CreditManagementEdit />} />
        </Route>

        <Route exact path="/credit" element={<BlankPage />} />
        <Route exact path="/credit">
          <Route exact path="show" element={<UserCreditManagementList />} />
        </Route>

        <Route exact path="/company-details">
          <Route exact path=":company_id" element={<CompanyDetails />} />
        </Route>
      </Routes>
    </>
  )
}

export default AppRoutes;