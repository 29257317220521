import api from "../../support/support_api";

const getPrivacyPolicy = async (params) => {
    const response  = await api.axiosGet(`/pdfPrivacyProlicy`, params);

    return response.data;
}

const getReturnRefund = async (params) => {
    const response  = await api.axiosGet(`/pdfRefund`, params);

    return response.data;
}

const getTermUse = async (params) => {
    const response  = await api.axiosGet(`/pdfTermsOfUse`, params);

    return response.data;
}


const assetService = {
    getPrivacyPolicy, getReturnRefund, getTermUse
}

export default assetService;