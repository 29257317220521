import { routesPaths } from "constants/routes.constants";
import { useLocation, useNavigate } from "react-router-dom";
import {useParams} from "react-router-dom";

const DemandFarmControls = () => {
  const navigate = useNavigate();
  const location = useLocation();

    const params = useParams();
  const demand_id = 1;

  const widgetViewHandler = () => {
      navigate(`/farms/view/farmer/${params.farmer_id}/widgets`)
  };

  const mapViewHandler = () => {
    window.location=`/farms/view/farmer/${params.farmer_id}/map`;
    // navigate(`${routesPaths.DEMANDS}/${demand_id}/show/map`);
  };

  return (
    <div data-testid="demand-farm-controls" className="self-center justify-center w-[30rem] flex gap-4">


        {/*{location.pathname !== `${routesPaths.DEMANDS}/${demand_id}/show/map` && (*/}
          {/*<button type="button" className="max-w-[15rem] saveButton" onClick={mapViewHandler}>*/}
            {/*Map View*/}
          {/*</button>*/}
        {/*)}*/}
        {/*{location.pathname !== `${routesPaths.DEMANDS}/${demand_id}/show/widgets` && (*/}
          {/*<button type="button" className="max-w-[15rem] saveButton" onClick={widgetViewHandler}>*/}
            {/*Widget View*/}
          {/*</button>*/}
        {/*)}*/}

        {/*/farms/view/farmer/1/map*/}
        {/*/farms/view/farmer/1/widgets*/}

        <button type="button" className="max-w-[15rem] saveButton" onClick={widgetViewHandler}>
            Widget View
        </button>
        <button type="button" className="max-w-[15rem] saveButton" onClick={mapViewHandler}>
            Map View
        </button>

    </div>
  );
};

export default DemandFarmControls;
