import api from "../../support/support_api";


const downloadBill = async (params) => {
    const response = await api.axiosDownloadPDF('/billing/download/'+params.id);

    return response.data;
}

const billingIndex = async (params) => {
    const response  = await api.axiosGet(`/billing`, params);

    return response.data;
}

const billingShow = async (params) => {
    const response  = await api.axiosGet(`/billing/${params.id}`, params);

    return response.data;
}

const billingUploadPaymentReceipt = async (params) => {
    const response  = await api.axiosPost(`/billing/uploadPaymentReceipt`, params);

    return response.data;
}

const billingService = {
    downloadBill,
    billingIndex,
    billingShow,
    billingUploadPaymentReceipt
}


export default billingService;