import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  packageSubscriptionService from "./packageSubscriptionService";

export const getPackages = createAsyncThunk(
    "retrieve-packages",
    async (thunkAPI) => {
        try {
            return await packageSubscriptionService.getPackages();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getPackage = createAsyncThunk(
    "retrieve-package",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await packageSubscriptionService.getPackage(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const addPackage = createAsyncThunk(
    "retrieve-packages",
    async (args, thunkAPI) => {
        try {
            return await packageSubscriptionService.addPackage(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const editPackage = createAsyncThunk(
    "edit-package",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await packageSubscriptionService.editPackage(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const deletePackage = createAsyncThunk(
    "delete-package",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await packageSubscriptionService.deletePackage(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

//
// export const currentUser = createAsyncThunk(
//     "auth/users/login",
//     async (credentials, thunkAPI) => {
//         try {
//             return await loginService.login(credentials);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )

const initialState = {
    data: {},
    loading: false,
    isSuccess: false,
    currentUser: {},
    currentCompany: {},
    token: 'asdasd'
}

export const packageSubscriptionSlice = createSlice({
    name: 'packageSubscription',
    initialState,
    reducers: {
        getCurrentUserInfo: (state) => {
            let user = JSON.parse(localStorage.getItem("user"));

            state.token = user.data.token;
            state.currentUser = user.data.user;
            state.currentCompany = user.data.company;
        },

    },
    extraReducers : {
        [getPackages.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [getPackages.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [getPackages.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [getPackage.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [getPackage.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [getPackage.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
        [addPackage.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [addPackage.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [addPackage.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
        [editPackage.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [editPackage.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [editPackage.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
        [deletePackage.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [deletePackage.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [deletePackage.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

export const { getCurrentUserInfo } = packageSubscriptionSlice.actions

export default packageSubscriptionSlice;