import api from "../../support/support_api";

const orderDetailStore = async (params) => {
    const response  = await  api.axiosPost('/order-details', params);

    return response.data;
}

const orderDetailUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/order-details/${params.order_detail_id}`, params);

    return response.data;
}

const orderDetailShow = async (params) => {
    const response  = await api.axiosShow(`/order-details/${params.order_detail_id}`, params);

    return response.data;
}

const orderDetailDeliveryDecline = async (params) => {
    const response  = await api.axiosPatch(`/order-details/${params.order_detail_id}/delivery-decline`, params);

    return response.data;
}

const orderDetailDeliveryAccept = async (params) => {
    const response  = await api.axiosPatch(`/order-details/${params.order_detail_id}/delivery-accept`, params);

    return response.data;
}

const orderDetailDeliveryStart = async (params) => {
    const response  = await api.axiosPatch(`/order-details/${params.order_detail_id}/delivery-start`, params);

    return response.data;
}

const orderDetailDeliveryDone = async (params) => {
    const response  = await api.axiosPatch(`/order-details/${params.order_detail_id}/delivery-done`, params);

    return response.data;
}

const orderDetailUpdateQuantity = async (params) => {
    const response  = await api.axiosPatch(`/order-details/${params.order_detail_id}/update-quantity`, params);

    return response.data;
}

const orderDetailIndex = async (params) => {
    const response  = await api.axiosGet(`/order-details`, params);

    return response.data;
}

const orderDetailDelete = async (params) => {
    const response  = await api.axiosDelete(`/order-details/${params.order_detail_id}`, params);

    return response.data;
}

const orderDetailReceivableForFarmer = async (params) => {
    const response  = await api.axiosGet(`/order-details/receivable-for-farmer`, params);

    return response.data;
}

const orderDetailMadeByCustomer = async (params) => {
    const response  = await api.axiosGet(`/order-details/made-by-customer`, params);

    return response.data;
}

const generateInvoiceNumber = async (params) => {
    const response  = await api.axiosPut(`/order-details/generateInvoiceNumber/${params.order_detail_id}`, params);

    return response.data;
}

const downloadInvoice = async (params) => {
    const response  = await  api.axiosDownloadPDF(`/order-details/download-invoice/${params.order_id}`, params);

    return response.data;
}

const uploadPaymentReceipt = async (params) => {
    const response  = await api.axiosPost(`/order-details/uploadPaymentReceipt`, params);

    return response.data;
}

const orderDetailService = {
    orderDetailStore,
    orderDetailUpdate,
    orderDetailUpdateQuantity,
    orderDetailShow,
    orderDetailIndex,
    orderDetailDelete,
    orderDetailReceivableForFarmer,
    orderDetailMadeByCustomer,
    orderDetailDeliveryDecline,
    orderDetailDeliveryAccept,
    orderDetailDeliveryStart,
    orderDetailDeliveryDone,
    generateInvoiceNumber,
    downloadInvoice,
    uploadPaymentReceipt,
}

export default orderDetailService;