import api from "../../support/support_api";

const fileUpload = async (params) => {
    const response  = await api.axiosPost(`/files/upload/${params.get('model_id')}`, params);

    return response.data;
}

const fileDelete = async (params) => {
    const response  = await api.axiosDelete(`/files/${params.file_id}`, params);

    return response.data;
}

const fileUpdate = async (params) => {
    const response  = await api.axiosPatch(`/files/${params.file_id}`, params);

    return response.data;
}

const fileShow = async (params) => {
    const response  = await api.axiosShow(`/files/${params.file_id}`, params);

    return response.data;
}

const fileService = {
    fileUpload,
    fileDelete,
    fileUpdate,
    fileShow
}

export default fileService;