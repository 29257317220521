import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

//import {axios} from  "axios";
// import { AxiosResponse } from "axios";
//import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  ratingService from "./ratingService";

export const ratingIndex = createAsyncThunk(
    "rating/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await ratingService.ratingIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const ratingGuest = createAsyncThunk(
    "rating/index",
    async (args, thunkAPI) => {
        try {
            return await ratingService.ratingGuest(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const ratingAuth = createAsyncThunk(
    "rating/index",
    async (args, thunkAPI) => {
        try {
            return await ratingService.ratingAuth(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const ratingStore = createAsyncThunk(
    "rating/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await ratingService.ratingStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const ratingStoreWithoutStoreId = createAsyncThunk(
    "rating/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await ratingService.ratingStoreWithoutStoreId(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const ratingUpdate = createAsyncThunk(
    "rating/update",
    async (args, thunkAPI) => {
        try {
            return await ratingService.ratingUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const ratingDelete = createAsyncThunk(
    "rating/delete",
    async (args, thunkAPI) => {
        try {
            return await ratingService.ratingDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const ratingUploadPhoto = createAsyncThunk(
    "rating/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await ratingService.ratingUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const ratingShow = createAsyncThunk(
    "rating/:id/show",
    async (args, thunkAPI) => {
        try {
            return await ratingService.ratingShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    rating: {
        grades: {
            data: []
        }
    },
    ratings: {
        data: []
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const ratingSlice = createSlice({
    name: 'rating',
    initialState,
    reducers: {
    },
    extraReducers : {
        [ratingStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [ratingStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.rating = payload.data.rating;
            state.loading=false;
            state.isSuccess = true;
        },
        [ratingStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [ratingStoreWithoutStoreId.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [ratingStoreWithoutStoreId.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.rating = payload.data.rating;
            state.loading=false;
            state.isSuccess = true;
        },
        [ratingStoreWithoutStoreId.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [ratingUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [ratingUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [ratingUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [ratingDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [ratingDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [ratingDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [ratingShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [ratingShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.rating = payload.data.rating;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [ratingShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [ratingIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [ratingIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.ratings = payload.data.ratings;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [ratingIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = ratingSlice.actions

export default ratingSlice;