import api from "../../support/support_api";

const cartStore = async (params) => {
    const response  = await  api.axiosPost('/cart', params);

    return response.data;
}

const cartUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/cart/${params.cart_id}`, params);

    return response.data;
}

const cartShow = async (params) => {
    const response  = await api.axiosShow(`/cart/${params.cart_id}`, params);

    return response.data;
}

const cartUpdateQuantity = async (params) => {
    const response  = await api.axiosPatch(`/cart/${params.cart_id}/update-quantity`, params);

    return response.data;
}

const cartIndex = async (params) => {
    const response  = await api.axiosGet(`/cart`, params);

    return response.data;
}

const cartDelete = async (params) => {
    const response  = await api.axiosDelete(`/cart/${params.cart_id}`, params);

    return response.data;
}

const cartService = {
    cartStore,
    cartUpdate,
    cartUpdateQuantity,
    cartShow,
    cartIndex,
    cartDelete
}

export default cartService;