import api from "../../support/support_api";

const gradeProductStore = async (params) => {
    const response  = await  api.axiosPost('/grade-product', params);

    return response.data;
}

const gradeProductUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/grade-product/${params.id}`, params);

    return response.data;
}

const gradeProductUploadPhotos = async (params) => {
    const response  = await  api.axiosPatch(`/grade-product/${params.id}`, params);

    return response.data;
}

const gradeProductDelete = async (params) => {
    const response  = await  api.axiosDelete(`/grade-product/${params.id}`);

    return response.data;
}

const gradeProductShow = async (params) => {
    const response  = await api.axiosShow(`/grade-product/${params.id}`, params);

    return response.data;
}

const gradeProductIndex = async (params) => {
    const response  = await api.axiosGet(`/grade-products`, params);

    return response.data;
}

const gradeProductService = {
    gradeProductStore, gradeProductUpdate, gradeProductUploadPhotos, gradeProductShow, gradeProductIndex, gradeProductDelete
}

export default gradeProductService;