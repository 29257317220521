import PageLayout from "pages/AccountLayout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountAsc } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ManageOrders from "./ManageOrders";
import { useSelector } from "react-redux";
import { orderIndex, orderStatistic } from "features/order/orderSlice";
import { auctionBiddingGeneralList, auctionBiddingStatistic } from "features/auction-bidding/auctionBiddingSlice";
import { advanceBookingGeneralList, advanceBookingStatistic } from "features/advance-booking/advanceBookingSlice";

const ManageOrdersList = () => {
  const dispatch = useDispatch();
  const { orders, statistic } = useSelector((state) => state.order);
  const { booking_plots, bookingStatistic } = useSelector((state) => state.advanceBooking);
  const { auctionList, auctionStatistic } = useSelector((state) => state.auctionBidding);
  const [typeOrder, setTypeOrder] = useState("Incoming");
  const [currentPage, setCurrentPage] = useState(1);

  const loadOrder = (pageNumber, limit) => {
    dispatch(
      orderIndex({
        limit: limit,
        page: pageNumber
      })
    )
  }

  const loadBooking = (pageNumber, itemsPerPage) => {
    dispatch(
      advanceBookingGeneralList({
        limit: itemsPerPage,
        page: pageNumber,
      })
    );
  }

  const loadAuction = (pageNumber, itemsPerPage) => {
    dispatch(
      auctionBiddingGeneralList({
        limit: itemsPerPage,
        page: pageNumber,
      })
    );
  }

  const loadStatistic = () => {
    dispatch(
      orderStatistic()
    );

    dispatch(
      advanceBookingStatistic()
    );

    dispatch(
      auctionBiddingStatistic()
    );
  }

  const handleChangePage = (event, pageNumber) => {
    setCurrentPage(pageNumber);
    loadOrder(pageNumber, 10);
  };

  useEffect(() => {
    if (window.location.hash === '#order') {
      setTypeOrder('Incoming');
    } else if (window.location.hash === '#booking') {
      setTypeOrder('Advanced')
    } else {
      setTypeOrder('Auction')
    }

    loadStatistic();
    loadOrder(1, 10);
    loadBooking(1, 10);
    loadAuction(1, 10);
  }, []);

  return (
    <>
      <PageLayout>
        <div data-testid="user-list" className="flex flex-col items-start gap-6 p-4">
          <h1 className="text-3xl text-left font-semibold font-regular" style={{ color: "#395B50" }}>Order Management</h1>
          <div className="ml-1 mb-2 mt-2">
            <section className="flex gap-8">
              <a
                href="#order"
                className={
                  typeOrder === "Incoming"
                    ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                    : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
                }
                onClick={() => {
                  setTypeOrder("Incoming");
                  loadOrder(1, 10);
                  loadStatistic();
                }}
              >
                Incoming Orders
              </a>
              <a
                href="#booking"
                className={
                  typeOrder === "Advanced"
                    ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                    : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
                }
                onClick={() => {
                  setTypeOrder("Advanced")
                  loadBooking(1, 10);
                  loadStatistic();
                }}
              >
                Advanced Booking
              </a>
              <a
                href="#bidding"
                className={
                  typeOrder === "Auction"
                    ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                    : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
                }
                onClick={() => {
                  setTypeOrder("Auction")
                  loadAuction(1, 10);
                  loadStatistic();
                }}
              >
                Auction / Bidding
              </a>
            </section>
          </div>
          <div className="flex w-full">
            <div className="laptop:w-5/6 w-full max-w bg-white flex flex-col items-center shadow rounded-lg font-regular">
              <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
                  Sort
                </div>
                <div className="flex items-center tablet:flex justify-start w-full desktop:w-2/5">
                  <select className="select-custom mr-2">
                    <option value="Name" selected>
                      Name
                    </option>
                    <option value="Username" selected>
                      Username
                    </option>
                  </select>
                  <FontAwesomeIcon icon={faSortAmountAsc} />
                </div>
                <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit ml-4">
                  Filter
                </div>
                <div className="flex items-center tablet:flex justify-start w-full desktop:w-2/5">
                  <select className="select-custom ">
                    <option value="Name" selected>
                      Name
                    </option>
                    <option value="Username" selected>
                      Username
                    </option>
                    <option value="Email" selected>
                      Email
                    </option>
                    <option value="CompanyID" selected>
                      Company ID
                    </option>
                  </select>
                </div>
                <div className="flex w-full justify-end">
                  <form className="flex justify-end w-full desktop:w-fit">
                    <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 laptop:hidden desktop:hidden">Search</label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                      </div>
                      <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search" />
                    </div>
                  </form>
                </div>
              </div>
              <div className="p-2 w-full max-w bg-main text-white">
                <div className="hidden laptop:flex flex-row items-center justify-center text-sm text-semibold font-regular">
                  <div className="flex items-center justify-center font-medium"></div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">{typeOrder === 'Incoming' ? 'Order ID' : typeOrder === 'Advanced' ? 'Booking ID' : 'Auction ID'}</div>
                  {typeOrder === 'Incoming' && <div className="basis-1/4 flex items-center justify-center font-medium">No of Farmer(s)</div>}
                  <div className="basis-1/4 flex items-center justify-center font-medium">Customer</div>
                  {typeOrder !== 'Auction' && <div className="basis-1/4 flex items-center justify-center font-medium">Payment Type</div>}
                  <div className="basis-1/4 flex items-center justify-center font-medium">Status</div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">Options</div>
                </div>
              </div>
              <div className="w-full max-w mt-1">
                {typeOrder === 'Incoming' && orders?.data && orders?.data.length > 0 && orders?.data.map((order, index) => (
                  <ManageOrders data={order} key={index} typeOrder={typeOrder} index={index} loadList={loadOrder} loadStatistic={loadStatistic} />
                ))}
                {typeOrder === 'Advanced' && booking_plots?.data && booking_plots?.data.length > 0 && booking_plots?.data.map((bookingplot, index) => (
                  <ManageOrders data={bookingplot} key={index} typeOrder={typeOrder} index={index} loadList={loadBooking} loadStatistic={loadStatistic} />
                ))}
                {typeOrder === 'Auction' && auctionList && auctionList.length > 0 && auctionList.map((auction, index) => (
                  <ManageOrders data={auction} key={index} typeOrder={typeOrder} index={index} loadList={loadAuction} loadStatistic={loadStatistic}/>
                ))}
                {typeOrder === 'Incoming' && orders?.data && orders?.data.length === 0 && <p className="pl-12">No order available</p>}
                {typeOrder === 'Advanced' && booking_plots?.data && booking_plots?.data.length === 0 && <p className="pl-12">No booking available</p>}
                {typeOrder === 'Auction' && auctionList?.data && auctionList?.data?.length === 0 && <p className="pl-12">No auction available</p>}
              </div>
            </div>
            <div className="laptop:w-1/6 max-w bg-white flex flex-col shadow rounded-lg laptop: ml-10">
              <div className="p-2 w-full max-w rounded-t-lg" style={{ backgroundColor: "#00AF54", color: "white" }}>
                <div className="basis-1/4 flex items-center justify-center font-medium">{typeOrder === 'Incoming' ? 'Order' : typeOrder === 'Advanced' ? 'Booking' : 'Auction'} Summary</div>
              </div>
              <div className="font-bold text-gray-500 items-start my-4 mx-3">{typeOrder === 'Incoming' ? 'Orders' : typeOrder === 'Advanced' ? 'Booking' : 'Auction'} Today</div>
              <div className="font-extrabold text-5xl text-center my-4">{typeOrder === 'Incoming' ? statistic?.todayOrderTotal : typeOrder === 'Advanced' ? bookingStatistic?.todayBookingTotal : auctionStatistic?.todayAuctionTotal}</div>
              <div className="font-bold text-gray-500 items-start my-4 mx-3">Pending {typeOrder === 'Incoming' ? 'Orders' : typeOrder === 'Advanced' ? 'Booking' : 'Auction'}</div>
              <div className="font-extrabold text-5xl text-center my-4">{typeOrder === 'Incoming' ? statistic?.pendingOrdersTotal : typeOrder === 'Advanced' ? bookingStatistic?.pendingBookingTotal : auctionStatistic?.pendingAuctionTotal}</div>
              {typeOrder === 'Incoming' && <div className="font-bold text-gray-500 items-start my-4 mx-3">Delivered Orders</div>}
              {typeOrder === 'Incoming' && <div className="font-extrabold text-5xl text-center my-4">{statistic?.deliveredOrderTotal}</div>}
              {typeOrder !== 'Auction' && <div className="font-bold text-gray-500 items-start my-4 mx-3">Cancelled {typeOrder === 'Incoming' ? 'Orders' : typeOrder === 'Advanced' ? 'Booking' : 'Auction'}</div>}
              {typeOrder !== 'Auction' && <div className="font-extrabold text-5xl text-center my-4">{typeOrder === 'Incoming' ? statistic?.cancelledOrderTotal : typeOrder === 'Advanced' ? bookingStatistic?.cancelledBookingTotal : 'Auction'}</div>}
            </div>
          </div>
          <Stack spacing={2}>
            <Pagination count={orders.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={currentPage} />
          </Stack>
        </div>
      </PageLayout>
    </>
  );
};
export default ManageOrdersList;