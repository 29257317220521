import api from "../../support/support_api";

const demandStore = async (params) => {
    const response  = await  api.axiosPost('/demands', params);

    return response.data;
}

const demandDelete = async (params) => {
    const response  = await  api.axiosDelete(`/demands/${params.demand_id}`, params);

    return response.data;
}

const demandFarmerApply = async (params) => {
    const response  = await  api.axiosPost(`/demands/${params.demand_id}/farmer-apply`, params);

    return response.data;
}

const demandUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/demands/${params.demand_id}`, params);

    return response.data;
}

const demandUploadPhotos = async (params) => {
    const response  = await  api.axiosPatch(`/demands/${params.demand_id}`, params);

    return response.data;
}

const demandShow = async (params) => {
    const response  = await api.axiosShow(`/demands/${params.demand_id}`, params);

    return response.data;
}

const demandShowGuest = async (params) => {
    const response  = await api.axiosShow(`/demands/${params.demand_id}/show-guest`, params);

    return response.data;
}

const demandIndex = async (params) => {
    const response  = await api.axiosGet(`/demands`, params);

    return response.data;
}

const demandSearch = async (params) => {
    const response  = await api.axiosGet(`/demands/search`, params);

    return response.data;
}

const demandFarmerApplications = async (params) => {
    const response  = await api.axiosGet(`/demands/farmer-applications`, params);

    return response.data;
}

const demandApplications = async (params) => {
    const response  = await api.axiosGet(`/demands/${params.demand_id}/applications`, params);

    return response.data;
}
//
// const demandFarmerApplicationAccepted = async (params) => {
//     const response  = await api.axiosPost(`/demands/${params.demand_id}/applications`, params);
//
//     return response.data;
// }

const demandService = {
    demandStore, demandDelete, demandFarmerApply, demandUpdate, demandUploadPhotos, demandShow, demandShowGuest, demandIndex, demandSearch, demandFarmerApplications, demandApplications
}

export default demandService;