import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useState, useEffect, useContext } from "react";

import AccountLayout from "pages/AccountLayout";
import DemandFarmControls from "../view/FarmViewControls";
import {userFarmerFarms} from "../../../features/user/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {farmIndex} from "../../../features/farm/farmSlice";
import FarmControls from "../FarmControls";
import SnackbarContext from "_helpers/snackbar-context";

const DemandFarmMap = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const { isLoaded } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
    });
    const [showDetails, setShowDetails] = useState(false);
    const [details, setDetails] = useState({ name: "", address: "", location: { lat: "", lng: "" }, photo: "" });
    const [mapCenter, setMapCenter] = useState();
    const snackbarCtx = useContext(SnackbarContext);
    // 8.507075, 124.611549
    // location
    // lat: "8.513506"
    // lng: "124.657154"

    const dummy_farm_locations = {
        farm_list: [
            {
                name: "Fresh Farm",
                address: "123 Fresh Farm Street",
                location: { lat: 8.507075, lng: 124.611549 },
                // location: { lat: 4.465, lng: 101.370 },
                photo: "/assets/images/plots/PlotPhoto1.svg",
            },
            {
                name: "Green Farm",
                address: "123 Green Farm Street",
                location: { lat: 8.513506, lng: 124.657154 },
                photo: "/assets/images/plots/PlotPhoto2.svg",
            },
            // {
            //   name: "Abundant Farm",
            //   address: "123 Abundant Farm Street",
            //   location: { lat: 4.470, lng: 101.345 },
            //   photo: "/assets/images/plots/PlotPhoto3.svg",
            // },
        ],
    };

    const farm_locations = dummy_farm_locations;

    useEffect(() => {
    }, [details]);

    const reduxFarm= useSelector((state) => state.farm);

    const loadFarms = () => {
        /*
            note: this code can be used later.
            if(farms.data) {
                setFarmList(farms.data);
            }
            */
        dispatch(
            farmIndex({
                page: 1,
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    let location = response.payload.data.farms.data[0].location;

                    setMapCenter(location);

                }
            }
        });
    };

    useEffect(() => {
        loadFarms();
    }, []);

    return (
        <AccountLayout>
            <div data-testid="demand-farm-map" className="w-full flex flex-col items-center gap-8">

                <h1 className="text-3xl">Edit Farm Details</h1>
                <FarmControls />

                {/*<DemandFarmControls />*/}

                {!isLoaded && reduxFarm.loading ? (
                    "Loading..."
                ) : (
                    <>
                    {reduxFarm.farms.data.length === 0? <div className={"text-center mt-2"}><span>{reduxFarm.message}</span></div>:

                        <section className="relative max-w-[60rem] w-full h-[60rem] rounded-xl">

                            <GoogleMap
                                zoom={12}
                                // center={mapCenter ? mapCenter : farm_locations.farm_list[0].location}
                                center={mapCenter}
                                mapContainerClassName="w-full h-full rounded-xl shadow"
                            >
                                {reduxFarm.farms.data && reduxFarm.farms.data.map((farm, index) => {

                                    return ( <Marker
                                        position={farm.location}
                                        title={farm.name}
                                        label="F"
                                        onClick={() => {
                                            setDetails({
                                                name: farm.name,
                                                address: farm.address,
                                                location: farm.location,
                                                photo: farm.photo_default,
                                                id:farm.id
                                            });
                                            setMapCenter(farm.location)
                                            setShowDetails(true);
                                        }}
                                        key={index}
                                    />)
                                })}
                            </GoogleMap>

                            {showDetails && (
                                <div className="absolute top-0 right-0 w-2/5 h-full bg-formDark/70 flex flex-col gap-4 p-4 rounded-r-xl">
                                    <img src={details.photo} alt="Photo of the farm" className={"cursor-pointer"} onClick={() => navigate(`/farms/${details.id}/edit`)} />

                                    <div className="flex w-full">
                                        <label className="indentedText">{details.name ? details.name : "Farm Name"}</label>
                                    </div>
                                    <div className="flex w-full">
                                        <label className="indentedText">{details.address ? details.address : "Farm Address"}</label>
                                    </div>
                                    <div className="flex w-full">
                                        <label className="indentedText">
                                            {details.location ? `${details.location.lat.toFixed(3)}°N, ${details.location.lng.toFixed(3)}°E` : "Farm Location"}
                                        </label>
                                    </div>
                                    <button className="borderedButton" onClick={() => setShowDetails(false)}>
                                        Close Farm Details
                                    </button>
                                </div>
                            )}
                        </section>
                    }
                    </>
                )}
            </div>
        </AccountLayout>
    );
};

export default DemandFarmMap;
