import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import demandApplicationService from "./demandApplicationService";
import catalogService from "../catalog/catalogService";

export const demandApplicationAccept = createAsyncThunk(
    "demand-applications/accept-decline",
    async (args, thunkAPI) => {
        try {
            return await demandApplicationService.demandApplicationAccept(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandApplicationDecline = createAsyncThunk(
    "demand-applications/accept-decline",
    async (args, thunkAPI) => {
        try {
            return await demandApplicationService.demandApplicationDecline(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandApplicationIndex = createAsyncThunk(
    "demand-applications/index",
    async (args, thunkAPI) => {
        try {
            return await demandApplicationService.demandApplicationIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandApplicationStore = createAsyncThunk(
    "demand-applications/store",
    async (args, thunkAPI) => {
        try {
            return await demandApplicationService.demandApplicationStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandApplicationUpdate = createAsyncThunk(
    "demand-applications/update",
    async (args, thunkAPI) => {
        try {
            return await demandApplicationService.demandApplicationUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandApplicationShow = createAsyncThunk(
    "demand-applications/:id/show",
    async (args, thunkAPI) => {
        try {
            return await demandApplicationService.demandApplicationShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)
export const demandApplicationDestroy = createAsyncThunk(
    "demand-applications/:id/delete",
    async (args, thunkAPI) => {
        try {
            return await demandApplicationService.demandApplicationDestroy(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)



const initialState = {
    data: {},
    owner: {},
    company: {},
    demandApplication: {},
    demand_applications: {
        data: [

        ]
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const demandApplicationSlice = createSlice({
    name: 'demandApplication',
    initialState,
    reducers: {
    },
    extraReducers : {
        [demandApplicationAccept.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [demandApplicationAccept.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.demand_application = payload.data.demand_application;
            state.loading=false;
            state.isSuccess = true;
        },
        [demandApplicationAccept.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [demandApplicationStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [demandApplicationStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.demand_application = payload.data.demand_application;
            state.loading=false;
            state.isSuccess = true;
        },
        [demandApplicationStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [demandApplicationUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [demandApplicationUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [demandApplicationUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [demandApplicationIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [demandApplicationIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.demand_applications = payload.data.demand_applications;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [demandApplicationIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [demandApplicationDestroy.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [demandApplicationDestroy.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.demand_applications = payload.data.demand_applications;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [demandApplicationDestroy.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        }
    }
})

// export const {  } = demandApplicationSlice.actions

export default demandApplicationSlice;