import api from "../../support/support_api";

const urustaniIntegrationCompany = async (params) => {
    const response  = await  api.axiosPost('/urustani-integrations/company', params);

    return response.data;
}

const urustaniIntegrationFarms = async (params) => {
    const response  = await  api.axiosGet('/urustani-integrations/farms', params);

    return response.data;
}

const urustaniIntegrationFarmsStore = async (params) => {
    const response  = await  api.axiosPost('/urustani-integrations/farms', params);

    return response.data;
}

const urustaniIntegrateionService = {
    urustaniIntegrationCompany,
    urustaniIntegrationFarms,
    urustaniIntegrationFarmsStore
}

export default urustaniIntegrateionService;