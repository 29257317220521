
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toggle from 'react-styled-toggle';
import editUser from "./../../images/pencil.svg";
import deleteUser from "./../../images/redBin.svg";
import { faEllipsisVertical, faXmark } from "@fortawesome/free-solid-svg-icons";
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { deletePackage, getPackages, editPackage } from "features/auth/packageSubscriptionSlice";
import SnackbarContext from "_helpers/snackbar-context";
import Text from "components/text/Text";
import { useTranslation } from "react-i18next";

const Package = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const handleClose = () => setOpen(false);
  const [packages, setPackages] = useState([]);
  const [detailsModal, setDetailsModal] = useState({})
  const [packageAvailStatus, setPackageAvailStatus] = useState(1)

  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const navigate = useNavigate();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
  };

  const handleOpen = item => {
    setDetailsModal({ ...item })
    setOpen(true);
  };

  const handleDeleteModal = item => {
    if (open === true) {
      setOpen(false)
    }

    setDetailsModal({ ...item })
    setOpenDeletePopup(!openDeletePopup)

  };

  const handleToggle = item => {
    setPackages(current =>
      current.map(packageSub => {
        if (packageSub.id === item.id) {
          return { ...packageSub, package_avail_status: item.package_avail_status === 1 ? 0 : 1 };
        }

        return packageSub;
      }),
    );

    dispatch(
      editPackage({
        id: item.id,
        package_name: item.package_name,
        subscription_fee: item.subscription_fee,
        service_charge: item.service_charge,
        package_details: item.package_details,
        package_avail_status: item.package_avail_status === 1 ? 0 : 1,
        package_period: item.package_period,
        package_period_format: item.package_period_format,
        role_id: item.role_id
      })
    );
  };

  const loadPackage = () => {
    dispatch(
      getPackages()
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        setPackages(response.payload.data.packages.data)
      }
    });
  }


  const editHandler = id => {
    navigate(`/package-management/` + id + `/edit`);
  };

  const deleteHandler = item => {
    setDetailsModal({ ...item })
    setOpenDeletePopup(!openDeletePopup)
  };

  const submitDeletePackage = () => {
    dispatch(
      deletePackage({
        id: detailsModal.id,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        setOpenDeletePopup(false)
        loadPackage()
      }
    });
  }

  const accountType = id => {
    let type;

    switch (id) {
      case 1:
        type = 'farmerBuyer';
        break;

      case 2:
        type = "buyer";
        break;

      default:
        type = "farmer";
        break;
    }

    return type;
  }

  useEffect(() => {
    loadPackage();
  }, []);

  return (
    <>
      <div className="p-2 w-full max-w mt-1">
        {packages.map(item => {
          return <div className="hidden laptop:flex flex-row items-center justify-center pb-2 font-light text-sm" key={item.id}>
            <div className="flex items-center justify-center font-medium">
              <button onClick={() => handleOpen(item)}><FontAwesomeIcon icon={faEllipsisVertical} style={{ fontSize: "15px", }} /></button>
            </div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{item.package_name}</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">RM {item.subscription_fee}/month</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{item.service_charge}% / transaction</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{<Text id={accountType(item.role_id)} />}</div>
            <div className="basis-1/4 pl-20 flex font-medium">
              <Toggle backgroundColorChecked="#00AF54" width={50} height={20} sliderHeight={12} sliderWidth={12} labelRight={item.package_avail_status === 1 ? t('active') : t('inactive')} onChange={() => handleToggle(item)} checked={item.package_avail_status === 1} />
            </div>
            <div className="basis-1/4 flex items-center justify-center font-medium">
              <img src={editUser} className="w-4 h-4 cursor-pointer mr-2" onClick={() => editHandler(item.id)} /> / <img src={deleteUser} className="w-4 h-4 cursor-pointer ml-2" onClick={() => deleteHandler(item)} /></div>
          </div>
        })}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="flex justify-between items-start" style={{ borderBottomWidth: "1px" }}>
                <div>Package Details</div>
                <button onClick={handleClose}>
                  <FontAwesomeIcon icon={faXmark} style={{ fontSize: "15px", }} />
                </button>
              </div>
            </Typography>
            <Typography id="modal-modal-description" >
              <div className="flex col-span-2 gap-4 items-center justify-center font-regular text-sm">
                <div className="mr-8 font-semibold" >
                  <div className="p-2">Package Name: </div>
                  <div className="p-2">Subscription Fee: </div>
                  <div className="p-2">Service Charge: </div>
                  <div className="p-2">Package Details: </div>
                  <div className="p-2">Status: </div>
                  <div className="p-2">Package Period: </div>
                  <div className="p-2">Account Type: </div>
                  <div className="p-2">Actions: </div>
                </div>
                <div>
                  <div className="p-2">{detailsModal.package_name}</div>
                  <div className="p-2">RM {detailsModal.subscription_fee}/month</div>
                  <div className="p-2">{detailsModal.service_charge}% / transaction</div>
                  <div className="p-2">{detailsModal.package_details !== null ? detailsModal.package_details : "-"}</div>
                  <div className="p-2">{detailsModal.package_avail_status === 1 ? t('active') : t('inactive')}</div>
                  <div className="p-2">{detailsModal.package_period} days</div>
                  <div className="p-2"><Text id={accountType(detailsModal.role_id)} /></div>
                  <div className="basis-1/4 flex items-center justify-start p-2">
                    <div className="flex mr-2 cursor-pointer" onClick={() => editHandler(detailsModal.id)}><img src={editUser} className="w-4 h-4 mx-2" />Edit</div> / <div className="flex cursor-pointer" onClick={() => handleDeleteModal(detailsModal)}><img src={deleteUser} className="w-4 h-4 cursor-pointer mx-2" /> Delete</div></div>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={openDeletePopup}
          onClose={handleDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  pb: 1,
                  mb: 1,
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <div style={{ color: '#00AF54' }}>Delete Package</div>
                <button onClick={handleDeleteModal}>
                  <FontAwesomeIcon icon={faXmark} style={{ fontSize: "15px", }} />
                </button>
              </Box>
            </Typography>

            <Typography id="modal-modal-description" >
              <Box
                sx={{
                  pb: 2,
                  mb: 2,
                  bgcolor: 'background.paper',
                }}
              >
                <div>Are you sure to delete this package?</div>
              </Box>
              <div className="flex justify-end">
                <button onClick={submitDeletePackage} className="border-none borderedwhiteButton btn-add-carts w-full desktop:w-1/4 text-center borderedInactiveButton">
                  Delete
                </button>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default Package;
