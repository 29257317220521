// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBsfoy0YXs4quO_qH3njVdaY9NbII1g9wI",
    authDomain: "tani-exchange.firebaseapp.com",
    projectId: "tani-exchange",
    storageBucket: "tani-exchange.appspot.com",
    messagingSenderId: "996627210606",
    appId: "1:996627210606:web:cfe0ffb1942a0debb8617f",
    measurementId: "G-J7H8BMSL2G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const messaging = getMessaging(app);

const isIOS = () => {
    const browserInfo = navigator.userAgent.toLowerCase();

    if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
        return true;
    }
    if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
        return true;
    }
    return false;
}

const isGranted = () => {
    if (isIOS()) return;

    return Notification.permission === "granted"
}

export const requestPermission = async (setFcmKey) => {
    if (isGranted()) {
        try {
            const currentToken = await getToken(messaging, { vapidKey: `BPYzl6NNU4WU3I5qtxqaRsdw_vq4JSH12pkKB3Umo_knMSqC9c0IK263OZhHfU5ZRibn6pQiEb382Y_3SJqRmHI` });
            if (currentToken) {
                setFcmKey(currentToken);
            } else {
                setFcmKey(null);
            }
        } catch (err) {
            setFcmKey(null);
        }
    } else {
        setFcmKey(null);
        // console.log("User Permission Denied.");
    }

}

export const onMessageListener = () =>
    new Promise((resolve) => {
        // console.log("cek masuk sini");
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });


