const BlankPage = () => {
  return (
    <div className="bg-gray-100 flex flex-col justify-center items-center min-h-screen space-y-4">
      <h1 className="text-5xl">404</h1>
      <p className="text-md">This page does not exist.</p>
    </div>
  );
};

export default BlankPage;
