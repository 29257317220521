import { useLocation, useParams } from "react-router-dom";

const DepositProgress = ({bookingPlot}) => {
  const location = useLocation();
  const params  = useParams();
  const activeTab = "text-green-800";

  let progress = 1;
  let bgColor = '';

  if (bookingPlot) {
    if (bookingPlot.status == 3) {
      progress = 2;
      bgColor = 'bg-green-500';
    } else if (bookingPlot.status == 1) {
      if (bookingPlot.payment_history?.status == '11') { // failed
        bgColor = 'bg-red-500';
      } else {
        bgColor = 'bg-yellow-500';
      }
    }
  } else {
    progress = 0;
  }

  const progressBar = {
    width: `${(progress / 2) * 100}%`,
    height: "17px"
  };

  
  return (
    <>
      <div className="bg-yellow-500">{/** This is a dummy DIV to make Tailwind can generate this custom theme color **/}</div>
      <div className="flex flex-col gap-4 w-full" style={{ height: "17px" }}>
        <div className="bg-gray flex rounded-lg" style={{ height: "17px" }}>
          <div className={` ${bgColor} flex rounded-lg justify-center h-3`} style={progressBar}></div>
        </div>
      </div>
    </>
  );
};

export default DepositProgress;
