const ProductPlotWidget = ({ info }) => {
  return (
    <section className="bg-formLight flex flex-col w-full h-full rounded-lg shadow">
      <img src={info.photo} alt="plot photo" className="w-full h-[12rem] object-cover rounded-t-lg" />
      <div className="flex flex-col p-2 gap-2">
        <label>
          Photo taken on: {info.date.day} / {info.date.month} / {info.date.year}
        </label>
        <p>{info.text}</p>
      </div>
    </section>
  );
};

export default ProductPlotWidget;
