import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import AccountLayout from "pages/AccountLayout";
import { routesPaths } from "constants/routes.constants";
import MessageEmpty from "../../ui/message/empty/MessageEmpty"
import { useDispatch, useSelector } from "react-redux";
import { orderFarmerMadeByCustomer } from "../../../features/order-farmer/orderFarmerSlice";
import OrderFarmer from "./OrderFarmer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowUpWideShort, faArrowDownWideShort } from '@fortawesome/free-solid-svg-icons';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const OrderFarmerList = () => {
  const navigate = useNavigate();
  const [typeOrder, setTypeOrder] = useState('Incoming');
  const [sorting, setSorting] = useState('desc');
  const [sortType, setSortType] = useState('created_at');
  const dispatch = useDispatch();
  const reduxOrderFarmer = useSelector((state) => state.orderFarmer);
  const [orderList, setOrderList] = useState({
    data: []
  })
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const addHandler = () => {
    navigate(routesPaths.CATALOG);
  };

  const loadOrderFarmers = (pageNumber, itemsPerPage, keyword, sort) => {
    dispatch(
      orderFarmerMadeByCustomer({
        limit: itemsPerPage,
        page: pageNumber,
        keyword: keyword,
        sortType: sortType,
        sort: sort
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        alert(response.payload);
      } else {
        let result = response.payload.data.order_farmers;
        setOrderList(result);
      }
    });
  }

  const handlePageChange = (newPageNumber, newItemsPerPage) => {
    setCurrentPage(newPageNumber);
    setItemsPerPage(newItemsPerPage);
    loadOrderFarmers(newPageNumber, newItemsPerPage, '', 'desc');
  };

  const handleChangePage = (event, newPageNumber) => {
    handlePageChange(newPageNumber, itemsPerPage, '');
  };

  const handleLimitPage = (newItemsPerPage) => {
    setCurrentPage(1);
    setItemsPerPage(newItemsPerPage);
    loadOrderFarmers(1, newItemsPerPage, '', 'desc');
  };

  const searchNestedObject = (obj, searchValue) => {
    if (searchValue === '') {
      return "";
    } else {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          const result = searchNestedObject(obj[key], searchValue);
          if (result) {
            return parseInt(key);
          }
        } else if (obj[key].toLowerCase().includes(searchValue.toLowerCase())) {
          return key;
        }
      }
    }
  }

  useEffect(() => {
    loadOrderFarmers(1, 10, '', 'desc');
  }, []);

  return (
    <AccountLayout>
      <div data-testid="order-list" className="flex flex-col items-start gap-6 p-4 mb-5">
        <h1 className="text-2xl text-left font-bold" style={{ color: "#395B50" }}>My Orders</h1>
        <div className="ml-1 mb-2 mt-2">
          <section className="flex gap-8">
            <label
              className={
                typeOrder === "Incoming"
                  ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                  : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
              }
              onClick={() => setTypeOrder("Incoming")}
            >
              Incoming Orders
            </label>
            <label
              className={
                typeOrder === "Advanced"
                  ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                  : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
              }
              onClick={() => {
                setTypeOrder("Advanced");
                navigate(routesPaths.MANAGE_ADVANCE_BOOKING);
              }}
            >
              Advanced Booking
            </label>
            <label
              className={
                typeOrder === "Auction"
                  ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                  : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
              }
              onClick={() => {
                setTypeOrder("Auction");
                navigate(routesPaths.MANAGE_AUCTION_BIDDING);
              }}
            >
              Auction / Bidding
            </label>
          </section>
        </div>
        <div className="w-full bg-white flex flex-col shadow rounded-lg">
          <div className="flex w-full gap-x-4 justify-between desktop:items-center py-3 px-4 flex-col gap-y-2 desktop:flex-row border-b-2 space-y-4 laptop:space-y-0">
            <div className="laptop:flex laptop:flex-nowrap w-full laptop:w-2/3 laptop:items-center laptop:space-x-6 justify-star space-y-2 laptop:space-y-0">
              <div className="flex w-full desktop:w-1/3 items-center space-x-2">
                <div className="flex flex-col font-semibold tablet:flex justify-start desktop:w-fit">
                  Sort
                </div>
                <div className="flex items-center tablet:flex justify-start w-full">
                  <select value={sortType} className="select-custom mr-2" onChange={({ target }) => { setSortType(target.value); setSorting('desc'); loadOrderFarmers(1, 10, '', 'desc') }}>
                    <option value="created_at">
                      Date
                    </option>
                    <option value="amount_total_overall">
                      Price
                    </option>
                  </select>
                  {sorting === 'desc' ? <FontAwesomeIcon icon={faArrowDownWideShort} onClick={() => { setSorting('asc'); loadOrderFarmers(1, 10, '', 'asc') }} /> : <FontAwesomeIcon icon={faArrowUpWideShort} onClick={() => { setSorting('desc'); loadOrderFarmers(1, 10, '', 'desc') }} />}
                </div>
                {/* <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
              Filter
            </div>
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
              <select className="select-custom ">
                <option value="DATE" selected>
                  Farm
                </option>
                <option value="DATE" selected>
                  Product
                </option>
              </select>
            </div> */}
              </div>
              <div className="flex items-center tablet:flex justify-start w-full">
                <form className="tablet:flex justify-start desktop:w-1/2 w-full items-cente">
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </div>
                    <input name="search-order" type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400" placeholder="Search" onChange={({ target }) => loadOrderFarmers(1, 10, target.value, 'desc')} />
                  </div>
                </form>
              </div>
            </div>
            <div className="flex tablet:flex desktop:w-1/3 justify-end">
              <button
                type="button"
                onClick={addHandler}
                className="border-none saveButton btn-add-carts  w-full desktop:w-1/2 text-center" style={{ marginTop: "0px", height: "35px" }}>
                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                Add New Order
              </button>
            </div>
          </div>
          {/*<button type="button" className="borderedButton h-[9rem]" onClick={addHandler}>*/}
          {/*<label className="cursor-pointer text-2xl">Add New Order</label>*/}
          {/*</button>*/}
          <div className="p-4 w-full max-w">
            <div className="hidden laptop:flex flex-row items-center justify-center pb-3" style={{ borderBottomWidth: "1px" }}>
              <div className="basis-1/4 flex items-center justify-center font-medium"><b>Order</b></div>
              <div className="basis-1/4 flex items-center justify-center font-medium"><b>Price</b></div>
              <div className="basis-1/4 flex items-center justify-center font-medium"><b>Status</b></div>
              <div className="basis-1/4 flex items-center justify-center font-medium"></div>
            </div>
            {reduxOrderFarmer.order_farmers.data && reduxOrderFarmer.order_farmers.data.map((orderFarmer, index) => (
              <OrderFarmer orderFarmer={orderFarmer} key={index} />
            ))}
            <div className="mt-2"><MessageEmpty loading={reduxOrderFarmer.loading} length={reduxOrderFarmer.order_farmers.data.length} message={reduxOrderFarmer.data.message} /></div>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 sm:grid-rows-2 w-full">
          <div className="flex items-center tablet:flex lg:justify-start justify-center w-full ">
            <span>
              <select className="select-custom" onChange={(e) => handleLimitPage(e.target.value)}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={1000}>All</option>
              </select>
            </span>
            <span className="ml-2 text-sm">
              <label>entries per page</label>
            </span>
          </div>
          <div className="flex lg:justify-end justify-center">
            <Stack spacing={2} >
              <Pagination count={orderList.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={currentPage} />
            </Stack>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};

export default OrderFarmerList;
