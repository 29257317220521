import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserInfo,
  authUpdateCompany,
  authUpdateCompanyPhoto,
  authGetCompany,
  authGetUser
} from "../../features/auth/authSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { fileDelete } from "features/file/fileSlice";
import { delyvaIntegrationStore } from "features/delyva-intgrations/delyvaIntegrationsSlice";

const AccountCompany = () => {
  const { currentUser, updating, currentCompany } = useSelector((state) => state.auth);
  const [companyID, setCompanyID] = useState(currentCompany?.identity_id);
  const [companyName, setCompanyName] = useState(currentCompany?.name);
  const [regNumber, setRegNumber] = useState(currentCompany?.registration_number);
  const [companyAddress, setCompanyAddress] = useState(currentCompany?.billing_address);
  const [contactNumber, setContactNumber] = useState(currentCompany?.business_contact_number);
  const [delyaCompanyCode, setDelyaCompanyCode] = useState("");
  const [inputElement, setInputElement] = useState("");
  const [delyvaUsername, setDelyaUsername] = useState("");
  const [delyvaPassword, setDelyvaPassword] = useState("");

  const dispatch = useDispatch();

  const snackbarCtx = useContext(SnackbarContext);

  const submitHandler = (event) => {
    dispatch(
      authUpdateCompany({
        identity_id: companyID,
        name: companyName,
        registration_number: regNumber,
        billing_address: companyAddress,
        business_contact_number: contactNumber,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });

    event.preventDefault();
  };

  const integrateDelyva = (event) => {
    dispatch(
      delyvaIntegrationStore({
        companyCode: delyaCompanyCode,
        username: delyvaUsername,
        password: delyvaPassword,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        dispatch(authGetUser());
        snackbarCtx.displayMsg(response.payload.message, "success");
      }
    });

    event.preventDefault();
  };

  const onFileChange = (event) => {
    let selectedFile = event.target.files[0];

    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);
    formData.append("store", "single");
    formData.append("model", "company");
    formData.append("type", "photo");
    formData.append("company_id", currentCompany.id);

    dispatch(authUpdateCompanyPhoto(formData)).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        alert(response.payload);
      }
    });
  };

  const itemDelete = (photo) => {
    if (window.confirm("Are you sure you want to delete this Photo?")) {
      dispatch(
        fileDelete({
          file_id: photo.id,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
        } else {
          let message = response.payload.message;
          dispatch(authGetCompany())
        }
      });
    }
    else {
    }
  }

  const companyPhotoSrc = typeof currentCompany.photo === 'object' ? currentCompany.photo.src
    : currentCompany.photo;

  const deletablePhoto = companyPhotoSrc.indexOf("ProfileIcon.png") > 0 ? false : true; //typeof currentCompany.photo === 'object' ? true : false;


  useEffect(() => {
    reloadCompany();
  }, []);

  const reloadCompany = () => {
    dispatch(getCurrentUserInfo());

    setCompanyID(currentCompany?.identity_id);
    setCompanyName(currentCompany?.name);
    setRegNumber(currentCompany?.registration_number);
    setCompanyAddress(currentCompany?.billing_address);
    setContactNumber(currentCompany?.business_contact_number);
  }

  return (
    <form className="self-start flex gap-4 flex-wrap w-full " onSubmit={submitHandler}>
      <div className="col-lg-5">
        <div className="flex items-start ">
          <img
            src={companyPhotoSrc}
            alt="profile"
            onClick={() => currentUser.is_company_owner ? inputElement.click() : null}
            className="bg-white w-25 h-25 cursor-pointer object-cover aspect-square border-[.01rem] border-green-400 rounded-md"
          />
          <input accept="image/png, image/jpeg" className={"hidden"} type="file" onChange={onFileChange}
            ref={(input) => setInputElement(input)} />
        </div>

        <div className="flex flex-col space-y-6">
          <section className="flex flex-col space-y-1">
            <label>Company ID:</label>
            <input
              type="text"
              className={"bg-gray-300 input-search"}
              placeholder={currentCompany?.identity_id}
              value={companyID}
              onChange={({ target }) => setCompanyID(target.value)}
              disabled={true}
            />
          </section>
          <section className="flex flex-col space-y-1">
            <label>Company Name:</label>
            <input
              type="text"
              className={currentUser.is_company_owner ? "input-search" : "bg-gray-300 input-search"}
              placeholder={currentCompany?.name}
              value={companyName}
              onChange={({ target }) => setCompanyName(target.value)}
              disabled={!currentUser.is_company_owner}
            />
          </section>
          <section className="flex flex-col space-y-1">
            <label>Company Registration Number:</label>
            <input
              type="text"
              className={currentUser.is_company_owner ? "input-search" : "bg-gray-300 input-search"}
              placeholder={currentCompany?.registration_number}
              value={regNumber}
              onChange={({ target }) => setRegNumber(target.value)}
              disabled={!currentUser.is_company_owner}
            />
          </section>
          <section className="flex flex-col space-y-1">
            <label>Company Address (Billing Address):</label>
            <input
              type="text"
              className={currentUser.is_company_owner ? "input-search" : "bg-gray-300 input-search"}
              placeholder={currentCompany?.billing_address}
              value={companyAddress}
              onChange={({ target }) => setCompanyAddress(target.value)}
              disabled={!currentUser.is_company_owner}
            />
          </section>
          <section className="flex flex-col space-y-1">
            <label>Business Contact Number:</label>
            <input
              type="text"
              className={currentUser.is_company_owner ? "input-search" : "bg-gray-300 input-search"}
              placeholder={currentCompany?.business_contact_number}
              value={contactNumber}
              onChange={({ target }) => setContactNumber(target.value)}
              disabled={!currentUser.is_company_owner}
            />
          </section>
          <div className="row">
            <div className="col-md-4">
              <button
                type="submit"
                className={currentUser.is_company_owner ? "saveButton" : "saveButton bg-gray-500 text-white"}
                disabled={!currentUser.is_company_owner}
              >
                {updating ? "Processing, please wait.." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {(currentUser.role_name === "both" || currentUser.role_name === "farmer") && <div className="flex flex-col space-y-1 col-lg-5 md:mt-2">
        <p className="text-2xl font-bold text-delyva">Delyva Integration</p>
        {currentCompany?.delyva_integration === null ? (<div>
          <label>Company Code: </label>
          <input
            type="text"
            className={currentUser.is_company_owner ? "input-search" : "bg-gray-300 input-search"}
            placeholder="Eg: my"
            value={delyaCompanyCode}
            onChange={({ target }) => setDelyaCompanyCode(target.value)}
          />
          <label>Username:</label>
          <input
            type="text"
            className={currentUser.is_company_owner ? "input-search" : "bg-gray-300 input-search"}
            placeholder=""
            value={delyvaUsername}
            onChange={({ target }) => setDelyaUsername(target.value)}
          />
          <label>Password:</label>
          <input
            type="text"
            className={currentUser.is_company_owner ? "input-search" : "bg-gray-300 input-search"}
            placeholder=""
            value={delyvaPassword}
            onChange={({ target }) => setDelyvaPassword(target.value)}
          />
          <button
            type="button"
            onClick={integrateDelyva}
            className={currentUser.is_company_owner ? "saveButton lg:w-1/3 mt-2" : "saveButton mt-2 bg-gray-500 text-white lg:w-1/3"}
          >
            {updating ? "Processing, please wait.." : "Integrate"}
          </button>
          <p className="mt-4 text-sm"><i>Note 1: If you do not have Delyva account, please register at <a className="text-delyva" href="https://my.delyva.app/customer/signup" target="_blank">this website</a></i></p>
          <p className="text-sm"><i>{'Note 2: Company code can be retrieved in Settings > API Integration'}</i></p>
        </div>) : (
          <div>
            <label>Delyva API Key:</label>
            <input
              type="text"
              className="bg-gray-300 input-search"
              placeholder=""
              value={currentCompany?.delyva_integration?.delyva_api_key}
              onChange={({ target }) => setDelyvaPassword(target.value)}
              disabled
            />
            <label>Delyva Customer ID:</label>
            <input
              type="text"
              className="bg-gray-300 input-search"
              value={currentCompany?.delyva_integration?.delyva_customer_id}
              onChange={({ target }) => setDelyvaPassword(target.value)}
              disabled
            />
          </div>)}
      </div>}
    </form>
  );
};

export default AccountCompany;
