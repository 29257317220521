import { useCallback, useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "components/header/Header";
import Snackbar from "components/ui/snackbar/Snackbar";
import SnackbarContext from "_helpers/snackbar-context";
import AppRoutes from "routes/AppRoutes";
import { onMessageListener, requestPermission } from "firebase-config";
import { useState } from "react";
import SnackbarNotif from "components/ui/snackbar/SnackbarNotif";
import { useDispatch, useSelector } from "react-redux";
import { updateFCMKey, updateNotifFirebase } from "features/auth/authSlice";
import { useEffect } from "react";
import SnackbarAlertPermission from "components/ui/snackbar/SnackbarAlertPermission";
import { updateRoomChat, updateRoomMsgChat } from "features/room-chat/roomChatSlice";

function App() {
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isShowNotif, setShowNotif] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(true);
  const { isAcitiveRoomChat } = useSelector((state) => state.roomChat);

  const unsubscribe = onMessageListener().then((payload) => {
    setNotification({
      title: payload?.notification?.title,
      body: payload?.notification?.body,
    });

    if (payload?.data?.type == "NOTIF") {
      dispatch(updateNotifFirebase(1));
      setTimeout(() => {
        setShowNotif(true);

      }, 200);
    } else {
      dispatch(updateRoomChat(1));
      if ((window.location.pathname.substring(0, 15) != '/room-chat/live') && !isAcitiveRoomChat) {
        // console.log("masuk gening");
        dispatch(updateRoomMsgChat(1));
      }
    }

    //send data for get notif
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      // Send a message to the service worker to get the variable
      navigator.serviceWorker.controller.postMessage({ type: 'getVariable' });
      // console.log(isTabVisible);
    }

  });

  const handleVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    requestPermission(setFcmKey);
  }, []);

  const setFcmKey = (token) => {
    dispatch(updateFCMKey(token));
  }

  //send data for get notif
  useEffect(() => {
    if (isTabVisible) {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        // Send a message to the service worker to get the variable
        navigator.serviceWorker.controller.postMessage({ type: 'getVariable' });
        // console.log(isTabVisible);
      }
      dispatch(updateRoomChat(1));
      // console.log(window.location.pathname.substring(0, 15));
      if ((window.location.pathname.substring(0, 15) != '/room-chat/live') && !isAcitiveRoomChat) {
        // console.log("masuk gening");
        dispatch(updateRoomMsgChat(1));
      }
    }
  }, [isTabVisible]);

  return (
    <div className="flex flex-col">
      <BrowserRouter>
        <Header />
        <AppRoutes />
      </BrowserRouter>
      <SnackbarAlertPermission setFcmKey={setFcmKey} />
      {snackbarCtx.isDisplayed && <Snackbar />}
      {isShowNotif && <SnackbarNotif title={notification.title} body={notification.body} setIsShowNotif={setShowNotif} />}
    </div>
  );
}

export default App;
