import { useEffect, useContext } from "react";
import CartProduct from "./CartProduct";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import deliveryIcon from "images/delivery_icons.png";
import { cartIndex } from "features/cart/cartSlice";

const CartProductList = ({ selectedCourier, isLoading, courierList, loadCourier, setSelectedCourier, setShippingCharge, setTotalAmount, cartsArr, totalAmount, productPrice, shippingCharge, setProductPrice }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const { cartsByFarmer, cartsTotal } = useSelector((state) => state.cart);

  const loadCarts = () => {
    dispatch(
      cartIndex({
        status: 0,
      })
    ).then(function (response) {
      console.log(response?.payload);
    })
  }

  const changeAddress = () => {
    navigate('/account/edit#address');
  }

  useEffect(() => {
    loadCarts();
  }, []);

  return (
    <>
      <div data-testid="cart-product-list" className="flex flex-col gap-4 ">
        <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
          <div className="w-full flex flex-row items-center justify-center py-3" style={{ borderBottomWidth: "1px" }}>
            <img
              src={deliveryIcon}
              alt="photo of product"
              className="cartProductLarge:inline min-w-[2rem] max-w-[2rem] min-h-[2rem] max-h-[2rem] object-cover aspect-square rounded-full"
            /> &nbsp;
            <div className="flex items-center justify-center font-medium">Delivery Address</div>
          </div>
          <div className="w-full laptop:flex flex-row items-center justify-center py-3" style={{ borderBottomWidth: "1px" }}>
            <div className="basis-3/4 flex items-center justify-center font-medium">
              {currentUser?.first_name}&nbsp;{currentUser?.last_name}&nbsp; | {currentUser?.user_address?.address?.phone} <br />
              {currentUser?.user_address?.address?.address} <br />{currentUser?.user_address?.address?.city}&nbsp;,&nbsp;{currentUser?.user_address?.address?.postcode}
              &nbsp;,&nbsp;{currentUser?.user_address?.address?.state}&nbsp;,&nbsp;{currentUser?.user_address?.address?.country}
            </div>
            <div className="flex items-center justify-center font-medium lg:p-0 p-3">
              <button type="button" className="btn-border-green" onClick={() => {
                changeAddress()
              }}>
                Change Address
              </button>
            </div>
          </div>
        </div>
      </div>

      <div data-testid="cart-product-list" className="flex flex-col gap-4">
        <div className="w-full max-w bg-white flex flex-col items-center shadow  rounded-lg">
          <CartProduct carts={cartsByFarmer} loadCarts={loadCarts} selectedCourier={selectedCourier} isLoading={isLoading} courierList={courierList} loadCourier={loadCourier} setSelectedCourier={setSelectedCourier} setShippingCharge={setShippingCharge} setTotalAmount={setTotalAmount} cartsArr={cartsArr} totalAmount={totalAmount} shippingCharge={shippingCharge} productPrice={productPrice} setProductPrice={setProductPrice} />
          {cartsTotal == 0 && (<h1>The cart is empty</h1>)}
        </div>
      </div>
    </>
  );
};

export default CartProductList;