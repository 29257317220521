import { faCheck, faPlug, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import DemandMarketPage from "pages/DemandMarketPage";
import { Col, Row } from "react-bootstrap";
import DemandMarketControls from "./DemandMarketControls";
import DemandMarketWidget from "./DemandMarketWidget";

const DemandMarketList = ({ demands, selectedDemandHandler, search, searchByHandler, inputHandler, showSideBar }) => {
  return (
    <div className="p-3">
      <Row className="mb-3">
        <Col md={6} sm={12}>
          <span className="title-content mb-1">
            Market Demands
          </span>
        </Col>
        <Col md={6} sm={12} className="text-right">
          <DemandMarketControls search={search} searchByHandler={searchByHandler} inputHandler={inputHandler} />
        </Col>
      </Row>
      {/* Actual Catalog */}
      <div className={clsx("w-full grid place-items-center tablet:grid-cols-2 mb-5 gap-4", !showSideBar ? "laptop:grid-cols-5 wide:grid-cols-6" : "laptop:grid-cols-3 wide:grid-cols-5")}>

        {demands.data.map((demand, index) => (
          <DemandMarketWidget demand={demand} selectedDemandHandler={selectedDemandHandler} key={index} />
        ))}

      </div>

    </div>

  );
};

export default DemandMarketList;
