import { useEffect, useContext } from "react";
import AccountLayout from "pages/AccountLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SnackbarContext from "_helpers/snackbar-context";
import CropEdit from "../crops/CropEdit"
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { companyCropIndex, companyCropStore } from "features/company-crop/companyCropSlice";

// TODO - All select has a delay on setting state hook, so this must be fixed.
const StoreEdit = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);
    const { companyCrops } = useSelector((state) => state.companyCrop);

    const update = (event) => {
        event.preventDefault();
    };

    const addCrop = () => {
        dispatch(companyCropStore({
            master_crop_id: '',
            variety: '',
            status: ''
        })).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure");
            } else {
                loadCompanyCrops();
            }
        });
    }

    const loadCompanyCrops = () => {
        dispatch(companyCropIndex())
    }

    useEffect(() => {
        loadCompanyCrops();
    }, []);

    return (
        <AccountLayout>
            <div className="w-full flex flex-col gap-4 p-4 laptop:p-6 wide:p-6">
                <div>
                    <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
                        {" Go Back"}
                    </label>
                    <div className="title-content">
                        Crop & Stock Basic Information
                    </div>
                </div>
                <div className="flex flex-col rounded-lg shadow bg-card-white-new mb-5">
                    <form onSubmit={update}>
                        <div className="w-full">
                            {/* <Row>
                                <Col md={12} className="mb-3">
                                    <div className="title-sidebar">
                                        Collection Center
                                    </div>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col md={12} className="mb-3">
                                    <div className="title-sidebar">
                                        Crops
                                    </div>
                                </Col>

                            </Row>
                            <div className="space-y-4">
                                {companyCrops && companyCrops.data.map((companyCrop, companyCropIndex) => {
                                    return (<CropEdit companyCrop={companyCrop} key={companyCropIndex} companyCropIndex={companyCropIndex} reloadCompanyCrop={loadCompanyCrops} />)
                                })}
                            </div>
                            <Row className="mt-4">
                                <Col md={12}>
                                    <button
                                        onClick={addCrop}
                                        className="btn-home btn-browser-product laptop:w-[12rem] wide:w-[12rem]"
                                        style={{ marginTop: "0px", height: "35px" }}>
                                        <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                        Add Crop
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </div>
            </div>
        </AccountLayout>
    );
};

export default StoreEdit;
