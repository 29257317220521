export const auctionStatus = {
    "Bid Place": {
        'label': 'Bid Place',
        'badge': 'lightBlue'
    },
    "Outbid": {
        'label': 'Outbid',
        'badge': 'extraWarning'
    },
    "Bid Lost": {
        'label': 'Bid Lost',
        'badge': 'pastelRed'
    },
    "Cancelled": {
        'label': 'Cancelled',
        'badge': 'pastelRed'
    },
    "Ended": {
        'label': 'Ended',
        'badge': 'pastelRed'
    },
    "Cancelled": {
        'label': 'Cancelled',
        'badge': 'pastelRed'
    },
    "Bid Won": {
        'label': 'Bid Won',
        'badge': 'almostMain'
    },
    "Booking Created": {
        'label': 'Booking Created',
        'badge': 'almostMain'
    },
};