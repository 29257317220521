import { routesPaths } from "constants/routes.constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserInfo } from "../../features/auth/authSlice";
import { useEffect } from "react";

const ProductWidget = ({ product }) => {
  const navigate = useNavigate();

  const photo_default = "/assets/images/products/WheatIcon.svg";

  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCurrentUserInfo());
  }, []);

  const clickHandler = () => {
    if (isLoggedIn) {
      navigate(`${routesPaths.CATALOG}/${product.catalog.id}/show`);
    } else {
      alert("You need to login to view this content. Please Login. Not a Member? Join Us.");
      window.location = "/login";
    }
  };

  return (
    <div
      data-testid="product-catalog-widget"
      className="cursor-pointer relative w-full h-full rounded-lg shadow-lg border-2"
      onClick={clickHandler}
    >
      <img
        src={product.thumbnail_photo.length > 0 ? product.thumbnail_photo[0].photo : product?.photo_default}
        alt="photo of the product"
        className="object-cover w-full h-full aspect-square rounded-lg"
      />
      <section className="absolute bg-black/50 text-white left-0 bottom-0 rounded-b-lg flex flex-col w-full h-1/4 p-2 box-img-card">
        <label className="cursor-pointer font-in-box">{product?.name ? product.name : "Product Name"}</label>
        <label className="cursor-pointer font-in-box">{product?.scientific_name && <span>( {product?.scientific_name} )</span>} </label>
      </section>
    </div>
  );
};

export default ProductWidget;
