import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { routesPaths } from "constants/routes.constants";
import {
  orderFarmerGetByOrderNumber
} from "../../../../features/order-farmer/orderFarmerSlice";
import { Col, Row } from "react-bootstrap";
import PaymentProgress from "./PaymentProgress";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const PaymentResultPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [paymentMessage, setPaymentMessage] = useState(null);

  const loadDetail = () => {
    setIsLoading(true);
    dispatch(
      orderFarmerGetByOrderNumber({
        order_number: params.order_number
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        alert(response.payload);
      } else {
        let order_farmer = response.payload.data.order_farmer;
        setOrder(order_farmer.order);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    loadDetail();
  }, []);

  useEffect(() => {
    // Check for special case where the status is "Awaiting Deposit" but the payment is still being processed
    if (order) {
      if (order.status === 3 || order.status === 9) {
        setPaymentMessage(<>
          <center><FontAwesomeIcon icon={faCircleCheck} color="green" size="6x" /></center><br />
          <p className="text-center mt-1">You have successfully paid for Order #{(order) ? order.order_number : ''}.</p>
          <p className="text-center mt-1">Farmer will be notified to process your order.</p>
          <p className="text-center mt-1">Thank You!</p>
          <center>
            <button type="button" className="text-center mt-4 btn-green w-fit" onClick={() => { navigate(routesPaths.CUSTOMER_ORDER_FARMERS); }}>
              Go to Incoming Order section
            </button>
          </center>
        </>);
      } else if (order.status === 1) {
        if (order.payment_history?.status == '11') { // Failed
          setPaymentMessage(<>
            <center><FontAwesomeIcon icon={faCircleXmark} color="red" size="6x" /></center><br />
            <p className="text-center mt-1">We're sorry, but it seems that there was an issue processing your payment for the remaining balance of Order <strong>#{(order) ? order.order_number : ''}</strong>.</p>
            <p className="text-center mt-1">Please double-check the payment details you've entered, including card information and billing address, and try again. If the issue persists, you might want to contact our customer support for assistance.</p>
            <p className="text-center mt-1">We appreciate your patience!</p>
          </>);
        } else if (order.payment_history?.status == '22') { // Pending
          setPaymentMessage(<>
            <center><FontAwesomeIcon icon={faCircleExclamation} color="#cc9a06" size="6x" /></center><br />
            <p className="text-center mt-1">Your payment for the remaining balance of Order <strong>#{(order) ? order.order_number : ''}</strong> is currently pending confirmation by our payment processing partner.</p>
            <p className="text-center mt-1">This may take some time as we wait for approval from the acquirer. We appreciate your patience during this process.</p>
            <p className="text-center mt-1">Should you have any questions or need assistance, please don't hesitate to reach out to our support team.</p>
          </>);
        }
      }
    }
  }, [order]);

  return (
    <Row className="container">
      <Col md={3}></Col>
      <Col md={6}>
        <div className="flex flex-col items-center mt-4">
          <div style={{ minWidth: "80%" }} >
            <PaymentProgress step={step} order={(order) ? order : null} />
          </div>
        </div>
        <Row className="mt-3">
          <Col md={12}>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
              <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                <div className="p-4 w-full max-w">
                  {paymentMessage}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={3}>
        <div className="flex flex-col items-center mt-4" >
          <div style={{ minWidth: "80%", height: "17px" }} >
            {/* <DepositProgress/> */}
          </div>
        </div>
        <Row className="mt-3">
          <Col md={12}>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
              <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                <div className="p-4 w-full max-w">
                  <p><b>Total Price</b> <span className="float-right">RM {(order) ? order.amount_total_formatted : ''}</span></p>
                  <p><b>+Delivery</b> <span className="float-right">RM {(order) ? order.amount_delivery_formatted : ''}</span></p>
                  {order && order.amount_deposit > 0 && <p className="text-green"><b>-Deposit Paid</b> <span className="float-right">RM {(order) ? order.amount_deposit_formatted : ''}</span></p>}
                  <br />
                  <hr />
                  <p className="mt-2"><b>Total</b> <span className="float-right">RM {(order) ? order.remaining_balance_formatted : ''}</span></p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PaymentResultPage;
