import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  storeService from "./storeService";

export const storeIndex = createAsyncThunk(
    "stores/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await storeService.storeIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const storeStore = createAsyncThunk(
    "stores/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await storeService.storeStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const storeUpdate = createAsyncThunk(
    "stores/update",
    async (args, thunkAPI) => {
        try {
            return await storeService.storeUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const storeUploadPhoto = createAsyncThunk(
    "stores/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await storeService.storeUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const storeShow = createAsyncThunk(
    "stores/:id/show",
    async (args, thunkAPI) => {
        try {
            return await storeService.storeShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const storeCheckForCrops = createAsyncThunk(
    "stores/:id/show",
    async (args, thunkAPI) => {
        try {
            return await storeService.storeCheckForCrops(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    store: {},
    stores: [],
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        storeSet: (state, newStore) => {
            state.store = newStore.payload;
        },
    },
    extraReducers : {
        [storeStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [storeStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.store = payload.data.store;
            state.loading=false;
            state.isSuccess = true;
        },
        [storeStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [storeUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [storeUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [storeUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [storeShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [storeShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.store = payload.data.store;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [storeShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [storeIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [storeIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.stores = payload.data.stores;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [storeIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [storeCheckForCrops.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [storeCheckForCrops.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.store = payload.data.store;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [storeCheckForCrops.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export const { storeSet } = storeSlice.actions

export default storeSlice;