import { useRef } from "react";
import HomeForm from "components/home/HomeForm";
import Intro from "components/home/Intro";
import Footer from "components/footer/footer";
import "./HomePage.css";
import Sitemap from "components/home/Sitemap";
import ScrollToTop from './../../components/home/ScrollToTop';

const HomePage = () => {
  const introSection = useRef(null);

  const scrollDown = () => {
    window.scrollTo({
      top: introSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <div className="main-container">
      <ScrollToTop />
      <div className="flex flex-col min-h-screen space-y-10 bg-hills-wallpaper bg-no-repeat bg-center bg-cover justify-center">
        <div className="flex flex-col items-center">
          <HomeForm clickIntro={scrollDown} />
        </div>
      </div>
      <div className="intro" ref={introSection}>
        <div className="flex flex-col min-h-screen space-y-10 py-12 bg-intro-wallpaper bg-no-repeat bg-center bg-cover intro-image items-center item justify-center">
          <Intro />
        </div>
      </div>
      <Sitemap />
      <Footer />
    </div>
  );
};

export default HomePage;
