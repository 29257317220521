import clsx from "clsx";
import { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Text from "components/text/Text";
import { forgotPassword } from "../../features/auth/forgotPasswordSlice";
import SnackbarContext from "_helpers/snackbar-context";

import googleLogo from "images/Google.svg";
import facebookLogo from "images/Facebook.svg";
import buyyerPng from "images/login/buyer.png";
import farmerPng from "images/login/farmer.png";
import styles from "./ForgotPassword.module.scss";
import { getAuthRememberMe, getCurrentUserInfo, setAuthRememberMe } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";

const ForgtoPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [accountType, setAccountType] = useState(0);
  const snackbarCtx = useContext(SnackbarContext);
  const dispatch = useDispatch();

  // const reduxAuth = useSelector((state) => state.auth);
  const { _rememberMe } = useSelector((state) => state.auth);

  const submitHandler = (event) => {
    dispatch(
      forgotPassword({
        email: email,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
      }
    });

    event.preventDefault();
  };

  return (
    <div className="flex justify-center">
      <div>
        
        <form data-testid="forgotPassword" className={clsx("flex", "flex-col", "p-8", "space-y-6", styles.loginForm)} onSubmit={submitHandler}>
          <span className={clsx("self-center", "text-2xl", styles.titleLogin)}>
            <Text id="Forgot Password" />
          </span>
          <div className="flex flex-col space-y-4">
            <input
              type="text"
              className={clsx("", styles.inputUserPass)}
              placeholder="Email"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
          </div>
        
          <div className={clsx(styles.dontHaveAccount)}>
            Don’t have an account? <Link
              to="/register/details"
              className={clsx("cursor-pointer")}
            >
              <u>Sign up here!</u>
            </Link>
          </div>

          <button className={clsx(styles.submit, "bg-green-500", "text-white", "hover:bg-green-600")} type="submit" style={{ marginTop: "0px" }}>
            <Text id="Submit" />
          </button>


        </form>
      </div>

    </div>

  );
};

export default ForgtoPasswordForm;
