import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";
import { useDispatch, useSelector } from "react-redux";
import { authGetUser, getCurrentUserInfo } from "features/auth/authSlice";
import taniExchangeLogo from "images/home/taniexchange.png";
import britishFlag from "images/BritishFlag.png";
import styles from "./Header.module.scss";
import clsx from "clsx";
import { settingDepositGet } from "features/settingDeposit/settingDepositSlice";
import { useStateWithCallback } from "support/support_function";
import { getTotalMsgByUser } from "features/room-chat/roomChatSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling, faHouse, faShop, faEnvelope, faUser, faCartShopping, faBell, faRightToBracket, faFilePen } from '@fortawesome/free-solid-svg-icons';
import { cartIndex } from "features/cart/cartSlice";

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn, notif_count } = useSelector((state) => state.auth);
  const { cartsTotal } = useSelector((state) => state.cart);
  const [showMenu, setShowMenu] = useState(false);
  const [isEnabledRegistration, setIsEnabledRegistration] = useStateWithCallback(0);
  const [countNotif, setCountNotif] = useState(0);
  const [totalMsgUnRed, setTotalMsgUnRed] = useState(0);
  const { room_chat_msg_count } = useSelector((state) => state.roomChat);

  const loadSettingRegistration = () => {
    dispatch(
      settingDepositGet()
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
      } else {
        let result = response.payload.data.setting;
        setIsEnabledRegistration(result.is_registration_closed, () => {
        });
      }
    });
  }

  const loadCarts = () => {
    dispatch(
      cartIndex({
        status: 0,
      })
    ).then(function (response) {
      console.log(response?.payload);
    })
  }

  const loadTotalMsgUnread = () => {
    dispatch(
      getTotalMsgByUser()
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
      } else {
        let result = response.payload.data;
        setTotalMsgUnRed(result.total);
      }
    });
  }

  useEffect(() => {
    dispatch(getCurrentUserInfo());
    loadSettingRegistration();
    loadCarts();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(authGetUser());
    }
  }, [isLoggedIn]);

  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data.type === 'variableValue') {
        const variableValueLast = event.data.value;
        setCountNotif(variableValueLast);
      }
    });
  }, [notif_count]);

  useEffect(() => {
    if (isLoggedIn) {
      loadTotalMsgUnread();
    }
  }, [isLoggedIn, room_chat_msg_count]);

  let currentRoute;
  if (currentUser) {
    switch (currentUser.role_active) {
      case "super-admin":
        currentRoute = routesPaths.ACCOUNTADMIN;
        break;
      default:
        currentRoute = routesPaths.ACCOUNT;
    }
  }

  return (
    <>
      <header className="z-10 bg-white flex justify-between pr-8 items-center h-20 text-lg sticky top-0" style={{ boxShadow: "0 3px 8px rgba(57, 63, 72, 0.3)" }}>
        <Link to={routesPaths.HOME}>
          <img src={taniExchangeLogo} alt="Tani Exchange logo" width="200px" height="300px" style={{ margin: '10%' }} />
        </Link>
        <div className={clsx("flex items-center gap-8 text-sm")}>
          {location.pathname !== "/" && <Link
            to={isLoggedIn ? routesPaths.HOME : routesPaths.HOME}
            className={clsx(styles.menuWeb, styles.textHeader, "hidden laptop:inline hover:text-green-500")}
          >
            <div className="flex flex-col">
              <FontAwesomeIcon className="text-2xl" icon={faHouse} />
              <label>Home</label>
            </div>
          </Link>}
          <Link to={routesPaths.CATALOG}
            className={clsx(styles.menuWeb, styles.textHeader, "hidden laptop:inline hover:text-green-500")}>
            <div className="flex flex-col">
              <FontAwesomeIcon className="cursor-pointer text-2xl" icon={faSeedling} />
              <label>Farmers Produce</label>
            </div>
          </Link>
          <Link to={routesPaths.MARKETDEMANDS}
            className={clsx(styles.menuWeb, styles.textHeader, "hidden laptop:inline hover:text-green-500")}>
            <div className="flex flex-col">
              <FontAwesomeIcon className="cursor-pointer text-2xl" icon={faShop} />
              <label>Market Demand</label>
            </div>
          </Link>

          {/* Account */}
          {!isLoggedIn ? (
            <section className="flex gap-8 tablet:gap-8">
              <Link to={routesPaths.LOGIN} className={clsx(styles.menuWeb, styles.textHeader, "hidden laptop:inline hover:text-green-500")}>
                <div className="flex flex-col">
                  <FontAwesomeIcon className="cursor-pointer text-2xl" icon={faRightToBracket} />
                  <label>Login</label>
                </div>
              </Link>
              <Link onClick={() => { loadSettingRegistration() }} to={isEnabledRegistration === 1 ? routesPaths.LIMITREGISTRATION : routesPaths.REGISTER} className={clsx(styles.menuWeb, styles.textHeader, "hidden laptop:inline hover:text-green-500")}>
                <div className="flex flex-col">
                  <FontAwesomeIcon className="cursor-pointer text-2xl" icon={faFilePen} />
                  <label>Register</label>
                </div>
              </Link>
            </section>
          ) : (
            <section className="flex items-center gap-4">
              <Link
                to={currentRoute}
                className={clsx(styles.menuWeb, styles.textHeader, "text-[.8rem] tablet:text-sm flex items-center gap-x-2 cursor-pointer md:hover:text-green-500")}
              >
                <div className="flex flex-col">
                  <FontAwesomeIcon className="cursor-pointer text-2xl" icon={faUser} />
                  <label>My Account</label>
                </div>
              </Link>
              {cartsTotal > 0 ? <Link to={routesPaths.CART} className={clsx(styles.textHeader, styles.menuWeb, "hover:text-green-500")}>
                <span className="relative inline-block">
                  <div className="flex flex-col">
                    <FontAwesomeIcon className="cursor-pointer text-2xl" icon={faCartShopping} />
                    <label>My Cart</label>
                  </div>
                  {
                    cartsTotal > 0 ?
                      <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform -translate-y-1/2 bg-red-600 rounded-full hidden tablet:inline">{cartsTotal}</span>
                      : null
                  }
                </span>
              </Link> : null}
              <Link
                to={routesPaths.NOTIFICATION_LIST}
                className="hover:text-green-500">

                <span className="relative inline-block">
                  <div className={clsx(styles.textHeader, styles.menuWeb, "flex flex-col items-center hover:text-green-500")}>
                    <FontAwesomeIcon className="text-2xl cursor-pointer" icon={faBell} />
                    <label>Notification</label>
                  </div>
                  {
                    countNotif > 0 ?
                      <span className="absolute top-0 left-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-10 -translate-y-1/2 bg-red-600 rounded-full hidden tablet:inline">{countNotif}</span>
                      : null
                  }
                </span>

              </Link>
              <Link
                to={routesPaths.ROOM_CHAT_PAGE}
                className="hover:text-green-500">

                <span className="relative inline-block">
                  <div className={clsx(styles.textHeader, styles.menuWeb, "flex flex-col items-center hover:text-green-500")}>
                    <FontAwesomeIcon className="text-2xl hidden tablet:inline cursor-pointer" icon={faEnvelope} />
                    <label>Message</label>
                  </div>
                  {
                    totalMsgUnRed > 0 ?
                      <span className="absolute top-0 left-0 inline-flex items-center justify-center ml-9 px-2 py-1 text-xs font-bold leading-none text-red-100 transform -translate-y-1/2 bg-red-600 rounded-full hidden tablet:inline">{totalMsgUnRed}</span>
                      : null
                  }
                </span>

              </Link>
            </section>
          )}
          <button className="hidden tablet:flex items-center min-w-fit border border-solid rounded-2xl border-black p-2 text-sm gap-x-2">
            <img src={britishFlag} alt="British flag" className="w-4 h-4" />
            ENG
          </button>

          <button onClick={() => {
            setShowMenu(!showMenu);
          }} className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg laptop:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" >
            <span className="sr-only">Open main menu</span>
            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"></path></svg>
          </button>
        </div>
      </header>
      {(showMenu) && (
        <>
          <header className={clsx(styles.menuMobile)}>
            <div>
              <ul>
                {location.pathname !== "/" && <li>
                  <Link
                    onClick={() => { setShowMenu(!showMenu) }}
                    to={isLoggedIn ? routesPaths.HOME : routesPaths.HOME}
                    className={clsx(styles.textHeader, "flex py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent")}
                  >
                    <FontAwesomeIcon className="cursor-pointer text-2xl w-1/5" style={{ color: "#395B50" }} icon={faHouse} title="Home" />
                    Home
                  </Link>
                </li>}
                <li>
                  <Link
                    onClick={() => { setShowMenu(!showMenu) }}
                    className={clsx(styles.textHeader, "flex py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent")}
                    to={routesPaths.CATALOG}>
                    <FontAwesomeIcon className="cursor-pointer text-2xl w-1/5" style={{ color: "#395B50" }} icon={faSeedling} title="Farmers Produce" />
                    Farmers Produce
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => { setShowMenu(!showMenu) }}
                    to={routesPaths.MARKETDEMANDS}
                    className={clsx(styles.textHeader, "flex py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent")}>
                    <FontAwesomeIcon className="cursor-pointer text-2xl w-1/5" style={{ color: "#395B50" }} icon={faShop} title="Market Demand" />
                    Market Demand
                  </Link>
                </li>

                {!isLoggedIn ? (
                  <>
                    <li>
                      <Link
                        onClick={() => { setShowMenu(!showMenu) }}
                        className="flex py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                        to={routesPaths.LOGIN}>
                        <FontAwesomeIcon className="text-2xl cursor-pointer w-1/5" icon={faBell} style={{ color: "#395B50" }} />
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => { setShowMenu(!showMenu); loadSettingRegistration() }}
                        to={isEnabledRegistration === 1 ? routesPaths.LIMITREGISTRATION : routesPaths.REGISTER}
                        className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                        <FontAwesomeIcon className="cursor-pointer text-2xl w-1/5" icon={faFilePen} style={{ color: "#395B50" }} />
                        Register
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link
                        onClick={() => { setShowMenu(!showMenu) }}
                        className={clsx(styles.textHeader, "flex py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent")}
                        to={currentRoute}>
                        <FontAwesomeIcon className="cursor-pointer text-2xl w-1/5" style={{ color: "#395B50" }} icon={faUser} title="My Account" />
                        My Account
                      </Link>
                    </li>
                    <li>
                      {cartsTotal > 0 ? <Link
                        onClick={() => { setShowMenu(!showMenu) }}
                        to={routesPaths.CART}
                        className={clsx(styles.textHeader, "flex py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent")}>
                        <div className="flex w-1/5 justify-center">
                          <span className="relative inline-block">
                            <FontAwesomeIcon className="cursor-pointer text-2xl" style={{ color: "#395B50" }} icon={faCartShopping} title="My Cart" />
                            {
                              cartsTotal > 0 ?
                                <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-3/4 -translate-y-1/2 bg-red-600 rounded-full">{cartsTotal}</span>
                                : null
                            }
                          </span>
                        </div>
                        My Cart
                      </Link> : null}
                    </li>
                    <li>
                      <Link
                        onClick={() => { setShowMenu(!showMenu) }}
                        to={routesPaths.NOTIFICATION_LIST}
                        className={clsx(styles.textHeader, "flex py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent")}>
                        <div className="flex w-1/5 justify-center">
                          <FontAwesomeIcon className="text-2xl h-7 cursor-pointer w-1/5" style={{ color: "#395B50" }} icon={faBell} />
                          <span className="relative inline-block">
                            {
                              countNotif > 0 ?
                                <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-3/4 -translate-y-1/2 bg-red-600 rounded-full">{countNotif}</span>
                                : null
                            }
                          </span>
                        </div>
                        Notification
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => { setShowMenu(!showMenu) }}
                        to={routesPaths.ROOM_CHAT_PAGE}
                        className={clsx(styles.textHeader, "flex py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent")}>
                        <div className="flex w-1/5 justify-center">
                          <FontAwesomeIcon className={clsx(styles.textHeader, "text-2xl cursor-pointer hover:text-green-500 ")} style={{ color: "#395B50" }} icon={faEnvelope} title="Message" />
                          <span className="relative inline-block">
                            {totalMsgUnRed > 0 ?
                              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-3/4 -translate-y-1/2 bg-red-600 rounded-full">{totalMsgUnRed}</span>
                              : null}
                          </span>
                        </div>
                        Message
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </header>
        </>
      )}

    </>
  );
};

export default Header;
