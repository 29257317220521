import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CheckCircle from "../../images/CheckCircle.svg";
import { useDispatch, useSelector } from "react-redux";
import { fileUpload } from "../../features/file/fileSlice";
import { farmDelete, farmShow, farmUpdate } from "../../features/farm/farmSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { fileDelete } from "../../features/file/fileSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import AccountLayout from "pages/AccountLayout";
import PlotWidget from "./plots/PlotWidget";
import { Col, Modal, Row } from "react-bootstrap";
import NewPhotoSlider from "components/files/NewSlider/NewPhotoSlider";
import "./FarmEdit.css";
import PickMapLocation from "components/map/PickMapLocation";
import { companyCropCheckCropExist } from "features/company-crop/companyCropSlice";
import { productCheckExist } from "features/product/productSlice";

const FarmEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);

  const { farm, updating } = useSelector((state) => state.farm);
  const [inputElementPhoto, setInputElementPhoto] = useState("");
  const [inputElementCertificate, setInputElementCertificate] = useState("");

  const [name, setName] = useState("Green Gardens");
  const [address, setAddress] = useState("123 Fresh Farms St");
  const [storeDefault, setStoreDefault] = useState({});
  const [plotList, setPlotList] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [show, setShow] = useState(false);

  const [location, setLocation] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latModal, setLatModal] = useState(null);
  const [lngModal, setLngModal] = useState(null);

  const params = useParams();

  const popupClose = () => setShow(false);
  const popupShow = () => setShow(true);

  const manageHandler = (event) => {
    dispatch(productCheckExist(
    )).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        event.preventDefault();
      } else {
        navigate(`/farms/${params.farm_id}/plots/create`);
      }
    });
  };

  const uploadPhotos = (event) => {
    let length = event.target.files.length;

    for (let i = 0; i < length; i++) {
      let selectedFile = event.target.files[i];

      const formData = new FormData();

      formData.append("file", selectedFile, selectedFile.name);
      formData.append("store", "multiple");
      formData.append("model", "farm");
      formData.append("type", "photo");
      formData.append("model_id", farm.id);

      dispatch(fileUpload(formData)).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure");
        } else {
          snackbarCtx.displayMsg(response.payload.message, "success");
          let photos = response.payload.data.farm.photos.data;
          setPhotos(photos);
        }
      });
    }
  };

  const uploadCertificates = (event) => {
    let length = event.target.files.length;

    for (let i = 0; i < length; i++) {
      let selectedFile = event.target.files[i];

      const formData = new FormData();

      formData.append("file", selectedFile, selectedFile.name);
      formData.append("store", "multiple");
      formData.append("model", "farm");
      formData.append("type", "certificate");
      formData.append("model_id", farm.id);

      dispatch(fileUpload(formData)).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure");
        } else {
          let certificates = response.payload.data.farm.certificates;
          snackbarCtx.displayMsg(response.payload.message, "success");
          setCertificates(certificates);
        }
      });
    }
  };

  const update = (event) => {
    let farmId = params.farm_id;

    dispatch(
      farmUpdate({
        name: name,
        address: address,
        location: location,
        latitude: latitude,
        longitude: longitude,
        farm_id: farmId,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");

        navigate("/farms/widgets");
      }
    });

    event.preventDefault();
  };

  const loadFarm = () => {
    let farmId = params.farm_id;

    dispatch(
      farmShow({
        farm_id: farmId,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
      } else {
        let farm = response.payload.data.farm;

        setName(farm.name);
        setAddress(farm.address);
        setLongitude(farm.longitude);
        setLatitude(farm.latitude);
        setLocation(farm.location);
        setCertificates(farm.certificates);
        setPhotos(farm.photos.data);
        setPlotList(farm.plots.data);
        setStoreDefault(farm.store_default);
        setLatModal(farm.latitude);
        setLngModal(farm.longitude);
      }
    });
  };

  const deleteFarm = () => {
    if (window.confirm("Are you sure you want to delete this farm?")) {
      dispatch(farmDelete({
        farm_id: params.farm_id,
      })).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure");
        } else {
          navigate(-1);
        }
      });
    }
  }

  const itemDelete = (certificate) => {
    if (window.confirm("Are you sure you want to delete this Certificate?")) {
      dispatch(
        fileDelete({
          file_id: certificate.id,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
        } else {
          loadFarm()
        }
      });
    }
  }

  const setPickLocation = () => {
    setLatitude(latModal);
    setLongitude(lngModal);
    popupClose();
  }

  useEffect(() => {
    loadFarm();
  }, []);

  return (
    <AccountLayout>
      <Modal className="modal-location" show={show} onHide={popupClose}>
        <Modal.Header style={{ placeSelf: "center" }}>
          <Modal.Title className="text-center">Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PickMapLocation
            curentLatMap={latitude}
            curentLngMap={longitude}
            curentLatMark={latitude}
            curentLngMark={longitude}
            saveLat={setLatModal}
            saveLng={setLngModal} />

        </Modal.Body>
        <Modal.Footer>
          <button className="border-none saveButton" onClick={() => {
            setPickLocation();
          }}>Set Location</button>
        </Modal.Footer>
      </Modal>
      <form className="w-full flex flex-col space-y-4" onSubmit={update}>
        <div className="w-full flex flex-col p-4 laptop:p-6 wide:p-6">
          <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
            {" Go Back"}
          </label>
          <Row className="mb-2">
            <Col xs={12} md={6}>
              <div className="title-content mb-3">
                Edit Farm Details
              </div>
            </Col>
          </Row>
          <div className="flex flex-col rounded-lg shadow bg-card-white-new mb-5">
            <Row>
              <Col md={6}>
                <NewPhotoSlider name={name} address={address} photos={photos} reloadData={loadFarm} />
                <div className="mt-3">
                  <button type="button" className="btn-border-green" onClick={() => {
                    popupShow();
                  }}
                    style={{ height: "40px" }}>
                    <label
                      className="cursor-pointer text-sm"

                    >
                      Edit Location From Map
                    </label>
                  </button>
                </div>

                <div className="flex justify-between space-x-4 mt-1">
                  <div className="w-1/2 flex flex-col gap-2">
                    <label>Latitude:</label>
                    <input
                      type="text"
                      placeholder={latitude}
                      value={latitude}
                      className="input-search"
                      onChange={({ target }) => setLatitude(target.value)}
                    />
                  </div>
                  <div className="w-1/2 flex flex-col gap-2">
                    <label>Longitude:</label>
                    <input
                      type="text"
                      placeholder={longitude}
                      value={longitude}
                      className="input-search"
                      onChange={({ target }) => setLongitude(target.value)}
                    />
                  </div>

                </div>
                <div className="flex justify-between space-x-4 mt-3">
                  <div className="w-1/2 flex flex-col gap-2">
                    <label>New Name:</label>
                    <input type="text"
                      className="input-search"
                      placeholder={name} value={name} onChange={({ target }) => setName(target.value)} />
                  </div>
                  <div className="w-1/2 flex flex-col gap-2">
                    <label>New Address:</label>
                    <input
                      type="text"
                      className="input-search"
                      placeholder={address}
                      value={address}
                      onChange={({ target }) => setAddress(target.value)}
                    />
                  </div>
                </div>
                <div className="flex space-x-4 mt-4">
                  <button type="button" className="btn-border-green" onClick={() => {
                    inputElementCertificate.click();
                  }}>
                    <label
                      className="cursor-pointer text-sm"

                    >
                      Upload Certificate
                    </label>
                    <input
                      className={"hidden"}
                      type="file"
                      accept="application/pdf"
                      multiple
                      onChange={uploadCertificates}
                      ref={(input) => setInputElementCertificate(input)}
                    />
                  </button>
                  <button type="button" className="btn-border-green" style={{ height: "40px" }} onClick={() => {
                    inputElementPhoto.click();
                  }}>
                    <label
                      className="cursor-pointer text-sm"
                    >
                      Upload Photos
                    </label>
                    <input
                      className={"hidden"}
                      type="file"
                      accept="image/png, image/jpeg"
                      multiple
                      onChange={uploadPhotos}
                      ref={(input) => setInputElementPhoto(input)}
                    />
                  </button>
                </div>
                <div className="flex flex-col gap-2 mt-4 mb-2">
                  {certificates.map((certificate, index) => {
                    return (
                      <>
                        <label className="flex py-2 gap-2" index={index} key={certificate.name}>
                          {certificate.name}
                          <img src={CheckCircle} alt="check mark" />

                          <button className="ml-auto" onClick={(e) => {
                            e.preventDefault();
                            itemDelete(certificate)
                          }}>
                            <FontAwesomeIcon icon={faTrashCan} className={"text-red-400"} />
                          </button>
                        </label>
                        <div className="w-full border-b-2">

                        </div>
                      </>
                    );
                  })}
                </div>
              </Col>
              <Col md={6}>
                <div className="w-full grid place-items-center tablet:grid-cols-2 dekstop:grid-cols-3 wide:grid-cols-3 gap-2" style={{ justifyContent: "right" }}>
                  {plotList.map((plot, i) => {
                    return (
                      <div className="w-full h-full" key={i}>
                        <PlotWidget plot={plot} />
                      </div>
                    )
                  })}
                </div>
                <div className="w-full flex flex-row flex-wrap justify-start mt-3" style={{ justifyContent: "right" }}>
                  <button type="button" className="btn-border-green laptop:max-w-[15rem] wide:max-w-[15rem]" onClick={() => manageHandler()}>
                    Add Plot
                  </button>
                </div>
              </Col>

            </Row>
            <Row className="mt-4">
              <Col md={5}>
              </Col>
              <Col md={7}>
                <div className="flex justify-between space-x-4 mt-3">
                  <button type="submit" className="saveButton">
                    {updating ? "Processing, please wait.." : "Save"}
                    {/*{loading ? "Save" : ""}*/}
                  </button>
                  <button type="button" className="saveButton bg-red-500 hover:bg-red-600 hover:ring-red-600" onClick={(event) => {
                    event.preventDefault();

                    deleteFarm()
                  }}>
                    {updating ? "Processing, please wait.." : "Delete Farm"}
                    {/*{loading ? "Save" : ""}*/}
                  </button>
                </div>
              </Col>
            </Row>

          </div>
        </div>
      </form>
    </AccountLayout>
  );
};

export default FarmEdit;
