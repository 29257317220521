import  api from "../../support/support_api";
import storage from "../../support/support_storage"
const login = async (credentials) => {

   let response = await api.axiosPost('/users/login', credentials)

    if(response.data) {
        storage.setUser(response.data)
    }

    return response.data;
}


const FBlogin = async (params) => {

    let response = await api.axiosPost('/users/verify-fb-login', params)

    if(response.data) {
        storage.setUser(response.data)
    }

     return response;
 }

 const Googlelogin = async (params) => {

    let response = await api.axiosPost('/users/verify-google-login', params)

    if(response.data) {
        storage.setUser(response.data)
    }

     return response.data;
 }

const loginService = {
    login, FBlogin, Googlelogin,
}

export default loginService;