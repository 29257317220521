import api from "../../support/support_api";

const creditManagementHistoryStore = async (params) => {
    const response  = await  api.axiosPost('/credit-management-history', params);

    return response.data;
}

const creditManagementHistoryUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/redit-management-historyy/${params.id}`, params);

    return response.data;
}

const creditManagementHistoryDelete = async (params) => {
    const response  = await  api.axiosDelete(`/redit-management-history/${params.id}`, params);

    return response.data;
}

const creditManagementHistoryShow = async (params) => {
    const response  = await api.axiosShow(`/redit-management-history/${params.id}`, params);

    return response.data;
}

const creditManagementHistoryIndex = async (params) => {
    const response  = await api.axiosGet(`/redit-management-history`, params);

    return response.data;
}

const creditManagementHistoryService = {
    creditManagementHistoryStore,
    creditManagementHistoryUpdate,
    creditManagementHistoryShow,
    creditManagementHistoryIndex,
    creditManagementHistoryDelete,
}


export default creditManagementHistoryService;