import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "pages/AccountLayout";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { billingShow, billingUploadPaymentReceipt, downloadBill } from "features/billing/billingSlice";
import { Spinner } from 'react-bootstrap';
import md5 from 'md5';
import clsx from "clsx";
import cartStyle from "../../cart/CartSidebar.module.scss";
import TermsOfUse from "components/home/TermsOfUse";

const BillingOfflinePayment = () => {
  const params = useParams();
  const { bill } = useSelector((state) => state.billing);
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [agreeTermCondition, setAgreeTermCondition] = useState(0);
  const [showTerms, setTermsModal] = useState(false);
  const [vcode, setVCode] = useState(null);

  const loadBill = () => {
    setLoading(true);
    dispatch(
      billingShow({
        id: params.id,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        dispatch(
          downloadBill({
            id: response?.payload?.data?.bill.invoice_number
          })
        ).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
          } else {
            let result = response.payload;
            const file = result;
            const fileURL = URL.createObjectURL(file);
            setFileUrl(fileURL);
          }
        });
      }
    });
    setLoading(false);
  }

  const downloadBillHandler = () => {
    setLoading(true);
    dispatch(
      downloadBill({
        id: bill.invoice_number
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
      } else {
        let result = response.payload;
        const file = result;
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      }
    });

    setLoading(false);
  };

  const handleChangeFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadPaymentReceiptHandler = () => {
    if (selectedFile == null) {
      alert("Please choose payment proof.");
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile, selectedFile.name);
      formData.append("order_number", bill.invoice_number);

      dispatch(billingUploadPaymentReceipt(formData)).then(function (response) {
        setLoading(false);
        if (response.error && response.error.message === "Rejected") {
          alert(response.payload);
        } else {
          setLoading(false);
          snackbarCtx.displayMsg(response.payload.message, "success");
          navigate(`/cart/done`);
        }
      });
    }
  }

  useEffect(() => {
    loadBill();
  }, []);

  return (
    <>
      <PageLayout>
        <div data-testid="order-list" className="flex flex-col items-start gap-6 p-4">
          <label className="cursor-pointer head-bar-nav font-regular font-semibold text-sm" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
            {" Go Back"}
          </label>
          <h1 className="text-3xl text-left font-semibold font-regular" style={{ color: "#395B50" }}>Billing Offline Payment</h1>
          <div className="flex justify-end w-full">
            <button onClick={() => downloadBillHandler()} className="saveButton w-fit"><FontAwesomeIcon icon={faPrint} className="mr-2" />Print</button>
          </div>
          <div className="w-full flex max-w bg-white border-2 p-8 rounded-lg font-regular space-y-6">
            <div className="w-3/4 border-r-2">
              <div className="font-bold text-greenGrayGray text-center">Fund Transfer To</div>
              <div className="font-bold text-lg text-center mt-6">SINGULARITY AEROTECH ASIA SDN BHD</div>
              <div className="m-0 text-center">8603878122</div>
              <div className="m-0 text-center">CIMB Islamic Bank Berhad</div>
              <div className="m-0 text-center">Swift Code: CTBBMYKL</div>
              <div className="font-bold text-greenGrayGray text-center my-10">Before initiating a fund transfer, please take a moment to review the following guidelines to ensure a smooth and secure transaction experience.</div>
              <div className="text-justify font-bold text-greenGrayGray">1. Verify Recipient Information</div>
              <div className="m-0">Double-check the recipient's account details, including account number, name, and bank information, to ensure accuracy. Any errors in the recipient information can result in failed or delayed transactions.</div>
              <div className="text-justify font-bold text-greenGrayGray mt-5">2. Review Transaction Details
              </div>
              <div className="m-0">Before confirming the fund transfer, carefully review the transaction details displayed on the payment interface to verify accuracy and completeness.</div>
              <div className="text-justify font-bold text-greenGrayGray mt-5">3. Contact Customer Support</div>
              <div className="m-0">In case of any questions, concerns, or issues encountered during the fund transfer process, don't hesitate to reach out to our customer support team for assistance.</div>
            </div>
            <div className="w-1/4 p-5 items-center h-max">
              <div className="text-lg">Subtotal: {bill?.current_charge}</div>
              <div className="text-lg">Tax total: 0</div>
              <label className="underline" />
              <div className="text-xl font-bold">Tax total: {bill?.current_charge}</div>
            </div>
          </div>
          {bill?.payment_history?.channel !== 'Credit' && <div className="w-full max-w bg-white items-center border-2 p-8 rounded-lg font-regular space-y-6">
            <div className="mt-4 mb-2">
              <label className="col-md-12">Payment Proof Attachment</label>
            </div>
            <div>
              <div>
                {/* <label className="input-group-text" for="inputGroupFile" style={{ marginTop: "1px" }}>{(selectedFile) ? "File Selected" : "Select File"}</label> */}
                <input
                  id="inputGroupFile"
                  // className={"hidden"}
                  className="form-control w-fit"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleChangeFile}
                />
              </div>
              <div>
                <button type="button" className="btn-green w-fit" onClick={(event) => {
                  event.preventDefault();
                  uploadPaymentReceiptHandler();
                }}>
                  {(isLoading) ? "Processing, please wait.." : "Upload"}
                  {/* Next */}
                </button>
              </div>
            </div>
          </div>}
        </div>
      </PageLayout >
    </>
  );
};
export default BillingOfflinePayment;
