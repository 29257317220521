import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import { ratingShow, ratingStore, ratingUpdate } from "features/rating/ratingSlice";
import { fileDelete, fileUpload } from "features/file/fileSlice";
import CustomImageProduct from "../../products/CustomImageProduct";
import styles from './RatingModal.module.scss';
import { faChevronLeft, faChevronRight, faTrashCan } from '@fortawesome/free-solid-svg-icons';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
};

const RatingModal = ({ orderDetailId, isRatingModal, handleRatingModal, isFromBooking, ratingObject, companyId }) => {
    const [comment, setComment] = useState("");
    const [rating, setRating] = useState(0);
    const [ratingResult, setRatingResult] = useState(null);
    const [photos, setPhotos] = useState([]);
    const [inputElementPhoto, setInputElementPhoto] = useState("");
    const [isShowPopupImage, setIsShowPopupImage] = useState(false);
    const [defaultPopUpImage, setDefaultPopUpImage] = useState(0);
    const [ratingId, setRatingId] = useState(null);
    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);
    const [imageIndex, setImageIndex] = useState(defaultPopUpImage + 1);

    const submitRating = () => {
        if (ratingId || ratingObject) {
            dispatch(ratingUpdate({
                id: ratingId || ratingObject.id,
                rating: rating,
                comments: comment,
            })).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success")
                    handleRatingModal();

                    if (ratingObject.id) {
                        loadRating();
                    }
                }
            });
        } else {
            dispatch(ratingStore({
                rating: "",
                comments: "",
                type: isFromBooking === 1 ? 'farm' : 'product',
                order_detail_id: orderDetailId,
                company_id: companyId
            })).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    setRatingId(response?.payload?.data?.rating?.id)
                }
            });
        }

    }

    const handleRatingModalProp = e => {
        handleRatingModal && handleRatingModal(e);
    };

    const uploadPhotos = (event) => {
        let length = event.target.files.length;

        for (let i = 0; i < length; i++) {
            let selectedFile = event.target.files[i];

            const formData = new FormData();

            formData.append("file", selectedFile, selectedFile.name);
            formData.append("store", "multiple");
            formData.append("model", "rating");
            formData.append("type", "photo");
            formData.append("model_id", ratingId);

            dispatch(fileUpload(formData)).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    let photos = response.payload.data.rating.photos;
                    setPhotos(photos);
                    setRatingResult(response.payload.data.rating)
                }
            });
        }
    };

    const uploadImg = () => {
        inputElementPhoto.click();
    }

    const setAddImage = () => {
        dispatch(ratingStore({
            rating: "",
            comments: "",
            type: isFromBooking === 1 ? 'farm' : 'product',
            order_detail_id: orderDetailId,
            company_id: companyId
        })).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                setRatingId(response?.payload?.data?.rating?.id)
            }
        });
    }

    const itemDelete = (photo) => {
        if (window.confirm("Are you sure you want to delete this Photo?")) {
            dispatch(
                fileDelete({
                    file_id: photo.id,
                })
            ).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                } else {
                    loadRating()
                }
            });
        }
    }

    const nextSlide = () => {
        if (imageIndex !== photos.data.length) {
            setImageIndex(imageIndex + 1)
        } else if (imageIndex === photos.data.length) {
            setImageIndex(1)
        }
    }

    const prevSlide = () => {
        if (imageIndex !== 1) {
            setImageIndex(imageIndex - 1)
        } else if (imageIndex === 1) {
            setImageIndex(photos.data.length)
        }
    }

    const changeImage = (i) => {
        setImageIndex(i);
    }

    const loadRating = () => {
        dispatch(ratingShow({
            id: ratingId || ratingObject?.id,
        })).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                setPhotos(response?.payload?.data?.rating?.photos)
            }
        });
    }

    useEffect(() => {
        if (ratingObject) {
            setRating(ratingObject?.rating);
            setComment(ratingObject?.comments);
            setPhotos(ratingObject?.photos);
        }
    }, []);

    return (
        <Modal
            open={isRatingModal}
            onClose={handleRatingModalProp}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            pb: 1,
                            mb: 1,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                        }}
                    >
                        <div style={{ color: '#00AF54' }}>{(ratingObject ? 'Edit Rating for ' : 'Rate & Comment For ')}{isFromBooking === 1 ? "Plot" : "Product"}</div>
                        <button onClick={handleRatingModalProp}>
                            <FontAwesomeIcon icon={faXmark} style={{ fontSize: "15px", }} />
                        </button>
                    </Box>
                </Typography>
                <Typography id="modal-modal-description" >
                    <Box>
                        <Rating name="rating" value={rating} onChange={(event, newValue) => {
                            setRating(newValue);
                        }} />
                        <textarea
                            className="input-search"
                            name="comment"
                            placeholder={isFromBooking === 1 ? "Eg: The plot produced a lot of good products" : "Eg: The product is delivered on time"}
                            value={comment}
                            onChange={({ target }) => setComment(target.value)}
                        />
                        {(ratingId == null && ratingObject == null) && <button onClick={() => setAddImage()} className="text-center mt-2 btn-border-green w-full desktop:w-1/4">
                            Add Photos
                        </button>}
                        <div className="flex flex-col items-center">
                            {photos?.data && photos?.data.length > 0 && <div className="flex flex-col justify-center items-center rounded-b-xl">
                                <section
                                    data-testid="popup-layout"
                                    className="bg-formLight flex flex-col rounded-xl shadow"
                                >
                                    <div className={styles.sliderContainer}>
                                        {photos.data.length > 0 ? photos.data.map((obj, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={imageIndex === index + 1 ? `${styles.slide}${styles.active}` : `${styles.slide}`}
                                                >
                                                    <section className="absolute text-white flex flex-col left-0 top-0 rounded-lg w-full px-2 py-1 box-img-card-top">
                                                        <section className="absolute top-5 right-2">
                                                            <button className="absolute top-5 right-2 focus:pointer-events-auto" onClick={(e) => {
                                                                e.preventDefault();
                                                                itemDelete(obj)
                                                            }}>
                                                                <div>
                                                                    <FontAwesomeIcon icon={faTrashCan} className={"text-cyan-400"} />
                                                                </div>
                                                            </button>
                                                        </section>
                                                    </section>
                                                    <img
                                                        src={obj.src}
                                                        alt="rating"
                                                        className={styles.sliderImage}
                                                    />
                                                    <div className="absolute bg-black/50 text-white left-0 bottom-0 rounded-lg flex flex-col w-full p-2 box-img-card" key={0}>
                                                        <section className="absolute bottom-5 left-5">
                                                            <div>
                                                                <label className="font-in-box">{obj.date}</label>

                                                            </div>
                                                            <div>
                                                                <label className="font-in-box">{obj.description_short}</label>

                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            )
                                        }) : <img src={ratingResult?.defaultPhoto} className={styles.sliderImage} alt="default" />}
                                        {photos?.data.length > 0 ? (<><button onClick={prevSlide} className={styles.buttonLeft}>
                                            <FontAwesomeIcon icon={faChevronLeft} size="3x" className={"text-gray-400"} />
                                        </button>
                                            <button onClick={nextSlide} className={styles.buttonRight}>
                                                <FontAwesomeIcon icon={faChevronRight} size="3x" className={"text-gray-400"} />
                                            </button></>) : null}
                                        <div className={styles.dotContainer}>
                                            {photos.data && Array.from({ length: photos.data.length }).map((dot, index) => (
                                                <div
                                                    onClick={() => changeImage(index + 1)}
                                                    className={imageIndex === index + 1 ? `${styles.imageDot} ${styles.dotActive}` : `${styles.imageDot}`}
                                                >

                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </section>
                            </div>}
                            {ratingId && <div className="w-full h-fit pl-5 mt-3">
                                {photos && <CustomImageProduct defaultPhoto={null} photos={photos} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} uploadImg={uploadImg} reloadData={window.location.reload} setDefaultPopUpImage={setDefaultPopUpImage} />}
                            </div>}
                            <input
                                className={"hidden"}
                                type="file"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={uploadPhotos}
                                ref={(input) => setInputElementPhoto(input)}
                            />
                        </div>
                    </Box>
                    <div className="flex justify-end">
                        <button onClick={() => submitRating()} className="mt-2 btn-green btn-add-carts w-full desktop:w-1/4 text-center">
                            Submit
                        </button>
                    </div>
                </Typography>
            </Box>
        </Modal>
    );
};

export default RatingModal;
