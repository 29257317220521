import { useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import * as statusConst from 'constants/advanceBooking.constants';
import { current } from "@reduxjs/toolkit";
const AuctionBiddingBuyer = ({ auctionPlot, currentUser }) => {
    const navigate = useNavigate();

    const detailHandler = () => {
        navigate(`/manage-auction-bidding/detail-buyer/${auctionPlot.auction_id}`);
    };

    // let bookingStatus = statusConst.bookingPlotStatus[auctionPlot.status];

    return (
        <div data-testid="order-card" className="hover:ring-2 hover:ring-formDark bg-white rounded-lg w-full" style={{ borderBottomWidth: "1px" }}>
            <div className="hidden desktop:flex flex-row items-center justify-center pb-3" style={{ borderBottomWidth: "1px" }}>
                <div className="basis-1/2 flex items-center justify-center font-medium">
                    <img
                        // src={orderDetail.product.photo ? info.photo : }
                        src={auctionPlot.plot ? auctionPlot.plot.photo_default : "/assets/images/products/WheatIcon.svg"}
                        alt={auctionPlot.plot ? auctionPlot.plot.name : "-"}
                        className="  orderLarge:inline min-w-[8rem] max-w-[8rem] min-h-[8rem] max-h-[8rem] object-cover aspect-square rounded-full"
                    />
                    <div className="hidden lg:flex flex-col ml-3">
                        <label className="text-2md font-semibold">{(auctionPlot.auction_id) ? auctionPlot.auction_id : "-"} </label>
                        <label>{`${auctionPlot.plot.name}`}</label>
                        {/*<label>{`${orderDetail.quantity} ${$orderDeta.grade.price_unit_symbol} / ${info.frequency}`}</label>*/}
                    </div>
                </div>
                <div className="basis-1/4 flex items-center justify-center font-medium"> {auctionPlot.available_quantity}</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">RM {auctionPlot.bid_price} </div>
                <div className="basis-1/4 flex items-center justify-center font-medium">RM {auctionPlot.total_bid_price} </div>
                <div className="basis-1/4 flex items-center justify-center font-medium">
                    {(auctionPlot.status_current == "Bid Placed") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#7EDBF8"} style={{backgroundColor: "#7EDBF8"}}>
                                {auctionPlot.status_current}</Badge>
                        </>
                    )}
                    {(auctionPlot.status_current == "Outbid") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#FFC107"} style={{ backgroundColor: "#FFC107"}}>
                                {auctionPlot.status_current}</Badge>
                        </>
                    )}
                    {(auctionPlot.status_current == "Bid Lost") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#E54C4C" } style={{ backgroundColor: "#E54C4C" }}>
                                {auctionPlot.status_current}</Badge>
                        </>
                    )}
                    {(auctionPlot.status_current == "Canceled") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#E54C4C"} style={{backgroundColor: "#E54C4C"}}>
                                {auctionPlot.status_current}</Badge>
                        </>
                    )}
                    {(auctionPlot.status_current == "Bid Won") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#00C960" } style={{ backgroundColor: "#00C960"}}>
                                {auctionPlot.status_current}</Badge>
                        </>
                    )}
                    {(auctionPlot.status_current == "Booking Created") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#00C960" } style={{ backgroundColor: "#00C960"}}>
                                {auctionPlot.status_current}</Badge>
                        </>
                    )}
                </div>
                <div className="basis-1/4 flex items-center justify-center font-medium">
                    <>
                        <button
                            className="btn-border-green w-[120px] mr-auto ml-auto mb-3"
                            onClick={detailHandler}
                        >View Details</button>
                    </>
                </div>
                {/* <div className="basis-1/4 flex items-center justify-center font-medium"></div> */}
            </div>
            <div className="desktop:hidden flex flex-row p-2 gap-1 items-center justify-center">
                <section className="basis-1/4 items-center gap-x-8 text-center">
                    
                    <div className="flex justify-center">
                    <img
                      // src={orderDetail.product.photo ? info.photo : "/assets/images/products/WheatIcon.svg"}
                      src={auctionPlot.plot ? auctionPlot.plot.photo_default : "/assets/images/products/WheatIcon.svg"}
                      alt={auctionPlot.plot ? auctionPlot.plot.name : "-"}
                      className="min-w-[4rem] max-w-[4rem] min-h-[4rem] max-h-[4rem] object-cover aspect-square rounded-full"
                  />
                    </div>
                    <label className="lg:hidden text-sm font-semibold">{`${auctionPlot.plot.name}`} </label>
                  </section>
                <section className="basis-1/4 flex w-full flex-col gap-1 items-center text-center">
                <label className="lg:hidden text-sm font-semibold mt-2">{(auctionPlot.auction_id) ? auctionPlot.auction_id : "-"} </label>

                    <label className="lg:hidden">
                        <span className="text-sm font-semibold">Quantity:</span>
                        <span className="ml-2">{auctionPlot.available_quantity}</span> </label>
                    <label className="lg:hidden" style={{textAlign:"center"}}>
                        <span className="text-sm font-semibold">Price:</span>
                        <span className="ml-2">RM{auctionPlot.bid_price} </span> </label>
                    <label className="lg:hidden" style={{textAlign:"center"}}>
                        <label className="text-sm font-semibold">Amount Paid:</label>
                        </label>
                        <label className="ml-2">RM {auctionPlot.total_bid_price}  </label> 
                    <label className="text-sm font-semibold">Status:</label>
                    <label className="mt-[-0.5rem]" style={{whiteSpace: 'normal'}}>
                        {(auctionPlot.status_current == "Bid Placed") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#7EDBF8"} style={{ padding: "4px", backgroundColor: "#7EDBF8"}}>
                                {auctionPlot.status_current}</Badge>
                        </>
                        )}
                        {(auctionPlot.status_current == "Outbid") && (
                            <>
                                <Badge className="ml-2 text-center" bg={"#FFC107"} style={{ padding: "4px", backgroundColor: "#FFC107"}}>
                                {auctionPlot.status_current}</Badge>
                            </>
                        )}
                        {(auctionPlot.status_current == "Bid Lost") && (
                            <>
                                <Badge className="ml-2 text-center" bg={"#E54C4C"} style={{ padding: "4px", backgroundColor: "#E54C4C"}}>
                                {auctionPlot.status_current}</Badge>
                            </>
                        )}
                        {(auctionPlot.status_current == "Bid Won") && (
                            <>
                                <Badge className="ml-2 text-center" bg={"#00C960" } style={{ padding: "4px", backgroundColor: "#00C960"  }}>
                                {auctionPlot.status_current}</Badge>

                            </>
                        )}
                        {(auctionPlot.status_current == "Booking Created") && (
                            <>
                                <Badge className="ml-2 text-center" bg={"#00C960"} style={{ padding: "4px", backgroundColor: "#00C960"}}>
                                {auctionPlot.status_current}</Badge>
                            </>
                        )}</label> 

                </section>
            </div>
            <button className="desktop:hidden btn-border-green w-[150px] mr-auto ml-auto mb-3 mt-2 flex justify-center" onClick={detailHandler}>View Details</button>
        </div>
    );
};

export default AuctionBiddingBuyer;
