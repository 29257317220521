import api from "../../support/support_api";

const plotStore = async (params) => {
    const response  = await  api.axiosPost('/plots', params);

    return response.data;
}

const plotUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/plots/${params.plot_id}`, params);

    return response.data;
}

const plotUploadPhotos = async (params) => {
    const response  = await  api.axiosPatch(`/plots/${params.plot_id}`, params);

    return response.data;
}

const plotShow = async (params) => {
    const response  = await api.axiosShow(`/plots/${params.plot_id}`, params);

    return response.data;
}

const plotIndex = async (params) => {
    const response  = await api.axiosGet(`/plots`, params);

    return response.data;
}

const plotDelete = async (params) => {
    const response  = await api.axiosDelete(`/plots/${params.plot_id}`, params);

    return response.data;
}

const plotUpdatePublishStatus = async (params) => {
    const response  = await api.axiosPut(`/plots/${params.plot_id}/updatePublishStatus`, params);

    return response.data;
}

const plotSaveAdvancedBooking = async (params) => {
    const response  = await  api.axiosPost('/plots/store/advancedBookingSetting', params);

    return response.data;
}

const plotSaveAuctionBidding = async (params) => {
    const response  = await  api.axiosPost('/plots/store/auctionBiddingSetting', params);

    return response.data;
}

const getCompanyPackage = async (params) => {
    const response  = await  api.axiosGet('/plots/getCompanyPackage/'+params.company_id);

    return response.data;
}

const getAucPlot = async (params) => {
    const response  = await  api.axiosGet('/plots/getAucPlot/'+params.plot_id);

    return response.data;
}

const getGradeList = async (params) => {
    const response  = await  api.axiosGet('/grades/getByCropId/'+params.crop_id);

    return response.data;
}
const plotCancelAdvancedBooking = async (params) => {
    const response  = await  api.axiosPost('/plots/store/cancelAdvancedBooking', params);

    return response.data;
}

const plotUpdateViewOnlyPubStatus = async (params) => {
    const response  = await api.axiosPut(`/plots/${params.plot_id}/updateViewOnlyPubStatus`, params);

    return response.data;
}

const plotService = {
    plotStore, plotUpdate, plotUploadPhotos, plotShow, plotIndex, plotDelete, plotSaveAdvancedBooking, plotUpdatePublishStatus, getCompanyPackage, getGradeList,
    plotCancelAdvancedBooking, plotSaveAuctionBidding, getAucPlot, plotUpdateViewOnlyPubStatus
}

export default plotService;