import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  demandService from "./demandService";
import catalogService from "../catalog/catalogService";

export const demandSearch = createAsyncThunk(
    "demands/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await demandService.demandSearch(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandIndex = createAsyncThunk(
    "demands/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await demandService.demandIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandStore = createAsyncThunk(
    "demands/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await demandService.demandStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)


export const demandDelete = createAsyncThunk(
    "demands/:id/delete",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await demandService.demandDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandFarmerApply = createAsyncThunk(
    "demands/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await demandService.demandFarmerApply(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandUpdate = createAsyncThunk(
    "demands/update",
    async (args, thunkAPI) => {
        try {
            return await demandService.demandUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandUploadPhoto = createAsyncThunk(
    "demands/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await demandService.demandUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandShow = createAsyncThunk(
    "demands/:id/show",
    async (args, thunkAPI) => {
        try {
            return await demandService.demandShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandShowGuest = createAsyncThunk(
    "demands/:id/show",
    async (args, thunkAPI) => {
        try {
            return await demandService.demandShowGuest(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)


export const demandFarmerApplications = createAsyncThunk(
    "demands/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await demandService.demandFarmerApplications(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const demandApplications = createAsyncThunk(
    "demands/applications",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await demandService.demandApplications(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// export const demandFarmerApplicationAccepted = createAsyncThunk(
//     "demands/farmer-application-accepted",
//     async (args, thunkAPI) => {
//         try {
//             // alert("test");
//             return await demandService.demandFarmerApplicationAccepted(args);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )


const initialState = {
    user_demand_applications: {
        data: [
        ]
    },
    data: {},
    owner: {},
    company: {},
    demand: {},
    demands: {
        data: [

        ]
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const demandSlice = createSlice({
    name: 'demand',
    initialState,
    reducers: {
    },
    extraReducers : {
        [demandStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [demandStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.demand = payload.data.demand;
            state.loading=false;
            state.isSuccess = true;
        },
        [demandStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },


        [demandDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [demandDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.demand = {} // payload.data.demand;
            state.loading=false;
            state.isSuccess = true;
        },
        [demandDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },



        [demandUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [demandUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [demandUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [demandShow.pending]:(state, {payload}) => {
            state.data = {};
            state.demand = {};
            state.owner = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [demandShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.demand = payload.data.demand;
            state.company = payload.data.company;
            state.owner = payload.data.owner;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [demandShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [demandIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [demandIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.demands = payload.data.demands;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [demandIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },


        [demandApplications.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [demandApplications.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.user_demand_applications = payload.data.user_demand_applications;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [demandApplications.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = demandSlice.actions

export default demandSlice;