import styles from "./CustomSliderProduct.module.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { fileDelete } from "../../features/file/fileSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { routesPaths } from "constants/routes.constants";
import { Col, Row } from "react-bootstrap";
import clsx from "clsx";

// TODO - All select has a delay on setting state hook, so this must be fixed.
const CustomImageProduct = ({ photos, reloadData, setIsShowPopupImage, uploadImg, setDefaultPopUpImage, isAbleUploadImg, isAbleToDelete, isAbleToEdit }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const reduxFile = useSelector((state) => state.file);


    const itemDelete = (photo) => {
        if (window.confirm("Are you sure you want to delete this Photo?")) {
            dispatch(
                fileDelete({
                    file_id: photo.id,
                })
            ).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                } else {
                    reloadData();
                }
            });
        }
    }

    return (
        <>
            <Row style={{ width: "100%" }}>
                <Col md={12} className="title-content mb-4" style={{ padding: "0px" }}>
                    {photos.length > 0 ? (
                        <>
                            <div className={"flex flex-col relative rounded-lg"} key={0}>
                                <section className="absolute text-white flex flex-col left-0 top-0 rounded-t-lg w-full h-fit px-2 py-1 box-img-card-top">
                                    <section className="absolute top-2 right-2">
                                        {isAbleToDelete && <button className="absolute top-2 right-2 fosuc:pointer-events-auto" onClick={(e) => {
                                            e.preventDefault();
                                            itemDelete(photos[0])
                                        }}>
                                            <div>
                                                {/* <span className={'text-white mr-2'}>Delete</span> */}
                                                <FontAwesomeIcon icon={faTrashCan} className={"text-cyan-400"} />
                                            </div>
                                        </button>}
                                    </section>
                                    <section className="absolute top-2 left-2">
                                        {isAbleToEdit && <button className="absolute top-2 left-2 fosuc:pointer-events-auto" onClick={(e) => {
                                            e.preventDefault();

                                            navigate(`${routesPaths.FILES}/${photos[0].id}/edit`);
                                        }}>
                                            <div>
                                                {/* <span className={'text-white mr-2'}>Edit</span> */}
                                                <FontAwesomeIcon icon={faPenToSquare} className={"text-rose-400"} />
                                            </div>
                                        </button>}
                                    </section>
                                </section>
                                <img
                                    src={photos[0].src}
                                    alt="product"
                                    className={styles.sliderImageNew}
                                />
                                <div className="absolute bg-black/50 text-white left-0 bottom-0 rounded-b-lg flex flex-col w-full h-1/4 p-2 box-img-card" key={0}>
                                    <section className="absolute bottom-5 left-5">
                                        <div>
                                            <label className="font-in-box">{photos[0].date}</label>

                                        </div>
                                        <div>
                                            <label className="font-in-box">{photos[0].description_short}</label>

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Col md={12} className="title-content">
                            <div className={"flex flex-col relative rounded-lg"}>

                                <div
                                    className={"cursor-pointer flex flex-col justify-center items-center bg-white rounded-md border-[.01rem] border-green-500 space-y-2 "}
                                    style={{ height: '100%', width: '100%' }}
                                    onClick={() => {
                                        uploadImg();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} className={"change-icon-color-green"} />
                                </div>
                            </div>
                        </Col>
                    )}

                </Col>

            </Row>
            <Row className="mb-3" style={{ width: "100%" }}>

                {photos.length > 0 && photos.map((obj, index) => {
                    if (index > 0 && index <= 3) {
                        return (
                            <>
                                <Col md={3} className="title-content">
                                    <div className={"flex flex-col relative rounded-lg"} key={0}>
                                        <section className="absolute text-white flex flex-col left-0 top-0 rounded-t-lg w-full h-fit px-2 py-1 box-img-card-top">
                                            <section className="absolute top-1 right-1">
                                                {isAbleToDelete && <button className="absolute top-0 right-0 fosuc:pointer-events-auto" onClick={(e) => {
                                                    e.preventDefault();
                                                    itemDelete(photos[index])
                                                }}>
                                                    <div>
                                                        {/* <span className={'text-white mr-2'}>Delete</span> */}
                                                        <FontAwesomeIcon icon={faTrashCan} className={"text-cyan-400"} />
                                                    </div>
                                                </button>}
                                            </section>s
                                            <section className="absolute top-1 left-1">
                                                {isAbleToEdit && <button className="absolute top-0 left-0 fosuc:pointer-events-auto" onClick={(e) => {
                                                    e.preventDefault();

                                                    navigate(`${routesPaths.FILES}/${photos[index].id}/edit`);
                                                }}>
                                                    <div>
                                                        {/* <span className={'text-white mr-2'}>Edit</span> */}
                                                        <FontAwesomeIcon icon={faPenToSquare} className={"text-rose-400"} />
                                                    </div>
                                                </button>}
                                            </section>
                                        </section>
                                        <div
                                            onClick={() => {
                                                // if ((index == 3)) {
                                                setIsShowPopupImage(true);
                                                setDefaultPopUpImage(index);
                                                // }

                                            }}
                                            className={clsx((index == 3) ? styles.bgColorImageNewRel : "")}>
                                            <img
                                                src={obj.src}
                                                alt="product"
                                                className={styles.sliderImageSubNew}
                                            />
                                            <div className={clsx((index == 3) ? (styles.bgColorImageNewAbs) : "")}>
                                                {(index == 3) && (
                                                    <>
                                                        <div style={{ display: "table", height: "100%", width: "100%", overflow: "hidden" }}>
                                                            <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                                                                <div style={{ fontSize: "35px", color: "#FFFFFF" }}>
                                                                    +{photos.length - 4}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "20px" }}>

                                    </div>
                                </Col>
                            </>

                        )
                    }

                })}
                {isAbleUploadImg && photos.length > 0 &&
                    (<Col xs={12} className="title-content">
                        <div
                            className={clsx(styles.sliderImageSubNew, "cursor-pointer flex flex-col justify-center items-center bg-white rounded-md border-[.01rem] border-green-500 space-y-2")}
                            onClick={() => {
                                uploadImg();
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} className={"change-icon-color-green"} />
                        </div>
                    </Col>)}
            </Row>
        </>
    );
};

export default CustomImageProduct;
