import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerCompany } from "../../features/auth/registerSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { urustaniIntegrationCompany } from "../../features/urustani-integration/urustaniIntegrationSlice";
import { useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './RegisterDetail.module.scss';
import clsx from "clsx";
import "./RegisterCompany.css";

const RegisterCompany = ({ nextHandler }) => {
  const [hasID, setHasID] = useState(false);
  const [companyID, setCompanyID] = useState("");
  const [personName, setPersonName] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [personSurname, setPersonSurname] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyUrustaniLogo, setCompanyUrustaniLogo] = useState("");
  const [companyUrustaniId, setCompanyUrustaniId] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [address, setAddress] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const snackbarCtx = useContext(SnackbarContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = () => {
    setLoading(true);

    dispatch(
      registerCompany({
        identity_id: companyID,
        name: companyName,
        person_name: personName,
        person_surname: personSurname,
        registration_number: regNumber,
        phone_number: contactNumber,
        billing_address: address,
        billing_address_street: street,
        billing_address_city: city,
        billing_address_zipcode: zipcode,
        business_contact_number: contactNumber,
        with_existing_company_id: hasID,
        company_urustani_logo: companyUrustaniLogo,
        company_urustani_id: companyUrustaniId,
        profile_url: profileUrl,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        setLoading(false)
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        navigate("/register/type");
      }
    });
  };

  const reduxUrustaniIntegration = useSelector((state) => state.urustaniIntegrateion);


  const urustaniCompanyRetrieve = () => {

    // popupShow(true);
    // let username = window.prompt("username","");
    // let password = window.prompt("password","");

    if (window.confirm("Are you sure you want to syncronize with urustani company?")) {
      popupClose();
      dispatch(urustaniIntegrationCompany({
        username: username,
        password: password
      })).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure")
        } else {
          snackbarCtx.displayMsg(response.payload.message, "success")
        }
      });
    }
  }

  useEffect(() => {
    let urustaniCompany = reduxUrustaniIntegration.urustani_company;

    setProfileUrl(urustaniCompany.profile_url)
    setPersonName(urustaniCompany.contact_person)
    setRegNumber(urustaniCompany.business_registration)
    setContactNumber(urustaniCompany.phone_number)
    setAddress(urustaniCompany.address)
    setCompanyName(urustaniCompany.name)
    setCompanyUrustaniLogo(urustaniCompany.logo)
    setCompanyUrustaniId(urustaniCompany.id)

  }, [reduxUrustaniIntegration.urustani_company]);


  const [show, setShow] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const popupClose = () => setShow(false);
  const popupShow = () => setShow(true);
  return (
    <div data-testid="register-company" className="flex flex-col space-y-6" >

      {/*todo - move to ui*/}
      <Modal show={show} onHide={popupClose}>
        <Modal.Header style={{ placeSelf: "center" }}>
          <Modal.Title className="text-center">UrusTani</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type={"text"}
            value={username}
            className={clsx("", styles.inputUserPass)}
            placeholder={"username"}
            onChange={({ target }) => {
              setUsername(target.value)
            }} />
          <input type={"password"}
            className={clsx("mt-3", styles.inputUserPass)}
            value={password}
            placeholder={"password"}
            onChange={({ target }) => {
              setPassword(target.value)
            }} />
        </Modal.Body>
        <Modal.Footer>
          <button className="border-none saveButton" onClick={urustaniCompanyRetrieve}>Login</button>
        </Modal.Footer>
      </Modal>
      {/*todo - move to ui*/}


      {/* Title */}
      <h1 className={clsx("text-xl self-center", styles.headerLogin)}>Company Details</h1>

      {/* Company ID Checkbox */}
      <section className="flex gap-x-2">
        <input type="checkbox" value={hasID} onClick={() => setHasID(!hasID)} />
        <p className="text-sm">I already have a company ID</p>
      </section>

      {/* User Input */}
      <section className="self-center flex flex-col w-72 space-y-4">
        <input
          type="text"
          className={clsx(!hasID ? "bg-gray-400" : "", styles.inputUserPass)}
          placeholder="Company ID"
          value={companyID}
          disabled={!hasID}
          onChange={({ target }) => setCompanyID(target.value)}
        />
        <input
          type="text"
          className={clsx("", styles.inputUserPass)}
          placeholder="Profile Url"
          value={profileUrl}
          onChange={({ target }) => setProfileUrl(target.value)}
        />
        <input
          type="text"
          className={clsx("", styles.inputUserPass)}
          placeholder="Person Name"
          value={personName}
          onChange={({ target }) => setPersonName(target.value)}
        />
        <input
          type="text"
          className={clsx("", styles.inputUserPass)}
          placeholder="Surname "
          value={personSurname}
          onChange={({ target }) => setPersonSurname(target.value)}
        />
        <input
          type="text"
          className={clsx(hasID ? "bg-gray-400" : "", styles.inputUserPass)}
          placeholder="Company Name"
          value={companyName}
          disabled={hasID}
          onChange={({ target }) => setCompanyName(target.value)}
        />
        <input
          type="text"
          className={clsx(hasID ? "bg-gray-400" : "", styles.inputUserPass)}
          placeholder="Company Registration Number"
          value={regNumber}
          disabled={hasID}
          onChange={({ target }) => setRegNumber(target.value)}
        />
        <input
          type="text"
          className={clsx(hasID ? "bg-gray-400" : "", styles.inputUserPass)}
          placeholder="Company Address (Billing Address)"
          value={address}
          disabled={hasID}
          onChange={({ target }) => setAddress(target.value)}
        />
        <input
          type="text"
          className={clsx(hasID ? "bg-gray-400 hidden" : "hidden", styles.inputUserPass)}
          placeholder="Street"
          value={street}
          disabled={hasID}
          onChange={({ target }) => setStreet(target.value)}
        />
        <input
          type="text"
          className={clsx(hasID ? "bg-gray-400 hidden" : "hidden", styles.inputUserPass)}
          placeholder="City"
          value={city}
          disabled={hasID}
          onChange={({ target }) => setCity(target.value)}
        />
        <input
          type="text"
          className={clsx(hasID ? "bg-gray-400 hidden" : "hidden", styles.inputUserPass)}
          placeholder="Zip Code"
          value={zipcode}
          disabled={hasID}
          onChange={({ target }) => setZipcode(target.value)}
        />
        <input
          type="text"
          className={clsx(hasID ? "bg-gray-400" : "", styles.inputUserPass)}
          placeholder="Business Contact Number"
          value={contactNumber}
          disabled={hasID}
          onChange={({ target }) => setContactNumber(target.value)}
        />
      </section>
      <button
        className={clsx(styles.nextBtnDetail, loading ? "bg-gray-400 border-none" : "border-none saveButton")}
        type="button"
        disabled={loading}
        style={{ borderRadius: "8px" }}
        onClick={submitHandler}
      >
        Submit
      </button>
      {/* <div className={"text-center"} style={{ margin: "7px" }}>or </div>
      <button style={{ marginTop: "0px", borderRadius: "8px" }} onClick={() => { popupShow(true) }}
        className={clsx(styles.nextBtnDetail, "border-none saveButton")}
      >
        {reduxUrustaniIntegration.updating ? "Processing please wait.." : "Synchronize with UrusTani"}
      </button> */}
    </div>
  );
};

export default RegisterCompany;
