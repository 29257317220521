import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import "./CompanyDetails.css";
import { companyShow } from "features/company/companySlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faComments, faCalendar, faStar } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faBox, faTree, faUsers, faCheck, faCircleXmark, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import Rating from '@mui/material/Rating';
import { DateTime } from "luxon";
import { companyRatingStore } from "features/company-rating/companyRatingSlice";
import CompanyDetailsHome from "./CompanyDetailsHome";
import CompanyDetailsProducts from "./CompanyDetailsProducts";
import CompanyDetailsFarms from "./CompanyDetailsFarms";
import CompanyDetailsUsers from "./CompanyDetailsUsers";
import CompanyDetailsRating from "./CompanyDetailsRating";
import { followCompany, unfollowCompany } from "features/follower/followerSlice";
import { authGetUser } from "features/auth/authSlice";
import { updateActiveRoomChat, updateInActiveRoomChat } from "features/room-chat/roomChatSlice";
import ChatRoomFloating from "components/chat-room/ChatRoomFloating";

const CompanyDetails = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const reduxCatalog = useSelector((state) => state.catalog);
    const { company } = useSelector((state) => state.company);
    const { currentUser } = useSelector((state) => state.auth);
    const [comment, setComment] = useState("");
    const [tab, setTab] = useState('Home');
    const [showFLoatChat, setShowFLoatChat] = useState(false);


    const companyPhotoSrc = typeof company.photo === 'object' ? company.photo.src
        : company.photo;

    const loadCompany = () => {
        dispatch(
            companyShow({
                company_id: params.company_id
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                alert(response.payload);
            }
        });
    }

    const loadUser = () => {
        dispatch(authGetUser());
    }

    const ratingCompany = newValue => {
        dispatch(
            companyRatingStore({
                rating: newValue,
                company_id: company?.id,
                comment: ""
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                alert(response.payload);
            } else {
                loadCompany();
            }
        });
    }

    const followerCompany = () => {
        dispatch(
            followCompany({
                companyId: company?.id
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                alert(response.payload);
            } else {
                loadUser();
            }
        });
    }

    const unfollowerCompany = () => {
        dispatch(
            unfollowCompany({
                companyId: company?.id
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                alert(response.payload);
            } else {
                loadUser();
            }
        });
    }

    const hoverRating = rating => {
        switch (rating) {
            case 1:
                setComment('Terrible');
                break;
            case 1.5:
                setComment('Terrible');
                break;
            case 2:
                setComment('Poor');
                break;
            case 2.5:
                setComment('Poor');
                break;
            case 3:
                setComment('Fair');
                break;
            case 3.5:
                setComment('Fair');
                break;
            case 4:
                setComment('Good');
                break;
            case 4.5:
                setComment('Good');
                break;
            case 5:
                setComment('Amazing');
                break;
            default:
                setComment('Amazing');
                break;
        }
    }

    useEffect(() => {
        loadUser()
        loadCompany();
    }, []);

    return (
        <>
            <div className="bg-gray-50" style={{ height: '92vh' }}>
                <div>
                    <div data-testid="demand-farm-show" className="w-full flex flex-col items-center gap-8">
                        <div className="w-full p-5 self-center flex flex-col gap-3">
                            <Row>
                                <Col md={12}>
                                    <div className="title-content mb-3">
                                        Company Details
                                    </div>
                                    <div className="flex flex-col rounded-lg shadow bg-card-white-farm-show">
                                        <div className="flex items-start">
                                            <img
                                                src={companyPhotoSrc}
                                                alt="profile"
                                                className="rounded-full aspect-square bg-white w-24 h-24 object-cover border-[.01rem] border-green-400"
                                            />
                                            <div className="w-1/5 mx-6">
                                                <div className="font-extrabold">{company?.name}</div>
                                                <div className="flex space-x-3">
                                                    <Rating name="rating" value={company?.rating || 0} precision={0.5} onChange={(event, newValue) => {
                                                        ratingCompany(newValue);
                                                    }} onChangeActive={(event, newHover) => {
                                                        hoverRating(newHover);
                                                    }} />
                                                    <div>{comment}</div>
                                                </div>
                                                <div><b>Status:</b> Online</div>
                                                <div className="flex space-x-1">
                                                    <button className="borderedwhiteButton" style={{ background: "#F3FFFCE5" }} onClick={() => {
                                                        dispatch(updateActiveRoomChat());
                                                        setShowFLoatChat(true);
                                                    }}> <FontAwesomeIcon icon={faMessage} className="mr-2" /> Chat Now</button>
                                                    <button className="borderedwhiteButton" style={{ background: "#F3FFFCE5" }} onClick={() => { currentUser?.followedCompanies.find((currentCompany) => currentCompany?.id === company?.id) ? unfollowerCompany() : followerCompany() }}> <FontAwesomeIcon className="mr-2" icon={currentUser?.followedCompanies && currentUser?.followedCompanies.find((currentCompany) => currentCompany?.id === company?.id) ? faCheck : faPlus} /> {currentUser?.followedCompanies && currentUser?.followedCompanies.find((currentCompany) => currentCompany?.id === company?.id) ? "Followed" : "Follow"}</button>
                                                </div>
                                            </div>
                                            <div className="w-1/5 h-full mx-6">
                                                <div className="font-extrabold h-1/3"><FontAwesomeIcon icon={faBox} /> Products: {company?.products && company?.products.length} </div>
                                                <div className="font-extrabold h-1/3"><FontAwesomeIcon icon={faTree} /> Farms: {company?.farms && company?.farm_total.length}</div>
                                                {/* <div className="font-extrabold h-1/3"><FontAwesomeIcon icon={faComments} /> Chat Performance: </div> */}
                                            </div>
                                            <div className="w-1/5 h-full mx-6">
                                                <div className="font-extrabold h-1/3"><FontAwesomeIcon icon={faCalendar} /> Joined: {DateTime.fromISO(company?.created_at).toFormat('dd/LL/yyyy')}</div>
                                                <div className="font-extrabold h-1/3"><FontAwesomeIcon icon={faUsers} /> Users:  {company?.users && company?.users.length} </div>
                                                <div className="font-extrabold h-1/3"><FontAwesomeIcon icon={faStar} /> Rating: {company?.rating}</div>
                                            </div>
                                        </div>
                                        <section className="flex gap-36 mt-5">
                                            <label
                                                className={
                                                    tab === "Home"
                                                        ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                                                        : "cursor-pointer font-medium border-b-[.2rem] border-b-black text-black tab-not-active-new"
                                                }
                                                onClick={() => setTab("Home")}
                                            >
                                                Home
                                            </label>
                                            <label
                                                className={
                                                    tab === "Products"
                                                        ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                                                        : "cursor-pointer font-medium border-b-[.2rem] border-b-black text-black tab-not-active-new"
                                                }
                                                onClick={() => {
                                                    setTab("Products");
                                                }}
                                            >
                                                All Products
                                            </label>
                                            <label
                                                className={
                                                    tab === "Farm"
                                                        ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                                                        : "cursor-pointer font-medium border-b-[.2rem] border-b-black text-black tab-not-active-new"
                                                }
                                                onClick={() => {
                                                    setTab("Farm");
                                                }}
                                            >
                                                Farm
                                            </label>
                                            <label
                                                className={
                                                    tab === "Users"
                                                        ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                                                        : "cursor-pointer font-medium border-b-[.2rem] border-b-black text-black tab-not-active-new"
                                                }
                                                onClick={() => {
                                                    setTab("Users");
                                                }}
                                            >
                                                Users
                                            </label>
                                            <label
                                                className={
                                                    tab === "Rating"
                                                        ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                                                        : "cursor-pointer font-medium border-b-[.2rem] border-b-black text-black tab-not-active-new"
                                                }
                                                onClick={() => {
                                                    setTab("Rating");
                                                }}
                                            >
                                                Rating & Reviews
                                            </label>
                                        </section>
                                    </div>
                                </Col>
                            </Row>
                            {tab === 'Home' && <CompanyDetailsHome company={company} />}
                            {tab === 'Products' && <CompanyDetailsProducts company={company} />}
                            {tab === 'Farm' && <CompanyDetailsFarms company={company} />}
                            {tab === 'Users' && <CompanyDetailsUsers company={company} />}
                            {tab === 'Rating' && <CompanyDetailsRating company={company} />}
                        </div>
                    </div>
                </div>
            </div>
            {showFLoatChat && (
                <>
                    <div className="chat-popup-float z-10">
                        <div className="container-chat">
                            <div className="flex flex-row" style={{ backgroundColor: "#77b06e", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                <div className="basis-1/2 p-1">
                                    <FontAwesomeIcon style={{ cursor: "pointer", color: "white" }} icon={faUpRightAndDownLeftFromCenter} className={"text-gray-400"} onClick={() => {
                                        navigate(`/room-chat/live/${reduxCatalog.catalog.user_id}`);
                                    }} />
                                </div>
                                <div className="basis-1/2 text-right p-1">
                                    <FontAwesomeIcon style={{ cursor: "pointer", color: "white" }} icon={faCircleXmark} className={"text-gray-400"} onClick={() => {
                                        dispatch(updateInActiveRoomChat());
                                        setShowFLoatChat(false);
                                    }} />
                                </div>
                            </div>
                            <ChatRoomFloating id_catalog={reduxCatalog.catalog.id} to_id={reduxCatalog.catalog.user_id} type_chat="product" type_chat_id={reduxCatalog.product.id} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CompanyDetails;


