import taniExchangeShadowWhiteLogo from "images/home/taniexchange-shadow-white.png";
import whiteBudgetLogo from "images/home/white-budget.png";
import whiteFarmerLogo from "images/home/white-farmer.png";
import whiteDashedLine from "images/home/white-dashed-line.png";
import mobileWhiteDashedLine from "images/home/mobile-white-dashed-line.svg";
import { useTranslation } from "react-i18next";
import styles from "./Intro.module.scss";
import clsx from "clsx";

const Intro = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center flex-col" style={{ zIndex: 2 }}>
      <div className={clsx(styles.IntroTitle,"flex font-bold leading-10 justify-center")}>
        <div className="mr-3 text-white">{t('what.is')}</div>
        <div style={{ color: '#0E5934' }}>{t('tani')} </div>
        <div style={{ color: '#82C784' }}>{t('exchange')} </div>
        <div className="mr-3 text-white">{t('?')}</div>
      </div>
      <div className={clsx(styles.IntroOne,"justify-center text-base")}>
        <div className="text-center font-bold my-4">{t('intro.one')}</div>
        <div className="text-center font-bold">{t('intro.two')}</div>
      </div>
      <div className={clsx(styles.subTitle,"text-white font-bold mb-10")}>{t('howItWorks')}</div>
      
      <div className={clsx(styles.menuWeb)}>
          <div className="flex flex-col justify-center items-center" style={{ marginTop: '-40px' }}>
          <img src={whiteFarmerLogo} alt="tx-white-logo" />
          <div className="text-white mt-4" style={{ width: '250px' }}>
            <div className="text-lg font-semibold text-center">
              {t('farmerTitle')}
            </div>
            <ul className="list-disc text-l font-medium">
              <li>{t('farmerFirstDesc')}</li>
              <li>{t('farmerSecondDesc')}</li>
            </ul>
          </div>
          </div>
          <div>
          <img src={whiteDashedLine} alt="tx-white-logo" />
          </div>
          <div className="flex flex-col justify-center items-center">
            <img src={taniExchangeShadowWhiteLogo} alt="tx-white-logo" style={{ paddingTop: '20px' }} />
            <div style={{ width: '250px', marginTop: "30px" }} >
              <div className="text-lg font-semibold text-center" style={{ color: "#00E26D" }}>
                {t('taniexchange')}
              </div>
              <div className="text-l font-medium text-white text-center">{t('taniexchangeDesc')}</div>
            </div>
          </div>
          <div>
            <img src={whiteDashedLine} alt="tx-white-logo" />
          </div>
          <div className="flex flex-col justify-center items-center" style={{ marginTop: '-40px' }}>
          <img src={whiteBudgetLogo} alt="tx-white-logo" />
          <div className="text-white mt-4" style={{ width: '250px' }}>
            <div className="text-lg font-semibold text-center">
              {t('buyerTitle')}
            </div>
            <ul className="list-disc text-l font-medium">
              <li>{t('buyerFirstDesc')}</li>
              <li>{t('buyerSecondDesc')}</li>
            </ul>
          </div>
          </div>
      </div> 
      <div className={clsx(styles.menuMobile)}>
        <div className="flex flex-col justify-center items-center">
          <img src={whiteFarmerLogo} alt="tx-white-logo" />
          <div className="text-white mt-4" style={{ width: '250px' }}>
            <div className="text-lg font-semibold text-center">
              {t('farmerTitle')}
            </div>
            <ul className="list-disc text-l font-medium">
              <li>{t('farmerFirstDesc')}</li>
              <li>{t('farmerSecondDesc')}</li>
            </ul>
        </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={mobileWhiteDashedLine} alt="tx-white-logo" className="w-20 h-20"/>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={taniExchangeShadowWhiteLogo} alt="tx-white-logo" style={{ paddingTop: '10px' }} />
        <div style={{ width: '340px', marginTop: "10px" }} >
          <div className="text-lg font-semibold text-center" style={{ color: "#00E26D" }}>
            {t('taniexchange')}
          </div>
          <div className="text-l font-medium text-white text-center">{t('taniexchangeDesc')}</div>
        </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={mobileWhiteDashedLine} alt="tx-white-logo" className="w-20 h-20" />
        </div>
        <div className="flex flex-col justify-center items-center">
        <img src={whiteBudgetLogo} alt="tx-white-logo" />
        <div className="text-white mt-4" style={{ width: '250px' }}>
          <div className="text-lg font-semibold text-center">
            {t('buyerTitle')}
          </div>
          <ul className="list-disc text-l font-medium">
            <li>{t('buyerFirstDesc')}</li>
            <li>{t('buyerSecondDesc')}</li>
          </ul>
        </div>
        </div>
      </div>
        
      <div></div>
    </div>
  );
};

export default Intro;
