import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import auctionBiddingService from "./auctionBiddingService";
import AuctionBiddingDetail from 'components/auction-bidding/detail/AuctionBiddingDetail';

export const auctionBiddingList = createAsyncThunk(
    "auction-plots/getListManageAuction",
    async (args, thunkAPI) => {
        try {
            return await auctionBiddingService.auctionBiddingList(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const auctionBiddingGeneralList = createAsyncThunk(
    "auctionBiddingGeneralList",
    async (args, thunkAPI) => {
        try {
            return await auctionBiddingService.auctionBiddingGeneralList(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getListMyAuctionBuyer = createAsyncThunk(
    "auction-plots/getListMyAuctionBuyer",
    async (args, thunkAPI) => {
        try {
            return await auctionBiddingService.getListMyAuctionBuyer(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const auctionBiddingDetail = createAsyncThunk(
    "auction-plots/:auction_id/getDetailAuctionPlot",
    async (args, thunkAPI) => {
        try {
            return await auctionBiddingService.auctionBiddingDetail(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const auctionBiddingDetailBuyer = createAsyncThunk(
    "auction-plots/:auction_id/getDetailAuctionPlotBuyer",
    async (args, thunkAPI) => {
        try {
            return await auctionBiddingService.auctionBiddingDetailBuyer(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const auctionBiddingStatistic = createAsyncThunk(
    "auction-plots/statistic",
    async (args, thunkAPI) => {
        try {
            return await auctionBiddingService.auctionBiddingStatistic(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const auctionBiddingDelete = createAsyncThunk(
    "auction-plots/delete",
    async (args, thunkAPI) => {
        try {
            return await auctionBiddingService.auctionBiddingDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
);

const initialState = {
    data: {},
    booking_plot: {},
    booking_plots: {
        data: []
    },
    auctionList: {
        data: []
    },
    auction_plot: {},
    auctionStatistic: {},
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const auctionBiddingSlice = createSlice({
    name: 'auctionBidding',
    initialState,
    reducers: {
    },
    extraReducers: {
        [auctionBiddingList.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [auctionBiddingList.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.booking_plots = payload.data.booking_plots;
            state.loading = false;
            state.isSuccess = true;
        },
        [auctionBiddingList.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [auctionBiddingGeneralList.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [auctionBiddingGeneralList.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.auctionList = payload.data.auction_plots;
            state.loading = false;
            state.isSuccess = true;
        },
        [auctionBiddingGeneralList.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [auctionBiddingStatistic.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [auctionBiddingStatistic.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.auctionStatistic = payload.data;
            state.loading = false;
            state.isSuccess = true;
        },
        [auctionBiddingStatistic.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [auctionBiddingDetail.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [auctionBiddingDetail.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.auction_plot = payload.data.auction_plot;
            state.loading = false;
            state.isSuccess = true;
        },
        [auctionBiddingDetail.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [auctionBiddingDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [auctionBiddingDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [auctionBiddingDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export default auctionBiddingSlice;