import api from "../../support/support_api";

const masterCropStore = async (params) => {
    const response  = await  api.axiosPost('/master-crop', params);

    return response.data;
}

const masterCropUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/master-crop/${params.id}`, params);

    return response.data;
}

const masterCropDelete = async (params) => {
    const response  = await  api.axiosDelete(`/master-crop/${params.id}`, params);

    return response.data;
}

const masterCropShow = async (params) => {
    const response  = await api.axiosShow(`/master-crop/${params.id}`, params);

    return response.data;
}

const masterCropIndex = async (params) => {
    const response  = await api.axiosGet(`/master-crop`, params);

    return response.data;
}

const masterCropShowRelatedCategory = async (params) => {
    const response  = await api.axiosGet(`/master-crop/show-related-category/${params.id}`);
    return response.data;
}

const masterCropService = {
    masterCropStore,
    masterCropUpdate,
    masterCropShow,
    masterCropIndex,
    masterCropDelete,
    masterCropShowRelatedCategory
}

export default masterCropService;