import api from "../../support/support_api";

const categoryStore = async (params) => {
    const response  = await  api.axiosPost('/categories', params);

    return response.data;
}

const categoryDelete = async (params) => {
    const response  = await  api.axiosDelete(`/categories/delete-category/${params.id}`, params);

    return response.data;
}

const categoryUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/categories/${params.category_id}`, params);

    return response.data;
}

const categoryUploadPhotos = async (params) => {
    const response  = await  api.axiosPatch(`/categories/${params.category_id}`, params);

    return response.data;
}

const categoryShow = async (params) => {
    const response  = await api.axiosShow(`/categories/${params.category_id}`, params);

    return response.data;
}

const categoryIndex = async (params) => {
    const response  = await api.axiosGet(`/categories`, params);

    return response.data;
}

const categoryService = {
    categoryStore, categoryDelete, categoryUpdate, categoryUploadPhotos, categoryShow, categoryIndex
}

export default categoryService;