import { routesPaths } from "constants/routes.constants";
import PopupLayoutConfirmation from "pages/PopupLayoutConfirmation";
import { useNavigate } from "react-router-dom";

const PopupConfirmation = (props) => {
  const navigate = useNavigate();

  

  return (
    <PopupLayoutConfirmation>
      <label className="text-xl">{props.title}</label>
      <div className="w-full flex gap-4">
        <button className="saveButton" 
        onClick={() => {
              props.onSuccess();
        }}
        >
          {props.successButtonTitle}
        </button>
        <button className="borderedButton" onClick={() => {
              props.onCancel(false);
        }}>
          {props.cancelButtonTitle}
        </button>
      </div>
    </PopupLayoutConfirmation>
  );
};

export default PopupConfirmation;
