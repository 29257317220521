import api from "../../support/support_api";

const creditManagementStore = async (params) => {
    const response  = await  api.axiosPost('/credit-management', params);

    return response.data;
}

const creditManagementUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/credit-management/${params.id}`, params);

    return response.data;
}

const creditManagementDelete = async (params) => {
    const response  = await  api.axiosDelete(`/credit-management/${params.id}`, params);

    return response.data;
}

const creditManagementShow = async (params) => {
    const response  = await api.axiosShow(`/credit-management/${params.id}`, params);

    return response.data;
}

const creditManagementIndex = async (params) => {
    const response  = await api.axiosGet(`/credit-management`, params);

    return response.data;
}

const creditManagementService = {
    creditManagementStore,
    creditManagementUpdate,
    creditManagementShow,
    creditManagementIndex,
    creditManagementDelete,
}


export default creditManagementService;