import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getStatesList, orderFarmerGetByOrderNumber, generateInvoiceNumber } from "../../../../features/order-farmer/orderFarmerSlice";
import { Col, Row } from "react-bootstrap";
import PaymentProgress from "./PaymentProgress";
import md5 from 'md5';
import TermsOfUse from "components/home/TermsOfUse";

const OrderFarmerPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [orderDetails, setOrderDetails] = useState([]);
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [statesList, setStatesList] = useState([]);
  const [vcode, setVCode] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [showTerms, setTermsModal] = useState(false);

  //receiver address
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [additionalInformation, setAdditionalInformation] = useState(null);
  const [agreeTermCondition, setAgreeTermCondition] = useState(0);

  // const { advanceBooking } = useSelector((state) => state);
  // const bookingPlot = advanceBooking.data.data.booking_plot;

  const handleClickProceed = () => {
    if (step == 1) {
      setStep(2);
    } else {
      if (paymentType) {
        if (agreeTermCondition) {
          setIsLoading(true);
          if (paymentType == 1) {
            document.getElementById("razerForm").submit();
          } else {
            dispatch(
              generateInvoiceNumber({
                order_number: params.order_number
              })
            ).then(function (response) {
              if (response.error && response.error.message == "Rejected") {
                alert(response.payload);
              } else {
                let order_farmer = response.payload.data.order_farmer;
                setIsLoading(false);
                navigate('/customer/order-farmers/' + params.order_number + '/payment-offline');
              }
            });
          }
        } else {
          alert('Please agree with term & condition');
        }
      } else {
        alert('Please select payment method');
      }

    }
  };

  const handleClickCancel = () => {
    if (window.confirm("Are you sure want to cancel this payment?")) {
      navigate('/customer/order-farmers/' + params.order_farmer_id + '/tracking');
    }
  }

  const loadDetail = () => {
    setIsLoading(true);
    dispatch(
      orderFarmerGetByOrderNumber({
        order_number: params.order_number
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        alert(response.payload);
      } else {
        let order_farmer = response.payload.data.order_farmer;

        let result = response.payload.data.order_farmer.order;
        setAddress(result.delivery_address_street);
        setCity(result.delivery_address_city);
        setState(result.delivery_address_state);
        setPostcode(result.delivery_address_zipcode);
        setLat(result.delivery_address_lat);
        setLon(result.delivery_address_lon);

        let order_details = response.payload.data.order_details;
        setOrderDetails(order_details);
        setOrder(order_farmer.order);
        setVCode(md5(result.remaining_balance + process.env.REACT_APP_RAZER_MERCHANT_ID + "ORDER_" + result.order_number + process.env.REACT_APP_RAZER_VERIFY_KEY));
        setIsLoading(false);
      }
    });
  };

  const loadStates = () => {
    dispatch(
      getStatesList()
    ).then(function (response) {

      if (response.error && response.error.message == "Rejected") {
      } else {
        let states = response.payload.data.states;
        setStatesList(states);
      }
    });
  };

  useEffect(() => {
    loadDetail();
    loadStates();
  }, []);

  return (
    <Row className="container">
      <Col md={3}></Col>
      <Col md={6}>
        <div className="flex flex-col items-center mt-4">
          <div style={{ minWidth: "80%" }} >
            <PaymentProgress step={step} />
          </div>
        </div>
        <Row className="mt-3">
          <Col md={12}>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
              <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                <div className="p-4 w-full max-w">
                  {(step === 1) ?
                    <>
                      <table className="table">
                        <thead>
                          <tr className="text-center">
                            <td colSpan={2}>Product List</td>
                            <td>Actual Quantity</td>
                            <td>Price</td>
                          </tr>
                        </thead>
                        <tbody>
                          {orderDetails.data && orderDetails.data.map((orderDetail, index) => (
                            <tr>
                              <td colSpan={2}>
                                <Row>
                                  <Col md={4}>
                                    <img src={(orderDetail.product) ? orderDetail?.product?.photo[0]?.photo_default : "/assets/images/products/WheatIcon.svg"}
                                      className="min-w-[5rem] max-w-[5rem] min-h-[5rem] max-h-[5rem] object-cover aspect-square rounded-full"
                                    /></Col>
                                  <Col md={8}>
                                    <Row>
                                      <Col md={2}>
                                        <p className="mt-4"><b>Name</b></p>
                                        <p><b>Price</b></p>
                                      </Col>
                                      <Col md={10}>
                                        <p className=" mt-4"> : {(orderDetail.product) ? orderDetail.product.name : ''}</p>
                                        <p> : {(orderDetail.price_formatted) ? orderDetail.price_unit + ' ' + orderDetail.price_formatted : ''}</p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </td>
                              <td className="text-center"><p className="mt-4">{orderDetail.quantity}</p></td>
                              <td className="text-center"><p className="mt-4">{orderDetail.price_unit} {orderDetail.total_price_formatted}</p></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                    :
                    <>
                      <h2 className="text-3xl text-left">Delivery Address</h2>
                      <Row>
                        <Col md={12}>
                          <label className="mt-1">Address Line</label>
                          <textarea
                            // type="text"
                            className="input-search"
                            name="address"
                            placeholder="Address"
                            value={address}
                            disabled={true}
                            onChange={({ target }) => setAddress(target.value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label className="mt-1">City</label>
                          <input
                            type="text"
                            className="input-search"
                            name="city"
                            placeholder="City"
                            value={city}
                            disabled={true}
                            onChange={({ target }) => setCity(target.value)}
                          />
                        </Col>
                        <Col md={6}>
                          <label className="mt-1">State</label>
                          <select className="input-search" defaultValue={state} value={state} disabled={true} onChange={({ target }) => { setState(target.value); }}>
                            <option key={-1} value="">
                              Select State..
                            </option>
                            {
                              statesList.map((stateVal, key) => <option key={stateVal.id} value={stateVal.name}>{stateVal.name}</option>)
                            }
                          </select>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label className="mt-1">Postcode</label>
                          <input
                            type="number"
                            className="input-search"
                            name="postcode"
                            placeholder="Postcode"
                            value={postcode}
                            disabled={true}
                            onChange={({ target }) => setPostcode(target.value)}
                          />
                        </Col>
                        <Col md={6}>
                          <label className="mt-1">Country</label>
                          <input
                            type="text"
                            className="input-search"
                            name="country"
                            placeholder="Country"
                            value="Malaysia"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                      <h4 className="mt-1">Coordinate</h4>
                      <Row>
                        <Col md={6}>
                          <label className="mt-1">Latitude</label>
                          <input
                            type="text"
                            className="input-search"
                            name="receiverLat"
                            placeholder="Latitude"
                            value={lat}
                            disabled={true}
                            onChange={({ target }) => setLat(target.value)}
                          />
                        </Col>
                        <Col md={6}>
                          <label className="mt-1">Longitude</label>
                          <input
                            type="text"
                            className="input-search"
                            name="receiverLon"
                            placeholder="Longitude"
                            value={lon}
                            disabled={true}
                            onChange={({ target }) => setLon(target.value)}
                          />
                        </Col>
                      </Row>
                      <h4 className="mt-1">Additional Information for Farmer</h4>
                      <Row>
                        <Col md={12}>
                          <textarea
                            // type="text"
                            className="input-search"
                            name="additional_information"
                            placeholder="Information"
                            value={additionalInformation}
                            onChange={({ target }) => setAdditionalInformation(target.value)}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <input
                            type="checkbox"
                            name="agree_term_condition"
                            value={agreeTermCondition}
                            // checked={agreeTermCondition == 1}
                            onChange={({ target }) => {
                              setAgreeTermCondition((agreeTermCondition == 0) ? 1 : 0);
                            }}
                          />
                          &nbsp;&nbsp;
                          <label>I've read and agree to <span className="text-green" style={{ cursor: "pointer" }} onClick={() => setTermsModal(true)}>Terms & Conditions</span> </label>
                        </Col>
                      </Row>
                      <form id="razerForm" action={process.env.REACT_APP_RAZER_PAYMENT_URL} method="POST">
                        <input type="hidden" name="merchant_id" value={process.env.REACT_APP_RAZER_MERCHANT_ID} />
                        <input type="hidden" name="amount" value={order?.remaining_balance} />
                        <input type="hidden" name="orderid" value={"ORDER_" + order?.order_number} />
                        <input type="hidden" name="bill_name" value={order?.customer.name} />
                        <input type="hidden" name="bill_email" value={order?.customer.email} />
                        <input type="hidden" name="bill_mobile" value={order?.customer.phone_number} />
                        <input type="hidden" name="bill_desc" value={order?.order_number} />
                        {/* <input type="hidden" name="returnurl" value={process.env.REACT_APP_FRONTEND + "/manage-advance-booking/deposit/" + bookingPlot?.id} /> */}
                        <input type="hidden" name="returnurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-remaining-balance-return"} />
                        <input type="hidden" name="callbackurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-remaining-balance-callback"} />
                        <input type="hidden" name="vcode" value={vcode} />
                        {/* <input type="submit" /> */}
                      </form>
                    </>
                  }

                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={3}>
        <div className="flex flex-col items-center mt-4" >
          <div style={{ minWidth: "80%", height: "17px" }} >
            {/* <DepositProgress/> */}
          </div>
        </div>
        <Row className="mt-3">
          <Col md={12}>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
              <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                <div className="p-4 w-full max-w">
                  <p><b>Total Price</b> <span className="float-right">RM {(order) ? order.amount_total_formatted : ''}</span></p>
                  <p><b>+Delivery</b> <span className="float-right">RM {(order) ? order.amount_delivery_formatted : ''}</span></p>
                  {order && order.amount_deposit > 0 && <p className="text-green"><b>-Deposit Paid</b> <span className="float-right">RM {(order) ? order.amount_deposit_formatted : ''}</span></p>}
                  <br />
                  <hr />
                  <div className="flex justify-between">
                    <p className="mt-2"><b>Total Remaining Balance</b></p>
                    <p className="mt-2 float-right">RM {(order) ? order.remaining_balance_formatted : ''}</p>
                  </div>
                  <select className="input-search" defaultValue={null} value={paymentType} onChange={({ target }) => { setPaymentType(target.value); }}>
                    <option key={"paymentMethod-1"} value={null}>
                      Select Payment Method
                    </option>
                    {/* <option key={"paymentMethod0"} value={0}>Offline</option> */}
                    <option key={"paymentMethod1"} value={1}>Online</option>
                  </select>
                  <br />
                  <br />
                  <button type="button" className="btn-green" onClick={(event) => {
                    event.preventDefault();
                    handleClickProceed();
                  }}>
                    {(isLoading) ? 'Processing, please wait..' : 'Proceed'}
                  </button>
                  <br />
                  <button type="button" className="btn-red" onClick={(event) => {
                    event.preventDefault();
                    handleClickCancel();
                  }}>
                    {(isLoading) ? 'Processing, please wait..' : 'Cancel'}
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      {showTerms && (
        <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
          <section data-testid="popup-layout" className="absolute bg-white w-4/5 h-3/4 flex flex-col rounded-xl shadow pb-4">
            <div className="bg-white w-full h-full flex flex-col rounded-t-xl px-8 py-4 overflow-y-auto">
              <TermsOfUse />
            </div>
            <div className="w-full flex gap-4 justify-center mt-2">
              <button className="borderedButton w-2/5" onClick={() => setTermsModal(false)}>
                Close
              </button>
            </div>
          </section>
        </div>
      )}
    </Row>
  );
};

export default OrderFarmerPayment;
