import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  urustaniIntegrationService from "./urustaniIntegrationService";

export const urustaniIntegrationCompany = createAsyncThunk(
    "urustani-integrations/company",
    async (args, thunkAPI) => {
        try {
            return await urustaniIntegrationService.urustaniIntegrationCompany(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const urustaniIntegrationFarms = createAsyncThunk(
    "urustani-integrations/farms",
    async (args, thunkAPI) => {
        try {
            return await urustaniIntegrationService.urustaniIntegrationFarms(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const urustaniIntegrationFarmsStore = createAsyncThunk(
    "urustani-integrations/farms/store",
    async (args, thunkAPI) => {
        try {
            return await urustaniIntegrationService.urustaniIntegrationFarmsStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    urustani_company: {},
    products: [],
    loading: false,
    isSuccess: false,
    updating: false,
}

export const urustaniIntegrateionSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
    },
    extraReducers : {
        [urustaniIntegrationCompany.pending]:(state, {payload}) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [urustaniIntegrationCompany.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.urustani_company = payload.data.urustani_company;
            state.loading=false;
            state.isSuccess = true;
        },
        [urustaniIntegrationCompany.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [urustaniIntegrationFarms.pending]:(state, {payload}) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [urustaniIntegrationFarms.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.urustani_farms = payload.data.urustani_company;
            state.loading=false;
            state.isSuccess = true;
        },
        [urustaniIntegrationFarms.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [urustaniIntegrationFarmsStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [urustaniIntegrationFarmsStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.urustani_farms = payload.data.urustani_company;
            state.loading=false;
            state.isSuccess = true;
        },
        [urustaniIntegrationFarmsStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = urustaniIntegrateionSlice.actions

export default urustaniIntegrateionSlice;