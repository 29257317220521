
import { useState } from "react";
import Rating from '@mui/material/Rating';
import "./CompanyDetails.css";
import { DateTime } from "luxon";

const CompanyDetailsRating = ({ company }) => {
    const [tab, setTab] = useState('All');

    return (
        <>
            <div className="mx-4 mb-2 text-2xl font-extrabold text-greenGray">Rating & Reviews</div>
            <div className="flex flex-col mx-4">
                <section className="flex gap-12">
                    <label
                        className={
                            tab === "All"
                                ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                                : "cursor-pointer font-medium border-b-[.2rem] border-b-black text-black tab-not-active-new"
                        }
                        onClick={() => setTab("All")}
                    >
                        All
                    </label>
                    <label
                        className={
                            tab === "product"
                                ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                                : "cursor-pointer font-medium border-b-[.2rem] border-b-black text-black tab-not-active-new"
                        }
                        onClick={() => {
                            setTab("product");
                        }}
                    >
                        Products
                    </label>
                    <label
                        className={
                            tab === "farm"
                                ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                                : "cursor-pointer font-medium border-b-[.2rem] border-b-black text-black tab-not-active-new"
                        }
                        onClick={() => {
                            setTab("farm");
                        }}
                    >
                        Farm
                    </label>
                </section>
                {company?.product_plot_rating && company?.product_plot_rating?.data.filter(item => {
                    if (item?.type === tab) {
                        return item;
                    }
                    if (tab === 'All') {
                        return item;
                    }
                }).map((rating, key) =>
                    <div key={key} className="mt-5">
                        <div className="flex w-full space-x-3">
                            <img
                                src={rating?.commenter?.photo?.src}
                                alt="photo of the commenter"
                                className="rounded-full aspect-square bg-white w-16 h-16 object-cover border-[.01rem] border-green-400"
                            />
                            <div className="flex flex-col w-full">
                                <label className="font-bold">{rating?.commenter?.name}</label>
                                <Rating name="rating" value={rating?.rating || 0} precision={0.5} readOnly />
                                <div className="text-base">{DateTime.fromISO(rating?.created_at).toFormat('dd/LL/yyyy')} | Product: {rating?.order?.product?.name} | Grade: {rating?.order?.grade_product?.name} </div>
                                <div className="mt-3 text-lg">{rating?.comments}</div>
                                <div className="flex flex-row w-full">
                                    {rating?.photos && rating?.photos?.data.map((photo, key) => {
                                        <img
                                            key={key}
                                            src={photo?.src}
                                            alt="photo of the rating"
                                            className="w-30 h-30"
                                        />
                                    })}
                                </div>
                            </div>
                        </div>
                        {key !== 0 && <span className="border-b-2 w-full block">&#8203;</span>}
                    </div>)}
            </div>
        </>
    );
};

export default CompanyDetailsRating;


