import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  assetsService from "./assetsService";

export const getPrivacyPolicy = createAsyncThunk(
    "pdfPrivacyProlicy",
    async (args, thunkAPI) => {
        try {
            return await assetsService.getPrivacyPolicy(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getReturnRefund = createAsyncThunk(
    "pdfRefund",
    async (args, thunkAPI) => {
        try {
            return await assetsService.getReturnRefund(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getTermUse = createAsyncThunk(
    "farms/update",
    async (args, thunkAPI) => {
        try {
            return await assetsService.getTermUse(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)


const initialState = {
    data: {},
    loading: false,
    isSuccess: false,
    updating: false,
}

export const assetSlice = createSlice({
    name: 'asset',
    initialState,
    reducers: {
    },
    extraReducers : {
        [getPrivacyPolicy.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [getPrivacyPolicy.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.loading=false;
            state.isSuccess = true;
        },
        [getPrivacyPolicy.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [getReturnRefund.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [getReturnRefund.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [getReturnRefund.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [getTermUse.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [getTermUse.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [getTermUse.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export default assetSlice;