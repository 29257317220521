import CartProgress from "components/cart/CartProgress";
import { useState, useEffect, useContext } from "react";
import { orderDetailIndex } from "../../features/order-detail/orderDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import CartProductTable from "./CartProductTable";
import { useLocation } from 'react-router-dom';

const CartPaymentProcess = () => {
    const [step, setStep] = useState(3);
    const location = useLocation();
    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);
    const reduxOrderDetail = useSelector((state) => state.orderDetail);
    const paymentType = new URLSearchParams(location.search).get('paymentType');

    const loadOrder = () => {
        dispatch(
            orderDetailIndex({
                status: 'pending',
                page: 1,
                limit: 100
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            }
        });
    }

    useEffect(() => {
        loadOrder();
    }, []);

    return (
        <div className="bg-gray-50 min-h-screen p-4 grid gap-4 grid-cols-1 tablet:grid-rows-3 laptop:grid-cols-4 desktop:grid-cols-8 mb-5">
            <div className="max-w-[60rem] w-full justify-self-center flex flex-col gap-4 col-span-1 tablet:col-span-2 laptop:col-span-3 desktop:col-span-4 desktop:col-start-3">
                <CartProgress step={step} />
                <div data-testid="cart-product-list" className="flex flex-col gap-4 ">
                    <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
                        <div className="hidden w-full laptop:flex flex-row items-center justify-center py-3" style={{ borderBottomWidth: "1px" }}>
                            <div className="basis-2/4 flex items-center justify-center font-medium">Item</div>
                            <div className="basis-1/3 flex items-center justify-center font-medium">Quantity</div>
                            <div className="basis-1/4 flex items-center justify-center font-medium">Price</div>
                            <div className="basis-1/3 flex items-center justify-center font-medium">Actions</div>
                            <div className="basis-1/5 flex items-center justify-center font-medium"></div>
                        </div>
                        {reduxOrderDetail.order_details.data && reduxOrderDetail.order_details.data.filter(orderDetail => orderDetail.status == 0).map((orderDetail, index) => (
                            <CartProductTable orderDetail={orderDetail} key={index} loadOrder={loadOrder} paymentType={paymentType} />
                        ))}
                    </div>
                    {reduxOrderDetail.order_details.data && reduxOrderDetail.order_details.data.length == 0 && (<h1>{reduxOrderDetail.message}</h1>)}
                </div>
            </div>
        </div>
    );
}

export default CartPaymentProcess;