import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { cartDelete, cartUpdateQuantity } from "features/cart/cartSlice";

const CartProduct = ({ carts, loadCarts, isLoading, courierList, loadCourier, setSelectedCourier, setShippingCharge, setTotalAmount, cartsArr, totalAmount, productPrice, shippingCharge, setProductPrice }) => {
    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);

    //choose delivery
    const [wait, setWait] = useState(false);

    const [updating, setUpdating] = useState(false);

    const updateQuantity = (e, cart) => {
        let t = clearTimeout(wait);
        setWait(t);

        setWait(setTimeout(function () {
            setUpdating(true);
            let quantity = parseInt(e.target.value);
            if (!quantity) return;
            dispatch(
                cartUpdateQuantity(
                    {
                        cart_id: cart?.id,
                        quantity: quantity
                    }
                )
            ).then(function (response) {
                setUpdating(false);

                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success")
                    loadCarts();
                }
            });
        }, 1000))

    }

    const updateQuantityCustom = (val, cart, operator) => {
        let t = clearTimeout(wait);
        setWait(t);

        setWait(setTimeout(function () {
            setUpdating(true);
            let quantity = parseInt(val);
            if (!quantity) return;
            dispatch(
                cartUpdateQuantity(
                    {
                        cart_id: cart?.id,
                        quantity: quantity,
                        operator: operator
                    }
                )
            ).then(function (response) {
                setUpdating(false);

                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success")
                    loadCarts();
                }
            });
        }, 1000))
    }

    const addQuantity = (e, order) => {
        updateQuantityCustom(order?.quantity + 1, order, 'plus');
        e.preventDefault();
    }

    const subQuantity = (e, order) => {
        if (order?.quantity > 0) {
            updateQuantityCustom(order?.quantity - 1, order , 'minus')
        }

        e.preventDefault();
    }

    const deleteHandler = order => {
        if (window.confirm(`Are you sure you want to delete product ${order?.product.name}? `)) {
            dispatch(
                cartDelete({
                    cart_id: order.id
                })
            ).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success")
                    loadCarts();
                }
            });
        }
    }

    const checkByFarmer = (farmer, index) => {
        let checkboxes = document.getElementsByName(farmer);
        let checkbox = document.getElementsByName(farmer + '-main');

        for (var i = 0, n = checkboxes.length; i < n; i++) {
            if (checkbox[0].checked === true) {
                checkboxes[i].checked = true
            } else {
                checkboxes[i].checked = false
            }
        }

        if (checkbox[0].checked === true) {
            for (let j = 0; j < Object.values(carts)[index].length; j++) {
                setProductPrice(productPrice + parseFloat(Object.values(carts)[index][j].total_price.toFixed(2)))
                setTotalAmount(totalAmount + parseFloat(shippingCharge.toFixed(2)) + parseFloat(Object.values(carts)[index][j].total_price.toFixed(2)))
            }
        } else {
            for (let j = 0; j < Object.values(carts)[index].length; j++) {
                setProductPrice(productPrice - parseFloat(Object.values(carts)[index][j].total_price.toFixed(2)))
                setTotalAmount(totalAmount + parseFloat(shippingCharge.toFixed(2)) - parseFloat(Object.values(carts)[index][j].total_price.toFixed(2)))
            }
        }
    }

    return (
        Object.keys(carts).map((farmer, index) => <div className="w-full bg-white" style={{ borderBottomWidth: "1px" }}>
            <div className="w-full flex items-center px-4 pt-3">
                <input
                    type="checkbox"
                    className="mr-3"
                    name={farmer + "-main"}
                    onChange={() => checkByFarmer(farmer, index)}
                />
                <div key={index} className="bg-white text-main text-lg font-extrabold">{farmer}</div>
            </div>
            <div className="w-full max-w bg-white flex flex-col items-center ">
                {Object.values(carts)[index].map((order, index) => <div className="lg:w-full lg:px-5">
                    <div key={index} data-testid="cart-product"
                        className="bg-white w-full flex flex-row lg:py-4 lgjustify-between items-center" >
                        <section className="items-center tablet:flex">
                            <input
                                type="checkbox"
                                name={farmer}
                                onClick={() => {
                                    loadCourier(order, farmer, index)
                                }}
                            />
                        </section>
                        <section className="flex items-center px-4 gap-4 laptop:basis-2/4 sm: mb-3 lg:mb-0">
                            <img
                                src={order?.grade?.photo}
                                alt="photo of product"
                                className="cartProductLarge:inline min-w-[5rem] max-w-[5rem] min-h-[5rem] max-h-[5rem] object-cover aspect-square rounded-full"
                            />
                            <span> <b>Name: {order?.product?.name}</b><br /><b>Grade:</b>  {order?.grade?.name} <br /> Available:  {order?.grade?.available_qty} <br />  </span>
                        </section>
                        <section className="hidden items-center justify-center tablet:flex basis-1/3">
                            <div className="hidden items-center tablet:flex gap-2 ">
                                <div className="input-group" style={{ borderWidth: "1px" }}>
                                    <div className="laptop:flex input-group-prepend">
                                        <input type="button" value="-" className="button-minus" onClick={e => subQuantity(e, order)} data-field="quantity" style={{ boxShadow: "none" }} />
                                    </div>
                                    <input type="text" id="quantity" disabled value={order?.quantity} onChange={e => updateQuantity(e, order)} name="quantity" className="form-control" style={{ borderRadius: "0px", boxShadow: "none", borderWidth: "1px", textAlign: "center", maxWidth: "100px" }} />
                                    <div className="laptop:flex input-group-append">
                                        <input type="button" value="+" className="button-plus" onClick={e => addQuantity(e, order)} data-field="quantity" style={{ boxShadow: "none" }} />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="hidden items-center justify-center tablet:flex basis-1/3">
                            <div className="hidden items-center tablet:flex gap-2 ">
                                <label>
                                    RM {order?.total_price.toFixed(2)}
                                </label>
                            </div>
                        </section>
                        <section className="hidden laptop:flex items-center justify-center basis-2/12 ">
                            <div className="flex items-center">
                                <label className=" text-center">{order?.price_unit} {order?.subtotal_formatted}</label>
                            </div>
                        </section>
                        <section className="hidden items-center px-4 justify-end tablet:flex basis-2/12 ">

                            <img
                                src="/assets/images/TrashIcon.svg"
                                alt="trash icon"
                                className="w-[2rem] h-[2rem] aspect-square cursor-pointer"
                                onClick={() => deleteHandler(order)}
                            />
                        </section>
                    </div>
                    <div className="tablet:hidden w-full flex flex-row p-4 pt-0 justify-between items-center">
                        <div className="input-group w-[10rem]" style={{ borderWidth: "1px" }}>
                            <div className="input-group-prepend">
                                <input type="button" value="-" className="button-minus" onClick={subQuantity} data-field="quantity" style={{ boxShadow: "none" }} />
                            </div>
                            <input type="text" id="quantity" defaultValue={order?.quantity} onChange={updateQuantity} name="quantity" className="form-control" style={{ borderRadius: "0px", boxShadow: "none", borderWidth: "1px", textAlign: "center" }} />
                            <div className="input-group-append">
                                <input type="button" value="+" className="button-plus" onClick={addQuantity} data-field="quantity" style={{ boxShadow: "none" }} />
                            </div>
                        </div>
                        <img
                            src="/assets/images/TrashIcon.svg"
                            alt="trash icon"
                            className="aspect-square cursor-pointer mr-auto ml-auto"
                            onClick={() => deleteHandler()}
                        />
                    </div>
                </div>)}
            </div>
            <div className="flex justify-end px-12 pb-6">
                <select className="desktop:w-1/6 laptop:w-2/6 tablet:w-3/6 input-search mt-1" defaultValue={"self_delivery"} value={courierList} onChange={(e) => {
                    if (e.target.value) {
                        setSelectedCourier(e.target.value);
                        let tempShippingCharge = e.target[e.target.selectedIndex].getAttribute('data-shippingCharge');
                        setShippingCharge(tempShippingCharge);
                        if (cartsArr.length > 0) {
                            cartsArr.map((orderItem, index) => {
                                setTotalAmount(totalAmount + orderItem.subtotal_formatted);
                            })

                            setTotalAmount(totalAmount + parseFloat(tempShippingCharge))
                        }
                    }
                }}>
                    <option key={-1}>{(isLoading) ? 'Processing, please wait..' : 'Choose Courier Service'}</option>
                    {courierList.map((courier, index) => (
                        <option key={index} value={courier.service.name}
                            data-serviceCode={courier.service.code}
                            data-shippingCompany={courier.service.serviceCompany.name}
                            data-shippingCharge={courier.price.amount}
                        >
                            {courier.service.name} | RM {courier.price.amount}
                        </option>
                    ))}
                </select>
            </div>
        </div>)
    );
}
export default CartProduct;
