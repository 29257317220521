const PrivacyPolicy = () => {
    return (
        <div className="flex justify-start flex-col ">
            <div className="italic font-bold text-sm p-2">
                TaniExchange Privacy Policy
            </div>
            <div className="text-xs px-4 flex flex-col">
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            1. 
                        </span>
                        <span>
                            Introduction 
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            TaniExchange is a platform developed by Singularity Aerotech Asia Sdn Bhd (SAT Asia) which acts
                            an eco-system platform bringing together multiple players in agricultural sector supply chains
                            involving farmers, buyers, retailers, and logistics players. SAT Asia offers this Service through a
                            subscription model, designed for its intended use
                        </label>
                        <label className="mt-2">
                            This page outlines our practices for gathering, utilizing, and revealing personal data when engaging
                            with our services. We hold user privacy in high regard and are dedicated to safeguarding personal
                            information. Utilization of our services indicates your consent to abide by the terms detailed in this
                            Privacy Policy.
                        </label>
                    </div>
                </div>

                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            2. 
                        </span>
                        <span>
                            Information Collection and Use
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            We gather and utilize personal data to enhance our services, facilitate communication, and
                            customize your interaction. When prompted by the system, you may be asked to furnish specific
                            personally identifiable information. The data you provide will be stored and utilized as outlined in
                            this privacy policy. In adherence to relevant laws and regulations, we may share your personal
                            details with third-party service providers.
                        </label>
                    </div>
                </div>

                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            3. 
                        </span>
                        <span>
                            Usage Data
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            Usage Data is automatically gathered during service utilization. This data might encompass details
                            like Your Device's Internet Protocol address (e.g., IP address), the version and type of your browser,
                            the specific pages visited within our Service, the date and time of your visit, the duration spent on
                            those pages, unique identifiers for devices, and other diagnostic information. When accessing the
                            Service through a mobile device, certain information, including the mobile device's type, unique ID,
                            IP address, mobile operating system, mobile browser type, and other diagnostic data, may be
                            collected automatically. Furthermore, we may collect information sent by your browser each time
                            you visit our Service or access it through a mobile device.
                        </label>
                    </div>
                </div>

                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            4. 
                        </span>
                        <span>
                            Cookies
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            We use cookies and similar tools to gather and keep track of how you use our services. Cookies
                            are small files that get stored on your device when you use our website. We use two types of
                            cookies: session cookies that disappear when you close your browser, and persistent cookies that
                            stay on your device until they expire or you remove them. Cookies help us remember your choices,
                            see how you use our services, and make our services better for you. We also use cookies to
                            understand how people use our site and show you ads. You can change your cookie settings in
                            your web browser, but if you turn off cookies, some parts of our services might not work as well.
                        </label>
                    </div>
                </div>

                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            5. 
                        </span>
                        <span>
                            Service Providers
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            We will use third-party service providers to assist us in providing our services and to help us
                            understand how our services are used. These service providers may collect and process personal
                            information on our behalf. We require our service providers to use reasonable security measures
                            to protect the confidentiality and integrity of personal information and to comply with applicable
                            laws and regulations governing the processing of personal information.
                        </label>
                        <label className="mt-1">
                            Our service providers are prohibited from using personal information for any purpose other than to
                            provide services to us. We may share personal information with our service providers to the extent
                            necessary to enable them to provide services to us.
                        </label>
                    </div>
                </div>

                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            6. 
                        </span>
                        <span>
                            Security
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            We appreciate you trusting us with your Personal Information, and we're working hard to keep it
                            safe using methods that are commonly considered reliable. However, please keep in mind that no
                            method of sending information online or storing it electronically is completely foolproof, and we
                            cannot guarantee its absolute security
                        </label>
                    </div>
                </div>

                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            7. 
                        </span>
                        <span>
                            Links to Other Sides
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            This Service might have links to other websites. If you click on a third-party link, you will be directed
                            to that site. Note that these external sites are not operated by us. Hence, we highly recommend
                            you to review the Privacy Policy of these websites which we have no control over, and assume no
                            responsibility for the content, privacy policies, or practices of any third-party sites or services.
                        </label>
                    </div>
                </div>

                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            8. 
                        </span>
                        <span>
                            Children's Privacy
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            These Services DO NOT address anyone under the age of 13. We DO NOT knowingly collect
                            personal information from children aged below 13 without parental consent. In the case we discover
                            that a child aged below 13 has provided us with personal information, we immediately delete this
                            from our servers. If you are a parent or guardian and you are aware that your child has provided us
                            with personal information, please contact us so that we will be able to do the necessary actions.
                        </label>
                    </div>
                </div>

                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            9. 
                        </span>
                        <span>
                            Changes to This Privacy Policy
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            We will update this privacy policy to reflect changes in our practices or laws. We will post notice of
                            changes on our website or through other means and encourage you to review the updated policy.
                            Your continued use of our services will constitute acceptance of changes. Changes to this Privacy
                            Policy are effective when they are posted on this page.
                        </label>
                    </div>
                </div>

                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            10. 
                        </span>
                        <span>
                            Contact Us
                        </span>
                    </label>
                    <div>
                        <label className="mt-1 mr-1">
                            If you have any questions or suggestions about our Privacy Policy, you may contact us at
                        </label>
                        <label className="underline">
                            admin.taniexchange
                        </label>
                        <label className="underline">
                            @singularityaerotech.asia
                        </label>
                    </div>
                </div>

                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            Revision 1.0. 
                        </span>
                        <span>
                            Date 21/08/2023
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                        © SINGULARITY AEROTECH ASIA SDN BHD 2020. ALL RIGHTS RESERVED. CONFIDENTIAL AND PROPRIETARY DOCUMENT.
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;