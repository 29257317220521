import React from "react";
import {Provider} from "react-redux";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import '_helpers/i18n';
import {store} from "app/store";
import {SnackBarContextProvider} from "_helpers/snackbar-context";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { GoogleOAuthProvider } from '@react-oauth/google';

import "./index.css";
import "./tailwind.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//     <React.StrictMode>
//         <Provider store={store}>
//             <SnackBarContextProvider>
//                 <App/>
//             </SnackBarContextProvider>
//         </Provider>
//     </React.StrictMode>
// );
root.render(
    <Provider store={store}>
        <SnackBarContextProvider>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <App/>
            </GoogleOAuthProvider>
        </SnackBarContextProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

reportWebVitals();
