import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import creditManagementService from './creditManagementService';

export const creditManagementIndex = createAsyncThunk(
    "creditManagement/index",
    async (args, thunkAPI) => {
        try {
            return await creditManagementService.creditManagementIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const creditManagementStore = createAsyncThunk(
    "creditManagement/store",
    async (args, thunkAPI) => {
        try {
            return await creditManagementService.creditManagementStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const creditManagementUpdate = createAsyncThunk(
    "creditManagement/update",
    async (args, thunkAPI) => {
        try {
            return await creditManagementService.creditManagementUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const creditManagementDelete = createAsyncThunk(
    "creditManagement/delete",
    async (args, thunkAPI) => {
        try {
            return await creditManagementService.creditManagementDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const creditManagementShow = createAsyncThunk(
    "creditManagement/:id/show",
    async (args, thunkAPI) => {
        try {
            return await creditManagementService.creditManagementShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    creditManagement: {},
    creditManagements: {
        data: []
    },
    loading: false,
    isSuccess: false,
    updating: false,
}

export const creditManagementSlice = createSlice({
    name: 'creditManagement',
    initialState,
    reducers: {
    },
    extraReducers : {
        [creditManagementStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [creditManagementStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.creditManagement = payload.data.creditManagement;
            state.loading=false;
            state.isSuccess = true;
        },
        [creditManagementStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [creditManagementUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [creditManagementUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [creditManagementUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        
        [creditManagementDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [creditManagementDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [creditManagementDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [creditManagementShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [creditManagementShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.creditManagement = payload.data.creditManagement;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [creditManagementShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [creditManagementIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [creditManagementIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.creditManagements = payload.data.creditManagements;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [creditManagementIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export default creditManagementSlice;