const CatalogControls = ({ search, searchByHandler, inputHandler }) => {
  return (
    <div
      data-testid="product-catalog-controls"
      className=""
    >
      {/* <section className="flex gap-8">
        <label
          className={
            search.searchBy === "Product"
              ? "cursor-pointer font-medium border-b-[.2rem] border-b-main"
              : "cursor-pointer border-b-[.2rem] border-b-gray-300"
          }
          onClick={() => searchByHandler("Product")}
        >
          Search by Product
        </label>
        <label
          className={
            search.searchBy === "Farmer"
              ? "cursor-pointer font-medium border-b-[.2rem] border-b-main"
              : "cursor-pointer border-b-[.2rem] border-b-gray-300"
          }
          onClick={() => searchByHandler("Farmer")}
        >
          Search by Farmer
        </label>
      </section> */}
      <input
        type="search"
        className="input-search input-search-width"
        placeholder="Search.."
        value={search.input}
        onChange={({ target }) => inputHandler(target.value)}
      />
    </div>
  );
};

export default CatalogControls;
