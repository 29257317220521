const resources = {
    en: {
        translation: {
            'home': 'Home',
            'next': 'Next',
            'or': 'or',
            'farmersProduce': 'Farmers Produce',
            'marketDemand': 'Market Demand',
            'login': 'Login',
            'register': 'Register',
            'myAccount': 'My Account',
            'registration.accountRegistration': 'Account Registration',
            'registration.registerUsingEmail': 'Register using email',
            'registration.registerUsingPhoneNumber': 'Register using phone number',
            'registration.usingGoogle': 'Register using Google',
            'registration.usingFacebook': 'Register using Facebook',
            'registration.passwordMustBe': 'Must be at least 6 characters in length or more',
            'registration.closed': 'Registration is closed for time being',
            'registration.beBack': 'We will be back after this',
            'login.signIn': 'Sign In',
            'home.simple': 'Simple.',
            'home.easy': 'Easy.',
            'home.fair': 'Fair.',
            'home.harvest': 'Harvest.',
            'home.connect': 'Connect.',
            'home.thrive': 'Thrive.',
            'growing_connection': 'Growing Connections',
            'cultivating_success': 'Cultivating Success',
            'login.rememberMe': 'Remember Me',
            'login.forgotPassword': 'Forgot Password',
            'login.signInWithGoogle': 'Login with Google',
            'login.signInWithFacebook': 'Login with Facebook',
            'login.registerInWithGoogle': 'Register with Google',
            'login.registerInWithFacebook': 'Register with Facebook',
            'login.socialCancelButton': 'User cancelled login or did not fully authorize',
            'farmer': 'Farmer',
            'buyer': 'Buyer',
            'farmerBuyer': 'Farmer/Buyer',
            'both': 'Both',
            'active': 'Active',
            'activate': 'Activate',
            'inactive': 'Inactive',
            'days': 'Days',
            'months': 'Months',
            'years': 'Years',
            'what.is': 'What is',
            'tani': 'tani',
            'exchange': 'exchange',
            'intro.one': 'Welcome to TaniExchange, a platform developed by Singularity Aerotech Asia Sdn Bhd (SAT Asia) to facilitate collaboration among various players in the agricultural sector supply chains, including farmers, buyers, retailers, and logistics participants.',
            'intro.two': 'TaniExchange aims to empower farmers, especially smallholders, by providing them with a business matching platform. This platform enables them to connect with potential customers and secure contract farming opportunities at an early stage.',
            'howItWorks': 'How it works',
            'farmerTitle': 'I have a farm',
            'farmerFirstDesc': 'That produces fresh products',
            'farmerSecondDesc': 'That has plot(s) available for advanced booking/auction',
            'taniexchange': 'TaniExchange',
            'taniexchangeDesc': 'acts as a business matching platform providing farmers and buyers direct market access, eliminating needs for intermediaries',
            'buyerTitle': 'I have a business',
            'buyerFirstDesc': 'That requires fresh products',
            'buyerSecondDesc': 'That requires advanced and secured supplies from market',
            'harvestConnectThrive': 'Harvest. Connect. Thrive',
            'aboutUs': 'About Us',
            'termsOfUse': 'Terms Of Use',
            'returnRefund': 'Return & Refund Policy',
            'privacyPolicy': 'Privacy Policy',
            'contact': "Contact",
            'trademark': '© 2023 SAT.ASIA',
            'offline' : 'Offline',
            'online' : 'Online'
        }
    },
    my: {
        translation: {
            'home': 'Home',
            'next': 'Next',
            'or': 'or',
            'farmersProduce': 'Farmers Produce',
            'marketDemand': 'Market Demand',
            'login': 'Login',
            'register': 'Register',
            'myAccount': 'My Account',
            'registration.accountRegistration': 'Account Registration',
            'registration.registerUsingEmail': 'Register using email',
            'registration.registerUsingPhoneNumber': 'Register using phone number',
            'registration.usingGoogle': 'Register using Google',
            'registration.usingFacebook': 'Register using Facebook',
            'registration.passwordMustBe': 'Must be at least 6 characters in length or more',
            'login.signIn': 'Sign In',
            'home.simple': 'Simple.',
            'home.easy': 'Easy.',
            'home.fair': 'Fair.',
            'home.harvest': 'Tuai.',
            'home.connect': 'Sambung.',
            'home.thrive': 'Berkembang.',
            'growing_connection': 'Mengembangkan Hubungan',
            'cultivating_success': 'Memupuk Kejayaan',
            'login.rememberMe': 'Remember Me',
            'login.forgotPassword': 'Forgot Password',
            'login.signInWithGoogle': 'Sign in with Google',
            'login.signInWithFacebook': 'Sign in with Facebook',
            'login.registerInWithGoogle': 'Register with Google',
            'login.registerInWithFacebook': 'Register with Facebook',
            'offline' : 'Luar talian',
            'online' : 'Dalam talian'
        }
    }
};

export default resources
