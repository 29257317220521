import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const PaymentProgress = ({step, order}) => {
  const location = useLocation();
  const params  = useParams();
  let progress = 1;
  let bgColor = '';
  if(location.pathname == '/customer/order-farmers/'+params.order_number+'/payment' && step == 2){
    progress = 2;
    bgColor = "bg-green-500";
  }

  if(location.pathname == '/customer/order-farmers/'+params.order_number+'/payment-offline'){
    progress = 2;
    bgColor = "bg-green-500";
  }

  if(location.pathname == '/customer/order-farmers/'+params.order_number+'/payment-result'){
    progress = 3;
    if (order?.payment_history?.status == '11') { // failed
      bgColor = 'bg-red-500';
    } else if(order?.payment_history?.status == '00') { // success
      bgColor = 'bg-green-500';
    } else {
      bgColor = 'bg-yellow-500';
    }
  }
  const progressBar = {
    width: `${(progress / 3) * 100}%`,
    height: "17px"
  };

  return (
    <div className="flex flex-col gap-4 w-full" style={{ height: "17px" }}>
      <div className="bg-gray flex rounded-lg" style={{ height: "17px" }}>
        <div className={`flex rounded-lg justify-center h-3 ${bgColor}`} style={progressBar}></div>
      </div>
    </div>
  );
};

export default PaymentProgress;
