import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { farmShow } from "../features/farm/farmSlice";
import { catalogSearch } from "../features/catalog/catalogSlice";
import SnackbarContext from "_helpers/snackbar-context";

import CatalogList from "../components/catalogs/CatalogList";
import CatalogSidebar from "../components/catalogs/CatalogSidebar";
import * as url from "../support/support_url";
import "./Pages.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Footer from "components/footer/footer";
import FooterStyles from "components/footer/footer.module.scss";
import clsx from "clsx";
import LoadingWidget from "components/loading/LoadingWidget";

const CatalogPage = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbarCtx = useContext(SnackbarContext);
  const [showSideBar, setShowSideBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState({
    input: "",
    searchBy: "Product",
    searchPlotBy: "advanced",
    filters: {
      fruits: ["Bananas", "Grapes", "Citrus"],
      vegetables: ["Tomatoes", "Potatoes", "Salads"],
    },
    selectedFilter: "None",
    page: 1
  });

  const inputHandler = (newInput) => {
    setSearch({ ...search, input: newInput });
  };

  const filterHandler = (newFilter) => {
    setSearch({ ...search, selectedFilter: newFilter });
  };

  const searchByHandler = (newSearchBy) => {
    setSearch({ ...search, searchBy: newSearchBy, page: 1 });

  };

  const searchPlotByHandler = (newSearchPlotBy) => {
    setSearch({ ...search, searchPlotBy: newSearchPlotBy, page: 1 });
  };

  const handleChangePage = (event, newPageNumber) => {
    setSearch({ ...search, page: newPageNumber }); // Call the common function
  };

  const catalog = useSelector((state) => state.catalog);

  // const [testProducts, setTestProducts] = useState([
  //   {
  //     photo: "/assets/images/products/BananasPhoto.svg",
  //     farm_id: 1,
  //     grade_id: 1,
  //     name: "Apple Bananas",
  //     category: "Fruits",
  //     sub_category: "Bananas",
  //     description: "",
  //     quantity_number: 10,
  //     quantity_target: 10,
  //   },
  //   {
  //     photo: "/assets/images/products/GrapesPhoto.svg",
  //     farm_id: 1,
  //     grade_id: 1,
  //     name: "Autumn Royal Grapes",
  //     category: "Fruits",
  //     sub_category: "Grapes",
  //     description: "",
  //     quantity_number: 10,
  //     quantity_target: 10,
  //   },
  //   {
  //     photo: "/assets/images/products/OrangesPhoto.svg",
  //     farm_id: 1,
  //     grade_id: 1,
  //     name: "Valencia Oranges",
  //     category: "Fruits",
  //     sub_category: "Citrus",
  //     description: "",
  //     quantity_number: 10,
  //     quantity_target: 10,
  //   },
  //   {
  //     photo: "/assets/images/products/OrangesPhoto.svg",
  //     farm_id: 1,
  //     grade_id: 1,
  //     name: "Seville Oranges",
  //     category: "Fruits",
  //     sub_category: "Citrus",
  //     description: "",
  //     quantity_number: 10,
  //     quantity_target: 10,
  //   },
  //   {
  //     photo: "/assets/images/products/DuriansPhoto.svg",
  //     farm_id: 1,
  //     grade_id: 1,
  //     name: "Tekka Durians",
  //     category: "Fruits",
  //     sub_category: "Durians",
  //     description: "",
  //     quantity_number: 10,
  //     quantity_target: 10,
  //   },
  //   {
  //     photo: "",
  //     farm_id: 1,
  //     grade_id: 1,
  //     name: "Alicante Tomatoes",
  //     category: "Vegetables",
  //     sub_category: "Tomatoes",
  //     description: "",
  //     quantity_number: 10,
  //     quantity_target: 10,
  //   },
  //   {
  //     photo: "",
  //     farm_id: 1,
  //     grade_id: 1,
  //     name: "Starchy Potatoes",
  //     category: "Vegetables",
  //     sub_category: "Potatoes",
  //     description: "",
  //     quantity_number: 10,
  //     quantity_target: 10,
  //   },
  //   {
  //     photo: "/assets/images/products/SpinachPhoto.png",
  //     farm_id: 1,
  //     grade_id: 1,
  //     name: "Savoy Spinach",
  //     category: "Vegetables",
  //     sub_category: "Salads",
  //     description: "",
  //     quantity_number: 10,
  //     quantity_target: 10,
  //   },
  // ]);

  const [wait, setWait] = useState(false);

  const searchCatalogs = () => {
    var inputParams = {};
    if (search.searchBy == "Product") {
      inputParams = {
        keyword: search.input,
        searchBy: search.searchBy,
        filter: search.selectedFilter,
        limit: 18,
        page: search.page
      };
    } else {
      inputParams = {
        keyword: search.input,
        searchBy: search.searchBy,
        searchPlotBy: search.searchPlotBy,
        filter: search.selectedFilter,
        limit: 18,
      };

    }

    let t = clearTimeout(wait);
    setWait(t);
    setIsLoading(true);
    setWait(
      setTimeout(function () {
        dispatch(
          catalogSearch(inputParams)
        ).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
            snackbarCtx.displayMsg(response.payload, "failure")
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        });
      }, 1000)
    );
  };

  useEffect(() => {
    let qs = url.get_current_query_string()

    setSearch({
      ...search,
      input: qs.search ? qs.search : '',
      selectedFilter: qs.filter ? qs.filter : '',
      searchBy: qs.searchBy ? qs.searchBy : 'Product',
      searchPlotBy: qs.searchPlotBy ? qs.searchPlotBy : 'advanced',
    });
  }, []);


  useEffect(() => {
    searchCatalogs();

    let params = url.set_current_query_string_filter(search)

    navigate(`?${params.toString()}`);
  }, [search]);

  return (
    <>
      <Container className={clsx(FooterStyles.menuMobile)}>
        <Row>
          {showSideBar && (
            <>
              <Col md={3} className="margin-seidebar pb-10 laptop:min-h-screen">
                <CatalogSidebar search={search} filterHandler={filterHandler} setShowSideBar={setShowSideBar} />
              </Col>
            </>
          )}
          <Col md={showSideBar ? 9 : 12} className="bg-content-new mr-0">
            {!showSideBar && (
              <>
                <div
                  className="pl-4 pt-3 text-icon-color-green cursor-pointer"
                  onClick={() => {
                    setShowSideBar(true);
                  }}
                >
                  <FontAwesomeIcon icon={faFilter} className={"text-icon-color-green"} /> Filter
                </div>
              </>
            )}

            <CatalogList
              search={search}
              products={catalog.products}
              plots={catalog.plots}
              // products={testProducts}
              searchByHandler={searchByHandler}
              inputHandler={inputHandler}
              showSideBar={showSideBar}
              searchPlotByHandler={searchPlotByHandler}
              handleChangePage={handleChangePage}
            />
          </Col>
        </Row>
        <Footer />
      </Container>
      <LoadingWidget isLoading={isLoading} />
    </>


  );
};

export default CatalogPage;
