import { useState } from "react";

function initFBLoginAPI() {
    window.fbAsyncInit = function() {
        window.FB.init({
            appId      : process.env.REACT_APP_FB_APP_ID,
            cookie     : true,
            xfbml      : true,
            version    : process.env.REACT_APP_FB_API_VERSION
        });
            
        window.FB.AppEvents.logPageView();   
        
    };
  
    (function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
}


const useStateWithCallback = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const setValueAndCallback = (newValue, callback) => {
        setValue(prevValue => {
            if (callback) {
                callback(prevValue, newValue);
            }
            return newValue;
        });
    };

    return [value, setValueAndCallback];
}

export { useStateWithCallback, initFBLoginAPI};
