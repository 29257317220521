import api from "../../support/support_api";

const varietyStore = async (params) => {
    const response  = await  api.axiosPost('/variety', params);

    return response.data;
}

const varietyDelete = async (params) => {
    const response  = await  api.axiosDelete(`/variety/${params.id}`, params);

    return response.data;
}

const varietyUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/variety/${params.id}`, params);

    return response.data;
}

const varietyUploadPhotos = async (params) => {
    const response  = await  api.axiosPatch(`/variety/${params.variety_id}`, params);

    return response.data;
}

const varietyIndex = async (params) => {
    const response  = await api.axiosShow(`/variety/${params.variety_id}`, params);

    return response.data;
}

const varietyShow = async (params) => {
    const response  = await api.axiosGet(`/variety`, params);

    return response.data;
}

const varietyService = {
    varietyStore, varietyDelete, varietyUpdate, varietyUploadPhotos, varietyShow, varietyIndex
}

export default varietyService;