import api from "../../support/support_api";

const demandApplicationStore = async (params) => {
    const response  = await  api.axiosPost('/demand-applications', params);

    return response.data;
}

const demandApplicationUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/demand-applications/${params.demandApplication_id}`, params);

    return response.data;
}

const demandApplicationShow = async (params) => {
    const response  = await api.axiosShow(`/demand-applications/${params.demandApplication_id}`, params);

    return response.data;
}


const demandApplicationIndex = async (params) => {
    const response  = await api.axiosGet(`/demand-applications`, params);

    return response.data;
}


const demandApplicationAccept = async (params) => {
    const response  = await api.axiosPatch(`/demand-applications/${params.demand_application_id}/accept`, params);

    return response.data;
}


const demandApplicationDecline = async (params) => {
    const response  = await api.axiosPatch(`/demand-applications/${params.demand_application_id}/decline`, params);

    return response.data;
}

const demandApplicationDestroy = async (params) => {
    const response  = await api.axiosDelete(`/demand-applications/${params.demand_application_id}`, params);

    return response.data;
}


const demandApplicationService = {
    demandApplicationStore,
    demandApplicationUpdate,
    demandApplicationShow,
    demandApplicationIndex,
    demandApplicationAccept,
    demandApplicationDecline,
    demandApplicationDestroy
}

export default demandApplicationService;