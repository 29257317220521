import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "pages/AccountLayout";
import SnackbarContext from "_helpers/snackbar-context";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { companyShow, companyStatusUpdate } from "features/company/companySlice";
import Toggle from 'react-styled-toggle';
import { userStatusUpdate } from "features/user/userSlice";
import { packageIndex } from "features/package-subscription/packageSlice";
import { resubscribePackage } from "features/package-subscription/companyPackageSlice";

const CompanyPlan = () => {
  const params = useParams();
  const { company } = useSelector((state) => state.company);
  const { packages } = useSelector((state) => state.package);
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [isChangePlan, setChangePlan] = useState(true);
  const [selectedPackage, setSelectecPackage] = useState(packages?.data[0]);

  const loadCompany = () => {
    dispatch(
      companyShow({
        company_id: params?.id
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      }
    });
  }

  const loadPackage = () => {
    dispatch(
      packageIndex()
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      }
    });
  }

  const updateCompany = activeness => {
    let company_id = params?.id;
    dispatch(
      companyStatusUpdate({
        company_id: company_id,
        active: activeness,
      }),
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
        loadCompany();
      }
    });
  };

  const toggleUserActiveness = (user) => {
    dispatch(
      userStatusUpdate({
        user_id: user?.id,
        active: user?.active === 1 ? 0 : 1
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        loadCompany();
      }
    });
  }

  const handlePackageChange = event => {
    let packageForPlan = packages?.data.find((item) => {
      return item?.id === parseInt(event?.target?.value);
    })
    
    setSelectecPackage(packageForPlan)

    event.preventDefault();
  };

  const savePlan = () => {
    dispatch(
      resubscribePackage({
        package_id: selectedPackage?.id || packages?.data[0]?.id,
        company_id: company.id,
        subs_start_date: DateTime.now(),
        subs_end_date: DateTime.now().plus({ days: selectedPackage?.package_period_format === 'Months' ? 30 : 356 || packages?.data[0]?.package_period_format === 'Months' ? 30 : 356 }),
        service_charge: selectedPackage?.service_charge,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        navigate(-1);
      }
    });
  }

  useEffect(() => {
    loadCompany();
    loadPackage();
  }, []);

  return (
    <>
      <PageLayout>
        <div data-testid="order-list" className="flex flex-col items-start gap-6 p-4">
          <label className="cursor-pointer head-bar-nav font-regular font-semibold text-sm" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
            {" Go Back"}
          </label>
          <h1 className="text-3xl text-left font-semibold font-regular" style={{ color: "#395B50" }}>Company Plan</h1>
          <div className="w-full max-w bg-white items-center border-2 p-8 rounded-lg font-regular space-y-6">
            <div className="space-y-2">
              <label className="font-bold">Company Name:</label>
              <input
                type="text"
                className={"bg-gray-300 input-search"}
                placeholder={company?.name}
                disabled
              />
            </div>
            {isChangePlan ?
              <div className="flex w-full space-x-5">
                <div className="border-2 rounded p-4 space-y-2">
                  <div className="flex w-full justify-end mb-3">
                    <label className="text-green-800 underline text-sm">Current Plan</label>
                  </div>
                  <div>
                    <label className="font-bold">Plan Subscribed:</label>
                    <input
                      type="text"
                      className={"bg-gray-300 input-search"}
                      placeholder={company?.selected_package?.data.length > 0 && company?.selected_package?.data[0].package?.package_name || 'N/A'}
                      disabled
                    />
                  </div>
                  <div>
                    <label className="font-bold">Billing Period:</label>
                    <input
                      type="text"
                      className={"bg-gray-300 input-search"}
                      placeholder={company?.selected_package?.data.length > 0 && company?.selected_package?.data[0].package?.package_period_format === 'Months' ? 'Monthly' : 'Yearly'}
                      disabled
                    />
                  </div>
                  <div className="w-full">
                    <label className="font-bold">Billing Date:</label>
                    <div className="flex justify-evenly space-x-4">
                      <div className="w-full">
                        <label>Start Date:</label>
                        <input
                          type="text"
                          className={"bg-gray-300 input-search"}
                          placeholder={DateTime.fromSQL(company?.selected_package?.data[0].subs_start_date).toFormat('dd/LL/yyyy')}
                          disabled
                        />
                      </div>
                      <div className="w-full">
                        <label>End Date:</label>
                        <input
                          type="text"
                          className={"bg-gray-300 input-search"}
                          placeholder={DateTime.fromSQL(company?.selected_package?.data[0].subs_end_date).toFormat('dd/LL/yyyy')}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="font-bold">Paid Amount:</label>
                    <input
                      type="text"
                      className={"bg-gray-300 input-search"}
                      placeholder={company?.bills?.data[0]?.current_charge}
                      disabled
                    />
                  </div>
                  <div>
                    <label className="font-bold">Overdue Amount:</label>
                    <input
                      type="text"
                      className={"bg-gray-300 input-search"}
                      placeholder={company?.bills?.data[0]?.overdue_amount}
                      disabled
                    />
                  </div>
                </div>
                <div className="border-2 rounded p-4 space-y-2">
                  <div className="flex w-full justify-end mb-3">
                    <label className="text-green-800 underline text-sm">New Plan</label>
                  </div>
                  <div>
                    <label className="font-bold">Plan Subscribed:</label>
                    <select
                      value={selectedPackage?.id}
                      className="input-search"
                      onChange={handlePackageChange}
                    >
                      {
                        packages?.data.map((item) => <option key={item?.id} value={item?.id}>{item?.package_name}</option>)
                      }
                    </select>
                  </div>
                  <div>
                    <label className="font-bold">Billing Period:</label>
                    <input
                      type="text"
                      className={"bg-gray-300 input-search"}
                      placeholder={selectedPackage && selectedPackage?.package_period_format === 'Months' ? 'Monthly' : 'Yearly' || packages?.data[0]?.package_period_format === 'Months' ? 'Monthly' : 'Yearly'}
                      disabled
                    />
                  </div>
                  <div className="w-full">
                    <label className="font-bold">Billing Date:</label>
                    <div className="flex justify-evenly space-x-4">
                      <div className="w-full">
                        <label>Start Date:</label>
                        <input
                          type="text"
                          className={"bg-gray-300 input-search"}
                          placeholder={DateTime.now().toFormat('dd/LL/yyyy')}
                          disabled
                        />
                      </div>
                      <div className="w-full">
                        <label>End Date:</label>
                        <input
                          type="text"
                          className={"bg-gray-300 input-search"}
                          placeholder={DateTime.now().plus({ days: selectedPackage && selectedPackage?.package_period_format === 'Months' ? 30 : 356 || packages?.data[0]?.package_period_format === 'Months' ? 30 : 356 }).toFormat('dd/LL/yyyy')}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="font-bold">Subscription Fee:</label>
                    <input
                      type="text"
                      className={"bg-gray-300 input-search"}
                      placeholder={selectedPackage &&  Number(selectedPackage?.subscription_fee).toFixed(2) || Number(packages?.data[0]?.subscription_fee).toFixed(2)}
                      disabled
                    />
                  </div>
                  <div>
                    <label className="font-bold">Service Fee:</label>
                    <input
                      type="text"
                      className={"bg-gray-300 input-search"}
                      placeholder={selectedPackage &&  Number(selectedPackage?.service_charge).toFixed(2) || Number(packages?.data[0]?.service_charge).toFixed(2)}
                      disabled
                    />
                  </div>
                </div>
              </div> :
              <div className="w-full">
                <div className="space-y-2">
                  <label className="font-bold">Plan Subscribed:</label>
                  <input
                    type="text"
                    className={"bg-gray-300 input-search"}
                    placeholder={packages?.data[0].subscription_fee || 'N/A'}
                    disabled
                  />
                </div>
                <div className="space-y-2">
                  <label className="font-bold">Billing Period:</label>
                  <input
                    type="text"
                    className={"bg-gray-300 input-search"}
                    placeholder={company?.selected_package?.data.length > 0 && company?.selected_package?.data[0].package?.package_period_format === 'Months' ? 'Monthly' : 'Yearly'}
                    disabled
                  />
                </div>
                <div className="space-y-2 w-full">
                  <label className="font-bold">Billing Date:</label>
                  <div className="flex justify-evenly space-x-4">
                    <div className="w-full">
                      <label>Start Date:</label>
                      <input
                        type="text"
                        className={"bg-gray-300 input-search"}
                        placeholder={DateTime.fromSQL(company?.selected_package?.data[0].subs_start_date).toFormat('dd/LL/yyyy')}
                        disabled
                      />
                    </div>
                    <div className="w-full">
                      <label>End Date:</label>
                      <input
                        type="text"
                        className={"bg-gray-300 input-search"}
                        placeholder={DateTime.fromSQL(company?.selected_package?.data[0].subs_end_date).toFormat('dd/LL/yyyy')}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="font-bold">Paid Amount:</label>
                  <input
                    type="text"
                    className={"bg-gray-300 input-search"}
                    placeholder={company?.bills?.data[0]?.current_charge}
                    disabled
                  />
                </div>
                <div className="space-y-2">
                  <label className="font-bold">Overdue Amount:</label>
                  <input
                    type="text"
                    className={"bg-gray-300 input-search"}
                    placeholder={company?.bills?.data[0]?.overdue_amount}
                    disabled
                  />
                </div>
              </div>
            }
            {!isChangePlan && <div className="flex justify-end">
              <button className="saveButton w-fit" onClick={() => setChangePlan(!isChangePlan)}>Change Plan</button>
            </div>}
          </div>
          <div className="w-full max-w bg-white items-center border-2 p-8 rounded-lg font-regular space-y-6">
            <label className="font-bold">Company Status</label>
            <div className="flex">
              <button className={company?.active === 0 ? "bg-gray-400 hover:bg-gray-400 hover:text-white hover:border-gray-400 hover:ring-2 hover:ring-gray-400 text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow w-[100px] border-r-0 rounded-r-none" : "" + "borderedActiveButton w-[100px] border-r-0 rounded-r-none text-white"} onClick={() => updateCompany(1)}>Active</button>
              <button className={company?.active === 1 ? "bg-gray-400 hover:bg-gray-400 hover:text-white hover:border-gray-400 hover:ring-2 hover:ring-gray-400 text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow w-[100px] border-l-0 rounded-l-none" : "" + "borderedInactiveButton w-[100px] border-l-0 rounded-l-none text-white"} onClick={() => updateCompany(0)}>Inactive</button>
            </div>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg font-regular">
              <div className="p-2 w-full max-w rounded-t-lg" style={{ backgroundColor: "#00AF54", color: "white" }}>
                <div className="hidden laptop:flex flex-row items-center justify-center text-sm text-semibold font-regular">
                  <div className="flex items-center justify-center font-medium"></div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">Name</div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">Email</div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">Phone Number</div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">Role</div>
                  <div className="basis-1/4 flex items-center justify-center font-medium">Status</div>
                </div>
              </div>
              <div className="p-2 w-full max-w mt-1">
                {company?.users && company?.users.map(item => {
                  return <div className="hidden laptop:flex flex-row items-center justify-center pb-2 font-light text-sm" key={item.id}>
                    <div className="basis-1/4 flex items-center justify-center font-medium">{item?.name}</div>
                    <div className="basis-1/4 flex items-center justify-center font-medium">{item?.email}</div>
                    <div className="basis-1/4 flex items-center justify-center font-medium">{item?.phone_number}</div>
                    <div className="basis-1/4 flex items-center justify-center font-medium">{item?.role_active.charAt(0).toUpperCase() + item?.role_active.slice(1)}</div>
                    <div className="basis-1/4 flex items-center justify-center font-medium space-x-3">
                      <Toggle backgroundColorChecked="#00AF54" width={50} height={20} sliderHeight={12} sliderWidth={12} labelRight={item.active === 1 ? 'Active' : 'Inactive'} onChange={() => toggleUserActiveness(item)} checked={item.active === 1} />
                    </div>
                  </div>
                })}
              </div>
            </div>
            <div className="flex justify-end space-x-2">
              <button className="borderedButton w-fit" onClick={() => navigate(-1)}>Cancel</button>
              <button className="saveButton w-fit" onClick={() => savePlan()}>Save</button>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};
export default CompanyPlan;
