import { useState, useEffect, useContext } from "react";

import profileIcon from "../../images/ProfileIcon.png";
import uploadIcon from "../../images/UploadIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Container, Col, Row, Modal, InputGroup, Form } from "react-bootstrap";
import {
    getBankDetail, storeBankDetail
} from "../../features/auth/authSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { fileDelete } from "features/file/fileSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PickMapLocation from "components/map/PickMapLocation";
import { useNavigate, useParams } from "react-router-dom";

const AccountBankDetail = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { currentUser, updating, currentCompany } = useSelector((state) => state.auth);
    const [bankName, setBankName] = useState(null);
    const [accountName, setAccountName] = useState(null);
    const [accountNo, setAccountNo] = useState(null);

    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);

    const submitHandler = (event) => {
        dispatch(
            storeBankDetail({
                bank_detail: {
                    bank_name: bankName,
                    account_name: accountName,
                    account_number: accountNo
                }
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                snackbarCtx.displayMsg(response.payload.message, "success")
            }
        });

        event.preventDefault();
    };

    const loadBankDetail = () => {
        dispatch(
            getBankDetail()
        ).then(function (response) {

            if (response.error && response.error.message == "Rejected") {
            } else {
                let result = response.payload.data.bank_detail;
                if (result) {
                    setBankName(result.bank_name);
                    setAccountName(result.account_name);
                    setAccountNo(result.account_number);
                }
            }
        });
    };

    useEffect(() => {
        loadBankDetail();
    }, []);

    return (
        <div>
            <Row>
                <Col md={4}>
                    <label className="text-dark-green-sm">Bank Name</label>
                    <br />
                    <input
                        type="text"
                        className="input-search"
                        name="bankName"
                        placeholder="Bank Name"
                        value={bankName}
                        onChange={({ target }) => setBankName(target.value.toUpperCase())}
                    />
                </Col>
                <Col md={4}>
                    <label className="text-dark-green-sm">Account Name</label>
                    <br />
                    <input
                        type="text"
                        className="input-search"
                        name="account_name"
                        placeholder="Account Name"
                        value={accountName}
                        onChange={({ target }) => setAccountName(target.value)}
                    />
                </Col>
                <Col md={4}>
                    <label className="text-dark-green-sm">Account Number</label>
                    <br />
                    <input
                        type="text"
                        className="input-search"
                        name="account_number"
                        placeholder="Account Number"
                        value={accountNo}
                        onChange={({ target }) => setAccountNo(target.value)}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={2}>
                    <button type="submit" className="saveButton" onClick={submitHandler}>
                        {updating ? "Processing, please wait.." : "Save"}
                    </button>
                </Col>
            </Row>
        </div>
    );
};

export default AccountBankDetail;
