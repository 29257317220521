import api from "../../support/support_api";

const delyvaIntegrationStore = async (params) => {
    const response  = await  api.axiosPost('/delyva-integrations', params);

    return response.data;
}

const delyvaIntegrationUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/delyva-integrations/${params.id}`, params);

    return response.data;
}

const delyvaIntegrationDelete = async (params) => {
    const response  = await  api.axiosDelete(`/delyva-integrations/${params.delyvaIntegration_id}`, params);

    return response.data;
}

const delyvaIntegrationShow = async (params) => {
    const response  = await api.axiosShow(`/delyva-integrations/${params.id}`, params);

    return response.data;
}

const delyvaIntegrationIndex = async (params) => {
    const response  = await api.axiosGet(`/delyva-integrations`, params);

    return response.data;
}

const delyvaIntegrationGuest = async (params) => {
    const response  = await api.axiosGet(`/delyva-integration/guest`, params);

    return response.data;
}

const delyvaIntegrationAuth = async (params) => {
    const response  = await api.axiosGet(`/delyva-integration/auth`, params);

    return response.data;
}

const delyvaIntegrationService = {
    delyvaIntegrationStore,
    delyvaIntegrationUpdate,
    delyvaIntegrationShow,
    delyvaIntegrationGuest,
    delyvaIntegrationIndex,
    delyvaIntegrationAuth,
    delyvaIntegrationDelete
}

export default delyvaIntegrationService;