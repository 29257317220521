import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  masterCropService from "./masterCropService";

export const masterCropIndex = createAsyncThunk(
    "masterCrop/index",
    async (args, thunkAPI) => {
        try {
            return await masterCropService.masterCropIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const masterCropStore = createAsyncThunk(
    "masterCrop/store",
    async (args, thunkAPI) => {
        try {
            return await masterCropService.masterCropStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const masterCropUpdate = createAsyncThunk(
    "masterCrop/update",
    async (args, thunkAPI) => {
        try {
            return await masterCropService.masterCropUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const masterCropDelete = createAsyncThunk(
    "masterCrop/delete",
    async (args, thunkAPI) => {
        try {
            return await masterCropService.masterCropDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const masterCropShow = createAsyncThunk(
    "masterCrop/:id/show",
    async (args, thunkAPI) => {
        try {
            return await masterCropService.masterCropShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const masterCropShowRelatedCategory = createAsyncThunk(
    "masterCrop/:id/masterCropShowRelatedCategory",
    async (args, thunkAPI) => {
        try {
            return await masterCropService.masterCropShowRelatedCategory(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    masterCrop: {},
    masterCrops: {
        data: []
    },
    loading: false,
    isSuccess: false,
    updating: false,
}

export const masterCropSlice = createSlice({
    name: 'masterCrop',
    initialState,
    reducers: {
    },
    extraReducers : {
        [masterCropStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [masterCropStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.masterCrop = payload.data.masterCrop;
            state.loading=false;
            state.isSuccess = true;
        },
        [masterCropStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [masterCropUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [masterCropUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [masterCropUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [masterCropDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [masterCropDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [masterCropDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [masterCropShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [masterCropShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.masterCrop = payload.data.masterCrop;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [masterCropShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [masterCropShowRelatedCategory.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [masterCropShowRelatedCategory.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.masterCrop = payload.data.masterCrop;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [masterCropShowRelatedCategory.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [masterCropIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [masterCropIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.masterCrops = payload.data.masterCrops;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [masterCropIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export default masterCropSlice;