import api from "../../support/support_api";

const companyCropStore = async (params) => {
    const response  = await  api.axiosPost('/company-crop', params);

    return response.data;
}

const companyCropUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/company-crop/${params.id}`, params);

    return response.data;
}

const companyCropDelete = async (params) => {
    const response  = await  api.axiosDelete(`/company-crop/${params.id}`, params);

    return response.data;
}

const companyCropShow = async (params) => {
    const response  = await api.axiosShow(`/company-crop/${params.id}`, params);

    return response.data;
}

const companyCropIndex = async (params) => {
    const response  = await api.axiosGet(`/company-crop`, params);

    return response.data;
}

const companyCropCheckCropExist = async (params) => {
    const response  = await api.axiosGet(`/company-crop/check-for-crops`, params);

    return response.data;
}

const companyCropAll = async () => {
    const response  = await api.axiosShow(`/company-crop/all`);

    return response.data;
}

const companyCropService = {
    companyCropStore,
    companyCropUpdate,
    companyCropShow,
    companyCropIndex,
    companyCropDelete,
    companyCropCheckCropExist,
    companyCropAll
}

export default companyCropService;