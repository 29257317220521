import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  fileService from "./fileService";

export const fileUpload = createAsyncThunk(
    "files/upload/:model_id",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await fileService.fileUpload(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const fileDelete = createAsyncThunk(
    "files/:model_id/delete",
    async (args, thunkAPI) => {
        try {
            return await fileService.fileDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const fileUpdate = createAsyncThunk(
    "files/:model_id/update",
    async (args, thunkAPI) => {
        try {
            return await fileService.fileUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const fileShow = createAsyncThunk(
    "files/:model_id/show",
    async (args, thunkAPI) => {
        try {
            return await fileService.fileShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    file: {},
    data: {},
    farm: {},
    farms: {},
    model: {},
    models: [],
    loading: false,
    isSuccess: false,
    updating: false,
}

export const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        // getCurrentUserInfo: (state) => {
        //
        // },
    },
    extraReducers : {
        [fileUpload.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [fileUpload.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.model = payload.data.model;
            state.farm = payload.data.farm;
            state.loading=false;
            state.isSuccess = true;
        },
        [fileUpload.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },

        [fileDelete.pending]:(state, {payload}) => {
            state.data      = {};
            state.loading   = true;
            state.isSuccess = false;
        },
        [fileDelete.fulfilled]:(state, {payload}) => {
            state.data      = payload;
            state.model     = payload.data.model;
            state.farm      = payload.data.farm;
            state.loading   = false;
            state.isSuccess = true;
        },
        [fileDelete.rejected]:(state, {payload}) => {
            state.data       = payload;
            state.loading    = false;
            state.isSuccess  = true;
        },

        [fileUpdate.pending]:(state, {payload}) => {
            state.data      = {};
            state.loading   = true;
            state.isSuccess = false;
            state.updating = true;
        },
        [fileUpdate.fulfilled]:(state, {payload}) => {
            state.data      = payload;
            state.file      = payload.data.file;
            state.loading   = false;
            state.isSuccess = true;
            state.updating = false;
        },
        [fileUpdate.rejected]:(state, {payload}) => {
            state.data       = payload;
            state.loading    = false;
            state.isSuccess  = true;
            state.updating  = false;
        },

        [fileShow.pending]:(state, {payload}) => {
            state.data      = {};
            state.file      = {};
            state.loading   = true;
            state.isSuccess = false;
        },
        [fileShow.fulfilled]:(state, {payload}) => {
            state.data      = payload;
            state.file      = payload.data.file;
            state.loading   = false;
            state.isSuccess = true;
        },
        [fileShow.rejected]:(state, {payload}) => {
            state.data       = payload;
            state.loading    = false;
            state.isSuccess  = true;
        },
    }
})

// export const {  } = farmSlice.actions

export default fileSlice;