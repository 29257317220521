import AuthLayout from "pages/AuthLayout";
import NewPasswordForm from "../../components/new-password/NewPasswordForm"

const NewPasswordPage = () => {
  return (
    <AuthLayout>
      <div className="flex flex-col items-center">
        <NewPasswordForm />
      </div>
    </AuthLayout>
  );
};

export default NewPasswordPage;
