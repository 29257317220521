import { Link } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";

import success from "../../images/newSucess.png";
import { login } from "../../features/auth/loginSlice";

const RegisterComplete = () => {
  const submitHandler = () => {
    window.location = routesPaths.ACCOUNT;
  };

  return (
    <div className="flex flex-col h-80 self-center rounded-md space-y-8">
      <div className=" flex justify-center h-28 rounded-t-md">
        <img src={success} style={{ width: "100px" }} className="self-center" />
      </div>
      <div className="flex flex-col items-center text-center space-y-4">
        <h1 className="text-xl" style={{ fontSize: "18px", fontWeight: "bold", color: "#395B50" }}>Done!</h1>
        <span style={{ color: "#545556", fontSize: "14px", fontWeight: "600" }}>Your account has been successfully created.</span>
      </div>
      <button style={{ backgroundColor: "#00AF54", color: "white", fontWeight: "600" }} type="button" className="w-1/2 border-[.09rem] border-buttonBorder self-center hover:bg-green-500 hover:text-white" onClick={submitHandler}>
        {/*<Link to={routesPaths.ACCOUNT} className="hover:text-green-500">*/}
        My Account
        {/*</Link>*/}
      </button>
    </div>
  );
};

export default RegisterComplete;
