import { routesPaths } from "constants/routes.constants";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const ProductFarm = () => {
  const navigate = useNavigate();
  const [certificate, setCertificate] = useState("DEFAULT");
  const [field, setField] = useState("DEFAULT");

  const { isLoaded } = useLoadScript({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
  });

  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState({ name: "", address: "", location: { lat: "", lng: "" }, photo: "" });
  const [mapCenter, setMapCenter] = useState();

  const viewPlotHandler = () => {
    navigate(`${routesPaths.CATALOG}/${info.product_id}/farm/plot/1`);
  };

  const dummyInfo = {
    product_id: 1,
    fields: [
      {
        name: "Fresh Farm",
        address: "123 Fresh Farm Street",
        location: { lat: 4.465, lng: 101.37 },
        photo: "/assets/images/plots/PlotPhoto1.svg",
      },
      {
        name: "Green Farm",
        address: "123 Green Farm Street",
        location: { lat: 4.475, lng: 101.4 },
        photo: "/assets/images/plots/PlotPhoto2.svg",
      },
      {
        name: "Abundant Farm",
        address: "123 Abundant Farm Street",
        location: { lat: 4.47, lng: 101.345 },
        photo: "/assets/images/plots/PlotPhoto3.svg",
      },
    ],
    plots: [{ id: 1 }, { id: 2 }, { id: 3 }],
    certificates: [{ name: "Food Standard 1" }, { name: "Food Standard 2" }, { name: "Food Standard 3" }],
  };

  const info = dummyInfo;

  useEffect(() => {
  }, [details]);

  return (
    // Screen
    <div className="bg-gray-50 h-screen w-screen self-center flex flex-col p-4">
      {/* Flex Column */}
      <div className="w-full max-w-[60rem] self-center flex flex-col gap-4">
        {/* Navigation */}
        <label
          className="cursor-pointer font-medium"
          onClick={() => navigate(`${routesPaths.CATALOG}/${info.product_id}/show`)}
        >{`< Go Back To Product`}</label>
        {/* Widgets */}
        <div className="flex flex-col items-center productFarmLarge:flex-row gap-4">
          {/* Farm */}
          <section className="bg-formDark w-full flex justify-between p-4 gap-4 rounded-xl shadow">
            <div className="w-full max-w-[19rem] flex flex-col gap-4">
              <label className="text-2xl">Farm</label>
              <div className="flex gap-2 justify-between items-center">
                <label>Number of Fields</label>
                <label className="indentedText text-center w-[5rem]">{info.fields.length}</label>
              </div>
              <div className="flex gap-2 justify-between items-center">
                <label>Number of Plots</label>
                <label className="indentedText text-center w-[5rem]">{info.plots.length}</label>
              </div>
            </div>
            <img src="/assets/images/FarmIconLarge.svg" alt="farm icon" className="self-center w-[9rem] h-[9rem]" />
          </section>
          {/* Certification */}
          <section className="bg-formDark w-full flex justify-between p-4 gap-4 rounded-xl shadow">
            <div className="w-full max-w-[19rem] flex flex-col gap-4">
              <label className="text-2xl">Certification</label>
              <div className="flex gap-2 justify-between items-center">
                <label>Number of Certificates</label>
                <label className="indentedText text-center w-[5rem]">{info.certificates.length}</label>
              </div>
              <select
                className="max-w-[19rem]"
                value={certificate}
                onChange={({ target }) => setCertificate(target.value)}
              >
                <option value="DEFAULT" disabled>
                  Choose Certificate
                </option>
                {info.certificates.map((certificate, index) => (
                  <option value={certificate.name} key={index}>
                    {certificate.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-[10rem] flex flex-col justify-around items-center">
              <label className="flex justify-center items-center">
                <p className="cursor-pointer w-[5rem] text-sm">View Certificate</p>
                <img src="/assets/images/ViewIcon.svg" alt="download icon" className="w-[2rem] h-[2rem]" />
              </label>
              <label className="flex justify-center items-center">
                <p className="cursor-pointer w-[5rem] text-sm">Download Certificate</p>
                <img src="/assets/images/DownloadIcon.svg" alt="download icon" className="w-[2rem] h-[2rem]" />
              </label>
            </div>
          </section>
        </div>
        {/* Map */}
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <select className="max-w-[19rem]" value={field} onChange={({ target }) => setField(target.value)}>
              <option value="DEFAULT" disabled>
                Choose Field
              </option>
              {info.fields.map((field, index) => (
                <option value={field.name} key={index}>
                  {field.name}
                </option>
              ))}
            </select>
            <button className="saveButton w-[9.6rem]" onClick={viewPlotHandler}>
              View Plot
            </button>
          </div>
          {!isLoaded ? (
            "Loading..."
          ) : (
            <section className="relative max-w-[60rem] w-full h-[30rem] rounded-xl">
              <GoogleMap
                zoom={12}
                center={mapCenter ? mapCenter : info.fields[0].location}
                mapContainerClassName="w-full h-full rounded-xl shadow"
              >
                {info.fields.map((farm, index) => (
                  <Marker
                    position={farm.location}
                    title={farm.name}
                    label="F"
                    onClick={() => {
                      setDetails({
                        name: farm.name,
                        address: farm.address,
                        location: farm.location,
                        photo: farm.photo,
                      });
                      setMapCenter(farm.location);
                      setShowDetails(true);
                    }}
                    key={index}
                  />
                ))}
              </GoogleMap>
              {showDetails && (
                <div className="absolute top-0 right-0 w-2/5 h-full bg-formDark/70 flex flex-col gap-4 p-4 rounded-r-xl">
                  <img src={details.photo} alt="Photo of the farm" />
                  <div className="flex w-full">
                    <label className="indentedText">{details.name ? details.name : "Farm Name"}</label>
                  </div>
                  <div className="flex w-full">
                    <label className="indentedText">{details.address ? details.address : "Farm Address"}</label>
                  </div>
                  <div className="flex w-full">
                    <label className="indentedText">
                      {details.location
                        ? `${details.location.lat.toFixed(3)}°N, ${details.location.lng.toFixed(3)}°E`
                        : "Farm Location"}
                    </label>
                  </div>
                  <button className="borderedButton" onClick={() => setShowDetails(false)}>
                    Close Farm Details
                  </button>
                </div>
              )}
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductFarm;
