import api from "../../support/support_api";

const storeStore = async (params) => {
    const response  = await  api.axiosPost('/stores', params);

    return response.data;
}

const storeUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/stores/${params.store_id}`, params);

    return response.data;
}

const storeUploadPhotos = async (params) => {
    // const response  = await  api.axiosPatch(`/stores/${params.store_id}`, params);
    //
    // return response.data;
}

const storeShow = async (params) => {
    const response  = await api.axiosShow(`/stores/${params.store_id}`, params);

    return response.data;
}

const storeIndex = async (params) => {
    const response  = await api.axiosGet(`/stores`, params);

    return response.data;
}

const storeCheckForCrops = async (params) => {
    const response  = await api.axiosGet(`/stores/${params.store_id}/check-for-crops`, params);

    return response.data;
}

const storeService = {
    storeStore, storeUpdate, storeUploadPhotos, storeShow, storeIndex, storeCheckForCrops
}

export default storeService;