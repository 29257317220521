import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "pages/AccountLayout";
import CreditManagement from "./CreditManagement";
import SnackbarContext from "_helpers/snackbar-context";
import { creditManagementIndex } from "features/credit-management/creditManagementSlice";

const CreditManagementList = () => {
  const { creditManagements } = useSelector((state) => state.creditManagement);
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);

  const loadCreditManagement = keyword => {
    dispatch(
      creditManagementIndex({
        keyword: keyword
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      }
    });
  }

  useEffect(() => {
    loadCreditManagement("");
  }, []);

  return (
    <>
      <PageLayout>
        <div data-testid="order-list" className="flex flex-col items-start gap-6 p-4">
          <h1 className="text-3xl text-left font-semibold font-regular" style={{ color: "#395B50" }}>Credit Management</h1>
          <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "0px", width: "100%" }}>
            <div className="flex w-full justify-end">
              <form className="tablet:flex justify-start w-full desktop:w-fit">
                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 laptop:hidden desktop:hidden">Search</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                  </div>
                  <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500" placeholder="Search" onChange={({ target }) => { loadCreditManagement(target.value) }}/>
                </div>
              </form>
            </div>
          </div>
          <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg font-regular">
            <div className="p-2 w-full max-w rounded-t-lg" style={{ backgroundColor: "#00AF54", color: "white" }}>
              <div className="hidden laptop:flex flex-row items-center justify-center text-sm text-semibold font-regular">
                <div className="flex items-center justify-center font-medium"></div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Company Name</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Plan Subscribed</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Credit Balance</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Auto Add Credit</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Auto Deduct Credit</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Action</div>
              </div>
            </div>
            <CreditManagement creditManagements={creditManagements} />
          </div>
        </div>
      </PageLayout>
    </>
  );
};
export default CreditManagementList;
