import Text from "components/text/Text";
import "./HomeForm.css";
import taniExchangeLogo from "images/home/taniexchange-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import styles from "./HomeForm.module.scss";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const HomeForm = ({ clickIntro }) => {
  const navigate = useNavigate();

  return (
    <>
      {/* logo */}
      <div className="div-top">
        <span className="self-center font-header">
          <img width={90} src={taniExchangeLogo} alt="Google logo" />
        </span>
      </div>
      {/* text */}
      <div>
        <span className={clsx(styles.header, "self-center")}>
          <Text id="home.harvest" />
          <span style={{ marginLeft: "4px" }}>
          </span>
          <Text id="home.connect" />
          <span style={{ marginLeft: "4px" }}>
          </span>
          <Text id="home.thrive" />
        </span>
      </div>
      <div>
        <span className={clsx(styles.subHeader, "self-center ")}>
          Growing Connections, Cultivating Success
        </span>
      </div>
      <div className="col-md-12 col-xs-12 col-sm-12 ">
        <div className="row" style={{ placeContent: "center" }}>
          <div className="desktop:w-2/12 laptop:w-3/12 tablet:w-5/12">
            <button className="btn-home btn-browser-product font-semibold" onClick={() => navigate(`/products/catalogs`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span style={{ marginLeft: "4px" }}></span>
              Browse Products
            </button>
          </div>
          <div className="desktop:w-2/12 laptop:w-3/12 tablet:w-5/12">
            <button className="btn-home btn-what-is font-semibold" onClick={clickIntro}>
              <FontAwesomeIcon icon={faPlus} />
              <span style={{ marginLeft: "4px" }}></span>
              What is TaniExchange?
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeForm;
