import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { advanceBookingDetail, advanceBookingPayDeposit } from "../../../features/advance-booking/advanceBookingSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import AccountLayout from "pages/AccountLayout";
import FarmImage from "../../../images/FarmImage.png";

import { Col, Modal, Row } from "react-bootstrap";
import { QRCodeSVG } from 'qrcode.react';
import * as statusConst from 'constants/advanceBooking.constants';
import styles from "./AdvanceBookingDeposit.module.scss";
import clsx from "clsx";
import AuthLayout from "pages/AuthLayout";
import DepositProgress from "./DepositProgress";
import Footer from "components/footer/footer";

// import { useSearchParams } from "react-router-dom";
// import {Route, Link, Routes, useParams} from 'react-router-dom';

const SuccessPaidDepositPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const snackbarCtx = useContext(SnackbarContext);
  const [bookingPlot, setBookingPlot] = useState(null);
  const id = params.booking_id;

  const loadDetail = () => {
    dispatch(
      advanceBookingDetail({
        id: id,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
      } else {
        let result = response.payload.data.booking_plot;
        setBookingPlot(result);
      }
    });
  };

  const backToBooking = () => {
    navigate(`/manage-advance-booking/detail/${bookingPlot.id}`);
  };

  useEffect(() => {
    loadDetail();
  }, []);

  return (
    <Row className="container mb-20">
      <Col md={3}></Col>
      <Col md={6}>
        <div className="flex flex-col items-center mt-4">
          <div style={{ minWidth: "80%" }} >
            <DepositProgress />
          </div>
        </div>
        <Row className="mt-3">
          <Col md={12}>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
              <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                <div className="w-full max-w">
                  <p className="text-center mt-1">You have successfully paid for the deposit of Booking #{(bookingPlot) ? bookingPlot.booking_number : ''}.</p>
                  <p className="text-center mt-1">Full payment is required upon the confirmation of harvest quantity.</p>
                  <p className="text-center mt-1">Thank You!</p>
                  <br />
                  <br />
                  <button type="button" className="btn-green" onClick={backToBooking}>
                    Back To My Orders
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={3}>
        <div className="flex flex-col items-center mt-4" >
          <div style={{ minWidth: "80%", height: "17px" }} >
            {/* <DepositProgress/> */}
          </div>
        </div>
        <Row className="mt-3">
          <Col md={12}>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
              <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                <div className="p-4 w-full max-w">
                  <p><b>Total Price</b> <span className="float-right">RM {(bookingPlot) ? bookingPlot.deposit_amount : ''}</span></p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Footer />
    </Row>
  );
};

export default SuccessPaidDepositPage;
