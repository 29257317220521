import React, { useState, useEffect, useContext } from "react";
import AccountLayout from "pages/AccountLayout";
import { gradeIndex } from "../../features/grade/gradeSlice";
import { productShow, productUpdate } from "../../features/product/productSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { farmIndex } from "../../features/farm/farmSlice";
import { categoryIndex } from "../../features/category/categorySlice";
import { useParams } from "react-router-dom";
import { fileUpload } from "features/file/fileSlice";
import { catalogDestroy, catalogStore } from "../../features/catalog/catalogSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { Col, Row, InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons';
import clsx from "clsx";
import { unitOfMeasureIndex } from "features/unit-of-measure/unitOfMeasureSlice";
import { gradeProductDelete, gradeProductStore, gradeProductUpdate } from "features/grade-product/gradeProductSlice";

/**
 * TODO -  Refactor this code lateron, it has to be readable and easy to understand
 * TODO - Refactor the adding/removing of catalog, make it a single method instead of using 2 methods.
 *
 * @returns {*}
 * @constructor
 */
const ProductEdit = () => {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [product, setProduct] = useState(null);
  const [quantityNumber, setQuantityNumber] = useState(0);
  const [quantityTarget, setQuantityTarget] = useState(0);
  const [includeDeliveryFee, setIncludeDeliveryFee] = useState(null);
  const [includeFixedPercentage, setIncludeFixedPercentage] = useState(1);
  const [deliveryChargeType, setDeliveryChargeType] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(0)
  const [showInProduce, setShowInProduce] = useState(0);
  const [grades, setGrade] = useState([]);
  const [cropId, setCropId] = useState("");
  const [inputElement, setInputElement] = useState("");
  const [isEnablePriceQuote, setEnablePriceQuote] = useState(0)
  const [farmId, setFarmId] = useState("");

  const { currentCompany } = useSelector((state) => state.auth);
  const reduxProduct = useSelector((state) => state.product);
  const { unitOfMeasures } = useSelector((state) => state.unitOfMeasure);

  let companyPackage = currentCompany?.company_packages?.data.find(companyPackage => companyPackage.status === 1);
  let productId = params.product_id;

  const snackbarCtx = useContext(SnackbarContext);

  const save = (event) => {
    event.preventDefault();
    if (parseFloat(quantityNumber) < 0 || parseFloat(quantityTarget) < 0) {
      snackbarCtx.displayMsg("Quantities cannot be less than zero.", "failure");
      return;
    }

    dispatch(
      productUpdate({
        product_id: productId,
        farm_id: farmId,
        name: name,
        company_crop_id: cropId,
        delivery_charge: parseFloat(deliveryCharge),
        delivery_charge_type: deliveryChargeType,
        show_in_produce: showInProduce,
        enable_price_quote: isEnablePriceQuote
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        let product = response.payload.data.product;

        for (let index = 0; index < grades.length; index++) {
          dispatch(
            gradeProductUpdate({
              id: grades[index].id,
              product_id: product.id,
              unit_id: parseInt(grades[index].unit_id),
              base_price: grades[index].base_price,
              selling_price: grades[index].selling_price,
              available_qty: grades[index].available_qty,
              is_thumbnail: grades[index].is_thumbnail,
              name: grades[index].name
            })
          ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
              snackbarCtx.displayMsg(response.payload, "failure")
            } else {
              navigate(`/products`);
            }
          });
        }
      }
    });
  };

  const loadCategories = () => {
    dispatch(
      categoryIndex({
        page: 1,
        limit: 100,
      })
    );
  };

  const loadProduct = () => {
    dispatch(
      productShow({
        product_id: productId,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        let product = response.payload.data.product;

        setProduct(product);
        setShowInProduce(product?.show_in_produce);
        setGrade(product?.grade_products);
        setName(product?.name);
        setCropId(product?.company_crop.id)
        setFarmId(product?.farm_id);
        setQuantityNumber(product?.quantity_number);
        setQuantityTarget(product?.quantity_target);
        setDeliveryCharge(parseFloat(product?.delivery_charge))
        setDeliveryChargeType(product?.delivery_charge_type !== null && product?.delivery_charge_type.toString())
        setEnablePriceQuote(product?.enable_price_quote);
        setIncludeDeliveryFee(product?.include_delivery_charge)

        if (product.enable_price_quote == 0 || product.enable_price_quote == null) {
          setIncludeFixedPercentage(1);
        } else {
          setIncludeFixedPercentage(0);
        }

        if (product?.delivery_charge !== null) {
          setIncludeDeliveryFee(0)
        }

        if (product?.delivery_charge === '0.00') {
          setIncludeDeliveryFee(1)
        }

        for (let index = 0; index < product?.grade_products.length; index++) {
          setGrade(prevItems =>
            prevItems.map(item => ({ ...item, unit_id: product?.grade_products[index]?.unit?.id }))
          );
        }
      }
    });
  };

  const productSaveToCatalog = () => {
    dispatch(
      catalogStore({
        product_id: productId,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      }
    });
  };

  const productRemoveFromCatalog = () => {
    dispatch(
      catalogDestroy({
        catalog_id: reduxProduct.product.catalog.id,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        loadProduct();
      }
    });
  };

  const loadUnitOfMeasure = () => {
    dispatch(
      unitOfMeasureIndex()
    )
  }

  const deleteGradeArray = (grade, index) => {
    let filteredArray = grades.filter((item, i) => i !== index)
    setGrade(filteredArray);

    dispatch(gradeProductDelete({
      id: grade?.id
    })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
        loadProduct()
      }
    });
  }

  const addGrade = () => {
    setGrade([...grades, { name: "", base_price: 0, selling_price: 0, available_qty: 0, image: "", is_thumbnail: 0, unit_id: "" }])
    dispatch(
      gradeProductStore({
        product_id: product.id,
        unit_id: "",
        base_price: 0,
        selling_price: 0,
        available_qty: 0,
        image: "",
        is_thumbnail: 0,
        name: "",
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");

      } else {
        loadProduct();
      }
    });
  }

  const updateGradeArray = (index) => (e) => {
    const newArray = grades.map((item, i) => {
      if (index === i) {
        if (e.target.name === 'image') {
          return { ...item, image: e.target.files[0] }
        }
        if (e.target.name === 'base_price') {
          let base_price = parseFloat(e.target.value).toFixed(2);
          let selling_price = (parseFloat(e.target.value) * parseFloat(companyPackage.service_charge) / 100) + parseFloat(e.target.value);

          return { ...item, selling_price: parseFloat(selling_price.toFixed(2)), base_price: base_price, [e.target.name]: e.target.value }
        }
        if (e.target.name === 'is_thumbnail') {
          let new_is_thumbnail = (parseInt(item.is_thumbnail) === 0) ? 1 : 0;

          return { ...item, is_thumbnail: new_is_thumbnail }
        }
        if (e.target.name === 'unit_id') {
          return { ...item, unit_id: parseInt(e.target.value) }
        } else {
          return { ...item, [e.target.name]: e.target.value };
        }
      }
      else {
        return item;
      }
    });

    setGrade(newArray);
  };

  const uploadPhoto = (event, grade) => {
    let selectedFile = event.target.files[0];
    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);
    formData.append("store", "single");
    formData.append("model", "gradeProduct");
    formData.append("type", "photo");
    formData.append("model_id", grade.id);

    dispatch(fileUpload(formData)).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        alert(response.payload);
      } else {
        loadProduct();
      }
    });
  };

  useEffect(() => {
    loadProduct();
    loadCategories();
    loadUnitOfMeasure();
  }, []);

  return (
    <AccountLayout>
      <div data-testid="product-edit" className="flex flex-col h-full max-h items-start gap-6 p-4 mb-5">
        {/* Title */}
        <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
          {" Go Back"}
        </label>
        <h1 className="title-content text-center text-xl" style={{ marginTop: -20 }}>Edit Product</h1>
        {/* Widget */}

        <div className="flex flex-col rounded-lg shadow bg-card-white-new w-full">
          <form className="w-full max-w place-self-center flex flex-col space-y-5 col-span-6" onSubmit={save}>
            <section className="flex justify-start items-center">
              <div className="lg:w-auto lg:mr-4 w-full flex items-center flex-wrap">
                <label className="lg:w-auto lg:mr-1 w-full">Product Name:</label>
                <input type="text" placeholder={name} value={name} onChange={({ target }) => setName(target.value)} className={"lg:w-[18rem] w-full input-search"} />
              </div>
              <div className="lg:flex w-1/3 items-center hidden">
                <input
                  type="checkbox"
                  name="agree_term_condition"
                  value={showInProduce}
                  onChange={() => {
                    setShowInProduce((showInProduce === 0) ? 1 : 0);
                    showInProduce == 0 ? productSaveToCatalog() : productRemoveFromCatalog()
                  }}
                  checked={showInProduce == 1}
                />
                &nbsp;&nbsp;
                <label>Show in Produce</label></div>
            </section>
            <section className="flex justify-start items-center flex-wrap">
              <label className="lg:w-auto lg:mr-1">Crop and Variety:</label> <span> {reduxProduct?.product?.company_crop?.master_crop?.name} -  {reduxProduct?.product?.company_crop?.variety}</span>
            </section>
            <div className="lg:hidden w-full items-center">
              <input
                type="checkbox"
                name="show_in_produce"
                value={showInProduce}
                onChange={() => {
                  setShowInProduce((showInProduce == 0) ? 1 : 0);
                }}
              />
              &nbsp;&nbsp;
              <label>Show in Produce</label>
            </div>
            <section>
              <label className="w-1/6">Grades: </label>
              <div className="overflow-y-scroll desktop:h-[22rem] w-full px-3 pt-2 pb-4">
                {grades.length > 0 && grades.map((grade, index) => <div key={index} className={clsx("bg-lightGray p-4 rounded-lg shadow border-main desktop:min-h-[20rem] tablet:min-h-[35rem]", index !== 0 ? "mt-4" : "")}>
                  <div className="flex justify-between" key={index}>
                    <div>Grade {index + 1}</div>
                    {index !== 0 && <FontAwesomeIcon icon={faTrashCan} className="text-lg text-greenGray" onClick={() => deleteGradeArray(grade, index)} />}
                  </div>
                  <div className="flex flex-wrap w-full mt-2">
                    <div className="desktop:w-2/12 w-full mb-11">
                      {grade?.photo && grade?.photo.includes('ProfileIcon.png') ?
                        <button
                          type="button"
                          accept="image/png, image/jpeg"
                          className="w-full w-m h-full flex flex-col justify-center items-center bg-white rounded-md border-[.01rem] border-green-500 space-y-2"
                          onClick={() => inputElement.click()}
                        > :
                          <FontAwesomeIcon icon={faPlus} className={"change-icon-color-green"} />
                        </button> : <img
                          src={grade.photo}
                          alt="profile"
                          onClick={() => inputElement.click()}
                          className="w-full w-m h-auto flex flex-col cursor-pointer object-cover aspect-square border-[.01rem] border-green-400 rounded-md"
                        />}
                      <input className={"hidden"} type="file" name="image" onChange={(e) => { uploadPhoto(e, grade); }} ref={(input) => setInputElement(input)} accept="image/png, image/jpeg" />
                      <div className="flex items-center mt-3"><input
                        type="checkbox"
                        value={grade.is_thumbnail}
                        name="is_thumbnail"
                        onChange={updateGradeArray(index)}
                        checked={grade.is_thumbnail == 1}
                      />
                        &nbsp;&nbsp;
                        <label>Use this as thumbnail</label>
                      </div>
                    </div>
                    <div className="desktop:w-5/12 desktop:pl-7 w-full">
                      <div className="text-greenGray">Grade Name</div>
                      <input className="w-full input-search" value={grade.name} onChange={updateGradeArray(index)} name="name" />
                      <div className="text-greenGray mt-3">Base Price</div>
                      <input className="w-full input-search" type="number" value={grade.base_price} onChange={updateGradeArray(index)} name="base_price" />
                      <div className="text-greenGray mt-3">Available Quantity</div>
                      <input className="w-full input-search" value={grade.available_qty} onChange={updateGradeArray(index)} name="available_qty" />
                    </div>
                    <div className="desktop:w-5/12 desktop:pl-7 md:w-full sm:w-full">
                      <div className="text-greenGray">Unit</div>
                      <div className="text-greenGray">
                        <select className="input-search w-full" value={grade?.unit_id} name="unit_id" onChange={updateGradeArray(index)}>
                          <option value="DEFAULT" disabled>
                            Select Unit
                          </option>
                          {unitOfMeasures.data.map((unit, index) => (
                            <option value={unit.id} key={index}>{unit.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="text-greenGray mt-3">Selling Price (Include {companyPackage?.service_charge}%)</div>
                      <input className="w-full input-search bg-gray-200 text-gray-600" type="number" value={grade.selling_price} name="selling_price" onChange={updateGradeArray(index)} />
                    </div>

                  </div>
                </div>)}
              </div>
              <div className="flex justify-end mr-2">
                <button type="button" className="saveButton rounded-md lg:w-1/6" onClick={() => addGrade()}>
                  Add Grade
                </button>
              </div>
            </section>
            <label>Include Delivery Charges?</label>
            <label className="text-green" style={{ fontSize: "15px" }}>*If you choose to include delivery charges you will be required to pay for delivery fee</label>
            <Row className="mt-2 mb-2">
              <Col md={3} style={{ width: '3cm' }}>
                <input
                  type="radio"
                  name="include_delivery_fee"
                  value={1}
                  checked={includeDeliveryFee == 1}
                  onChange={({ target }) => { setIncludeDeliveryFee(target.value); }}
                /> Yes
              </Col>
              <Col md={3} style={{ width: '3cm' }}>
                <input
                  type="radio"
                  name="include_delivery_fee"
                  value={0}
                  checked={includeDeliveryFee == 0}
                  onChange={({ target }) => { setIncludeDeliveryFee(target.value); }}
                /> No
              </Col>
            </Row>
            {(includeDeliveryFee == 0 && currentCompany?.delyva_integration) && (<div className="flex w-full mt-1">
              <input
                type="checkbox"
                name="show_in_produce"
                className="mt-1 text-sm"
                value={isEnablePriceQuote}
                onChange={() => {
                  setEnablePriceQuote((isEnablePriceQuote == 0) ? 1 : 0)
                  if (isEnablePriceQuote == 0) {
                    setIncludeFixedPercentage(0);
                  } else {
                    setIncludeFixedPercentage(1);
                  }
                }}
                checked={isEnablePriceQuote == 1}
              />
              &nbsp;&nbsp;
              <label className="text-sm mt-1">Enable buyer to quote delivery charge</label>
            </div>)}
            {includeDeliveryFee == 0 && includeFixedPercentage == 1 && (
              <>
                <label>Delivery Charge:</label>
                <Row className="mt-2 mb-2">
                  <Col md={6} xs={7}>
                    <InputGroup>
                      {(deliveryChargeType != 1) && (
                        <>
                          <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setDeliveryCharge(deliveryCharge - 1)}>-</div></InputGroup.Text>
                          <Form.Control
                            type="number"
                            min="0"
                            placeholder=""
                            className="text-center text-green input-group-plot"
                            value={deliveryCharge}
                            onChange={({ target }) => setDeliveryCharge(parseFloat(target.value))}
                            step="any"
                          />
                        </>
                      )}
                      {(deliveryChargeType == 1) && (
                        <>
                          <InputGroup.Text id="basic-addon1">RM</InputGroup.Text>
                          <Form.Control
                            type="number"
                            min="0"
                            placeholder=""
                            className="input-group-custom form-control"
                            value={deliveryCharge}
                            onChange={({ target }) => setDeliveryCharge(parseFloat(target.value))}
                            step="any"
                          />
                        </>
                      )}
                      {(deliveryChargeType != 1) && (
                        <>
                          <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setDeliveryCharge(deliveryCharge + 1)}>+</div></InputGroup.Text>
                        </>
                      )}
                    </InputGroup>
                  </Col>
                  <Col md={6} xs={5}>
                    <select className="input-search text-green" defaultValue={"DEFAULT"} value={deliveryChargeType} onChange={({ target }) => setDeliveryChargeType(target.value)}>
                      <option value="DEFAULT">
                        Select Type..
                      </option>
                      <option value="1">Fix Amount</option>
                      <option value="2">%</option>
                    </select>
                  </Col>
                </Row>
              </>
            )}
            <section className="flex">
              <button type="submit" className="saveButton rounded-md w-1/6">
                {reduxProduct.updating ? "Processing, please wait.." : "Save"}
              </button>
            </section>
          </form>
        </div>
      </div>
    </AccountLayout>
  );
};

export default ProductEdit;
