import  api from "../../support/support_api";
import storage from "../../support/support_storage"
const newPassword = async (credentials) => {

   let response = await api.axiosPost('/users/new-password', credentials)
   return response.data;
}

const newPasswordService = {
    newPassword,
}

export default newPasswordService;