import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

//import {axios} from  "axios";
// import { AxiosResponse } from "axios";
//import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  cropService from "./cropService";

export const cropIndex = createAsyncThunk(
    "crops/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await cropService.cropIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cropGuest = createAsyncThunk(
    "crops/index",
    async (args, thunkAPI) => {
        try {
            return await cropService.cropGuest(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cropAuth = createAsyncThunk(
    "crops/index",
    async (args, thunkAPI) => {
        try {
            return await cropService.cropAuth(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cropStore = createAsyncThunk(
    "crops/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await cropService.cropStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cropStoreWithoutStoreId = createAsyncThunk(
    "crops/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await cropService.cropStoreWithoutStoreId(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cropUpdate = createAsyncThunk(
    "crops/update",
    async (args, thunkAPI) => {
        try {
            return await cropService.cropUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cropDelete = createAsyncThunk(
    "crops/delete",
    async (args, thunkAPI) => {
        try {
            return await cropService.cropDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cropUploadPhoto = createAsyncThunk(
    "crops/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await cropService.cropUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cropShow = createAsyncThunk(
    "crops/:id/show",
    async (args, thunkAPI) => {
        try {
            return await cropService.cropShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    crop: {
        grades: {
            data: []
        }
    },
    crops: {
        data: []
    },
    store: {},
    stores: [],
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const cropSlice = createSlice({
    name: 'crop',
    initialState,
    reducers: {
    },
    extraReducers : {
        [cropStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [cropStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.crop = payload.data.crop;
            state.store = payload.data.store;
            state.loading=false;
            state.isSuccess = true;
        },
        [cropStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [cropStoreWithoutStoreId.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [cropStoreWithoutStoreId.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.crop = payload.data.crop;
            state.loading=false;
            state.isSuccess = true;
        },
        [cropStoreWithoutStoreId.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [cropUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [cropUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [cropUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [cropDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [cropDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [cropDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [cropShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [cropShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.crop = payload.data.crop;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [cropShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [cropIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [cropIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.crops = payload.data.crops;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [cropIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = cropSlice.actions

export default cropSlice;