import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { categoryIndex } from "../../../features/category/categorySlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { companyCropDelete, companyCropUpdate } from "features/company-crop/companyCropSlice";
import { masterCropShowRelatedCategory } from "features/master-crop/masterCropSlice";

// TODO - All select has a delay on setting state hook, so this must be fixed.
const CropEdit = ({ companyCrop, companyCropIndex, reloadCompanyCrop }) => {
    const dispatch = useDispatch();
    const [update, setUpdate] = useState(false);
    const [cropId, setCropId] = useState(companyCrop?.id || '');
    const [cropName, setCropName] = useState(companyCrop?.master_crop?.name || '');
    const [varietyName, setVarietyName] = useState(companyCrop?.variety || '');
    const [categoryId, setCategoryId] = useState(companyCrop?.master_crop?.category?.id || "");
    const [isCropSelected, setIsCropSelected] = useState(false);
    const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
    const [wait, setWait] = useState(false);
    const [filteredCrop, setFilteredCrop] = useState([]);
    const snackbarCtx = useContext(SnackbarContext);
    const reduxcategory = useSelector((state) => state.category);
    const { masterCrops } = useSelector((state) => state.masterCrop);

    const updateCompanyCrop = () => {
        let t = clearTimeout(wait);
        setWait(t);

        setWait(setTimeout(function () {
            dispatch(companyCropUpdate({
                id: companyCrop?.id,
                master_crop_id: cropId,
                variety: varietyName,
                status: 0
            })).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success");
                    reloadCompanyCrop();
                }
            });

        }, 1000))
    }

    const deleteCompanyCrop = () => {
        if (window.confirm("Are you sure you want to delete this crop?")) {
            dispatch(companyCropDelete({
                id: companyCrop?.id
            })).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success");
                    reloadCompanyCrop();
                }
            });
        }
    }

    useEffect(() => {
        return (() => {
            setUpdate(false)
        })
    }, [update]);

    const loadCategories = () => {
        dispatch(
            categoryIndex({
                page: 1,
                limit: 100
            })
        );
    }
    
    const loadMasterCrops = id => {
        dispatch(masterCropShowRelatedCategory({
            id: id
        })).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                setIsCropSelected(false);
                setFilteredCrop(response?.payload?.data?.masterCrops?.data)
            }
        });
    }

    useEffect(() => {
        loadCategories();
    }, []);

    return (
        <>
            <div className="flex flex-col space-y-4 w-full">
                <div className="flex flex-row space-x-8 relative">
                    <label className="title-crops-17-500 w-[60px]">Crop {companyCropIndex + 1}</label>
                    <FontAwesomeIcon onClick={deleteCompanyCrop} icon={faTrashCan} className={"cursor-pointer title-crops-17-500 py-0.5 px-2 rounded absolute right-0 text-right"} />
                </div>

                <div className="w-full grid justify-stretch lg:grid-cols-4 lg:space-x-1 space-y-1 lg:space-y-0">
                    <div className="flex flex-col">
                        <section>
                            {
                                <select
                                    className="input-search"
                                    defaultValue={""}
                                    value={categoryId|| "DEFAULT"}
                                    onChange={({ target }) => {
                                        setUpdate(true);
                                        setCategoryId(target.value)
                                        loadMasterCrops(target.value);
                                    }}>
                                    <option value="">
                                        Please select category
                                    </option>
                                    {reduxcategory.categories.data.map((category, index) => {
                                        return (
                                            <option value={category.id} key={index}>{category.name}</option>
                                        )
                                    })}
                                </select>
                            }
                        </section>
                    </div>
                    <div className="flex flex-col">
                        <div>
                            <input
                                type="search"
                                className={filteredCrop.length > 0 && isSearchButtonClicked ? ( isCropSelected ? "input-search" : "input-search-with-dropdown") : "input-search"}
                                placeholder="Search..."
                                value={cropName}
                                disabled={filteredCrop.length === 0}
                                onChange={({ target }) => {
                                    setIsCropSelected(false)
                                    setCropName(target.value)
                                }}
                                onClick={() => setIsSearchButtonClicked(true)}
                            />
                            {isSearchButtonClicked && <div className={isCropSelected ? "hidden" : "input-search-dropdown"}>
                                {filteredCrop.length > 0 && filteredCrop.map((crop, index) => {
                                    return (
                                        <div value={crop.name} key={index} onClick={() => { setIsCropSelected(true); setCropName(crop.name); setCropId(crop.id) }}>{crop.name}</div>
                                    )
                                })}
                            </div>}
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <section>
                            <input className="input-search" type="text" placeholder="Add variety name" value={varietyName}
                                onChange={({ target }) => {
                                    setUpdate(true);
                                    setVarietyName(target.value)
                                }}
                            />
                        </section>
                    </div>
                    <div className="flex flex-col">
                        <section>
                        <button className="saveButton" onClick={updateCompanyCrop}>Save</button>
                        </section>
                    </div>
                </div>
                <div className="w-full border-b-2 mt-4">
                </div>
            </div>
        </>
    );
};

export default CropEdit;
