// import { routesPaths } from "constants/routes.constants";
// import { useState } from "react";
// import { Link } from "react-router-dom";
// import AccountLayout from "pages/AccountLayout";
// import DemandAccept from "../DemandAccept";
//
const DemandFarmShow = () => {
//   const [certificate, setCertificate] = useState("DEFAULT");
//   const [accept, setAccept] = useState(false);
//
//   const acceptHandler = () => {
//     setAccept(true);
//   };
//
//   const demand_id = 1;
//
//   const dummyFarm = {
//     data: {
//       name: "",
//       fields: 0,
//       plots: 0,
//       certificates: [{ name: "Food Standard 1" }, { name: "Food Standard 2" }, { name: "Food Standard 3" }],
//       photos: [
//         {
//           photo: "/assets/images/plots/PlotPhoto1.svg",
//           date: { day: 25, month: 5, year: 2022 },
//         },
//         {
//           photo: "/assets/images/plots/PlotPhoto2.svg",
//           date: { day: 25, month: 5, year: 2022 },
//         },
//         {
//           photo: "/assets/images/plots/PlotPhoto3.svg",
//           date: { day: 25, month: 5, year: 2022 },
//         },
//       ],
//     },
//   };
//
//   const info = dummyFarm;
//
//   return (
//     <AccountLayout>
//       {accept && <DemandAccept />}
//       {/* Page Filler */}
//       <div data-testid="demand-farm-show" className="w-full flex flex-col items-center gap-8">
//         {/* Flex Column */}
//         <div className="w-full max-w-[60rem] self-center flex flex-col gap-4">
//           {/* Navigation */}
//
//           <Link to={`${routesPaths.DEMANDS}/${demand_id}/show`}>{"< Go Back To Applicant List"}</Link>
//           <div className="flex justify-between">
//             {/* Title */}
//             <label className="text-3xl">{info.data.name ? info.data.name : "Farm Name"}</label>
//             <button className="saveButton w-[10rem] h-fit" onClick={acceptHandler}>
//               Accept
//             </button>
//           </div>
//
//           {/* Widgets */}
//           <div className="bg-formDark flex flex-col p-4 gap-4 items-center rounded-lg shadow">
//             <div className="w-full flex flex-col gap-4 items-center demandFarm:flex-row">
//               {/* Farm */}
//               <section className="w-full flex justify-between gap-4">
//                 <div className="w-full flex flex-col gap-4">
//                   <label className="text-2xl">Farm</label>
//                   <div className="flex gap-2 justify-between items-center">
//                     <label>Number of Fields</label>
//                     <label className="indentedText text-center w-[5rem]">{info.data.fields}</label>
//                   </div>
//                   <div className="flex gap-2 justify-between items-center">
//                     <label>Number of Plots</label>
//                     <label className="indentedText text-center w-[5rem]">{info.data.plots}</label>
//                   </div>
//                 </div>
//                 <img src="/assets/images/FarmIconLarge.svg" alt="farm icon" className="self-center w-[9rem] h-[9rem]" />
//               </section>
//               {/* Certification */}
//               <section className="w-full flex justify-between gap-4">
//                 <div className="w-full max-w-[19rem] flex flex-col gap-4">
//                   <label className="text-2xl">Certification</label>
//                   <div className="flex gap-2 justify-between items-center">
//                     <label>Number of Certificates</label>
//                     <label className="indentedText text-center w-[5rem]">{info.data.certificates.length}</label>
//                   </div>
//                   <select
//                     className="max-w-[19rem]"
//                     value={certificate}
//                     onChange={({ target }) => setCertificate(target.value)}
//                   >
//                     <option value="DEFAULT" disabled>
//                       Choose Certificate
//                     </option>
//                     {info.data.certificates.map((certificate, index) => (
//                       <option value={certificate.name} key={index}>
//                         {certificate.name}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//                 <div className="w-[10rem] flex flex-col justify-around items-center">
//                   <label className="flex justify-center items-center">
//                     <p className="cursor-pointer w-[5rem] text-sm">View Certificate</p>
//                     <img src="/assets/images/ViewIcon.svg" alt="download icon" className="w-[2rem] h-[2rem]" />
//                   </label>
//                   <label className="flex justify-center items-center">
//                     <p className="cursor-pointer w-[5rem] text-sm">Download Certificate</p>
//                     <img src="/assets/images/DownloadIcon.svg" alt="download icon" className="w-[2rem] h-[2rem]" />
//                   </label>
//                 </div>
//               </section>
//             </div>
//             {/* Photos */}
//             <section className="bg-white w-full rounded-lg grid p-4 gap-4 justify-items-center grid-cols-1 productPlotWidgetSmall:grid-cols-2 productPlotWidgetLarge:grid-cols-3">
//               {info.data.photos.map((photo, index) => (
//                 <section className="bg-formLight flex flex-col w-full h-full rounded-lg shadow" key={index}>
//                   <img src={photo.photo} alt="plot photo" className="w-full h-[12rem] object-cover rounded-t-lg" />
//                   <div className="flex flex-col p-2 gap-2">
//                     <label>
//                       Photo taken on: {photo.date.day} / {photo.date.month} / {photo.date.year}
//                     </label>
//                   </div>
//                 </section>
//               ))}
//             </section>
//           </div>
//         </div>
//       </div>
//     </AccountLayout>
//   );
};

export default DemandFarmShow;
