import api from "../../support/support_api";

const unitOfMeasureStore = async (params) => {
    const response  = await  api.axiosPost('/unit-of-measure', params);

    return response.data;
}

const unitOfMeasureUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/unit-of-measure/${params.id}`, params);

    return response.data;
}

const unitOfMeasureDelete = async (params) => {
    const response  = await  api.axiosDelete(`/unit-of-measure/${params.id}`, params);

    return response.data;
}

const unitOfMeasureShow = async (params) => {
    const response  = await api.axiosShow(`/unit-of-measure/${params.id}`, params);

    return response.data;
}

const unitOfMeasureIndex = async (params) => {
    const response  = await api.axiosGet(`/unit-of-measure`, params);

    return response.data;
}

const unitOfMeasureService = {
    unitOfMeasureStore,
    unitOfMeasureUpdate,
    unitOfMeasureShow,
    unitOfMeasureIndex,
    unitOfMeasureDelete
}

export default unitOfMeasureService;