import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { farmIndex, farmShow, farmUpdate } from "../../../features/farm/farmSlice";

import FarmWidget from "./FarmWidget";
import AccountLayout from "pages/AccountLayout";
import FarmControls from "../FarmControls";

import plusIcon from "../../../images/PlusIcon.png";
import ExampleFarm from "../../../images/ExampleFarm.png";
import SnackbarContext from "_helpers/snackbar-context";
import MessageEmpty from "../../ui/message/empty/MessageEmpty"


import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
    urustaniIntegrationFarms,
    urustaniIntegrationFarmsStore
} from "../../../features/urustani-integration/urustaniIntegrationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import urustaniPng from "images/urustani.png";
import "./FarmWidgetList.css";
import clsx from "clsx";
import styles from "../../account/Account.module.scss";

const FarmWidgets = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { updating, farms } = useSelector((state) => state.farm);
    const [farmList, setFarmList] = useState({
        data: [],
    });

    const reduxFarm = useSelector((state) => state.farm);

    const addHandler = () => {
        navigate(`/farms/create`);
    };

    const snackbarCtx = useContext(SnackbarContext);

    const loadFarms = () => {
        /*
        note: this code can be used later.
        if(farms.data) {
            setFarmList(farms.data);
        }
        */

        dispatch(
            farmIndex({
                page: 1,
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                let farms = response.payload.data.farms;

                setFarmList(farms);
            }
        });
    };

    const reduxUrustaniIntegration = useSelector((state) => state.urustaniIntegrateion);

    const urustaniFarms = () => {
        if (window.confirm("Are you sure you want to syncronize with urustani farms?")) {

            popupClose()
            dispatch(urustaniIntegrationFarms({
                username: username,
                password: password
            })).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    if (window.confirm(response.payload.message)) {
                        urustaniFarmsStore();
                    }
                }
            });
        }
    }

    const urustaniFarmsStore = () => {
        dispatch(urustaniIntegrationFarmsStore({
            username: username,
            password: password
        })).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                snackbarCtx.displayMsg(response.payload.message, "success")

                // loadFarms();

                window.location.reload();
            }
        });
    }

    useEffect(() => {
        loadFarms();
    }, []);


    const [show, setShow] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const popupClose = () => setShow(false);
    const popupShow = () => setShow(true);

    return (
        <AccountLayout>
            <div className={clsx(styles.footerMobile,"w-full flex flex-col gap-4 p-4 laptop:p-6 wide:p-6 ")}>

                {/*todo - move to ui*/}
                <Modal show={show} onHide={popupClose}>
                    <Modal.Header style={{ placeSelf: "center" }}>
                        <Modal.Title className="text-center">UrusTani</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input type={"text"}
                            placeholder={"username"}
                            value={username}
                            className="input-search"
                            onChange={({ target }) => {
                                setUsername(target.value)
                            }} />
                        <input
                            className={"mt-3 input-search"}
                            type={"password"}
                            placeholder={"password"}
                            value={password}

                            onChange={({ target }) => {
                                setPassword(target.value)
                            }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="border-none saveButton" onClick={urustaniFarms}>Login</button>
                    </Modal.Footer>
                </Modal>
                {/*todo - move to ui*/}

                <div className="title-content text-2xl">
                    Farm Details
                </div>

                {/* <FarmControls /> */}

                <div
                    className="w-full grid place-items-center tablet:grid-cols-2 desktop:grid-cols-5 wide:grid-cols-6 gap-4">
                    {/*<span> Widget here</span>*/}
                    <button
                        className="min-w-[10rem] w-full min-h-[12rem] h-full flex flex-col justify-center items-center bg-white rounded-md border-[.01rem] border-green-500 space-y-2"
                        onClick={() => addHandler()}
                    >
                        <FontAwesomeIcon icon={faPlus} className={"change-icon-color-green"} />
                        <label className="cursor-pointer text-icon-color-green">Add New Farm</label>
                    </button>

                    {farmList.data.map((farm, i) => {
                        return <FarmWidget farm={farm} key={i} loadFarms={loadFarms} />;
                    })}

                    <MessageEmpty loading={reduxFarm.loading} length={reduxFarm.farms.data.length} message={reduxFarm.data.message} />

                    {/* <img src={ExampleFarm} className="object-cover w-full h-full rounded-lg shadow-lg"/>
                      <img src={ExampleFarm} className="object-cover w-full h-full rounded-lg shadow-lg"/>
                      <img src={ExampleFarm} className="object-cover w-full h-full rounded-lg shadow-lg"/>
                      <img src={ExampleFarm} className="object-cover w-full h-full rounded-lg shadow-lg"/>
                      <img src={ExampleFarm} className="object-cover w-full h-full rounded-lg shadow-lg"/> */}

                </div>
                {/* <div
                    className="w-full grid place-items-center tablet:grid-cols-2 laptop:grid-cols-3 wide:grid-cols-4 gap-4">
                    <button onClick={() => { popupShow(true) }} className={"border-none saveButton btn-add-carts mt-2"}>
                        <img src={urustaniPng} alt="" /><b>{reduxUrustaniIntegration.updating ? "Processing please wait.." : "Synchronize with Urus.Tani"}</b>
                    </button>
                </div> */}
            </div>
        </AccountLayout>
    );
};

export default FarmWidgets;
