import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStatesList, orderDetail, getCourier, storeAddress, orderFarmerDeliveryStart } from "../../features/order-farmer-delivery/orderFarmerDeliverySlice";
import SnackbarContext from "_helpers/snackbar-context";
import { Col, Modal, Row } from "react-bootstrap";
import PickMapLocation from "components/map/PickMapLocation";
import ReturnRefundPolicy from "components/home/ReturnRefundPolicy";
import Footer from "components/footer/footer";

const OrderFarmerDelivery = () => {
    const { currentUser } = useSelector((state) => state.auth);
    const snackbarCtx = useContext(SnackbarContext);
    const [statesList, setStatesList] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [agreeTermCondition, setAgreeTermCondition] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showTerms, setTermsModal] = useState(false);

    //pick location sender
    const [modalSenderLocation, setModalSenderLocation] = useState(false);
    const popupCloseModalSenderLocation = () => setModalSenderLocation(false);
    const popupShowModalSenderLocation = () => setModalSenderLocation(true);

    //pick location receiver
    const [modalReceiverLocation, setModalReceiverLocation] = useState(false);
    const popupCloseModalReceiverLocation = () => setModalReceiverLocation(false);

    //sender address
    const [fullname, setFullname] = useState((currentUser) ? currentUser.name : null);
    const [email, setEmail] = useState((currentUser) ? currentUser.email : null);
    const [phone, setPhone] = useState((currentUser) ? currentUser.phone_number : null);
    const [address, setAddress] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [postcode, setPostcode] = useState(null);
    const [lat, setLat] = useState(null);
    const [lon, setLon] = useState(null);
    const [farmerAddress, setFarmerAddress] = useState(false);

    //receiver address
    const [receiverAddress, setReceiverAddress] = useState(null);
    const [receiverCity, setReceiverCity] = useState(null);
    const [receiverState, setReceiverState] = useState(null);
    const [receiverPostcode, setReceiverPostcode] = useState(null);
    const [receiverLat, setReceiverLat] = useState(null);
    const [receiverLon, setReceiverLon] = useState(null);

    //package detail
    const [description, setDescription] = useState(null);
    const [totalWeight, setTotalWeight] = useState(0);

    //choose delivery
    const [courierList, setCourierList] = useState([]);
    const [serviceCode, setServiceCode] = useState(null);
    const [shippingCompany, setShippingCompany] = useState(null);
    const [shippingCharge, setShippingCharge] = useState(null);
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [selectedCourier, setSelectedCourier] = useState(null);

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const loadStates = () => {
        dispatch(
            getStatesList()
        ).then(function (response) {

            if (response.error && response.error.message == "Rejected") {
            } else {
                let states = response.payload.data.states;
                setStatesList(states);
            }
        });
    };

    const loadCourier = () => {
        setIsLoading(true);
        dispatch(
            getCourier({
                sender_address: {
                    address: address,
                    city: city,
                    state: state,
                    postcode: postcode,
                    country: "MY",
                    lat: lat,
                    lon: lon
                },
                receiver_address: {
                    address: receiverAddress,
                    city: receiverCity,
                    state: receiverState,
                    postcode: receiverPostcode,
                    country: "MY",
                    lat: receiverLat,
                    lon: receiverLon
                },
                weight: {
                    unit: "kg",
                    value: totalWeight
                }
            })
        ).then(function (response) {
            setIsLoading(false);
            if (response.error && response.error.message == "Rejected") {
            } else {
                let result = response.payload;
                setCourierList(result);
            }
        });
    };

    const loadOrder = () => {
        setIsLoading(true);
        dispatch(
            orderDetail({
                order_farmer_id: params.order_farmer_id
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                let orderDetail = response.payload.data.order_details.data;
                setOrderDetails(orderDetail);

                let tempTotalWeight = 0;
                for (let i = 0; i < orderDetail.length; i++) {
                    tempTotalWeight += orderDetail[i].weight;
                }
                setTotalWeight(tempTotalWeight);

                let result = response.payload.data.order_farmer.order;
                setReceiverAddress(result.delivery_address_street);
                setReceiverCity(result.delivery_address_city);
                setReceiverState(result.delivery_address_state);
                setReceiverPostcode(result.delivery_address_zipcode);
                setReceiverLat(result.delivery_address_lat);
                setReceiverLon(result.delivery_address_lon);

                let farmer_address = response.payload.data.order_farmer.shipping_address;
                if (farmer_address) {
                    setFullname(farmer_address.fullname);
                    setEmail(farmer_address.email);
                    setPhone(farmer_address.phone);
                    setAddress(farmer_address.address);
                    setCity(farmer_address.city);
                    setState(farmer_address.state);
                    setPostcode(farmer_address.postcode);
                    setLat(farmer_address.lat);
                    setLon(farmer_address.lon);

                    setFarmerAddress(true);
                } else {
                    alert("Please fill sender address.");
                }

                setIsLoading(false);
            }
        });
    };

    const setPickLocationSender = () => {
        setLat(lat);
        setLon(lon);
        popupCloseModalSenderLocation();
        saveSenderAddress();
    }

    const setPickLocationReceiver = () => {
        setReceiverLat(receiverLat);
        setReceiverLon(receiverLon);
        popupCloseModalReceiverLocation();
    }

    const saveSenderAddress = (event) => {
        dispatch(
            storeAddress({
                address: {
                    fullname: fullname,
                    email: email,
                    phone: phone,
                    country: 'Malaysia',
                    state: state,
                    city: city,
                    postcode: postcode,
                    address: address,
                    lat: lat,
                    lon: lon
                }
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                let result = response.payload.data.user_address;
                snackbarCtx.displayMsg(response.payload.message, "success");
                setSelectedDelivery("");
                setFarmerAddress(true);
            }
        });

        event.preventDefault();
    };

    const startDelivery = () => {
        if (agreeTermCondition) {
            if (window.confirm("Are you sure want to confirm delivery?")) {
                setIsLoading(true);
                dispatch(
                    orderFarmerDeliveryStart({
                        order_farmer_id: params.order_farmer_id,
                        delivery_option: selectedDelivery,
                        sender_address: {
                            fullname: fullname,
                            email: email,
                            phone: phone,
                            address: address,
                            state: state,
                            city: city,
                            postcode: postcode,
                            country: "Malaysia",
                            lat: lat,
                            lon, lon
                        },
                        service_code: serviceCode,
                        shipping_company: shippingCompany,
                        shipping_charge: shippingCharge,
                        orderDetails: orderDetails
                    })
                ).then(function (response) {
                    setIsLoading(false);
                    if (response.error && response.error.message === "Rejected") {
                        snackbarCtx.displayMsg(response.payload, "failure")
                    } else {
                        navigate(`/order-farmers/${params.order_farmer_id}/tracking`);
                        snackbarCtx.displayMsg(response.payload.message, "success");
                    }
                });
            }
        } else {
            alert('Please agree with terms & condition');
        }
    }

    useEffect(() => {
        loadStates();
        loadOrder();
    }, []);

    useEffect(() => {
        if (receiverAddress) {
            if (address && city && state && postcode && lat && lon) {
                if (totalWeight) {
                    loadCourier();
                }
            }
        }
    }, [address, city, state, postcode, lat, lon, receiverAddress, totalWeight]);

    useEffect(() => {
        let tempTotalWeight = 0;
        for (let i = 0; i < orderDetails.length; i++) {

            if (orderDetails[i].weight != null) {
                let weight = parseFloat(orderDetails[i].weight);
                tempTotalWeight += weight;
            }
        }
        setTotalWeight(tempTotalWeight);
    }, [orderDetails]);

    return (
        <div>
            <Row className="container mt-3 mb-5">
                <Col md={1}></Col>
                <Col md={10}>
                    <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
                        <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                            <div className="p-4 w-full max-w">
                                <h2 className="text-3xl text-left">Delivery Details</h2>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={1}></Col>
            </Row>
            <Row className="container mt-3">
                <Col md={1}></Col>
                <Col md={10}>
                    <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
                        <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                            <div className="p-4 w-full max-w">
                                <h2 className="text-3xl text-left">1. Sender Details</h2>
                                <Row>
                                    <Col md={12}>
                                        <label className="mt-1">Address Line</label>
                                        <textarea
                                            // type="text"
                                            className="input-search"
                                            name="address"
                                            placeholder="Address"
                                            value={address}
                                            onChange={({ target }) => setAddress(target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label className="mt-1">City</label>
                                        <input
                                            type="text"
                                            className="input-search"
                                            name="city"
                                            placeholder="City"
                                            value={city}
                                            onChange={({ target }) => setCity(target.value)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label className="mt-1">State</label>
                                        <select className="input-search" defaultValue={null} value={state} onChange={({ target }) => { setState(target.value); }}>
                                            <option key={-1} value="">
                                                Select State..
                                            </option>
                                            {
                                                statesList.map((state, key) => <option key={state.id} value={state.name}>{state.name}</option>)
                                            }
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label className="mt-1">Postcode</label>
                                        <input
                                            type="number"
                                            className="input-search"
                                            name="postcode"
                                            placeholder="Postcode"
                                            value={postcode}
                                            onChange={({ target }) => setPostcode(target.value)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label className="mt-1">Country</label>
                                        <input
                                            type="text"
                                            className="input-search"
                                            name="country"
                                            placeholder="Country"
                                            value="Malaysia"
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label className="mt-1">Fullname</label>
                                        <input
                                            type="text"
                                            className="input-search"
                                            name="fullname"
                                            placeholder="Fullname"
                                            value={fullname}
                                            onChange={({ target }) => setFullname(target.value)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label className="mt-1">Email</label>
                                        <input
                                            type="text"
                                            className="input-search"
                                            name="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={({ target }) => setEmail(target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label className="mt-1">Phone</label>
                                        <input
                                            type="number"
                                            className="input-search"
                                            name="phone"
                                            placeholder="Phone"
                                            value={phone}
                                            onChange={({ target }) => setPhone(target.value)}
                                        />
                                    </Col>
                                </Row>
                                <h4 className="mt-2">Coordinate</h4>
                                <Row>
                                    <Col md={6}>
                                        <label className="mt-1">Latitude</label>
                                        <input
                                            type="text"
                                            className="input-search"
                                            name="lat"
                                            placeholder="Latitude"
                                            value={lat}
                                            onChange={({ target }) => setLat(target.value)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label className="mt-1">Longitude</label>
                                        <input
                                            type="text"
                                            className="input-search"
                                            name="lon"
                                            placeholder="Longitude"
                                            value={lon}
                                            onChange={({ target }) => setLon(target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <button type="button" className="btn-green mt-2" onClick={() => { popupShowModalSenderLocation(); }}>{(isLoading) ? "Processing, please wait.." : "Set Location"}</button>
                                    </Col>
                                </Row>

                            </div>

                        </div>
                    </div>
                </Col>
                <Col md={1}></Col>
            </Row>
            <Row className="container mt-3">
                <Col md={1}></Col>
                <Col md={10}>
                    <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
                        <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                            <div className="p-4 w-full max-w">
                                <h2 className="text-3xl text-left">2. Receiver Details</h2>
                                <Row>
                                    <Col md={12}>
                                        <label className="mt-1">Address Line</label>
                                        <textarea
                                            // type="text"
                                            className="input-search"
                                            name="receiverAddress"
                                            placeholder="Address"
                                            value={receiverAddress}
                                            disabled={true}
                                            onChange={({ target }) => setReceiverAddress(target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label className="mt-1">City</label>
                                        <input
                                            type="text"
                                            className="input-search"
                                            name="receiverCity"
                                            placeholder="City"
                                            value={receiverCity}
                                            disabled={true}
                                            onChange={({ target }) => setReceiverCity(target.value)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label className="mt-1">State</label>
                                        <select className="input-search" defaultValue={receiverState} value={receiverState} disabled={true} onChange={({ target }) => { setReceiverState(target.value); }}>
                                            <option key={-1} value="">
                                                Select State..
                                            </option>
                                            {
                                                statesList.map((state, key) => <option key={state.id} value={state.name}>{state.name}</option>)
                                            }
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label className="mt-1">Postcode</label>
                                        <input
                                            type="number"
                                            className="input-search"
                                            name="receiverPostcode"
                                            placeholder="Postcode"
                                            value={receiverPostcode}
                                            disabled={true}
                                            onChange={({ target }) => setReceiverPostcode(target.value)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label className="mt-1">Country</label>
                                        <input
                                            type="text"
                                            className="input-search"
                                            name="country"
                                            placeholder="Country"
                                            value="Malaysia"
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <h4 className="mt-1">Coordinate</h4>
                                <Row>
                                    <Col md={6}>
                                        <label className="mt-1">Latitude</label>
                                        <input
                                            type="text"
                                            className="input-search"
                                            name="receiverLat"
                                            placeholder="Latitude"
                                            value={receiverLat}
                                            disabled={true}
                                            onChange={({ target }) => setReceiverLat(target.value)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label className="mt-1">Longitude</label>
                                        <input
                                            type="text"
                                            className="input-search"
                                            name="receiverLon"
                                            placeholder="Longitude"
                                            value={receiverLon}
                                            disabled={true}
                                            onChange={({ target }) => setReceiverLon(target.value)}
                                        />
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col md={12}>
                                        <button type="button" className="btn-green mt-2" onClick={() => { popupShowModalReceiverLocation(); }}>Set Location</button>
                                    </Col>
                                </Row> */}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={1}></Col>
            </Row>
            <Row className="container mt-3">
                <Col md={1}></Col>
                <Col md={10}>
                    <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
                        <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                            <div className="p-4 w-full max-w">
                                <h2 className="text-3xl text-left">3. Choose Delivery Option</h2>
                                <Row>
                                    <Col md={4}>
                                        <select className="input-search mt-1" defaultValue={""} value={selectedDelivery} onChange={(e) => {
                                            if (e.target.value == 'delivery_courier') {
                                                setSelectedDelivery(e.target.value);
                                                if (farmerAddress && totalWeight > 0) {
                                                    loadCourier();
                                                } else if (!farmerAddress && totalWeight == 0) {
                                                    alert('Please fill sender address & total weight')
                                                } else if (farmerAddress && totalWeight == 0) {
                                                    alert('Please fill total weight');
                                                } else if (!farmerAddress && totalWeight > 0) {
                                                    alert('Please fill sender address');
                                                }
                                            } else {
                                                setSelectedDelivery(e.target.value);
                                            }
                                        }}>
                                            <option key={0} value="">Choose Delivery Option</option>
                                            <option key={1} value="self_delivery">Self Delivery</option>
                                            <option key={2} value="delivery_courier">Delivery Courier</option>
                                        </select>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={1}></Col>
            </Row>

            {(selectedDelivery == 'delivery_courier') ?
                <>
                    <Row className="container mt-3">
                        <Col md={1}></Col>
                        <Col md={10}>
                            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
                                <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                                    <div className="p-4 w-full max-w">
                                        <h2 className="text-3xl text-left">4. Package Details</h2>
                                        {orderDetails.map((orderDetail, idx) => (
                                            <>
                                                <Row>
                                                    <Col md={3}>
                                                        <label className="mt-1">Item Type</label>
                                                        <input
                                                            type="text"
                                                            className="input-search"
                                                            name="itemType"
                                                            placeholder="Item Type"
                                                            value={orderDetail.product.name}
                                                            disabled={true}
                                                        // onChange={({ target }) => setItemType(target.value)}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <label className="mt-1">Total Weight</label>
                                                        <input
                                                            type="number"
                                                            className="input-search"
                                                            name="weight"
                                                            placeholder="Weight"
                                                            // value={(orderDetail.unit) ? (orderDetail.unit.toLowerCase() == 'kg') ? orderDetail.weight : '' : ''}
                                                            value={orderDetails[idx].weight}
                                                            // disabled={(orderDetail.weight != null) ?  true : false}
                                                            onChange={({ target }) => {
                                                                if (target.value <= 30) {
                                                                    let temp = JSON.parse(JSON.stringify(orderDetails));
                                                                    temp[idx].weight = target.value;
                                                                    setOrderDetails(temp);
                                                                } else {
                                                                    alert("Please use self delivery for total weight more than 30kg.");
                                                                }

                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <label className="mt-1">Quantity</label>
                                                        <input
                                                            type="number"
                                                            className="input-search"
                                                            name="quantity"
                                                            placeholder="Quantity"
                                                            value={orderDetail.quantity}
                                                            disabled={true}
                                                        // onChange={({ target }) => setQuantity(target.value)}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <label className="mt-1">Unit</label>
                                                        <input
                                                            type="text"
                                                            className="input-search"
                                                            name="unit"
                                                            placeholder="Unit"
                                                            value={"Kg"}
                                                            disabled={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <label className="mt-1">Description</label>
                                                        <textarea value={orderDetail.product.description} className={"input-search"} placeholder={"Description..."} onChange={({ target }) => setDescription(target.value)} ></textarea>
                                                    </Col>
                                                </Row>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </>
                : ''
            }

            <Row className="container mt-3 mb-20">
                <Col md={1}></Col>
                <Col md={10}>
                    <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
                        <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                            <div className="p-4 w-full max-w">
                                {(selectedDelivery == 'delivery_courier') ?
                                    <>

                                        <h2 className="text-3xl text-left">5. Choose Courier</h2>
                                        <Row>
                                            <Col md={4}>
                                                {/* <label className="mt-1">State</label> */}
                                                <select className="input-search mt-1" defaultValue={"self_delivery"} value={selectedCourier} onChange={(e) => {
                                                    // setSelectedDelivery(e.target.value);
                                                    if (e.target.value) {
                                                        setSelectedCourier(e.target.value);
                                                        let tempServiceCode = e.target[e.target.selectedIndex].getAttribute('data-serviceCode');
                                                        let tempShippingCompany = e.target[e.target.selectedIndex].getAttribute('data-shippingCompany');
                                                        let tempShippingCharge = e.target[e.target.selectedIndex].getAttribute('data-shippingCharge');
                                                        setServiceCode(tempServiceCode);
                                                        setShippingCompany(tempShippingCompany);
                                                        setShippingCharge(tempShippingCharge);
                                                    }
                                                }}>
                                                    <option key={-1}>{(isLoading) ? 'Processing, please wait..' : 'Choose Courier Service'}</option>
                                                    {courierList.map((courier, index) => (
                                                        <option key={index} value={courier.service.name}
                                                            data-serviceCode={courier.service.code}
                                                            data-shippingCompany={courier.service.serviceCompany.name}
                                                            data-shippingCharge={courier.price.amount}
                                                        >
                                                            {courier.service.name} | RM {courier.price.amount}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                    </>
                                    : ''
                                }
                                <Row className="mt-4">
                                    <Col md={12}>
                                        <input
                                            type="checkbox"
                                            name="agree_term_condition"
                                            value={agreeTermCondition}
                                            // checked={agreeTermCondition == 1}
                                            onChange={({ target }) => {
                                                setAgreeTermCondition((agreeTermCondition == 0) ? 1 : 0);
                                            }}
                                        />
                                        &nbsp;&nbsp;
                                        <label>I've read and agree to <span style={{ cursor: "pointer" }} className="text-green" onClick={() => setTermsModal(true)}>Return & Refund Policy</span> </label>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md={4}></Col>
                                    <Col md={4}>
                                        <button type="button" className="btn-green" onClick={startDelivery}>{(isLoading) ? 'Processing, please wait..' : 'Confirm Delivery'}</button>
                                    </Col>
                                    <Col md={4}></Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={1}></Col>
            </Row>

            <Modal className="modal-location" show={modalSenderLocation} onHide={popupCloseModalSenderLocation}>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <PickMapLocation
                                curentLatMap={lat}
                                curentLngMap={lon}
                                curentLatMark={lat}
                                curentLngMark={lon}
                                saveLat={setLat}
                                saveLng={setLon}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="border-none saveButton" onClick={() => {
                        setPickLocationSender();
                    }}>Set Pin Point Address</button>
                </Modal.Footer>
            </Modal>

            <Modal className="modal-location" show={modalReceiverLocation} onHide={popupCloseModalReceiverLocation}>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <PickMapLocation
                                curentLatMap={receiverLat}
                                curentLngMap={receiverLon}
                                curentLatMark={receiverLat}
                                curentLngMark={receiverLon}
                                saveLat={setReceiverLat}
                                saveLng={setReceiverLon}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="border-none saveButton" onClick={() => {
                        setPickLocationReceiver();
                    }}>Set Location</button>
                </Modal.Footer>
            </Modal>
            {showTerms && (
                <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
                    <section data-testid="popup-layout" className="absolute bg-white w-4/5 h-3/4 flex flex-col rounded-xl shadow pb-4">
                        <div className="bg-white w-full h-full flex flex-col rounded-t-xl px-8 py-4 overflow-y-auto">
                            <ReturnRefundPolicy />
                        </div>
                        <div className="w-full flex gap-4 justify-center mt-2">
                            <button className="borderedButton w-2/5" onClick={() => setTermsModal(false)}>
                                Close
                            </button>
                        </div>
                    </section>
                </div>
            )}
            <Footer />
        </div>
    );
};

export default OrderFarmerDelivery;
