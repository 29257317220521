import React from 'react';

function MessageEmpty({loading, length, message}) {
    return (
        <div>
            {(! loading && length === 0) && <div>{message}</div>}
        </div>
    )
}

export default MessageEmpty;