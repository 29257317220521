import { useContext, useState } from "react";
import editUser from "./../../images/pencil.svg";
import deleteUser from "./../../images/redBin.svg";
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import PopupConfirmation from "components/ui/popup/PopupConfirmation";
import { useDispatch } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import clsx from "clsx";
import styles from "../account/Account.module.scss";
import * as statusConst from "constants/normalOrder.constants";
import * as bookStatusConst from "constants/advanceBooking.constants";
import * as aucStatusConst from "constants/auction.constants";
import Text from "components/text/Text";
import { orderDelete } from "features/order/orderSlice";
import { advanceBookingDelete } from "features/advance-booking/advanceBookingSlice";
import { auctionBiddingDelete } from "features/auction-bidding/auctionBiddingSlice";

const ManageOrders = ({ data, typeOrder, index, loadList, loadStatistic }) => {
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const [isDelete, setDelete] = useState(false);
  const navigate = useNavigate();

  const editHandler = () => {
    if (typeOrder !== 'Auction') {
      navigate(`/manage-orders/${data.id}/edit/${typeOrder}`);
    } else {
      navigate(`/manage-orders/${data.auction_id}/edit/${typeOrder}`);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'max-content',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
  };

  const deleteOrder = () => {
    if (typeOrder === 'Incoming') {
      if (data?.status !== 0) {
        alert("This order cannot be delete");
      } else {
        dispatch(
          orderDelete({
            order_id: data.id,
          })
        ).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
            snackbarCtx.displayMsg(response.payload, "failure")
          } else {
            snackbarCtx.displayMsg(response.payload.message, "success")
            setDelete(false);
            loadList();
            loadStatistic();
          }
        });
      }
    } else if (typeOrder === 'Advanced') {
      if (data?.status !== 0) {
        alert("This booking cannot be delete");
      } else {
        dispatch(
          advanceBookingDelete({
            id: data.id,
          })
        ).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
            snackbarCtx.displayMsg(response.payload, "failure")
          } else {
            snackbarCtx.displayMsg(response.payload.message, "success")
            setDelete(false);
            loadList();
            loadStatistic();
          }
        });
      }
    } else {
      if (data?.status !== 0) {
        alert("This auction cannot be delete");
      } else {
        dispatch(
          auctionBiddingDelete({
            id: data.id,
          })
        ).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
            snackbarCtx.displayMsg(response.payload, "failure")
          } else {
            snackbarCtx.displayMsg(response.payload.message, "success")
            setDelete(false);
            loadList();
            loadStatistic();
          }
        });
      }
    }
  }

  const toggleCollapse = (sectionId, contentId) => {
    const section = document.getElementById(sectionId);
    section.firstElementChild.classList.toggle('rotate-180');

    const content = document.getElementById(contentId);
    content.style.maxHeight = content.style.maxHeight ? null : content.scrollHeight + 'px';
  }

  return (
    <>
      {isDelete && <PopupConfirmation title={`Are you sure want to delete this ` + (typeOrder === 'Incoming' ? ` order` : (typeOrder === 'Advanced' ? ` booking` : ` auction`)) + `?`} successButtonTitle="Delete" cancelButtonTitle="Cancel" onSuccess={deleteOrder} onCancel={setDelete} />}
      <div data-testid="data" className="" style={{ borderBottomWidth: "1px" }}>
        <div className="hidden laptop:flex flex-row items-center justify-center p-2 text-sm collapsible" key={index} onClick={() => toggleCollapse(("collapsible" + index), typeOrder + "-" + index)} id={"collapsible" + index}>
          <section className="flex items-center justify-center font-medium px-2">
            <svg class="w-5 h-5 text-gray-600 transform transition-transform" viewBox="0 0 24 24">
              <path d="M7 10l5 5 5-5z" fill="none" stroke="currentColor" stroke-width="2"></path>
            </svg>
          </section>
          <section className="basis-1/4 flex items-center justify-center font-medium ">
            <div>{typeOrder === 'Incoming' ? data.order_number : typeOrder === 'Advanced' ? data.booking_number : data.auction_id}</div>
          </section>
          {typeOrder === 'Incoming' && <section className="basis-1/4 flex items-center justify-center font-medium">
            <div>{data?.details?.data.length === 0 ? 'N/A' : data?.details?.data.length}</div>
          </section>}
          <section className="basis-1/4 flex items-center justify-center font-medium">
            <div className="">{typeOrder === 'Incoming' ? data?.customer?.first_name : data?.buyer?.name}</div>
          </section>
          {typeOrder !== 'Auction' && <section className="basis-1/4 flex items-center justify-center font-medium">
            <div className={clsx(styles.scroll)}>{data?.payment_method === null ? 'N/A' : (data?.payment_method === 0 ? <Text id="offline" /> : <Text id="online" />)}</div>
          </section>}
          <section className="basis-1/4 flex items-center justify-center font-medium">
            <div className={clsx(styles.scroll, typeOrder === 'Incoming' && statusConst.normalOrderStatus[data?.status]?.bg_color || typeOrder === 'Advanced' && ' bg-' + bookStatusConst.bookingPlotStatus[data?.status]?.badge || typeOrder === 'Auction' && ' bg-' + aucStatusConst.auctionStatus[data.status_current]?.badge, 'rounded-lg p-2')}>{typeOrder === 'Incoming' && statusConst.normalOrderStatus[data.status].label_for_order_management || typeOrder === 'Advanced' && bookStatusConst.bookingPlotStatus[data.status]?.label || typeOrder === 'Auction' && aucStatusConst.auctionStatus[data.status_current]?.label}</div>
          </section>
          <section className="basis-1/4 flex items-center justify-center font-medium">
            <img src={editUser} className="w-4 h-4 cursor-pointer mr-2" onClick={() => editHandler()} />
            {data?.status === 0 && <div>/</div>}{data?.status === 0 && <img src={deleteUser} className="w-4 h-4 cursor-pointer ml-2" onClick={() => setDelete(true)} />}
          </section>
        </div>
        <div className="overflow-hidden transition max-h-0" style={{ backgroundColor: '#F8FFF8' }} id={typeOrder + "-" + index}>
          <div className="hidden laptop:flex flex-row items-center justify-center text-sm text-semibold font-regular py-3 border-y-2">
            <div className="basis-1/4 flex items-center justify-center font-bold">Item</div>
            <div className="basis-1/4 flex items-center justify-center font-bold">Product Name</div>
            <div className="basis-1/4 flex items-center justify-center font-bold">Farmer Name</div>
            <div className="basis-1/4 flex items-center justify-center font-bold">Quantity</div>
            <div className="basis-1/4 flex items-center justify-center font-bold">Total Price</div>
            <div className="basis-1/4 flex items-center justify-center font-bold">Status</div>
          </div>
          {/* Incoming */}
          {data?.details?.data && data?.details?.data.length > 0 && data?.details?.data.map((order, index) => (<div className="hidden laptop:flex flex-row items-center justify-center text-sm text-semibold font-regular py-3 text-main">
            <div className="basis-1/4 flex items-center justify-center font-medium">{index + 1}</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{order?.product?.name}</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{order?.farmer?.name}</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{order?.quantity}</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{order?.total_price_formatted}</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{statusConst.normalOrderStatus[data.status]?.label_for_order_management}</div>
          </div>))}
          {/* Advanced & Auction */}
          {data?.plot && <div className="hidden laptop:flex flex-row items-center justify-center text-sm text-semibold font-regular py-3 text-main">
            <div className="basis-1/6 flex items-center justify-center font-medium">{index + 1}</div>
            <div className="basis-1/6 flex items-center justify-center font-medium">{data?.plot?.product?.name}</div>
            <div className="basis-1/6 flex items-center justify-center font-medium">{data?.plot?.farmer?.name}</div>
            <div className="basis-1/6 flex items-center justify-center font-medium">{data?.quantity}</div>
            <div className="basis-1/6 flex items-center justify-center font-medium">{data?.total_price || data?.total_bid_price}</div>
            <div className="basis-1/6 flex items-center justify-center font-medium px-2">{typeOrder === 'Auction' ? aucStatusConst.auctionStatus[data.status_current]?.label : bookStatusConst.bookingPlotStatus[data.status]?.label}</div>
          </div>}
        </div>
      </div>
    </>
  );
};

export default ManageOrders;