import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import companyPackageService from './companyPackageService';

export const subscribePackage = createAsyncThunk(
    "company/subscribe-package",
    async (args, thunkAPI) => {
        try {
            return await companyPackageService.subscribePackage(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)


export const updatePackage = createAsyncThunk(
    "company/update-package",
    async (args, thunkAPI) => {
        try {
            return await companyPackageService.renewPackage(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const resubscribePackage = createAsyncThunk(
    "company/resubscribe-package",
    async (args, thunkAPI) => {
        try {
            return await companyPackageService.resubscribePackage(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyPackageStore = createAsyncThunk(
    "companyPackage/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await companyPackageService.companyPackageStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const retrieveCompanyPackage = createAsyncThunk(
    "/company/package-details",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await companyPackageService.retrieveCompanyPackage(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    companyPackage: {},
    loading: false,
    isSuccess: false,
    currentUser: {},
    currentCompany: {},
    token: 'asdasd',
    updating: false,
}

export const companyPackageSlice = createSlice({
    name: 'companyPackage',
    initialState,
    reducers: {
        getCurrentUserInfo: (state) => {
            let user = JSON.parse(localStorage.getItem("user"));

            state.token = user.data.token;
            state.currentUser = user.data.user;
            state.currentCompany = user.data.company;
        },

    },
    extraReducers : {
        [subscribePackage.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [subscribePackage.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [subscribePackage.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
        [updatePackage.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [updatePackage.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [updatePackage.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
        [companyPackageStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [companyPackageStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.companyPackage = payload.data.companyPackage;
            state.loading=false;
            state.isSuccess = true;
        },
        [companyPackageStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
        [retrieveCompanyPackage.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [retrieveCompanyPackage.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.companyPackage = payload.data.companyPackage;
            state.loading=false;
            state.isSuccess = true;
        },
        [retrieveCompanyPackage.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

export const { getCurrentUserInfo } = companyPackageSlice.actions

export default companyPackageSlice;