import clsx from "clsx";
import { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import Text from "components/text/Text";
import logo from "images/home/Logo.svg";
import { subscribePackage } from "features/package-subscription/companyPackageSlice";
import SnackbarContext from "../../_helpers/snackbar-context";
import { getPackages } from "features/auth/packageSubscriptionSlice";
import { Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/pagination';
import styles from "./RegisterSubscription.module.scss";

const RegisterSubsription = () => {
    const { currentCompany } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [packages, setPackages] = useState([])
    const snackbarCtx = useContext(SnackbarContext)

    const ChoosePackage = ({ item }) => {
        return (
            <div data-testid="reg-sub" className={clsx("flex", "flex-col", "p-8", "space-y-6", styles.loginForm)} key={item.id}>
                <span className={clsx("self-center", "text-2xl", styles.titleLogin)}>
                    <div>{item.package_name}</div>
                </span>
                <div className="flex flex-col space-y-4 w-full p-3 mt-3" style={{ marginTop: "5px" }}>
                    <div className={styles.borderedButton} style={{ backgroundColor: "white" }}>
                        <div className="p-2 text-center">
                            <div className="text-gray-500 font-semibold" style={{ fontSize: "16px" }}>
                                <Text id="Subscription" />
                            </div>
                            <div className="text-green-500 font-semibold" style={{ fontSize: "30px" }}>
                                <div>RM {item.subscription_fee}</div>
                            </div>
                            <div className="text-green-500 font-semibold" style={{ fontSize: "16px" }}>
                                <Text id="Monthly" />
                            </div>
                        </div>
                        <div className="mt-2 p-2 text-center">
                            <div className="text-gray-500 font-semibold" style={{ fontSize: "16px" }}>
                                <Text id="Service Charge" />
                            </div>
                            <div className="text-green-500 font-semibold" style={{ fontSize: "30px" }}>
                                <div>{parseInt(item.service_charge)} %</div>
                            </div>
                            <div className="text-green-500 font-semibold" style={{ fontSize: "16px" }}>
                                <Text id="PER TRANSACTION" />
                            </div>
                        </div>
                    </div>
                </div>
                <p className="self-center" style={{ margin: "0px", padding: "10px" }}>
                    <img className="w-5 h-5" src={logo} alt="logo icon" />
                </p>
                <div className="flex flex-col space-y-4 p-2" style={{ marginTop: "5px", marginBottom: "5px" }}>
                    <div className="text-left pl-5 text-green-600 font-semibold" style={{ fontSize: "12px" }}>
                        <ul className="list-disc text-xs">
                            <li>{item.package_details}</li>
                        </ul>
                    </div>
                </div>
                <button className={clsx(styles.submit, "bg-green-500", "text-white", "hover:bg-green-600")} type="submit" style={{ marginTop: "0px" }} onClick={() => submitHandler(item)}>
                    <Text id="Choose Package" />
                </button>
            </div>
        )
    }

    const submitHandler = item => {
        dispatch(
            subscribePackage({
                package_id: item.id,
                company_id: currentCompany.id,
                service_charge_type: 1,
                status: 1
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                snackbarCtx.displayMsg(response.payload.message, "success")
                navigate("/register/complete");
            }
        });
    };

    const loadPackages = () => {
        dispatch(
            getPackages()
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                setPackages(response.payload.data.packages.data)
            }
        });
    }

    useEffect(() => {
        loadPackages();
    }, []);

    return (
        <div className="flex justify-center font-regular">
            <div className={clsx(styles.start)}>
                <Swiper slidesPerView={1} spaceBetween={30} className="mySwiper" pagination={{
                    clickable: true,}} modules={[Pagination]}>
                    {/* <button onClick={() => swiper.slidePrev()}>
                        <img className="" src={leftArrow} alt="left arrow icon" />
                    </button> */}
                    {packages.map((item) => (
                        <SwiperSlide key={item.id}><ChoosePackage item={item} /></SwiperSlide>
                    ))}
                    {/* <button onClick={() => swiper.slideNext()}>
                        <img className="" src={rightArrow} alt="right arrow icon" />
                    </button> */}
                </Swiper>
            </div>
        </div>
    );
};

export default RegisterSubsription;
