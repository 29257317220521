import api from "../../support/support_api";

const orderFarmerStore = async (params) => {
    const response  = await  api.axiosPost('/order-farmers', params);

    return response.data;
}

const orderFarmerUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/order-farmers/${params.order_farmer_id}`, params);

    return response.data;
}

const orderFarmerShow = async (params) => {
    const response  = await api.axiosShow(`/order-farmers/${params.order_farmer_id}`, params);

    return response.data;
}

const orderFarmerIndex = async (params) => {
    const response  = await api.axiosGet(`/order-farmers`, params);

    return response.data;
}

const orderFarmerUpdateByToken = async (params) => {
    const response  = await api.axiosPatch(`/order-farmers/update-by-token`, params);

    return response.data;
}

const orderFarmerPlace = async (params) => {
    const response  = await api.axiosPatch(`/order-farmers/place`, params);

    return response.data;
}

const orderFarmerReceivableForFarmer = async (params) => {
    const response  = await api.axiosGet(`/order-farmers/receivable-for-farmer`, params);

    return response.data;
}

const orderFarmerMadeByCustomer = async (params) => {
    const response  = await api.axiosGet(`/order-farmers/made-by-customer`, params);

    return response.data;
}

const orderFarmerDeliveryDecline = async (params) => {
    const response  = await api.axiosPatch(`/order-farmers/${params.order_farmer_id}/delivery-decline`, params);

    return response.data;
}

const orderFarmerDeliveryAccept = async (params) => {
    const response  = await api.axiosPatch(`/order-farmers/${params.order_farmer_id}/delivery-accept`, params);

    return response.data;
}

const orderFarmerDeliveryStart = async (params) => {
    const response  = await api.axiosPatch(`/order-farmers/${params.order_farmer_id}/delivery-start`, params);

    return response.data;
}

const orderFarmerDeliveryDone = async (params) => {
    const response  = await api.axiosPatch(`/order-farmers/${params.order_farmer_id}/delivery-done`, params);

    return response.data;
}

const bookingPlotDetail = async (params) => {
    const response  = await api.axiosShow(`/booking-plots/getByOrderFarmerId/${params.order_farmer_id}`, params);

    return response.data;
}

const confirmOrderQuantity = async (params) => {
    const response  = await api.axiosPut(`/order-farmers/confirmOrderQuantity`, params);

    return response.data;
}

const cancelOrder = async (params) => {
    const response  = await api.axiosPut(`/order-farmers/cancelOrder`, params);

    return response.data;
}

const printAwb = async (params) => {
    const response  = await api.axiosShow(`/order-farmers/${params.order_farmer_id}/print-awb`, params);

    return response.data;
}

const getStatesList = async (params) => {
    const response  = await api.axiosGet(`/states/list`);

    return response.data;
}

const payOrder = async (params) => {
    const response  = await api.axiosPost(`/orders/pay`, params);

    return response.data;
}

const orderFarmerGetByOrderNumber = async (params) => {
    const response  = await api.axiosShow(`/order-farmers/getByOrderNumber/${params.order_number}`, params);

    return response.data;
}

const generateInvoiceNumber = async (params) => {
    const response  = await api.axiosPut(`/order-farmers/generateInvoiceNumber/${params.order_number}`, params);

    return response.data;
}

const uploadPaymentReceipt = async (params) => {
    const response  = await api.axiosPost(`/order-farmers/uploadPaymentReceipt`, params);

    return response.data;
}

const confirmOfflinePayment = async (params) => {
    const response  = await api.axiosPut(`/order-farmers/confirmOfflinePayment/${params.order_farmer_id}`, params);

    return response.data;
}

const downloadInvoice = async (params) => {
    const response  = await  api.axiosDownloadPDF('/order-farmers/download-invoice/'+params.order_number, params);

    return response.data;
}

const orderFarmerInDelivery = async (params) => {
    const response  = await api.axiosPatch(`/order-farmers/${params.order_farmer_id}/in-delivery`, params);

    return response.data;
}

const orderFarmerDelivered = async (params) => {
    const response  = await api.axiosPatch(`/order-farmers/${params.order_farmer_id}/delivered`, params);

    return response.data;
}


const orderFarmerService = {
    orderFarmerStore,
    orderFarmerUpdate,
    orderFarmerShow,
    orderFarmerIndex,
    orderFarmerUpdateByToken,
    orderFarmerPlace,
    orderFarmerReceivableForFarmer,
    orderFarmerMadeByCustomer,
    orderFarmerDeliveryDecline,
    orderFarmerDeliveryAccept,
    orderFarmerDeliveryStart,
    orderFarmerDeliveryDone,
    bookingPlotDetail,
    confirmOrderQuantity,
    cancelOrder,
    printAwb,
    getStatesList,
    payOrder,
    orderFarmerGetByOrderNumber,
    generateInvoiceNumber,
    uploadPaymentReceipt,
    confirmOfflinePayment,
    downloadInvoice,
    orderFarmerInDelivery,
    orderFarmerDelivered,
}


export default orderFarmerService;