import  api from "../../support/support_api";
import storage from "../../support/support_storage"
const forgotPassword = async (credentials) => {

   let response = await api.axiosPost('/users/forgot-password', credentials)
   return response.data;
}

const forgotPasswordService = {
    forgotPassword,
}

export default forgotPasswordService;