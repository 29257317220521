import api from "../../support/support_api";

const userFarmerFarms = async (params) => {
    const response  = await  api.axiosGet(`/users/farmer/${params.farmer_id}/farms`, params);

    return response.data;
}

// const userAll = async (params) => {
//     const response  = await  api.axiosGet(`/user_all`, params);

//     return response.data;
// }

const userIndex = async (params) => {
    const response  = await api.axiosGet(`/users`, params);

    return response.data;
}

const userShow = async (params) => {
    const response  = await api.axiosShow(`/users/${params.user_id}`, params);

    return response.data;
}

const userUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/users/${params.user_id}/userUpdate`, params);

    return response.data;
}

const userDelete = async (params) => {
    const response  = await  api.axiosDelete(`/users/${params.user_id}`);

    return response.data;
}

const userStatusUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/users/${params.user_id}/userStatusUpdate`, params);

    return response.data;
}

const notificationIndex = async (params) => {
    const response  = await api.axiosGet(`/notifications`, params);

    return response.data;
}

// const farmStore = async (params) => {
//     const response  = await  api.axiosPost('/farms', params);
//
//     return response.data;
// }

// const farmUpdate = async (params) => {
//     const response  = await  api.axiosPatch(`/farms/${params.farm_id}`, params);
//
//     return response.data;
// }
//
// const farmUploadPhotos = async (params) => {
//     const response  = await  api.axiosPatch(`/farms/${params.farm_id}`, params);
//
//     return response.data;
// }
//
// const farmShow = async (params) => {
//     const response  = await api.axiosShow(`/farms/${params.farm_id}`, params);
//
//     return response.data;
// }
//
// const farmIndex = async (params) => {
//     const response  = await api.axiosGet(`/farms`, params);
//
//     return response.data;
// }
//
const userService = {
    userFarmerFarms, userIndex, userShow, userUpdate, userDelete, userStatusUpdate, notificationIndex
    // farmStore, farmUpdate, farmUploadPhotos, farmShow, farmIndex
}

export default userService;