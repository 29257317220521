import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  authService from "./authService";

export const getAuth = createAsyncThunk(
    "auth/users",
    async (getItem, thunkAPI) => {
        try {
            return await authService.auth(getItem);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const authUpdate = createAsyncThunk(
    "auth/users/update",
    async (user, thunkAPI) => {
        try {
            return await authService.authUpdate(user);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const authUpdateCompany = createAsyncThunk(
    "auth/users/company/update",
    async (company, thunkAPI) => {
        try {
            return await authService.authUpdateCompany(company);

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const authUpdateCompanyPhoto = createAsyncThunk(
    "auth/users/company/update-photo",
    async (company, thunkAPI) => {
        try {
            return await authService.authUpdateCompanyPhoto(company);

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const authUpdatePhoto = createAsyncThunk(
    "auth/users/update-photo",
    async (params, thunkAPI) => {
        try {
            return await authService.authUpdatePhoto(params);

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const authRoleActiveChange = createAsyncThunk(
    "auth/users/update",
    async (params, thunkAPI) => {
        try {
            return await authService.authRoleActiveChange(params);

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const fcmTokenUpdate = createAsyncThunk(
    "users/update-fcm-token",
    async (params, thunkAPI) => {
        try {
            return await authService.fcmTokenUpdate(params);

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const authGetUser = createAsyncThunk(
    "users/get/current-user-info",
    async (params, thunkAPI) => {
        try {
            return await authService.authGetUser(params);

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)
export const authGetCompany = createAsyncThunk(
    "auth/users/company/authenticated",
    async (params, thunkAPI) => {
        try {
            return await authService.authGetCompany(params);

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getStatesList = createAsyncThunk(
    "states/list",
    async (params, thunkAPI) => {
        try {
            return await authService.getStatesList(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const storeAddress = createAsyncThunk(
    "users/store/userAddress",
    async (params, thunkAPI) => {
        try {
            return await authService.storeAddress(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getAddress = createAsyncThunk(
    "users/get/userAddress",
    async (params, thunkAPI) => {
        try {
            return await authService.getAddress(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getBankDetail = createAsyncThunk(
    "users/get/bankDetail",
    async (params, thunkAPI) => {
        try {
            return await authService.getBankDetail(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const storeBankDetail = createAsyncThunk(
    "users/store/bankDetail",
    async (params, thunkAPI) => {
        try {
            return await authService.storeBankDetail(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)


const initialState = {
    // isLoggedIn:true,
    data: {},
    loading: false,
    isSuccess: false,
    currentUser: {},
    isLoggedIn: false,
    currentCompany: {},
    currentRoles: [],
    _rememberMe: {
        username:'',
        password:'',
    },
    token: '',
    updating: false,
    notif_count: 0,
    fcmKey: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentUser: (state, newUser) => {
            alert(" set current user ", newUser);

            // state.currentUser = newUser.payload;

            let user = JSON.parse(localStorage.getItem("user"));

            user.data.user = newUser.payload;

            state.currentUser = user.data.user;

            localStorage.setItem("user", JSON.stringify(user));
        },
        setCurrentCompany: (state, newCompany) => {
            let user = JSON.parse(localStorage.getItem("user"));

            user.data.company = newCompany.payload;

            state.currentCompany = user.data.company;

            localStorage.setItem("user", JSON.stringify(user));
        },
        setCurrentPhoto: (state, newCompany) => {
            // state.currentCompany = newCompany.payload;

            let user = JSON.parse(localStorage.getItem("user"));

            user.data.user.photo = newCompany.payload;

            state.currentCompany = user.data.user;

            localStorage.setItem("user", JSON.stringify(user));
        },
        getCurrentUserInfo: (state) => {
            let user = JSON.parse(localStorage.getItem("user"));

            if(user === null) {
                state.token = '';
                state.currentUser = {};
                state.currentCompany = {};
                state.currentRoles = [];
                state.isLoggedIn = false;
            }
            else {
                state.token = user.data.token;
                state.currentUser = user.data.user;
                state.currentCompany = user.data.company;
                state.currentRoles = user.data.roles;
                state.isLoggedIn = true;
            }
        },

        setAuthRememberMe: (state, credential) => {
            localStorage.setItem("_rememberMe", JSON.stringify(credential));
        },

        getAuthRememberMe: (state) => {
            let _rememberMe = JSON.parse(localStorage.getItem("_rememberMe"));

            if(_rememberMe) {
                state._rememberMe = _rememberMe.payload;
            }
        },
        updateNotifFirebase:(state, action) => {
            state.notif_count = parseInt(state.notif_count || 0) +  parseInt(action.payload || 0);
        },
        updateFCMKey:(state, action) => {
            state.fcmKey = action.payload;
        }
    },
    extraReducers : {
        [getAuth.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [getAuth.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [getAuth.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
        [authUpdate.pending]:(state, {payload}) => {
            state.updating = true;
        },
        [authUpdate.fulfilled]:(state, {payload}) => {

            state.updating = false;

            state.currentUser = payload.data.user;

            let user = JSON.parse(localStorage.getItem("user"));

            user.data.user = payload.data.user;

            localStorage.setItem("user", JSON.stringify(user));
        },
        [authUpdate.rejected]:(state, {payload}) => {
            state.updating = false;
        },

        [authUpdateCompany.pending]:(state, {payload}) => {
            state.updating = true;
        },
        [authUpdateCompany.fulfilled]:(state, {payload}) => {
            state.updating = false;

            let user = JSON.parse(localStorage.getItem("user"));

            user.data.company = payload.data.company;

            state.currentCompany = payload.data.company;

            localStorage.setItem("user", JSON.stringify(user));
        },
        [authUpdateCompany.rejected]:(state, {payload}) => {
            state.updating = false;
        },

        [authUpdatePhoto.pending]:(state, {payload}) => {
            state.updating = true;
        },
        [authUpdatePhoto.fulfilled]:(state, {payload}) => {
            state.updating = false;

            state.currentUser = payload.data.user;

            let user = JSON.parse(localStorage.getItem("user"));

            user.data.user = payload.data.user;

            localStorage.setItem("user", JSON.stringify(user));
        },
        [authUpdatePhoto.rejected]:(state, {payload}) => {
            state.updating = false;
        },


        [authUpdateCompanyPhoto.pending]:(state, {payload}) => {
            state.updating = true;
        },
        [authUpdateCompanyPhoto.fulfilled]:(state, {payload}) => {
            state.updating = false;

            let user = JSON.parse(localStorage.getItem("user"));

            user.data.company = payload.data.company;

            state.currentCompany = payload.data.company;

            localStorage.setItem("user", JSON.stringify(user));
        },
        [authUpdateCompanyPhoto.rejected]:(state, {payload}) => {
            state.updating = false;
        },
        [authGetCompany.pending]:(state, {payload}) => {
            state.updating = true;
        },
        [authGetCompany.fulfilled]:(state, {payload}) => {
            state.updating = false;

            let user = JSON.parse(localStorage.getItem("user"));

            user.data.company = payload.data.company;

            state.currentCompany = payload.data.company;

            localStorage.setItem("user", JSON.stringify(user));
        },
        [authGetCompany.rejected]:(state, {payload}) => {
            state.updating = false;
        },

        [authGetUser.pending]:(state, {payload}) => {
            state.updating = true;
        },
        [authGetUser.fulfilled]:(state, {payload}) => {
            state.updating = false;
            state.token = payload?.data?.token;
            state.currentUser = payload?.data?.user;
            state.currentCompany = payload?.data?.company;
        },
        [authGetUser.rejected]:(state, {payload}) => {
            state.updating = false;
        },

        [getStatesList.pending]:(state, {payload}) => {
            state.updating = true;
        },
        [getStatesList.fulfilled]:(state, {payload}) => {
            state.updating = false;
        },
        [getStatesList.rejected]:(state, {payload}) => {
            state.updating = false;
        },

        [storeAddress.pending]:(state, {payload}) => {
            state.updating = true;
        },
        [storeAddress.fulfilled]:(state, {payload}) => {
            state.updating = false;
        },
        [storeAddress.rejected]:(state, {payload}) => {
            state.updating = false;
        },

        [getAddress.pending]:(state, {payload}) => {
            state.updating = true;
        },
        [getAddress.fulfilled]:(state, {payload}) => {
            state.updating = false;
        },
        [getAddress.rejected]:(state, {payload}) => {
            state.updating = false;
        },

        [getBankDetail.pending]:(state, {payload}) => {
            state.updating = true;
        },
        [getBankDetail.fulfilled]:(state, {payload}) => {
            state.updating = false;
        },
        [getBankDetail.rejected]:(state, {payload}) => {
            state.updating = false;
        },

        [storeBankDetail.pending]:(state, {payload}) => {
            state.updating = true;
        },
        [storeBankDetail.fulfilled]:(state, {payload}) => {
            state.updating = false;
        },
        [storeBankDetail.rejected]:(state, {payload}) => {
            state.updating = false;
        },
    }
})

export const {
    getCurrentUserInfo,
    setCurrentUser,
    setCurrentCompany,
    setCurrentPhoto,
    setAuthRememberMe,
    getAuthRememberMe,
    updateNotifFirebase,
    updateFCMKey
} = authSlice.actions

export default authSlice;