import React from 'react';
import { useState } from 'react';
import styles from './CustomSlider.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faClose, faCross } from '@fortawesome/free-solid-svg-icons';
import PopupLayout from 'pages/PopupLayout';

export default function PopupImageSlider(props) {

  const [imageIndex, setImageIndex] = useState(1);

  const nextSlide = () => {
    if (imageIndex !== props.photos.data.length) {
      setImageIndex(imageIndex + 1)
    } else if (imageIndex === props.photos.data.length) {
      setImageIndex(1)
    }
  }

  const prevSlide = () => {
    if (imageIndex !== 1) {
      setImageIndex(imageIndex - 1)
    } else if (imageIndex === 1) {
      setImageIndex(props.photos.data.length)
    }
  }

  const closePopUp = () => {
    props.setIsShowPopupImage(false);
  }

  const changeImage = (i) => {
    setImageIndex(i);
  }

  return (
    <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
      <section
        data-testid="popup-layout"
        className="absolute bg-formLight flex flex-col rounded-xl shadow"
      >


        <div className={styles.sliderContainer}>

          {props.photos.data.length > 0 ? props.photos.data.map((obj, index) => {
            return (
              <div
                key={index}
                className={imageIndex === index + 1 ? `${styles.slide}${styles.active}` : `${styles.slide}`}
              >
                <img
                  src={obj.src}
                  alt="product"
                  className={styles.sliderImage}
                />
                {/* <div className={styles.sliderImage_description}>
                  <p>{obj.description ? obj.description_short : "No description"}</p>
                </div> */}
              </div>
            )
          }) : <img src={props.defaultPhoto} className={styles.sliderImage} alt="default" />}

          {props.photos?.data.length > 0 ? (<><button onClick={prevSlide} className={styles.buttonLeft}>
            <FontAwesomeIcon icon={faChevronLeft} size="3x" className={"text-gray-400"} />
          </button>
            <button onClick={nextSlide} className={styles.buttonRight}>
              <FontAwesomeIcon icon={faChevronRight} size="3x" className={"text-gray-400"} />
            </button></>) : null}

          <button
            onClick={() => {
              closePopUp();
            }}
            className={styles.buttonRightCross}>
            <FontAwesomeIcon icon={faClose} size="2x" color='black' />
          </button>


          <div className={styles.dotContainer}>
            {props.photos.data && Array.from({ length: props.photos.data.length }).map((dot, index) => (
              <div
                onClick={() => changeImage(index + 1)}
                className={imageIndex === index + 1 ? `${styles.imageDot} ${styles.dotActive}` : `${styles.imageDot}`}
              >

              </div>
            ))}
          </div>

        </div>
      </section>
    </div>
  )
}