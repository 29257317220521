import { useState, useEffect, useContext } from "react";

import profileIcon from "../../images/ProfileIcon.png";
import uploadIcon from "../../images/UploadIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Container, Col, Row, Modal, InputGroup, Form } from "react-bootstrap";
import {
    getCurrentUserInfo,
    setCurrentUser,
    authUpdate,
    authUpdateCompany,
    setCurrentCompany,
    authUpdatePhoto,
    authUpdateCompanyPhoto,
    authGetCompany,
    getStatesList,
    storeAddress,
    getAddress,
} from "../../features/auth/authSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { fileDelete } from "features/file/fileSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PickMapLocation from "components/map/PickMapLocation";
import { useNavigate, useParams } from "react-router-dom";

const AccountAddress = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { currentUser, updating, currentCompany } = useSelector((state) => state.auth);
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [statesList, setStatesList] = useState([]);
    const [stateName, setStateName] = useState(null);
    const [city, setCity] = useState(null);
    const [postCode, setPostCode] = useState(null);
    const [address, setAddress] = useState(null);
    const [fullname, setFullname] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);

    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);

    const submitHandler = (event) => {
        dispatch(
            storeAddress({
                address: {
                    fullname: fullname,
                    email: email,
                    phone: phone,
                    country: 'Malaysia',
                    state: stateName,
                    city: city,
                    postcode: postCode,
                    address: address,
                    lat: latitude,
                    lon: longitude
                }
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure");
            } else {
                let result = response.payload.data.user_address;
                let address = result.address;
                setFullname(address.fullname);
                setEmail(address.email);
                setPhone(address.phone);
                setStateName(address.state);
                setCity(address.city);
                setPostCode(address.postcode);
                setAddress(address.address);
                setLatitude(address.lat);
                setLongitude(address.lon);
                snackbarCtx.displayMsg(response.payload.message, "success");
                if (params.edit_type != undefined) {
                    if (params.edit_type == "Address") {
                        navigate(-1);
                    }
                }
            }
        });

        event.preventDefault();
    };

    const loadStates = () => {
        dispatch(
            getStatesList()
        ).then(function (response) {

            if (response.error && response.error.message == "Rejected") {
            } else {
                let states = response.payload.data.states;
                setStatesList(states);
            }
        });
    };

    const loadAddress = () => {
        dispatch(
            getAddress()
        ).then(function (response) {

            if (response.error && response.error.message == "Rejected") {
            } else {
                let result = response.payload.data.user_address;
                if (result) {
                    let address = result.address;
                    setFullname(address.fullname);
                    setEmail(address.email);
                    setPhone(address.phone);
                    setStateName(address.state);
                    setCity(address.city);
                    setPostCode(address.postcode);
                    setAddress(address.address);
                    setLatitude(address.lat);
                    setLongitude(address.lon);
                } else {
                    setLatitude(1.966773);
                    setLongitude(102.881449);
                }
                // let address = result.address;
                // setFullname(address.fullname);
                // setEmail(address.email);
                // setPhone(address.phone);
                // setStateName(address.state);
                // setCity(address.city);
                // setPostCode(address.postcode);
                // setAddress(address.address);
                // setLatitude(address.lat);
                // setLongitude(address.lon);
            }
        });
    };

    useEffect(() => {
        loadStates();
        loadAddress();
    }, []);

    return (
        <div>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={12} className="z-10">
                            {(latitude && longitude) ?
                                <>
                                    <PickMapLocation
                                        curentLatMap={latitude}
                                        curentLngMap={longitude}
                                        curentLatMark={latitude}
                                        curentLngMark={longitude}
                                        saveLat={setLatitude}
                                        saveLng={setLongitude}
                                    />
                                </>
                                : ""}
                        </Col>
                    </Row>
                    {/* <Row className="mt-2">
                        <Col md={12}>
                            <button type="submit" className="saveButton">
                                {updating ? "Processing, please wait.." : "Set Pin Location"}
                            </button>
                        </Col>
                    </Row> */}
                </Col>
                <Col md={6}>
                    <Row>
                        <Col md={6}>
                            <label className="text-dark-green-sm">Fullname</label>
                            <br />
                            <input
                                type="text"
                                className="input-search"
                                name="fullname"
                                placeholder="Fullname"
                                value={fullname}
                                onChange={({ target }) => setFullname(target.value)}
                            />
                        </Col>
                        <Col md={6}>
                            <label className="text-dark-green-sm">Email</label>
                            <br />
                            <input
                                type="email"
                                className="input-search"
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={({ target }) => setEmail(target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label className="text-dark-green-sm">Phone</label>
                            <br />
                            <input
                                type="number"
                                className="input-search"
                                name="phone"
                                placeholder="Phone"
                                value={phone}
                                onChange={({ target }) => setPhone(target.value)}
                            />
                        </Col>
                        <Col md={6}>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={12}>
                            <label className="text-dark-green-sm">Address</label>
                            <br />
                            <textarea
                                // type="text"
                                className="input-search"
                                name="address"
                                placeholder="Address"
                                value={address}
                                onChange={({ target }) => setAddress(target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label className="text-dark-green-sm">State</label>
                            <br />
                            <select className="input-search" defaultValue={null} value={stateName} onChange={({ target }) => { setStateName(target.value); }}>
                                <option key={-1} value="">
                                    Select State..
                                </option>
                                {
                                    statesList.map((state, key) => <option key={state.id} value={state.name}>{state.name}</option>)
                                }
                            </select>
                        </Col>
                        <Col md={6}>
                            <label className="text-dark-green-sm">City</label>
                            <br />
                            <input
                                type="text"
                                className="input-search"
                                name="city"
                                placeholder="City"
                                value={city}
                                onChange={({ target }) => setCity(target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label className="text-dark-green-sm">Postcode</label>
                            <br />
                            <input
                                type="number"
                                className="input-search"
                                name="postcode"
                                placeholder="Postcode"
                                value={postCode}
                                onChange={({ target }) => setPostCode(target.value)}
                            />
                        </Col>
                        <Col md={6}>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={12}>
                            <button type="submit" className="saveButton" onClick={submitHandler}>
                                {updating ? "Processing, please wait.." : "Save"}
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default AccountAddress;
