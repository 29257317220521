import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import starIcon from "./../../images/StarIcon.png";
import { faAnglesLeft, faArrowLeft, faCheck, faMinus, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";

const CatalogSidebar = ({ search, filterHandler, setShowSideBar }) => {

  const [isFruits, setIsFruits] = useState(true);
  const [isVegetables, setIsVegetables] = useState(true);

  return (
    <div
      data-testid="product-catalog-sidebar"
      className="pt-3"
    >
      <div className="flex justify-between">
        <div>
          <span className="title-sidebar">
            Filters
          </span>
        </div>
        <div>
          <FontAwesomeIcon onClick={() => { setShowSideBar(false) }} icon={faAnglesLeft} className={"text-icon-color-green cursor-pointer"} />
        </div>
      </div>

      {/* filter location */}
      <div className="mt-4">
        <div>
          <span className="subtitle-sidebar">
            Farm Location
          </span>
        </div>
        <div className="mt-1">
          <div className="flex gap-x-4">
            <input
              type="checkbox"
              // value={accept}
              onClick={() => {
                // setAccept(!accept);
              }}
            />
            <p className="text-xs">
              West Malaysia
            </p>
          </div>
        </div>
        <div className="mt-1">
          <div className="flex gap-x-4">
            <input
              type="checkbox"
              // value={accept}
              onClick={() => {
                // setAccept(!accept);
              }}
            />
            <p className="text-xs">
              East Malaysia
            </p>
          </div>
        </div>
        <div className="mt-1">
          <div className="flex gap-x-4">
            <input
              type="checkbox"
              // value={accept}
              onClick={() => {
                // setAccept(!accept);
              }}
            />
            <p className="text-xs">
              Selangor
            </p>
          </div>
        </div>
      </div>

      <div className="mt-4 mb-4">
        <hr />
      </div>

      {/* Price Range */}
      <div>
        <div className="mt-2">
          <span className="subtitle-sidebar">
            Price Range
          </span>
        </div>
        <div className="mt-1">
          <div className="row mr-1">
            <div className="col-md-5 col-sm-5 mb-3">
              <input
                style={{ width: "100%" }}
                type="search"
                className="input-search"
                placeholder="Min (RM)"
              // value={search.input}
              // onChange={({ target }) => inputHandler(target.value)}
              />
            </div>
            <div className="col-md-5 col-sm-5 mb-3">
              <input
                style={{ width: "100%" }}
                type="search"
                className="input-search"
                placeholder="Max (RM)"
              // value={search.input}
              // onChange={({ target }) => inputHandler(target.value)}
              />
            </div>
            <div className="col-md-2 col-sm-2">
              <button className="btn-home btn-browser-product" style={{ marginTop: "0px", width: "35px", height: "35px" }}>
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </div>

          </div>
        </div>
      </div>

      <div className="mt-4 mb-4">
        <hr />
      </div>

      {/* filter Category */}
      <div>
        <div className="mt-2">
          <span className="subtitle-sidebar">
            Category
          </span>
        </div>
        <div className="mt-1">
          <div className="flex gap-x-4">
            <input
              type="checkbox"
              // value={isFruits}
              defaultChecked={isFruits}
              onClick={() => {
                if (!isFruits && isVegetables) {
                  filterHandler("None");
                } else {
                  if (!isFruits) {
                    filterHandler("Fruits");
                  } else {
                    if (isVegetables) {
                      filterHandler("Vegetables");
                    } else {
                      filterHandler(search.selectedFilter);
                    }
                  }
                }
                setIsFruits(!isFruits);
              }}
            />
            <p className="text-xs">
              Fruits
            </p>
          </div>
        </div>
        <div className="mt-1">
          <div className="flex gap-x-4">
            <input
              type="checkbox"
              defaultChecked={isVegetables}
              // value={accept}
              onClick={() => {
                if (isFruits && !isVegetables) {
                  filterHandler("None");
                } else {
                  if (!isVegetables) {
                    filterHandler("Vegetables");
                  } else {
                    if (isFruits) {
                      filterHandler("Fruits");
                    } else {
                      filterHandler(search.selectedFilter);
                    }
                  }
                }
                setIsVegetables(!isVegetables);
              }}
            />
            <p className="text-xs">
              Vegetables
            </p>
          </div>
        </div>
      </div>

      {/* <div className="mt-4 mb-4">
        <hr />
      </div> */}

      {/* filter Minimum Rating */}
      {/* <div>
        <div className="mt-2">
          <span className="subtitle-sidebar">
            Minimum Rating
          </span>
        </div>
        <div className="mt-1">
          <div className="flex gap-x-4">
            <ol className="flex">
              <li><img src={starIcon} className="img-star-icon" /></li>
              <li><img src={starIcon} className="img-star-icon" /></li>
              <li><img src={starIcon} className="img-star-icon" /></li>
              <li><img src={starIcon} className="img-star-icon" /></li>
              <li><img src={starIcon} className="img-star-icon" /></li>
            </ol>
          </div>
        </div>
      </div> */}

      <div className="mt-4 mb-4">
        <hr />
      </div>

      <div>
        <div className="mt-2">
          <span className="subtitle-sidebar">
            Min Amount
          </span>
        </div>
        <div className="mt-1">
          <input
            type="search"
            className="input-search"
            placeholder="Input amount"
          // value={search.input}
          // onChange={({ target }) => inputHandler(target.value)}
          />
        </div>
      </div>

      {/* <label className="text-2xl">Filters</label>
      <section className="flex flex-col gap-3">
        <label className="text-xl">Product Categories</label>
        <label className="cursor-pointer text-lg hover:font-medium" onClick={() => filterHandler("None")}>Clear Filter</label>
        <section className="w-fit flex flex-col gap-2">
          <label className="flex items-center cursor-pointer text-lg gap-1" onClick={() => filterHandler("Fruits")}>
            <h3 className={search.selectedFilter === "Fruits" ? "font-medium" : "hover:font-medium"}>Fruits</h3>
            <img src="/assets/images/FruitsIcon.svg" />
          </label>
          <ul className="w-fit flex flex-col text-sm hidden">
            {search.filters.fruits.map((fruit, index) => (
              <li
                className={search.selectedFilter === fruit ? "cursor-pointer font-medium" : "cursor-pointer hover:font-medium"}
                onClick={() => filterHandler(fruit)}
                key={index}
              >
                {fruit}
              </li>
            ))}
          </ul>
        </section>
        <section className="w-fit flex flex-col gap-2">
          <label
            className="flex items-center cursor-pointer text-lg gap-1"
            onClick={() => filterHandler("Vegetables")}
          >
            <h3 className={search.selectedFilter === "Vegetables" ? "font-medium" : "hover:font-medium"}>Vegetables</h3>
            <img src="/assets/images/VegetablesIcon.svg" />
          </label>
          <ul className="w-fit flex flex-col text-sm hidden">
            {search.filters.vegetables.map((vegetable, index) => (
              <li
                className={search.selectedFilter === vegetable ? "cursor-pointer font-medium" : "cursor-pointer hover:font-medium"}
                onClick={() => filterHandler(vegetable)}
                key={index}
              >
                {vegetable}
              </li>
            ))}
          </ul>
        </section>
      </section> */}
    </div>
  );
};

export default CatalogSidebar;
