import { useNavigate } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";

const ProfileMenuFarmer = () => {
    const navigate = useNavigate();

    return (
        <>
            <div

                className="unborderedButton"
                style={!window.location.pathname.includes(routesPaths.MANAGE_CROP) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
                onClick={() => {
                    navigate(routesPaths.MANAGE_CROP);
                }}
            >
                Manage Crop
            </div>
            <div

                className="unborderedButton"
                style={!window.location.pathname.includes(routesPaths.PRODUCTS) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
                onClick={() => {
                    navigate(routesPaths.PRODUCTS);
                }}
            >
                Manage Products
            </div>
            <div

                className="unborderedButton"
                style={!window.location.pathname.includes("farms") ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
                onClick={() => {
                    navigate(routesPaths.FARMS);
                }}
            >
                Manage Farms
            </div>
            <div

                className="unborderedButton"
                style={!window.location.pathname.includes(routesPaths.ORDER_FARMERS)
                    && !window.location.pathname.includes(routesPaths.MANAGE_ADVANCE_BOOKING)
                    && !window.location.pathname.includes(routesPaths.MANAGE_AUCTION_BIDDING)
                    ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
                onClick={() => {
                    // navigate(routesPaths.MANAGE_ADVANCE_BOOKING);
                    navigate(routesPaths.ORDER_FARMERS);
                }}
            >
                Manage Orders
            </div>
            <div

                className="unborderedButton"
                style={!window.location.pathname.includes(routesPaths.DEMANDS_FARMER_APPLICATIONS) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
                onClick={() => {
                    navigate(routesPaths.DEMANDS_FARMER_APPLICATIONS);
                }}
            >
                My Applications
            </div>

        </>
    );
};

export default ProfileMenuFarmer;
