import React, { useState, useEffect, useContext } from "react";
import PageLayout from "pages/AccountLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { demandDelete, demandShow, demandStore, demandUpdate } from "../../features/demand/demandSlice";
import { fileUpload } from "../../features/file/fileSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import SliderDemand from "components/files/SliderDemand/SliderDemand";
import PopupImageSlider from "components/catalogs/PopupImageSlider";
import { companyCropAll } from "features/company-crop/companyCropSlice";
import { productAll, productShow } from "features/product/productSlice";

const DemandEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const { allProducts, product } = useSelector((state) => state.product);
  const { allCompanyCrops, companyCrop } = useSelector((state) => state.companyCrop);
  const reduxDemand = useSelector((state) => state.demand);
  const [productList, setProductList] = useState(allProducts || []);
  const [productId, setProductId] = useState("");
  const [productDemand, setProductDemand] = useState("");
  const [name, setName] = useState("");
  const [frequency, setFrequency] = useState("");
  const [selectableFrequencies, setSelectableFrequencies] = useState([{ name: "once" }, { name: "weekly" }]);
  const [selectableFrequencyDays, setSelectableFrequencyDays] = useState([
    { name: "monday" },
    { name: "tuesday" },
    { name: "wednesday" },
    { name: "thursday" },
    { name: "friday" },
    { name: "saturday" },
    { name: "sunday" },
  ]);
  const [frequencyDay, setFrequencyDay] = useState("");
  const [isShowPopupImage, setIsShowPopupImage] = useState(false);
  const [requirementOpenDate, setRequirementOpenDate] = useState("");
  const [requirementCloseDate, setRequirementCloseDate] = useState("");
  const [deliveryOneOf, setDeliveryOneOf] = useState("");
  const [photosNew, setPhotosNew] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [quantityRequired, setQuantityRequired] = useState("");
  const [cropId, setCropId] = useState("");
  const [gradeId, setGradeId] = useState("");
  const [photos, setPhotos] = useState([]);
  const [inputElementPhoto, setInputElementPhoto] = useState("");

  const remove = (event) => {
    if (window.confirm("Are you sure you want to delete this demand?")) {
      dispatch(demandDelete({
        demand_id: params.demand_id
      })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure")
        } else {
          snackbarCtx.displayMsg(response.payload.message, "success")

          navigate(`/demands`);
        }
      });
    }

    event.preventDefault();
  }

  const save = (event) => {
    dispatch(
      demandUpdate({
        demand_id: params.demand_id,
        name: name,
        grade_id: gradeId,
        crop_id: cropId,
        category_id: categoryId,
        quantity_required: quantityRequired,
        frequency: frequency,
        frequency_day: frequencyDay,
        delivery_one_off: deliveryOneOf,
        requirement_open_date: requirementOpenDate,
        requirement_close_date: requirementCloseDate,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        navigate(`/demands`);
      }
    });

    event.preventDefault();
  };

  const uploadImg = () => {
    inputElementPhoto.click();
  }

  const loadProduct = productId => {
    dispatch(
      productShow({
        product_id: productId,
      })
    );
  };

  const loadDemand = () => {
    dispatch(
      demandShow({
        demand_id: params.demand_id,
      })
    ).then(function (response) {
      let demand = response.payload.data.demand;

      setPhotos(demand.photos.data);
      setPhotosNew(demand.photos);
      setName(demand?.name);
      setCategoryId(demand.category_id);
      setDeliveryOneOf(demand.delivery_one_off);
      setFrequency(demand.frequency);
      setGradeId(demand.grade_id);
      setQuantityRequired(demand.quantity_required);
      setRequirementCloseDate(demand.requirement_close_date);
      setRequirementOpenDate(demand.requirement_open_date);
      setFrequencyDay(demand.frequency_day);
      setProductDemand(demand?.product);
      setProductId(demand?.product?.id);
      setCropId(demand?.crop?.id);

      loadProduct(demand?.product?.id);
    });
  };

  const loadCompanyCrops = () => {
    dispatch(
      companyCropAll()
    )
  }

  const selectCropVariety = cropId => {
    setCropId(cropId);

    let product = allProducts?.data.filter(product => product?.company_crop?.id === cropId);

    if (product.length === 1) {
      setProductDemand(product[0]);
      setName(product[0]?.name);
      setProductId(product[0]?.id);
      setGradeId(product[0]?.grade_products[0]?.id)
    }

    setProductList(product);
  }

  const selectProduct = productId => {
    setProductId(productId);

    let product = productList?.find(product => product?.id === productId);
    setName(product[0]?.name);

    if (product?.grade_products.length === 1) {
      setGradeId(product[0]?.grade_products[0]?.id)
    }

    setProductDemand(product);
  }

  const uploadPhotos = (event) => {
    let demandId = params.demand_id;
    let length = event.target.files.length;
    for (let i = 0; i < length; i++) {
      let selectedFile = event.target.files[i];

      const formData = new FormData();

      formData.append("file", selectedFile, selectedFile.name);
      formData.append("store", "multiple");
      formData.append("model", "demand");
      formData.append("type", "photo");
      formData.append("model_id", demandId);

      dispatch(fileUpload(formData)).then(function (response) {
        if (response.error && response.error.message == "Rejected") {
          alert(response.payload);
        } else {
          let photos = response.payload.data.demand.photos.data;

          setPhotos(photos);
          setPhotosNew(response.payload.data.demand.photos);
        }
      });
    }
  };

  const loadProducts = () => {
    dispatch(
      productAll()
    ).then(function (response) {
      if (!response.error) {
        let product = response?.payload?.data?.productsAll?.data.filter(product => product?.company_crop?.id === cropId);
        setProductList(product);
      }
    });
  };

  useEffect(() => {
    loadProducts();
    loadDemand();
    loadCompanyCrops();
  }, []);

  useEffect(() => {
    if (frequency !== "once") {
      setDeliveryOneOf("");
    } else {
      setFrequencyDay("");
    }
  }, [frequency]);

  return (
    <PageLayout>
      <div
        data-testid="demand-edit"
        className="flex flex-col items-start"
      >
        {(isShowPopupImage && photos) && <PopupImageSlider defaultPhoto={photos[0]} photos={photosNew} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} />}

        {/* Actual Component */}
        <div className="w-full flex flex-col p-4 laptop:p-6 wide:p-6 mb-5">
          <div className="head-bar-back">
            <section>
              <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
                {" Go Back"}
              </label>
            </section>
            <div className="mb-2">
              <label className="title-content">
                Edit Demand
              </label>
            </div>
          </div>

          <div className="flex flex-col rounded-lg shadow bg-card-white-new">
            <form>
              <Row>
                {/* image  */}
                <Col md={6}>
                  <section className="w-full  place-self-center laptop:self-start flex flex-col">
                    <SliderDemand photos={photos} reloadData={loadDemand} uploadImg={uploadImg} setIsShowPopupImage={setIsShowPopupImage} />
                    <input
                      className={"hidden"}
                      type="file"
                      accept="image/png, image/jpeg"
                      multiple
                      onChange={uploadPhotos}
                      ref={(input) => setInputElementPhoto(input)}
                    />
                  </section>
                </Col>

                <Col md={6} className="padding-form-market">
                  <div className="self-center w-full flex flex-col gap-4">
                    <div className="w-full flex flex-col gap-4">
                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Crop & Variety
                        </Col>
                        <Col md={8}>
                          <select
                            className="input-search"
                            value={cropId || ''}
                            onChange={({ target }) => selectCropVariety(parseInt(target.value))}
                          >
                            <option value="" disabled={true}>Select Crop</option>
                            {allCompanyCrops && allCompanyCrops?.data.map((crop, index) => {
                              return <option value={parseInt(crop?.id)} key={index}>{crop.master_crop?.name} - {crop.variety}</option>;
                            })}
                          </select>
                        </Col>
                      </Row>
                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Products
                        </Col>
                        <Col md={8}>
                          <select className={productList.length > 0 && allProducts?.data.length > 0 ? "input-search" : "bg-gray-300 input-search"} value={productId || product?.id} disabled={productList.length === 0} onChange={({ target }) => selectProduct(parseInt(target.value))} placeholder={productList.length > 0 ? "Select product" : "No product available under this crop"}>
                            {productList.length === 0 && <option>
                              {productList.length > 0 ? "Select product" : "No product available under this crop"}
                            </option>}
                            {productList.length > 0 && productList.map((product, index) => {
                              return <option value={parseInt(product?.id)} key={index}>{product?.name}</option>;
                            })}
                            {productList.length === 0 && allProducts?.data.length > 0 && allProducts?.data.filter(product => product?.company_crop?.id === cropId).map((product, index) => {
                              return <option value={parseInt(product?.id)} key={index}>{product?.name}</option>;
                            })}
                          </select>
                        </Col>
                      </Row>
                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Grades
                        </Col>
                        <Col md={8}>
                          <select
                            className={productList.length > 0 ? "input-search" : "bg-gray-300 input-search"}
                            value={gradeId || ''}
                            onChange={({ target }) => setGradeId(target.value)}
                          >
                            {productList.length === 0 && <option>
                              {productList.length > 0 ? "Select grade" : "No grade available under this product"}
                            </option>}
                            {productList.length > 0 && productDemand?.grade_products &&
                              productDemand?.grade_products.map((grade, index) => {
                                return (
                                  <option value={grade.id} key={index}>
                                    {grade.name}
                                  </option>
                                );
                              })}

                            {productList.length === 0 && product?.grade_products &&
                              product?.grade_products.map((grade, index) => {
                                return (
                                  <option value={grade.id} key={index}>
                                    {grade.name}
                                  </option>
                                );
                              })}
                          </select>
                        </Col>
                      </Row>
                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Amount Required
                        </Col>
                        <Col md={8}>
                          <input
                            className="input-search w-2/3"
                            type="number"
                            min={1}
                            placeholder="Amount Required"
                            value={quantityRequired}
                            onChange={({ target }) => setQuantityRequired(target.value)}
                          />
                        </Col>
                      </Row>
                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Frequency
                        </Col>
                        <Col md={8}>
                          <select
                            className="input-search"
                            defaultValue={"DEFAULT"}
                            value={frequency || 'DEFAULT'}
                            onChange={({ target }) => setFrequency(target.value)}
                          >
                            <option value="DEFAULT" disabled>
                              Select Frequency
                            </option>
                            {selectableFrequencies.map((frequency, index) => (
                              <option value={frequency.name} key={index}>
                                {frequency.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                      {frequency === "weekly" && (
                        <Row style={{ placeItems: "center" }}>
                          <Col md={4} className="head-bar-nav">
                            Frequency Day
                          </Col>
                          <Col md={8}>
                            <select
                              className="input-search"
                              defaultValue={"DEFAULT"}
                              value={frequencyDay || 'DEFAULT'}
                              onChange={({ target }) => setFrequencyDay(target.value)}
                            >
                              <option value="DEFAULT" disabled>
                                Select Frequency
                              </option>
                              {selectableFrequencyDays.map((frequencyDay, index) => (
                                <option value={frequencyDay.name} key={index}>
                                  {frequencyDay.name}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                      )}
                      {frequency == "once" && (
                        <Row style={{ placeItems: "center" }}>
                          <Col md={4} className="head-bar-nav">
                            Delivery one off
                          </Col>
                          <Col md={8}>
                            <input
                              className="input-search"
                              type="date"
                              placeholder="Date Requirement Open"
                              value={deliveryOneOf}
                              onChange={({ target }) => setDeliveryOneOf(target.value)}
                            />
                          </Col>
                        </Row>
                      )}

                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Demand Period
                        </Col>
                        <Col md={8}>
                          <Row style={{ placeItems: "center" }}>
                            <Col md={6} sm={6} xs={6}>
                              <input
                                className="input-search"
                                type="date"
                                placeholder="Date Requirement Open"
                                value={requirementOpenDate}
                                onChange={({ target }) => setRequirementOpenDate(target.value)}
                              />
                            </Col>
                            <Col md={6} sm={6} xs={6}>
                              <input
                                className="input-search"
                                type="date"
                                placeholder="Date Requirement Close"
                                value={requirementCloseDate}
                                onChange={({ target }) => setRequirementCloseDate(target.value)}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ placeItems: "center" }}>
                        <Col md={6} xs={5}>
                          <button type="submit" className="saveButton" onClick={save}>
                            {/*Post Demand*/}
                            {reduxDemand.updating ? "Processing, please wait.." : "Save"}
                          </button>


                        </Col>
                        <Col md={6} xs={7}>
                          <button className=" saveButton bg-red-500 hover:bg-red-600 hover:ring-2 hover:ring-red-600" onClick={remove}  >
                            {reduxDemand.updating ? "Processing, please wait.." : "Delete Demand"}
                          </button>
                        </Col>
                      </Row>
                    </div>

                  </div>
                </Col>

              </Row>

            </form>
          </div>

        </div>
      </div>
    </PageLayout>
  );
};

export default DemandEdit;
