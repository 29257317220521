
import "./CompanyDetails.css";

const CompanyDetailsUsers = ({ company }) => {
    return (
        <>
            <div className="mx-4 mb-2 text-2xl font-extrabold text-greenGray">Users</div>
            <div className="flex flex-col rounded-lg shadow bg-white pt-[30px] p-[40px]">
                {
                    company?.users.length > 0 ? company?.users.map((user, key) => <div key={key}><div className="flex items-center space-x-4">
                        <img
                            src={user?.photo?.src}
                            alt="profile"
                            className="rounded-full aspect-square bg-white w-16 h-16 object-cover border-[.01rem] border-green-400"
                        />
                        <div className="font-black">{user?.name}</div>
                    </div>{key > 1 && <label className="underline my-1" />}</div>) : 'No available user under this company'
                }
            </div>
        </>
    );
};

export default CompanyDetailsUsers;


