import ProfileSidebar from "components/profile/ProfileSidebar";
import "./Pages.css";
import Footer from "components/footer/footer";

const AccountLayout = ({ children }) => {
  return (
    <div className="bg-gray-50 grid gap-4 grid-cols-1 items-start tablet:grid-cols-3 tablet:min-h-min laptop:grid-cols-4 laptop:min-h-screen desktop:grid-cols-6 desktop:min-h-screen">
      <ProfileSidebar />
      <div className="col-span-1 items-start tablet:col-span-2 laptop:col-span-3 desktop:col-span-5 desktop:col-start-2">{children}</div>
      <Footer />
    </div>
  );
};

export default AccountLayout;
