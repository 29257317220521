import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/support_api";
import  newPasswordService from "./newPasswordService";

export const newPassword = createAsyncThunk(
    "auth/users/new-password",
    async (credentials, thunkAPI) => {
        try {
            return await newPasswordService.newPassword(credentials);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    loading: false,
    isSuccess: false,
    currentUser: {},
    currentCompany: {},
    token: 'asdasd'
}

export const newPasswordSlice = createSlice({
    name: 'newPassword',
    initialState,
    reducers: {
        //

    },
    extraReducers : {
        [newPassword.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [newPassword.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [newPassword.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

export const { getCurrentUserInfo } = newPasswordSlice.actions

export default newPasswordSlice;