import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import AccountLayout from "pages/AccountLayout";
import Product from "./Product";

import { productIndex } from "../../features/product/productSlice";

import { useDispatch } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { useSelector } from "react-redux/es/index";
import MessageEmpty from "../ui/message/empty/MessageEmpty"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountAsc, faPlus } from '@fortawesome/free-solid-svg-icons';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const ProductList = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);

    const [productList, setProductList] = useState({
        data: []
    })
    const reduxProduct = useSelector((state) => state.product);

    const [itemsPerPage, setItemsPerPage] = useState(10); // Initial value, you can change this
    const [currentPage, setCurrentPage] = useState(1);
    const loadProducts = (pageNumber, itemsPerPage) => {
        dispatch(
            productIndex({
                limit: itemsPerPage,
                page: pageNumber,
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                let products = response.payload.data.products;
                setProductList(products);
            }
        });
    }

    const handlePageChange = (newPageNumber, newItemsPerPage) => {
        setCurrentPage(newPageNumber);
        setItemsPerPage(newItemsPerPage); // Assuming you have an itemsPerPage state variable

        // Now you can call your data loading function with the updated values
        loadProducts(newPageNumber, newItemsPerPage);
    };

    const handleChangePage = (event, newPageNumber) => {
        handlePageChange(newPageNumber, itemsPerPage); // Call the common function
    };

    const handleLimitPage = (newItemsPerPage) => {
        setCurrentPage(1); // Reset the current page to 1
        setItemsPerPage(newItemsPerPage); // Update the itemsPerPage state variable
        loadProducts(1, newItemsPerPage);
    };

    useEffect(() => {
        loadProducts()
    }, []);

    return (
        <AccountLayout>
            <div data-testid="product-list" className="flex flex-col items-start gap-6 p-4 mb-5">
                <h1 className="text-2xl font-bold" style={{ color: "#395B50" }}>My Products</h1>
                {/* <div
                    className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg p-4 space-y-4">
                    <button type="button" className="borderedButton h-[9rem]"
                            onClick={() => navigate("/products/create")}>
                        <label className="cursor-pointer text-2xl">Add Product</label>
                    </button>
                    {productList.data && productList.data.map((product, index) => (
                        <Product product={product} loadProducts={loadProducts} key={index}/>
                    ))}
                </div> */}
                <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
                    {/* <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{borderBottomWidth: "1px", width:"100%"}}>
                        <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
                        Sort  
                        </div>
                        <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
                        <select className="select-custom mr-2">
                            <option value="DATE" selected>
                            Date
                            </option>
                            <option value="DATE" selected>
                            Price
                            </option>
                        </select>
                        <FontAwesomeIcon icon={faSortAmountAsc} />
                        </div>
                        <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
                        Filter  
                        </div>
                        <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
                        <select className="select-custom">
                            <option value="DATE" selected>
                            Farm
                            </option>
                            <option value="DATE" selected>
                            Product
                            </option>
                        </select>
                        </div>
                        
                        <div className="flex items-center tablet:flex justify-start w-full desktop:w-fit">
                        <form className="tablet:flex justify-start w-full desktop:w-[20rem]">   
                            <label for="default-search" className="mb-2 text-sm font-medium text-gray-900  dark:text-white laptop:hidden desktop:hidden">Search</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                </div>
                                <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                            </div>
                        </form>
                        </div>
                        <div className="flex items-center tablet:flex justify-start desktop:w-full justify-end">
                        <button type="button" className="borderedButton tablet:flex justify-start w-full desktop:w-1/6 rounded-md" onClick={() => navigate("/products/create")}>
                            <label className="cursor-pointer text-md">Add Product</label>
                        </button>
                        </div>
                    </div> */}
                    <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                        <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
                            Sort
                        </div>
                        <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/4">
                            <select className="select-custom mr-2">
                                <option value="DATE" selected>
                                    Date
                                </option>
                                <option value="DATE" selected>
                                    Price
                                </option>
                            </select>
                            <FontAwesomeIcon icon={faSortAmountAsc} />
                        </div>
                        <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
                            Filter
                        </div>
                        <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
                            <select className="select-custom ">
                                <option value="DATE" selected>
                                    Farm
                                </option>
                                <option value="DATE" selected>
                                    Product
                                </option>
                            </select>
                        </div>

                        <div className="flex items-center tablet:flex justify-start w-full desktop:w-fit">
                            <form className="tablet:flex justify-start w-full desktop:w-fit items-center">
                                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 mr-5 dark:text-white laptop:hidden desktop:hidden">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                    </div>
                                    <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                                </div>
                            </form>
                        </div>
                        <div className="flex items-center tablet:flex justify-start desktop:w-2/3 justify-end">
                            {/* <button type="button" className="borderedButton tablet:flex justify-start w-full desktop:w-1/6" onClick={addHandler}>
                            <label className="cursor-pointer text-md">Add New Order</label>
                        </button> */}
                            <button
                                type="button"
                                onClick={() => navigate(`/products/create`)}
                                className="border-none saveButton btn-add-carts  w-full desktop:w-1/2 text-center" style={{ marginTop: "0px", height: "35px" }}>
                                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                Add New Product
                            </button>
                        </div>
                    </div>
                    {/*<button type="button" className="borderedButton h-[9rem]" onClick={addHandler}>*/}
                    {/*<label className="cursor-pointer text-2xl">Add New Order</label>*/}
                    {/*</button>*/}
                    <div className="p-4 w-full max-w">

                        {productList.data && productList.data.map((product, index) => (
                            <Product product={product} loadProducts={loadProducts} key={index} />
                        ))}
                    </div>
                </div>
                <div className="grid md:grid-cols-2 sm:grid-rows-2 w-full">
                    <div className="flex items-center tablet:flex lg:justify-start justify-center w-full ">
                        <span>
                            <select className="select-custom" onChange={(e) => handleLimitPage(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={1000}>All</option>
                            </select>
                        </span>
                        <span className="ml-2 text-sm">
                            <label>entries per page</label>
                        </span>
                    </div>
                    <div className="flex lg:justify-end justify-center">
                        <Stack spacing={2} >
                            <Pagination count={productList.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={currentPage} />
                        </Stack>
                    </div>
                </div>
                <MessageEmpty loading={reduxProduct.loading} length={reduxProduct.products.data.length} message={reduxProduct.data.message} />
            </div>
        </AccountLayout>
    );
};

export default ProductList;
