import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  gradeProductService from "./gradeProductService";

export const gradeProductIndex = createAsyncThunk(
    "gradeProducts/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await gradeProductService.gradeProductIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const gradeProductStore = createAsyncThunk(
    "gradeProducts/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await gradeProductService.gradeProductStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const gradeProductUpdate = createAsyncThunk(
    "gradeProducts/update",
    async (args, thunkAPI) => {
        try {
            return await gradeProductService.gradeProductUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const gradeProductUploadPhoto = createAsyncThunk(
    "gradeProducts/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await gradeProductService.gradeProductUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const gradeProductShow = createAsyncThunk(
    "gradeProducts/:id/show",
    async (args, thunkAPI) => {
        try {
            return await gradeProductService.gradeProductShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const gradeProductDelete = createAsyncThunk(
    "gradeProducts/:id/delete",
    async (args, thunkAPI) => {
        try {
            return await gradeProductService.gradeProductDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    gradeProduct: {},
    gradeProducts:  {
        data:[]
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const gradeProductSlice = createSlice({
    name: 'gradeProduct',
    initialState,
    reducers: {
    },
    extraReducers : {
        [gradeProductStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [gradeProductStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.gradeProduct = payload.data.gradeProduct;
            state.loading=false;
            state.isSuccess = true;
        },
        [gradeProductStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [gradeProductUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [gradeProductUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [gradeProductUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [gradeProductShow.pending]:(state, {payload}) => {
            state.data = {};
            state.gradeProduct = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [gradeProductShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.gradeProduct = payload.data.gradeProduct;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [gradeProductShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [gradeProductDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.gradeProduct = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [gradeProductDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [gradeProductDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [gradeProductIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [gradeProductIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.gradeProducts = payload.data.gradeProducts;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [gradeProductIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = gradeProductSlice.actions

export default gradeProductSlice;