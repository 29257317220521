import api from "../../support/support_api";

const gradeStore = async (params) => {
    const response  = await  api.axiosPost('/grades', params);

    return response.data;
}

const gradeUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/grades/${params.grade_id}`, params);

    return response.data;
}

const gradeDelete = async (params) => {
    const response  = await  api.axiosDelete(`/grades/${params.grade_id}`);

    return response.data;
}

const gradeUploadPhotos = async (params) => {
    // const response  = await  api.axiosPatch(`/grades/${params.grade_id}`, params);
    //
    // return response.data;
}

const gradeShow = async (params) => {
    const response  = await api.axiosShow(`/grades/${params.grade_id}`, params);

    return response.data;
}

const gradeIndex = async (params) => {
    const response  = await api.axiosGet(`/grades`, params);

    return response.data;
}

const gradeService = {
    gradeStore, gradeUpdate, gradeUploadPhotos, gradeShow, gradeIndex, gradeDelete
}

export default gradeService;