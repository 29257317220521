
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import PageLayout from "pages/AccountLayout";

import Package from "./Package";
// import 'Component.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountAsc,faSortDown,faPlus } from '@fortawesome/free-solid-svg-icons';


const PackageList = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const navigate = useNavigate();
  const editHandler = () => {
    navigate(`/package-management/edit`);
  };
  const addHandler = () => {
    navigate(`/package-management/add`);
  };

  return (
    <>
    <PageLayout>
    <div data-testid="order-list" className="flex flex-col items-start gap-6 p-4">
        <h1 className="text-3xl text-left font-semibold font-regular" style={{ color: "#395B50" }}>Package Management</h1>
        <div className="flex items-center tablet:flex justify-start desktop:w-1/3">
          <button
            type="button"
            onClick={() => addHandler()}
            className="border-none saveButton btn-add-carts  w-full desktop:w-1/2 text-center" style={{ marginTop: "0px", height: "35px" }}>
            Add Package
          </button>
        </div>
        
        <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg font-regular">
          <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{borderBottomWidth: "1px", width:"100%"}}>
            <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
              Sort  
            </div>
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
              <select className="select-custom mr-2">
                <option value="NAME" selected>
                  Name
                </option>
                <option value="USERNAME" selected>
                  Username
                </option>
              </select>
              <FontAwesomeIcon icon={faSortAmountAsc} />
            </div>
            <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit ml-4">
              Filter  
            </div>
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
              <select className="select-custom ">
                <option value="NAME" selected>
                  Name
                </option>
                <option value="USERNAME" selected>
                  Username
                </option>
                <option value="EMAIL" selected>
                  Email
                </option>
                <option value="DATE" selected>
                  Company ID
                </option>
              </select>
            </div>
            <div className="flex items-center tablet:flex desktop:w-2/3 justify-end">
              <form className="tablet:flex justify-start w-full desktop:w-fit">   
                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 laptop:hidden desktop:hidden">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </div>
                    <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search" />
                </div>
              </form>
            </div>
          </div>

          <div className="p-2 w-full max-w" style={{backgroundColor:"#00AF54", color:"white"}}>
            <div className="hidden laptop:flex flex-row items-center justify-center text-sm text-semibold font-regular">
                <div className="flex items-center justify-center font-medium"></div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Name</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Subscription Fee</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Service Charge</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Account Type</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Status</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">Options</div>
            </div>
          </div>
        <Package/>
      </div>
    </div>
    </PageLayout>
    </>
  );
};
export default PackageList;
