export const normalOrderStatus = {
    1 : {
        label : 'Packaging',
        label_for_farmer : 'Start packaging',
        label_for_buyer : '',
        label_for_order_management : 'Start packaging',
        bg_color : 'bg-caution',
        text_color : 'text-black'
    },
    2 : {
        label : 'Item received',
        label_for_farmer : 'Item received',
        label_for_buyer : '',
        label_for_order_management : 'Item received',
        bg_color : 'bg-caution',
        text_color : 'text-black'
    },
    3 : {
        label : 'Order completed',
        label_for_farmer : '',
        label_for_buyer : '',
        label_for_order_management : 'Order completed',
        bg_color : 'bg-main',
        text_color: 'text-black'
    },
    4: {
        label : 'Wait for order to be confirmed',
        label_for_farmer : 'Confirm Order Quantity',
        label_for_buyer : 'Waiting for farmer to confirm the quantity ',
        label_for_order_management : 'Wait for order confirmed',
        bg_color : 'bg-extraWarning',
        text_color : 'text-black',
    },
    5 : {
        label : 'Order cancelled',
        label_for_farmer : 'Order cancelled ',
        label_for_buyer : 'Farmer has cancelled the order ',
        label_for_order_management : 'Order cancelled  ',
        bg_color : 'bg-pastelRed',
        text_color: 'text-black'
    },
    6 : {
        label : 'In delivery',
        label_for_farmer : '',
        label_for_buyer : 'In delivery',
        label_for_order_management : 'In delivery',
        bg_color : 'bg-caution',
        text_color : 'text-black'
    },
    7 : {
        label : 'Item delivered',
        label_for_farmer : '',
        label_for_buyer : 'Accept Delivery',
        label_for_order_management : 'Item delivered ',
        bg_color : 'bg-extraWarning',
        text_color: 'text-black'
    },
    9 : {
        label : 'Order created',
        label_for_farmer : 'Order created',
        label_for_buyer : 'Waiting for farmer to confirm the order',
        label_for_order_management : 'Waiting for farmer to confirm the order',
        bg_color : 'bg-extraWarning',
        text_color : 'text-black'
    },
    10 : {
        label : 'Farmer updating delivery information',
        label_for_farmer : 'Update delivery information',
        label_for_buyer : 'Awaiting farmer to start the delivery',
        label_for_order_management : 'Farmer updating delivery information',
        bg_color : 'bg-extraWarning',
        text_color : 'text-black'
    },
};