import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  orderFarmerService from "./orderFarmerService";
import orderDetailService from "../order-detail/orderDetailService";
// import orderFarmerDetailService from "../orderFarmer-detail/orderFarmerDetailService";

export const orderFarmerIndex = createAsyncThunk(
    "order-farmers/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await orderFarmerService.orderFarmerIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerStore = createAsyncThunk(
    "orderFarmers/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await orderFarmerService.orderFarmerStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerUpdate = createAsyncThunk(
    "order-farmers/update",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerShow = createAsyncThunk(
    "order-farmers/:id/show",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)
//
export const orderFarmerUpdateByToken = createAsyncThunk(
    "order-farmers/place",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerUpdateByToken(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerPlace = createAsyncThunk(
    "order-farmers/place",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerPlace(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerReceivableForFarmer = createAsyncThunk(
    "order-farmers/index",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerReceivableForFarmer(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerMadeByCustomer = createAsyncThunk(
    "order-farmers/index",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerMadeByCustomer(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)



export const orderFarmerDeliveryDecline = createAsyncThunk(
    "order-farmers/:id/status-update",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerDeliveryDecline(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerDeliveryAccept = createAsyncThunk(
    "order-farmers/:id/status-update",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerDeliveryAccept(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerDeliveryStart = createAsyncThunk(
    "order-farmers/:id/status-update",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerDeliveryStart(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerDeliveryDone = createAsyncThunk(
    "order-farmers/:id/status-update",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerDeliveryDone(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const bookingPlotDetail = createAsyncThunk(
    "booking-plots/getByOrderFarmerId/:order_farmer_id",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.bookingPlotDetail(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const confirmOrderQuantity = createAsyncThunk(
    "order-farmers/confirmOrderQuantity",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.confirmOrderQuantity(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cancelOrder = createAsyncThunk(
    "order-farmers/cancelOrder",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.cancelOrder(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const printAwb = createAsyncThunk(
    "order-farmers/:order_farmer_id/print-awb",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.printAwb(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getStatesList = createAsyncThunk(
    "states/list",
    async (params, thunkAPI) => {
        try {
            return await orderFarmerService.getStatesList(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const payOrder = createAsyncThunk(
    "orders/pay",
    async (params, thunkAPI) => {
        try {
            return await orderFarmerService.payOrder(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerGetByOrderNumber = createAsyncThunk(
    "order-farmers/getByOrderNumber/:order_number",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerGetByOrderNumber(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const generateInvoiceNumber = createAsyncThunk(
    "order-farmers/generateInvoiceNumber/:order_number",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.generateInvoiceNumber(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const uploadPaymentReceipt = createAsyncThunk(
    "order-farmers/uploadPaymentReceipt",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.uploadPaymentReceipt(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const confirmOfflinePayment = createAsyncThunk(
    "order-farmers/confirmOfflinePayment/:order_farmer_id",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.confirmOfflinePayment(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const downloadInvoice = createAsyncThunk(
    "order-farmers/download-invoice/:order_number",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.downloadInvoice(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerInDelivery = createAsyncThunk(
    "order-farmers/:order_farmer_id/in-delivery",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerInDelivery(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerDelivered = createAsyncThunk(
    "order-farmers/:order_farmer_id/delivered",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerService.orderFarmerDelivered(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)


const initialState = {
    data: {},
    order_farmer: {},
    order_farmers: {
        data: []
    },
    order_details: {
        data: []
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const orderFarmerSlice = createSlice({
    name: 'orderFarmer',
    initialState,
    reducers: {
    },
    extraReducers : {
        [orderFarmerIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [orderFarmerIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.order_farmers = payload.data.order_farmers;
            state.loading=false;
            state.isSuccess = true;
        },
        [orderFarmerIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        // [orderFarmerStore.pending]:(state, {payload}) => {
        //     state.data = {};
        //     state.updating=true;
        //     state.loading=true;
        //     state.isSuccess = false;
        // },
        // [orderFarmerStore.fulfilled]:(state, {payload}) => {
        //     state.data = payload;
        //     state.updating=false;
        //     state.order_farmer = payload.data.order_farmer;
        //     state.loading=false;
        //     state.isSuccess = true;
        // },
        // [orderFarmerStore.rejected]:(state, {payload}) => {
        //     state.data = payload;
        //     state.updating = false;
        //     state.loading = false;
        //     state.isSuccess = true;
        // },
        //
        // [orderFarmerUpdate.pending]:(state, {payload}) => {
        //     state.data = {};
        //     state.loading=true;
        //     state.updating=true;
        //     state.isSuccess = false;
        // },
        // [orderFarmerUpdate.fulfilled]:(state, {payload}) => {
        //     state.data = payload;
        //     state.loading=false;
        //     state.updating=false;
        //     state.isSuccess = true;
        // },
        // [orderFarmerUpdate.rejected]:(state, {payload}) => {
        //     state.data = payload;
        //     state.loading = false;
        //     state.updating = false;
        //     state.isSuccess = true;
        // },
        //
        [orderFarmerShow.pending]:(state, {payload}) => {
            state.data = {};
            state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderFarmerShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.order_farmer = payload.data.order_farmer;
            state.order_details = payload.data.order_details;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderFarmerShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },


        [orderFarmerDeliveryAccept.pending]:(state, {payload}) => {
            state.data = {};
            state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderFarmerDeliveryAccept.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderFarmerDeliveryAccept.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        //
        // [orderFarmerIndex.pending]:(state, {payload}) => {
        //     state.data = {};
        //     state.loading=true;
        //     state.updating=true;
        //     state.isSuccess = false;
        // },
        // [orderFarmerIndex.fulfilled]:(state, {payload}) => {
        //     state.data = payload;
        //     state.order_farmers = payload.data.order_farmers;
        //     state.loading=false;
        //     state.updating=false;
        //     state.isSuccess = true;
        // },
        // [orderFarmerIndex.rejected]:(state, {payload}) => {
        //     state.data = payload;
        //     state.loading = false;
        //     state.updating = false;
        //     state.isSuccess = true;
        // },
        //
        // [orderFarmerPlace.pending]:(state, {payload}) => {
        //     state.data = {};
        //     state.loading=true;
        //     state.updating=true;
        //     state.isSuccess = false;
        // },
        // [orderFarmerPlace.fulfilled]:(state, {payload}) => {
        //     state.data = payload;
        //     state.order_farmer = payload.data.order_farmer;
        //     state.loading=false;
        //     state.updating=false;
        //     state.isSuccess = true;
        // },
        // [orderFarmerPlace.rejected]:(state, {payload}) => {
        //     state.data = payload;
        //     state.loading = false;
        //     state.updating = false;
        //     state.isSuccess = true;
        // },
        [bookingPlotDetail.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [bookingPlotDetail.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [bookingPlotDetail.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [confirmOrderQuantity.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [confirmOrderQuantity.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [confirmOrderQuantity.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [cancelOrder.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [cancelOrder.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [cancelOrder.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [printAwb.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [printAwb.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [printAwb.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [payOrder.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [payOrder.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [payOrder.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [orderFarmerGetByOrderNumber.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderFarmerGetByOrderNumber.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderFarmerGetByOrderNumber.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [generateInvoiceNumber.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [generateInvoiceNumber.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [generateInvoiceNumber.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [uploadPaymentReceipt.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [uploadPaymentReceipt.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [uploadPaymentReceipt.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [confirmOfflinePayment.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [confirmOfflinePayment.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [confirmOfflinePayment.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [downloadInvoice.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [downloadInvoice.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [downloadInvoice.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [orderFarmerInDelivery.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderFarmerInDelivery.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderFarmerInDelivery.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [orderFarmerDelivered.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderFarmerDelivered.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.order_farmer = payload.data.order_farmer;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderFarmerDelivered.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = orderFarmerSlice.actions

export default orderFarmerSlice;