// TODO - This might need to be refactor because the id's aren't dynamic

const staticPaths = {
    HOME: '/',
    REGISTER: '/register/details',
    LIMITREGISTRATION: '/register/closed',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    ACCOUNT: '/account/edit#personal',
    ACCOUNTPATH: '/account',
    ACCOUNTADMIN: '/account/admin',
    FARMS: '/farms/widgets',
    PRODUCTS: "/products",
    CATALOG: "/products/catalogs",
    ORDERS: "/orders",
    CUSTOMER_ORDER_FARMERS: "/customer/order-farmers",
    CUSTOMER_ORDERS: "/customer/orders",
    CATALOG_PLOT_DETAIL: "/products/catalogs/plot",


    ORDER_DETAILS: "/order-details",

    ORDER_FARMERS: "/order-farmers",

    CUSTOMER_ORDER_DETAILS: "/customer/order-details",


    DEMANDS: "/demands",
    DEMANDS_FARMER_APPLICATIONS: "/demands/applications",
    DEMANDS_CREATE: "/demands/create",
    MARKETDEMANDS: "/demands/market",
    APPLYDEMANDS: "/demands/market/apply",
    APPLICATIONS: "/applications",
    CART: "/cart/products-new",
    NOTIFICATION_LIST: "/notification/show",
    CART_DONE: "/cart/done",
    FILES: "/files",

    USER_DETAIL: "/user-detail",
    USER_DETAIL_SHOW: "/user-detail/show",

    COMPANY_DETAIL: "/company-detail",
    COMPANY_DETAIL_SHOW: "/company-detail/show",

    PACKAGE_DETAIL: "/package-management",
    PACKAGE_DETAIL_SHOW: "/package-management/show",

    MANAGE_BILLING: "/manage-billing",
    MANAGE_BILLING_SHOW: "/manage-billing/show",

    MANAGE_CREDIT: "/manage-credit",
    MANAGE_CREDIT_SHOW: "/manage-credit/show",

    MANAGE_ADVANCE_BOOKING: "/manage-advance-booking",

    MANAGE_AUCTION_BIDDING: "/manage-auction-bidding",
    
    SETTING_PAGE: "/setting",

    ROOM_CHAT_PAGE: "/room-chat/page",

    MANAGE_CROP: '/manage-crop',
    MANAGE_ORDER: "/manage-orders",
    MANAGE_ORDER_SHOW: "/manage-orders/show#order",

    MANAGE_USER_CREDIT: "/credit/show"

};

const dynamicPaths = {};

export const routesPaths = {
    ...staticPaths,
    ...dynamicPaths,
};
