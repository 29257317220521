import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from "../account/Account.module.scss";
import clsx from "clsx";
import { faTrashCan, faPenToSquare, } from '@fortawesome/free-solid-svg-icons';
import { unitOfMeasureDelete, unitOfMeasureIndex, unitOfMeasureStore, unitOfMeasureUpdate } from "features/unit-of-measure/unitOfMeasureSlice";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 2,
};

const UnitOfMeasureSetting = () => {
  const reduxcategory = useSelector((state) => state.category);
  const { unitOfMeasures } = useSelector((state) => state.unitOfMeasure);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [unitOfMeasureName, setUnitOfMeasureName] = useState("");
  const [unitOfMeasureDescription, setUnitOfMeasureDescription] = useState("");

  const dispatch = useDispatch();

  const snackbarCtx = useContext(SnackbarContext);

  const openEditModal = unit => {
    setSelectedUnit(unit);
    setOpenEditPopup(true);
  }

  const loadUnitOfMeasures = () => {
    dispatch(unitOfMeasureIndex({}))
  }

  const handleDeleteModal = () => {
    setOpenDeletePopup(!openDeletePopup)
  };

  const handleEditModal = () => {
    setOpenEditPopup(!openEditPopup)
  };

  const submitDeleteUnit = () => {
    dispatch(unitOfMeasureDelete({
      id: selectedUnit.id
    })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
        loadUnitOfMeasures();
      }
    });

    setOpenDeletePopup(false)
  }

  const submitEditUnit = () => {
    dispatch(unitOfMeasureUpdate({
      id: selectedUnit.id,
      name: selectedUnit?.name,
      description: selectedUnit?.description,
    })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        loadUnitOfMeasures();
      }
    });

    setOpenEditPopup(false)
  }

  const openDeleteModal = unit => {
    setSelectedUnit(unit);
    setOpenDeletePopup(true);
  }

  const addUnitOfMeasure = () => {
    dispatch(unitOfMeasureStore({
      name: unitOfMeasureName,
      description: unitOfMeasureDescription,
    })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        loadUnitOfMeasures();
      }
    });
  }

  useEffect(() => {
    loadUnitOfMeasures()
  }, []);

  return (
    <>
      <Row className="mt-4">
        <Col md={2}>Add Unit of Measure:</Col>
        <Col>
          <div className="flex">
            <input
              type="text"
              placeholder="Name"
              value={unitOfMeasureName}
              onChange={({ target }) => setUnitOfMeasureName(target.value)}
              className="input-search mr-3 lg:w-2/12"
            />
            <input
              type="text"
              placeholder="Description"
              value={unitOfMeasureDescription}
              onChange={({ target }) => setUnitOfMeasureDescription(target.value)}
              className="input-search lg:w-2/12"
            />
            <button className="saveButton ml-4 lg:w-2/12" onClick={addUnitOfMeasure}>Save</button>
          </div>
          <div className="flex flex-wrap mt-2">
            <div className={clsx(styles.scroll, "lg:w-7/12 md:w-full sm:w-full mt-2 shadow rounded-lg", unitOfMeasures?.data.length > 9 ? "h-96" : "")}>
              <table className="rounded-t-lg" style={{ width: "100%" }}>
                <tr className="sticky top-0 text-center p-2 text-sm rounded-t-lg" style={{ backgroundColor: "#00AF54", color: "white" }}>
                  <th className="lg:w-4/12 sm:w-full md:w-full p-2 rounded-tl-lg">
                    Unit of Measure Name
                  </th>
                  <th className="lg:w-4/12 sm:w-full md:w-full p-2">
                    Unit of Measure Description
                  </th>
                  <th className="lg:w-4/12 sm:w-full md:w-full p-2 rounded-tr-lg">
                    Options
                  </th>
                </tr>
                <>
                  {unitOfMeasures?.data.map((unitOfMeasure, key) =>
                    <tr className={clsx("text-center text-sm bg-white rounded-b-lg", key !== unitOfMeasures?.data.length - 1 ? "border-y-2" : "")} key={key}>
                      <td className="p-2">
                        {unitOfMeasure.name}
                      </td>
                      <td className="p-2">
                        {unitOfMeasure.description}
                      </td>
                      <td className="p-2">
                        <div className="flex justify-center">
                          <FontAwesomeIcon icon={faPenToSquare} className="ml-6 text-lg cursor-pointer" onClick={() => openEditModal(unitOfMeasure)} />
                          <FontAwesomeIcon icon={faTrashCan} className=" ml-6 text-lg cursor-pointer" onClick={() => openDeleteModal(unitOfMeasure)} />
                        </div>
                      </td>
                    </tr>)}
                </>
                <tr>
                  <td colspan="3" className="px-2">{unitOfMeasures && unitOfMeasures?.data.length === 0 && <span>No unit of measure is available.</span>}</td>
                </tr>
              </table>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        open={openEditPopup}
        onClose={handleEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                pb: 1,
                mb: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <div style={{ color: '#00AF54' }}>Edit Unit of Measure</div>
              <button onClick={handleEditModal}>
                <FontAwesomeIcon icon={faXmark} style={{ fontSize: "15px", }} />
              </button>
            </Box>
          </Typography>
          <Typography id="modal-modal-description" >
            <Box
              sx={{
                pb: 2,
                mb: 2,
                bgcolor: 'background.paper',
              }}
            >
              <input
                type="text"
                value={selectedUnit.name}
                onChange={({ target }) => setSelectedUnit({ ...selectedUnit, name: target.value })}
                className="input-search lg:w-5/12"
              />
              <input
                type="text"
                value={selectedUnit.description}
                onChange={({ target }) => setSelectedUnit({ ...selectedUnit, description: target.value })}
                className="input-search lg:w-5/12 ml-3"
              />
            </Box>
            <div className="flex justify-end">
              <button onClick={submitEditUnit} className="border-none borderedwhiteButton btn-add-carts w-full desktop:w-1/4 text-center borderedInactiveButton">
                Save
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={openDeletePopup}
        onClose={handleDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                pb: 1,
                mb: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <div style={{ color: '#00AF54' }}>Delete Unit of Measure</div>
              <button onClick={handleDeleteModal}>
                <FontAwesomeIcon icon={faXmark} style={{ fontSize: "15px", }} />
              </button>
            </Box>
          </Typography>
          <Typography id="modal-modal-description" >
            <Box
              sx={{
                pb: 2,
                mb: 2,
                bgcolor: 'background.paper',
              }}
            >
              <div>{"Are you sure to delete this unit: " + (selectedUnit?.name) + " ?"}</div>
            </Box>
            <div className="flex justify-end">
              <button onClick={submitDeleteUnit} className="border-none borderedwhiteButton btn-add-carts w-full desktop:w-1/4 text-center borderedInactiveButton">
                Delete
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default UnitOfMeasureSetting;
