const TermsOfUse = () => {
    return (
        <div className="flex justify-start flex-col ">
            <div className="italic font-bold text-sm p-2">
                TaniExchage Terms of Use
            </div>
            <div className="text-xs px-4 flex flex-col">
                <label className="font-bold">
                    <span className="mr-2">
                        1. 
                    </span>
                    <span>
                        Interpretation 
                    </span>
                </label>
                <label className="mt-1">
                In this Terms of Use, the following words and experiences shall have the following meanings unless the context otherwise requires: -
                </label>
                <div>
                    <label className="flex flex-row mt-3">
                        <span className="font-bold mr-[5.7rem]">
                            "You" 
                        </span>
                        <span>
                        represents the User interacting with Our software application or system. As a user, you navigate the software interface, provide input, and receive output;
                        </span>
                    </label>
                    <label className="flex flex-row mt-2">
                        <span className="font-bold mr-4">
                            "We"/"Our"/"Us" 
                        </span>
                        <span className="ml-4">
                            signifies the collective entity responsible for developing, distributing, and
                            supporting the software. As a vendor, “We”/”Our”/”Us” encompasses the
                            company (“SAT ASIA”) and its affiliated partners or organizations.
                        </span>
                    </label>
                </div>
                <label className="font-bold mt-3">
                    <span className="mr-2">
                        2. 
                    </span>
                    <span>
                        Introduction 
                    </span>
                </label>
                <div>
                    <label className="mt-1">
                        Welcome to TaniExchange, a platform developed by Singularity Aerotech Asia Sdn Bhd (SAT Asia)
                        to facilitate collaboration among various players in the agricultural sector supply chains, including
                        farmers, buyers, retailers, and logistics participants.
                    </label>
                    <label className="mt-2">
                        TaniExchange aims to empower farmers, especially smallholders, by providing them with a
                        business matching platform. This platform enables them to connect with potential customers and
                        secure contract farming opportunities at an early stage. Leveraging smart farming technologies,
                        the data collected can serve as valuable marketing tools for farmers. 
                    </label>
                    <label className="mt-2">
                        Buyers also benefit from TaniExchange as they gain access to selected information from the
                        business matching platform. This information can be utilized to manage supply chain risks
                        effectively.
                    </label>
                    <label className="mt-2">
                        Before using the platform, it is crucial to thoroughly read and understand the terms outlined. These
                        terms of use specify the conditions of using the platform and establish legal rights and obligations.
                        By accessing or using the platform, you agree to be bound by these Terms of Use.
                    </label>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            3. 
                        </span>
                        <span>
                            Use of the Platform 
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            The Platform is provided solely for your business and commercial use. You may not use the
                            Platform for any unlawful or unauthorized purpose, including but not limited to, violating any
                            intellectual property rights, interfering with the proper functioning of the Platform, or accessing any
                            data or information without permission.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            4. 
                        </span>
                        <span>
                            Purchasing Terms
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            By agreeing to purchase, book and participate in a bidding through the Platform, you agree to the
                            terms and conditions of the purchasing agreement, which will be provided to you separately. These
                            terms will include details on payment schedules, insurance requirements, maintenance
                            responsibilities, and termination procedures.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            5. 
                        </span>
                        <span>
                            User Accounts
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            In order to access specific features on the Platform, you may need to create an account. It is your
                            responsibility to ensure the confidentiality of your account and password, and you commit to
                            informing us promptly if you suspect any unauthorized use of your account.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            6. 
                        </span>
                        <span>
                            Ownership and Intellectual Property
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            The Platform and all its content, which includes text, graphics, logos, images, and software, are
                            the property of Us or Our licensors and are safeguarded by copyright, trademark, and other
                            intellectual property laws. You are not allowed to copy, modify, distribute, or use any content from
                            the Platform without obtaining our prior written consent.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            7. 
                        </span>
                        <span>
                            Termination
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            We maintain the right to suspend or terminate your access to the Platform at any time, with or
                            without cause, and without prior notice. Upon termination, You must immediately cease all use of
                            the Platform and destroy all copies of any content obtained from the Platform.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            8. 
                        </span>
                        <span>
                            Third-Party Links
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            Your use of the Platform is also subject to our Privacy Policy, which can be accessed on the
                            Platform. By using the Platform, you agree to Our collection, use, and disclosure of your information
                            in accordance with Our Privacy Policy.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            9. 
                        </span>
                        <span>
                            Privacy Policy
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            Your use of the Platform is also subject to our Privacy Policy, which can be accessed on the
                            Platform. By using the Platform, you agree to Our collection, use, and disclosure of your information
                            in accordance with Our Privacy Policy.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            10. 
                        </span>
                        <span>
                            Limitation of Liability
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            In no event shall SAT Asia or its affiliates, licensors, or service providers be liable for any indirect,
                            consequential, punitive, incidental, special, or exemplary damages arising out of or in connection
                            with your use or inability to use the Platform, including without limitation any loss of profits, revenue,
                            or data, or interruption of business, even if SAT Asia has been advised of the possibility of such
                            damages.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            11. 
                        </span>
                        <span>
                            Data Accuracy and Responsibility 
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            You accept and agree that You bear full responsibility for the accuracy and completeness of any
                            data or information You enter on Our platform. By providing data or information on Our platform,
                            You represent and warrant that it is true, accurate, and up-to-date to the best of your knowledge.
                            We DO NOT endorse or verify the accuracy, reliability, or completeness of any user-populated
                            data. We are not responsible for any errors, omissions or inaccuracies in the data provided by
                            users.
                        </label>
                        <label className="mt-2">
                            Using data or information from other users comes with inherent risks, for which You acknowledge
                            the responsibility. Before making any decisions or taking actions based on data provided by other
                            users, You should verify its accuracy and suitability independently. If You discover any inaccuracies
                            or errors in the data populated by other users, You must promptly notify us so that we can
                            investigate and take appropriate actions as required. We reserve the right, at our sole discretion,
                            to remove or modify any data that we deem inaccurate, misleading, or in violation of Our Terms of
                            Use, without prior notice.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            12. 
                        </span>
                        <span>
                            Indemnification
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            You agree to defend, indemnify, and hold harmless SAT Asia and its affiliates, licensors, and
                            service providers from and against any claims, liabilities, damages, judgments, awards, losses,
                            costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your
                            violation of these Terms of Use or your use of the Platform.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            13. 
                        </span>
                        <span>
                            Governing Law
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            These Terms of Use and any dispute arising out of or in connection with them shall be governed
                            by and construed in accordance with the laws of the jurisdiction in which the company operating
                            the website or service is registered. Any legal action or proceeding arising under these Terms of
                            Use shall be brought exclusively in the courts located in the jurisdiction in which the company is
                            registered. By using the website or service, you consent to the jurisdiction and venue of such
                            courts.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            14. 
                        </span>
                        <span>
                            Dispute Resolution
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            Any dispute arising out of or relating to these Terms of Use or your use of the Platform shall be
                            resolved through binding arbitration in accordance with the rules of Malaysia, and judgment upon
                            the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            15. 
                        </span>
                        <span>
                            Entire Agreement
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            This Terms of Use, together with the Privacy Policy, constitute the entire agreement between you
                            and SAT Asia with respect to the use of the Platform and supersede all prior or contemporaneous
                            communications and proposals, whether oral or written, between you and SAT Asia.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            16. 
                        </span>
                        <span>
                            Waiver and Severability
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            The failure of SAT Asia to exercise or enforce any right or provision of these Terms of Use shall
                            not constitute a waiver of such right or provision. If any provision of these Terms of Use is held to
                            be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            17. 
                        </span>
                        <span>
                            Assignment
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            You may not assign or transfer these Terms of Use or any of your rights or obligations hereunder,
                            whether by operation of law or otherwise, without Our prior written consent. We may assign these
                            Terms of Use or any of its rights or obligations hereunder without your consent.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            18. 
                        </span>
                        <span>
                            Headings
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            The headings used in these Terms of Use are for convenience only and shall not affect the
                            interpretation of these Terms of Use.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            19. 
                        </span>
                        <span>
                            Notices
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            All notices, requests, consents, and other communications under these Terms of Use must be in
                            writing and addressed to SAT Asia at the address or email address specified in the Platform. Any
                            notices or other communications that you provide without compliance with this Section on Notices
                            shall have no legal effect.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            20. 
                        </span>
                        <span>
                            Changes to Terms of Use
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            SAT Asia reserves the right, at its sole discretion, to modify or replace these Terms of Use at any
                            time. If the alterations constitute a material change to these Terms of Use, SAT Asia will notify you
                            by posting an announcement on the Platform. What constitutes a "material change" will be
                            determined at SAT Asia's sole discretion. Your continued use of the Platform following the posting
                            of any changes to these Terms of Use constitutes acceptance of those changes.
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            21. 
                        </span>
                        <span>
                            Contact Us
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                            If you have any questions about these Terms of Use, please contact us at
                        </label>
                        <label className="underline ml-1">
                            admin.taniexchange
                        </label>
                        <label className="underline">
                            @singularityaerotech.asia
                        </label>
                    </div>
                </div>
                <div>
                    <label className="font-bold mt-3">
                        <span className="mr-2">
                            Revision 1.0. 
                        </span>
                        <span>
                            Date 21/08/2023
                        </span>
                    </label>
                    <div>
                        <label className="mt-1">
                        © SINGULARITY AEROTECH ASIA SDN BHD 2020. ALL RIGHTS RESERVED. CONFIDENTIAL AND PROPRIETARY DOCUMENT.
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsOfUse;