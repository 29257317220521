
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faFileLines, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const Billing = ({ companies }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="p-2 w-full max-w mt-1">
        {companies?.data.length > 0 && companies?.data.map(item => {
          return <div className="hidden laptop:flex flex-row items-center justify-center pb-2 font-light text-sm" key={item.id}>
            <div className="basis-1/3 flex items-startjustify-center font-medium">{item?.name}</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{item?.selected_package?.data.length > 0 && item?.selected_package?.data[0].package?.package_name || 'N/A'}</div>
            <div className="basis-1/3 flex items-center justify-center font-medium">{item?.selected_package?.data.length > 0 && DateTime.fromSQL(item?.selected_package?.data[0].subs_start_date).toFormat('dd/LL/yyyy')} - {item?.selected_package?.data.length > 0 && DateTime.fromSQL(item?.selected_package?.data[0].subs_end_date).toFormat('dd/LL/yyyy')}</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{item?.selected_package?.data.length > 0 && item?.selected_package?.data[0].package?.package_period_format === 'Months' ? 'Monthly' : 'N/A'}</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{item?.bills?.data.length && item?.bills?.data[0].overdue_amount || "0.00"}</div>
            <div className="basis-1/4 font-medium text-center"><span className={clsx(item?.active === 1 ? 'bg-green-300 text-green-700' : 'bg-red-300 text-red-600', 'w-fit rounded-lg px-3')}>{item?.active === 1 ? 'Active' : 'Inactive'}</span></div>
            <div className="basis-1/4 flex items-center justify-center font-medium space-x-3">
              <FontAwesomeIcon title='Edit' icon={faPenToSquare} className={item?.selected_package?.data.length > 0 ? 'text-xl text-main cursor-pointer' : 'text-xl text-gray-300'} onClick={() => item?.selected_package?.data.length > 0 && navigate(`/manage-billing/${item?.id}/company-plan`)} />
              <FontAwesomeIcon title='Subscription History' icon={faFileLines} className={item?.selected_package?.data.length > 0 ? 'text-xl text-main cursor-pointer' : 'text-xl text-gray-300'} onClick={() => item?.selected_package?.data.length > 0 && navigate(`/manage-billing/${item?.id}/subscription`)} />
              <FontAwesomeIcon title='Billing History' icon={faFileInvoiceDollar} className={item?.selected_package?.data.length > 0 ? 'text-xl text-main cursor-pointer' : 'text-xl text-gray-300'} onClick={() => item?.selected_package?.data.length > 0 && navigate(`/manage-billing/${item?.id}`)} />
            </div>
          </div>
        })}
      </div>
    </>
  );
};
export default Billing;
