import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import orderFarmerDeliveryService from './orderFarmerDeliveryService';

export const getStatesList = createAsyncThunk(
    "states/list",
    async (params, thunkAPI) => {
        try {
            return await orderFarmerDeliveryService.getStatesList(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderDetail = createAsyncThunk(
    "order-farmers/:id/show",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerDeliveryService.orderDetail(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getCourier = createAsyncThunk(
    "order-farmers/getCourier",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerDeliveryService.getCourier(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const storeAddress = createAsyncThunk(
    "users/store/userAddress",
    async (params, thunkAPI) => {
        try {
            return await orderFarmerDeliveryService.storeAddress(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderFarmerDeliveryStart = createAsyncThunk(
    "order-farmers/:id/status-update",
    async (args, thunkAPI) => {
        try {
            return await orderFarmerDeliveryService.orderFarmerDeliveryStart(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    order_farmer: {},
    order_farmers: {
        data: []
    },
    order_details: {
        data: []
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const orderFarmerDeliverySlice = createSlice({
    name: 'orderFarmerDelivery',
    initialState,
    reducers: {
    },
    extraReducers : {
        [getStatesList.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [getStatesList.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [getStatesList.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [orderDetail.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderDetail.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderDetail.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [getCourier.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [getCourier.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [getCourier.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [storeAddress.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [storeAddress.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [storeAddress.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [orderFarmerDeliveryStart.pending]:(state, {payload}) => {
            state.data = {};
            // state.order_farmer = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderFarmerDeliveryStart.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderFarmerDeliveryStart.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = orderFarmerSlice.actions

export default orderFarmerDeliverySlice;