import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  categoryService from "./categoryService";

export const categoryIndex = createAsyncThunk(
    "categories/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await categoryService.categoryIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const categoryStore = createAsyncThunk(
    "categories/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await categoryService.categoryStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const categoryDelete = createAsyncThunk(
    "categories/delete",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await categoryService.categoryDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const categoryUpdate = createAsyncThunk(
    "categories/update",
    async (args, thunkAPI) => {
        try {
            return await categoryService.categoryUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const categoryUploadPhoto = createAsyncThunk(
    "categories/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await categoryService.categoryUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const categoryShow = createAsyncThunk(
    "categories/:id/show",
    async (args, thunkAPI) => {
        try {
            return await categoryService.categoryShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    category: {},
    categories: {
        data : [

        ]
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
    },
    extraReducers : {
        [categoryStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [categoryStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.category = payload.data.category;
            state.loading=false;
            state.isSuccess = true;
        },
        [categoryStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [categoryUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [categoryUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [categoryUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [categoryShow.pending]:(state, {payload}) => {
            state.data = {};
            state.category = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [categoryShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.category = payload.data.category;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [categoryShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [categoryIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [categoryIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.categories = payload.data.categories;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [categoryIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = categorySlice.actions

export default categorySlice;