import { routesPaths } from "constants/routes.constants";
import PopupLayout from "pages/PopupLayout";
import { useNavigate } from "react-router-dom";
import success from "../../images/newSucess.png";

const ProductAdded = () => {
  const navigate = useNavigate();

  const shopHandler = () => {
    navigate(routesPaths.CATALOG);
  };

  const cartHandler = () => {
    navigate(routesPaths.CART);
  };

  return (
    <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
      <section
        data-testid="popup-layout"
        className="absolute bg-formLight w-11/12 tablet:w-[27rem] flex flex-col rounded-xl shadow"
      >
        <div className=" flex justify-center h-28 rounded-t-md">
          <img src={success} style={{ width: "80px" }} className="self-center" />
        </div>
        <div className="flex flex-col items-center text-center space-y-4">
          <h1 className="text-xl" style={{ fontSize: "18px", fontWeight: "bold", color: "#395B50" }}>Done!</h1>
          <span style={{ color: "#545556", fontSize: "14px", fontWeight: "600" }}>Product Added To Cart</span>
        </div>
        <div className="self-center w-full flex flex-col p-8 gap-8 text-center items-center">
          <div className="w-full flex gap-4">
            <button className="w-1/2 btn-border-green" onClick={cartHandler}>
              View Cart
            </button>
            <button className="w-1/2 btn-border-green" onClick={shopHandler}>
              Continue Shopping
            </button>
          </div>
        </div>

      </section>
    </div>
  );
};

export default ProductAdded;
