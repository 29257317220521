import api from "../../support/support_api";

const ratingStore = async (params) => {
    const response  = await  api.axiosPost('/rating', params);

    return response.data;
}

const ratingStoreWithoutStoreId = async (params) => {
    const response  = await  api.axiosPost('/rating/storeWithoutStoreId', params);

    return response.data;
}

const ratingUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/rating/${params.id}`, params);

    return response.data;
}

const ratingDelete = async (params) => {
    const response  = await  api.axiosDelete(`/rating/${params.rating_id}`, params);

    return response.data;
}

const ratingUploadPhotos = async (params) => {
    // const response  = await  api.axiosPatch(`/rating/${params.rating_id}`, params);
    //
    // return response.data;
}

const ratingShow = async (params) => {
    const response  = await api.axiosShow(`/rating/${params.id}`, params);

    return response.data;
}

const ratingIndex = async (params) => {
    const response  = await api.axiosGet(`/rating`, params);

    return response.data;
}

const ratingGuest = async (params) => {
    const response  = await api.axiosGet(`/rating/guest`, params);

    return response.data;
}

const ratingAuth = async (params) => {
    const response  = await api.axiosGet(`/rating/auth`, params);

    return response.data;
}

const ratingService = {
    ratingStore,
    ratingStoreWithoutStoreId,
    ratingUpdate,
    ratingUploadPhotos,
    ratingShow,
    ratingGuest,
    ratingIndex,
    ratingAuth,
    ratingDelete
}

export default ratingService;