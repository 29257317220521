// import { useNavigate } from "react-router-dom";
//
// import AccountLayout from "pages/AccountLayout";
// import DemandFarmControls from "./DemandFarmControls";
// import { routesPaths } from "constants/routes.constants";
//
const DemandFarmWidgets = () => {
//   const navigate = useNavigate();
//
//   const dummyFarmList = {
//     data: [
//       { farm_id: 1, name: "", address: "", photo: "" },
//       { farm_id: 2, name: "Green Farm", address: "21 Farm Street", photo: "/assets/images/plots/PlotPhoto1.svg" },
//       { farm_id: 3, name: "Fresh Farm", address: "22 Farm Street", photo: "/assets/images/plots/PlotPhoto2.svg" },
//       { farm_id: 4, name: "Healthy Farm", address: "23 Farm Street", photo: "/assets/images/plots/PlotPhoto3.svg" }
//     ],
//   }
//
//   const farmList = dummyFarmList;
//
//   const demand_id = 1;
//   const viewHandler = (farm_id) => {
//     navigate(`${routesPaths.DEMANDS}/${demand_id}/show/widgets/${farm_id}/show`);
//   }
//
//   return (
//     <AccountLayout>
//       <div data-testid="demand-farm-widgets" className="w-full flex flex-col items-center gap-8">
//         <DemandFarmControls />
//         <div className="w-full max-w-[60rem] grid place-items-center tablet:grid-cols-2 laptop:grid-cols-3 wide:grid-cols-4 gap-4">
//           {farmList.data.map((farm, index) => {
//             return (
//               <button
//                 className="w-full h-full ring-green-500 hover:ring-[.2rem] flex flex-col relative rounded-lg shadow"
//                 onClick={() => viewHandler(farm.farm_id)}
//                 key={index}
//               >
//                 <img
//                   src={farm.photo ? farm.photo : "/assets/images/FarmIcon.svg"}
//                   alt="example farm image"
//                   className="min-w-full max-w-full min-h-full max-h-full object-cover aspect-square rounded-lg"
//                 />
//                 <div className="absolute w-full bottom-0 bg-black/30 justify-center h-1/3 flex flex-col items-start p-2 rounded-b-md">
//                   <label className="text-white cursor-pointer">{farm.name ? farm.name : "Farm Name"}</label>
//                   <label className="text-white cursor-pointer">{farm.address ? farm.address : "Farm Address"}</label>
//                 </div>
//               </button>
//             );
//           })}
//         </div>
//       </div>
//     </AccountLayout>
//   );
};

export default DemandFarmWidgets;
