import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  cartService from "./cartService";

export const cartIndex = createAsyncThunk(
    "cart/index",
    async (args, thunkAPI) => {
        try {
            return await cartService.cartIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cartStore = createAsyncThunk(
    "cart/store",
    async (args, thunkAPI) => {
        try {
            return await cartService.cartStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cartUpdate = createAsyncThunk(
    "cart/update",
    async (args, thunkAPI) => {
        try {
            return await cartService.cartUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cartUpdateQuantity = createAsyncThunk(
    "cart/update",
    async (args, thunkAPI) => {
        try {
            return await cartService.cartUpdateQuantity(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const cartShow = createAsyncThunk(
    "cart/:id/show",
    async (args, thunkAPI) => {
        try {
            return await cartService.cartShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)  

export const cartDelete = createAsyncThunk(
    "cart/:id/delete",
    async (args, thunkAPI) => {
        try {
            return await cartService.cartDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    message:'',
    data: {},
    carts: {
        data: []
    },
    cartsTotal:0,
    cartsByFarmer:{},
    cart: {},
    loading: false,
    isSuccess: false,
    updating: false,
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
    },
    extraReducers : {
        [cartStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [cartStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.cart = payload.data.cart;
            state.loading=false;
            state.isSuccess = true;
        },
        [cartStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [cartUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [cartUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [cartUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [cartShow.pending]:(state, {payload}) => {
            state.data = {};
            state.cart = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [cartShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.cart = payload.data.cart;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [cartShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [cartIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [cartIndex.fulfilled]:(state, {payload}) => {
            // console.log(payload?.data?.carts);
            state.data          = payload;
            state.carts         = payload.data.carts;
            state.cartsTotal    = payload.data.cartsTotal;
            state.cartsByFarmer = payload.data.cartsByFarmer;
            state.message       = payload.message;
            state.loading       = false;
            state.updating      = false;
            state.isSuccess     = true;
        },
        [cartIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [cartDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [cartDelete.fulfilled]:(state, {payload}) => {
            state.data          = payload;
            state.cart = payload.data.cart;
            state.message       = payload.message;
            state.loading       = false;
            state.updating      = false;
            state.isSuccess     = true;
        },
        [cartDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export const { updateDeliveryFee } = cartSlice.actions

export default cartSlice;