import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  unitOfMeasureService from "./unitOfMeasureService";

export const unitOfMeasureIndex = createAsyncThunk(
    "unitOfMeasure/index",
    async (args, thunkAPI) => {
        try {
            return await unitOfMeasureService.unitOfMeasureIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const unitOfMeasureStore = createAsyncThunk(
    "unitOfMeasure/store",
    async (args, thunkAPI) => {
        try {
            return await unitOfMeasureService.unitOfMeasureStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const unitOfMeasureUpdate = createAsyncThunk(
    "unitOfMeasure/update",
    async (args, thunkAPI) => {
        try {
            return await unitOfMeasureService.unitOfMeasureUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const unitOfMeasureDelete = createAsyncThunk(
    "unitOfMeasure/delete",
    async (args, thunkAPI) => {
        try {
            return await unitOfMeasureService.unitOfMeasureDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const unitOfMeasureShow = createAsyncThunk(
    "unitOfMeasure/:id/show",
    async (args, thunkAPI) => {
        try {
            return await unitOfMeasureService.unitOfMeasureShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    unitOfMeasure: {},
    unitOfMeasures: {
        data: []
    },
    loading: false,
    isSuccess: false,
    updating: false,
}

export const unitOfMeasureSlice = createSlice({
    name: 'unitOfMeasure',
    initialState,
    reducers: {
    },
    extraReducers : {
        [unitOfMeasureStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [unitOfMeasureStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.unitOfMeasure = payload.data.unitOfMeasure;
            state.loading=false;
            state.isSuccess = true;
        },
        [unitOfMeasureStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [unitOfMeasureUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [unitOfMeasureUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [unitOfMeasureUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [unitOfMeasureDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [unitOfMeasureDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [unitOfMeasureDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [unitOfMeasureShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [unitOfMeasureShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.unitOfMeasure = payload.data.unitOfMeasure;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [unitOfMeasureShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [unitOfMeasureIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [unitOfMeasureIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.unitOfMeasures = payload.data.unitOfMeasures;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [unitOfMeasureIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export default unitOfMeasureSlice;