import api from "../../support/support_api";

const cropStore = async (params) => {
    const response  = await  api.axiosPost('/crops', params);

    return response.data;
}

const cropStoreWithoutStoreId = async (params) => {
    const response  = await  api.axiosPost('/crops/storeWithoutStoreId', params);

    return response.data;
}

const cropUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/crops/${params.crop_id}`, params);

    return response.data;
}

const cropDelete = async (params) => {
    const response  = await  api.axiosDelete(`/crops/${params.crop_id}`, params);

    return response.data;
}

const cropUploadPhotos = async (params) => {
    // const response  = await  api.axiosPatch(`/crops/${params.crop_id}`, params);
    //
    // return response.data;
}

const cropShow = async (params) => {
    const response  = await api.axiosShow(`/crops/${params.crop_id}`, params);

    return response.data;
}

const cropIndex = async (params) => {
    const response  = await api.axiosGet(`/crops`, params);

    return response.data;
}

const cropGuest = async (params) => {
    const response  = await api.axiosGet(`/crops/guest`, params);

    return response.data;
}

const cropAuth = async (params) => {
    const response  = await api.axiosGet(`/crops/auth`, params);

    return response.data;
}

const cropService = {
    cropStore,
    cropStoreWithoutStoreId,
    cropUpdate,
    cropUploadPhotos,
    cropShow,
    cropGuest,
    cropIndex,
    cropAuth,
    cropDelete
}

export default cropService;