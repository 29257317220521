
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const CreditManagement = ({ creditManagements }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="p-2 w-full max-w mt-1">
        {creditManagements?.data.length > 0 && creditManagements?.data.map(item => {
          return <div className="hidden laptop:flex flex-row items-center justify-center pb-2 font-light text-sm" key={item.id}>
            <div className="basis-1/4 flex items-startjustify-center font-medium">{item?.company?.name}</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{item?.company?.selected_package?.data.length > 0 && item?.company?.selected_package?.data[0].package?.package_name || 'N/A'}</div>
            <div className="basis-1/4 flex items-center justify-center font-medium">{item?.credit_balance}</div>
            <div className="basis-1/4 font-medium text-center"><span className={clsx(item?.is_auto_add_credit === 1 ? 'bg-green-300 text-green-700' : 'bg-red-300 text-red-600', 'w-fit rounded-lg px-3')}>{item?.is_auto_add_credit === 1 ? 'Active' : 'Inactive'}</span></div>
            <div className="basis-1/4 font-medium text-center"><span className={clsx(item?.is_auto_deduct_credit === 1 ? 'bg-green-300 text-green-700' : 'bg-red-300 text-red-600', 'w-fit rounded-lg px-3')}>{item?.is_auto_deduct_credit === 1 ? 'Active' : 'Inactive'}</span></div>
            <div className="basis-1/4 flex items-center justify-center font-medium space-x-3">
              <FontAwesomeIcon title='Edit' icon={faPenToSquare} className='text-xl text-main cursor-pointer' onClick={() => navigate(`/manage-credit/${item?.id}/edit`)} />
            </div>
          </div>
        })}
      </div>
    </>
  );
};
export default CreditManagement;
