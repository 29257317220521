import api from "../../support/support_api";

const productStore = async (params) => {
    const response  = await  api.axiosPost('/products', params);

    return response.data;
}

const productUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/products/${params.product_id}`, params);

    return response.data;
}

const productUploadPhotos = async (params) => {
    const response  = await  api.axiosPatch(`/products/${params.product_id}`, params);

    return response.data;
}

const productDelete = async (params) => {
    const response  = await  api.axiosDelete(`/products/${params.product_id}`);

    return response.data;
}

const productShow = async (params) => {
    const response  = await api.axiosShow(`/products/${params.product_id}`, params);

    return response.data;
}

const productIndex = async (params) => {
    const response  = await api.axiosGet(`/products`, params);

    return response.data;
}

const productCheckExist = async (params) => {
    const response  = await api.axiosGet(`/products/check-for-crops`, params);

    return response.data;
}

const productAll = async (params) => {
    const response  = await api.axiosGet(`/products/all`, params);

    return response.data;
}

const productService = {
    productStore, productUpdate, productUploadPhotos, productShow, productIndex, productDelete, productCheckExist, productAll
}

export default productService;