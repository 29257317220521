import api from "../../support/support_api";
import storage from "../../support/support_storage";

const subscribePackage = async (params) => {

    let response = await api.axiosPost('/company/subscribe-package', params)

    if(response.data) {
        storage.setUser(response.data)
    }

    return response.data;
}

const renewPackage = async (params) => {

    let response = await api.axiosPut(`/company/update-package/${params.id}`, params)

    return response.data;
}

const resubscribePackage = async (params) => {

    let response = await api.axiosPost('/company/resubscribe-package', params)

    if(response.data) {
        storage.setUser(response.data)
    }

    return response.data;
}

const companyPackageStore = async (params) => {
    const response  = await  api.axiosPost('/companyPackage', params);

    return response.data;
}

const retrieveCompanyPackage = async (params) => {
    const response  = await  api.axiosGet(`/company/package-details/${params.id}`);

    return response.data;
}

const companyPackageService = {
    subscribePackage, resubscribePackage, companyPackageStore, retrieveCompanyPackage, renewPackage
}

export default companyPackageService;