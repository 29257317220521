import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PlotPhoto from "../../../images/PlotPhoto.png";

const PlotWidget = ({ plot }) => {
  const navigate = useNavigate();
  const [photo, setPhoto] = useState(PlotPhoto);

  const manageHandler = (farmID) => {
    navigate(`/farms/${plot.farm_id}/plots/${plot.id}/edit`);
  };

  useEffect(() => {
    if (plot.photo_default) {
      setPhoto(plot.photo_default)
    }
  }, []);

  return (
    <div
      data-testid="product-catalog-widget"
      className="cursor-pointer relative w-full h-full rounded-lg shadow-lg border-2"
      onClick={() => manageHandler()}
    >
      <img
        src={photo}
        className="object-cover w-full h-full aspect-square rounded-lg"
      />
      <section className="absolute bg-black/50 text-white left-0 bottom-0 rounded-b-lg flex flex-col w-full h-1/4 p-2 box-img-card-70">
        <label className="cursor-pointer font-in-box-market">{plot.name}</label>
        <label className="cursor-pointer font-in-box-sub-market">{`Crop: ${plot.crop_name}`}</label>
        <label className="cursor-pointer font-in-box-sub-market">{`Stage of crop: ${plot.crop_stage}`}</label>
        <label className="cursor-pointer font-in-box-sub-market">{`Capacity: ${plot.harvest_expected_quantity}`}</label>
      </section>
    </div>

  );
};

export default PlotWidget;
