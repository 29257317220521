const WarSubsPack = () => {
    return (
        <div className="flex justify-center flex-col text-center ">
            <div className="p-2">
                <label className="font-bold text-lg p-2" style={{ fontSize: "27px" }}>
                    Subscription Package
                </label>
                <div>
                    <label className="text-sm p-2" style={{ fontSize: "20px" }}>
                        You haven't purchased a subscription package yet, please subscribe to take this action
                    </label>
                </div>

            </div>
        </div>
    );
}

export default WarSubsPack;