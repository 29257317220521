import api from "../../support/support_api";

const roomChatList = async (params) => {
    const response = await api.axiosGet('/room-chat', params);

    return response.data;
}

const roomChatPage = async (params) => {
    const response = await api.axiosGet('/room-chat/page', params);

    return response.data;
}

const sendMsg = async (params) => {
    const response = await api.axiosPost(`/room-chat/sendMsg`, params);

    return response.data;
}

const getTotalMsgByUser = async (params) => {
    const response = await api.axiosGet('/room-chat/total-msg-unread', params);

    return response.data;
}

const getUserIdAdmin = async (params) => {
    const response = await api.axiosGet('/room-chat/user-admin-id', params);

    return response.data;
}


const auctionBiddingService = {
    roomChatList,
    sendMsg,
    roomChatPage,
    getTotalMsgByUser,
    getUserIdAdmin
}


export default auctionBiddingService;