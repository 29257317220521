import PopupLayoutConfirmation from "pages/PopupLayoutConfirmation";

const PopupPay = (props) => {
  
  const cancelHandler = () => {
    props.onCancelIcon(false);
  };

  return (
    <PopupLayoutConfirmation onCancel={cancelHandler}>
      <label className="text-xl">{props.title}</label>
      <div className="w-full flex gap-4">
        <button className="btn-border-green w-[700px]" onClick={() => {
          props.onCancel(false);
        }}>
          {props.cancelButtonTitle}
        </button>
        <button className="saveButton w-[700px]"
          onClick={() => {
            props.onSuccess();
          }}
        >
          {props.successButtonTitle}
        </button>
      </div>
    </PopupLayoutConfirmation>
  );
};

export default PopupPay;
