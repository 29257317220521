import { useNavigate } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";

const ProfileMenuCustomer = () => {
    const navigate = useNavigate();

    return (
        <>
            <div
                className="unborderedButton"
                style={!window.location.pathname.includes(routesPaths.CUSTOMER_ORDER_FARMERS) 
                    && !window.location.pathname.includes(routesPaths.MANAGE_ADVANCE_BOOKING)
                    && !window.location.pathname.includes(routesPaths.MANAGE_AUCTION_BIDDING)
                    ? {backgroundColor:"white", color:"black"} : {backgroundColor:"#00AF54" , color:"white"}}
                onClick={() => {
                    navigate(routesPaths.CUSTOMER_ORDER_FARMERS);
                }} >
                My Orders
            </div>
            <div
                className="unborderedButton"
                style={!window.location.pathname.includes(routesPaths.DEMANDS) ? {backgroundColor:"white", color:"black"} : {backgroundColor:"#00AF54" , color:"white"}}
                onClick={() => {
                    navigate(routesPaths.DEMANDS);
                }} >
                My Demands
            </div>
        </>
    );
};

export default ProfileMenuCustomer;
