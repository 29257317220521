import api from "../../support/support_api";

const followCompany = async (params) => {
    const response  = await  api.axiosPost(`/companies/${params.companyId}/follow`, params);

    return response.data;
}

const unfollowCompany = async (params) => {
    const response  = await  api.axiosDelete(`/companies/${params.companyId}/unfollow`, params);

    return response.data;
}


const followerService = {
    followCompany,
    unfollowCompany
}

export default followerService;