import api from "../../support/support_api";

const auctionBiddingList = async (params) => {
    const response  = await  api.axiosGet('/auction-plots/getListManageAuction', params);

    return response.data;
}

const auctionBiddingGeneralList = async (params) => {
    const response  = await  api.axiosGet('/auction-plots', params);

    return response.data;
}

const getListMyAuctionBuyer = async (params) => {
    const response  = await  api.axiosGet('/auction-plots/getListMyAuctionBuyer', params);

    return response.data;
}

const auctionBiddingDetail = async (params) => {
    const response  = await  api.axiosGet('/auction-plots/'+params.id+'/getDetailAuctionPlot');

    return response.data;
}
const auctionBiddingDetailBuyer = async (params) => {
    const response  = await  api.axiosGet('/auction-plots/'+params.id+'/getDetailAuctionPlotBuyer');

    return response.data;
}

const auctionBiddingStatistic = async (params) => {
    const response  = await  api.axiosGet('/auction-plots/statistic', params);

    return response.data;
}

const auctionBiddingDelete = async (params) => {
    const response  = await  api.axiosDelete('/auction-plots/'+params.id);

    return response.data;
}


const auctionBiddingService = {
    auctionBiddingList,
    getListMyAuctionBuyer,
    auctionBiddingDetail,
    auctionBiddingDetailBuyer,
    auctionBiddingGeneralList,
    auctionBiddingStatistic,
    auctionBiddingDelete
}


export default auctionBiddingService;