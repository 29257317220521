import api from "../../support/support_api";

const catalogStore = async (params) => {
    const response  = await  api.axiosPost('/catalogs', params);

    return response.data;
}

const catalogUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/catalogs/${params.catalog_id}`, params);

    return response.data;
}

const catalogUploadPhotos = async (params) => {
    const response  = await  api.axiosPatch(`/catalogs/${params.catalog_id}`, params);

    return response.data;
}

const catalogShow = async (params) => {
    const response  = await api.axiosShow(`/catalogs/${params.catalog_id}`, params);

    return response.data;
}

const catalogIndex = async (params) => {
    const response  = await api.axiosGet(`/catalogs`, params);

    return response.data;
}

const catalogSearch = async (params) => {
    let response = null;
    if(params.searchBy == 'Product'){
        response  = await api.axiosGet(`/catalogs/search`, params);
    }else{
        response  = await api.axiosGet(`/plots/search`, params);
    }

    return response.data;
}

const catalogDestroy = async (params) => {
    const response  = await api.axiosDelete(`/catalogs/${params.catalog_id}`, params);

    return response.data;
}

const catalogService = {
    catalogStore, catalogUpdate, catalogUploadPhotos, catalogShow, catalogIndex, catalogDestroy, catalogSearch
}

export default catalogService;