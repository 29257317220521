import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auctionBiddingDetail } from "../../../features/auction-bidding/auctionBiddingSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import AccountLayout from "pages/AccountLayout";
import { Col, Modal, Row } from "react-bootstrap";
import { bookingPlot } from "features/catalog/plots/catalogPlotsSlice";
import { DateTime } from "luxon";
import { routesPaths } from "constants/routes.constants";

const AuctionBiddingDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const snackbarCtx = useContext(SnackbarContext);
  const [auctionPLot, setAuctionPlot] = useState(null);
  const id = params.auction_id;
  const { currentUser } = useSelector((state) => state.auth);

  // modal confirm createBook
  const [modalCreateBook, setModalCreateBookConf] = useState(false);
  const popupCloseModalCreateBookConf = () => setModalCreateBookConf(false);
  const popupShowModalCreateBookConf = () => setModalCreateBookConf(true);

  const loadDetail = () => {
    setIsLoading(true);
    dispatch(
      auctionBiddingDetail({
        id: id,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        popupCloseModalCreateBookConf();
        setIsLoading(false);
      } else {
        let result = response.payload.data.auction_plot;
        setAuctionPlot(result);
        popupCloseModalCreateBookConf();
        setIsLoading(false);
      }
    });
  };

  const confirmBooking = (event) => {
    setIsLoading(true);
    dispatch(
      bookingPlot({
        auction_id: auctionPLot.auction_id,
        plot_id: auctionPLot.plot_id,
        user_id: auctionPLot.auction_bid_plots[0].user_id,
        company_id: auctionPLot.auction_bid_plots[0].company_id,
        quantity: auctionPLot.available_quantity,
        quantity_unit: auctionPLot.available_quantity_unit,
        shipping_address: {
          fullname: auctionPLot.auction_bid_plots[0].user.user_address.address.fullname,
          email: auctionPLot.auction_bid_plots[0].user.user_address.address.email,
          phone: auctionPLot.auction_bid_plots[0].user.user_address.address.phone,
          country: 'Malaysia',
          state: auctionPLot.auction_bid_plots[0].user.user_address.address.state,
          city: auctionPLot.auction_bid_plots[0].user.user_address.address.city,
          postcode: auctionPLot.auction_bid_plots[0].user.user_address.address.postcode,
          address: auctionPLot.auction_bid_plots[0].user.user_address.address.address,
          lat: auctionPLot.auction_bid_plots[0].user.user_address.address.lat,
          lon: auctionPLot.auction_bid_plots[0].user.user_address.address.lon
        }
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        setIsLoading(false);
        popupCloseModalCreateBookConf();
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        setIsLoading(false);
        snackbarCtx.displayMsg(response.payload.message, "success");
        loadDetail();
      }
    });

    event.preventDefault();
  };

  useEffect(() => {
    loadDetail();
  }, []);

  return (
    <AccountLayout>
      <div className="w-full flex flex-col p-4 laptop:p-6 wide:p-6 mb-5">
        <Row className="mb-2">
          <Col md={6} sm={12}>
            <div className="title-content mb-3">
              Auction : {(auctionPLot) ? (auctionPLot) ? auctionPLot.auction_id : '' : ''}
            </div>
          </Col>
        </Row>
        <div className="flex flex-col rounded-lg shadow bg-card-white-new">
          <div>
            <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
              {" Go back to list"}
            </label>
          </div>

          <div className="text-center items-center mt-4" style={{ alignSelf: "center" }}>
            {((auctionPLot != null) && (auctionPLot.status_current != "Ended" && auctionPLot.status_current != "Order Created")) && (
              <>
                <table>
                  <tr>
                    <td style={{ textAlign: "left" }}>

                    </td>
                    <td style={{ textAlign: "center", color: "#395B50" }}>

                      <div style={{ fontSize: 15, fontWeight: "bold", color: "#395B50" }}>
                        DAY
                      </div>
                    </td>
                    <td style={{ textAlign: "center", color: "#395B50" }}>
                    </td>
                    <td style={{ textAlign: "center", color: "#395B50" }}>

                      <div style={{ fontSize: 15, fontWeight: "bold", color: "#395B50" }}>
                        HOUR
                      </div>
                    </td>
                    <td style={{ textAlign: "center", color: "#395B50" }}>
                    </td>
                    <td style={{ textAlign: "center", color: "#395B50" }}>
                      <div style={{ fontSize: 15, fontWeight: "bold", color: "#395B50" }}>
                        MIN
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      <div style={{ fontSize: 25 }} className="mr-5">
                        Time left:
                      </div>
                    </td>
                    <td style={{ textAlign: "center", color: "#00C960" }}>

                      <div style={{ fontSize: 40, fontWeight: "bold", color: "#00C960" }}>
                        {auctionPLot.time_left.days}
                      </div>
                    </td>
                    <td style={{ fontSize: 40, textAlign: "center", color: "#00C960" }}>
                      <div className="ml-3 mr-3">
                        :
                      </div>
                    </td>
                    <td style={{ textAlign: "center", color: "#00C960" }}>

                      <div style={{ fontSize: 40, fontWeight: "bold", color: "#00C960" }}>
                        {auctionPLot.time_left.hours}
                      </div>
                    </td>
                    <td style={{ fontSize: 40, textAlign: "center", color: "#00C960" }}>
                      <div className="ml-3 mr-3">
                        :
                      </div>
                    </td>
                    <td style={{ textAlign: "center", color: "#00C960" }}>
                      <div style={{ fontSize: 40, fontWeight: "bold", color: "#00C960" }}>
                        {auctionPLot.time_left.minutes}
                      </div>
                    </td>
                  </tr>
                </table>

              </>
            )}
          </div>

          <div className="text-center items-center lg:mt-7" style={{ alignSelf: "center" }}>
            <div className="flex flex-col tablet:flex-row ">
              <div className="lg:hidden items-center justify-center">
                <img
                  src={(auctionPLot != null) ? auctionPLot.plot.photo_default : "/assets/images/products/WheatIcon.svg"}
                  alt={(auctionPLot != null) ? auctionPLot.plot.name : "-"}
                  className="max-w-[8rem]  max-h-[8rem] aspect-square rounded-full"
                />
              </div>
              <div className="lg:hidden flex flex-col items-start justify-start px-3">
                <label className="text-sm font-semibold">{(auctionPLot != null) ? auctionPLot.plot.name : "-"}</label>
                <label className="mr-5">
                  <span className="text-sm font-semibold">Bids :</span>
                  <span className="text-green ml-3" style={{ fontWeight: "bold", fontSize: "17px" }}>
                    {(auctionPLot != null) ? auctionPLot.auction_bid_plots_count : "-"}
                  </span>
                </label>
                <label className="mr-5">
                  <span className="text-sm font-semibold">Bidders :</span>
                  <span className="text-green ml-3" style={{ fontWeight: "bold", fontSize: "17px" }}>
                    {(auctionPLot != null) ? auctionPLot.bidders_count : "-"}
                  </span>
                </label>
                <label className="">
                  <label className="text-sm font-semibold">Duration :</label>
                  <span className="text-green ml-3" style={{ fontWeight: "bold", fontSize: "17px" }}>
                    {(auctionPLot != null) ? `${auctionPLot.duration_day} Days` : "-"}
                  </span>
                </label>
                <label className="text-sm font-semibold">Current Highest Bid:</label>
                <label className="text-green" style={{ fontWeight: "bold", fontSize: "17px" }}>
                  {(auctionPLot != null) ? `RM ${auctionPLot.current_high_bid}` : "-"}
                </label>
              </div>
              <div className="hidden desktop:flex">
                <label className="mr-5">
                  <span className="text-sm font-semibold">Bids :</span>
                  <span className="text-green ml-3" style={{ fontWeight: "bold", fontSize: "17px" }}>
                    {(auctionPLot != null) ? auctionPLot.auction_bid_plots_count : "-"}
                  </span>
                </label>
                <label className="mr-5">
                  <span className="text-sm font-semibold">Bidders :</span>
                  <span className="text-green ml-3" style={{ fontWeight: "bold", fontSize: "17px" }}>
                    {(auctionPLot != null) ? auctionPLot.bidders_count : "-"}
                  </span>
                </label>
                <label className="mr-5">
                  <span className="text-sm font-semibold">Duration :</span>
                  <span className="text-green ml-3" style={{ fontWeight: "bold", fontSize: "17px" }}>
                    {(auctionPLot != null) ? `${auctionPLot.duration_day} Days` : "-"}
                  </span>
                </label>
                <label className="mr-5">
                  <span className="text-sm font-semibold">Current Highest Bid (MYR) :</span>
                  <span className="text-green ml-3" style={{ fontWeight: "bold", fontSize: "17px" }}>
                    {(auctionPLot != null) ? `RM ${auctionPLot.current_high_bid}` : "-"}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="mt-7" style={{ overflow: "auto" }}>
            <table className="" style={{ width: "100%" }}>
              <tr className="text-center" style={{ backgroundColor: "#00C960", color: "white" }}>
                <th style={{ padding: "10px" }}>
                  Ranking
                </th>
                <th>
                  Bid Price (RM/UNIT)
                </th>
                <th>
                  Final Price (RM)
                </th>
                <th>
                  Bid Time (MYT)
                </th>
                <th>
                  Auction Id
                </th>
                <th>
                  Bidder
                </th>
              </tr>
              {auctionPLot != null && (
                <>
                  {auctionPLot.auction_bid_plots.map((listValue, index) => {
                    return (
                      <tr key={index} className="text-center" style={(index != 0) ? { borderBottomWidth: "3px" } : { borderBottomWidth: "3px", backgroundColor: "rgb(172 197 181)" }}>
                        <td style={{ padding: "10px" }}>
                          {index + 1}
                        </td>
                        <td>
                          {listValue.bid_price}
                        </td>
                        <td>
                          {listValue.total_bid_price}
                        </td>
                        <td>
                          {DateTime.fromSQL(listValue.bid_time).toFormat('dd/LL/yyyy hh:mm a')}
                        </td>
                        <td>
                          {listValue.auction_id}
                        </td>
                        <td>
                          {listValue.user.name}
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}

            </table>
          </div>
          {(auctionPLot != null) && (
            <>
              <div className="text-center items-center mt-7" style={{ alignSelf: "center" }}>
                {(auctionPLot.status_current == "Ended") && (
                  <>
                    <button
                      className="btn-green w-[210px] mr-auto ml-auto mb-3 p-3"
                      onClick={popupShowModalCreateBookConf}
                    >Create Booking</button>
                  </>
                )}
                {(auctionPLot.status_current == "Order Created") && (
                  <>
                    <div style={{ color: "red" }}>
                      Auction has been transferred to ‘Advanced Booking’
                    </div>
                    <center>
                      <button type="button" className="text-center mt-4 btn-green w-fit" onClick={() => { navigate(routesPaths.MANAGE_ADVANCE_BOOKING) }}>
                        Go to Advanced Booking section
                      </button>
                    </center>
                  </>
                )}

              </div>
            </>
          )}

        </div>
      </div>
      {/* modal confirmation cancel advance booking */}
      <Modal className="modal-medium-custom" show={modalCreateBook} onHide={popupCloseModalCreateBookConf}>
        <Modal.Body>
          <Row className="mt-4 mr-4 ml-4">
            <Col md={12}>
              <center>
                <img src="/assets/images/plots/question.png" width={100} height={100} />
              </center>
              <br />
              <p style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>Create booking for [{(auctionPLot != null && auctionPLot.auction_bid_plots.length > 0) ? auctionPLot.auction_bid_plots[0].user.name : "-"}] ? <br />
              </p>
              <p style={{ fontSize: "12px", textAlign: "center" }}>
                By clicking yes, a booking will be created for the bidder with the highest bid.</p>
              <br />
              <Row className="mt-4">
                <Col md={6} xs={6}>
                  <center>
                    <button
                      type="button"
                      className="btn-green"
                      style={{ minHeight: "50px" }}
                      onClick={() => {
                        confirmBooking();
                      }}
                    >
                      {isLoading ? "Loading.." : "Yes"}
                    </button>
                  </center>
                </Col>
                <Col md={6} xs={6}>
                  <center>
                    <button type="button" onClick={popupCloseModalCreateBookConf} className="saveButton bg-red-500 hover:bg-red-600 hover:ring-red-600" style={{ minHeight: "50px" }}>
                      {isLoading ? "Loading.." : "No"}
                    </button>
                  </center>
                </Col>
              </Row>
            </Col>

          </Row>
        </Modal.Body>
      </Modal>
    </AccountLayout>
  );
};

export default AuctionBiddingDetail;
