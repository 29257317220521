import { useNavigate } from "react-router-dom";

import PopupLayout from "pages/PopupLayout";
import { routesPaths } from "constants/routes.constants";

const DemandAccept = () => {
  const navigate = useNavigate();

  const backHandler = () => {
    navigate(routesPaths.DEMANDS);
  }

  return (
    <PopupLayout>
      <label data-testid="demand-accept" className="text-xl">Farmer Accepted Successfully</label>
      <div className="w-full flex justify-center gap-4">
        <button className="borderedButton w-1/2" onClick={backHandler}>
          Go Back
        </button>
      </div>
    </PopupLayout>
  )
}

export default DemandAccept;