import React, { useState, useEffect, useContext } from "react";

import PageLayout from "pages/AccountLayout";
import DemandApplicantList from "./DemandApplicantList";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { demandIndex, demandShow, demandApplications } from "../../features/demand/demandSlice";
import { useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import PopupSuccesAcc from "./PopupSuccesAcc";

const DemandShow = () => {
  const [info, setInfo] = useState({
    id: 1,
    customer_id: 1,
    product_id: 1,
    farm_id: 1,
    grade_id: 1,
    name: "Bananas",
    photo: "/assets/images/products/BananasPhoto.svg",
    description: "Open",
    frequency: "Delivery Every Thursday",
    quantity_required: 150,
    unit: "KG",
    timeframe: "Week",
    applicants: [
      { photo: "/assets/images/StockGuy1.jpg", name: "Fresh Farms", location: "Brazil" },
      { photo: "/assets/images/StockGuy2.svg", name: "Greenville Farms", location: "California, US" },
      { photo: "", name: "", location: "" },
    ],
  });

  const tempInfo = {
    id: 1,
    customer_id: 1,
    product_id: 1,
    farm_id: 1,
    grade_id: 1,
    name: "Loading...",
    photo: "/assets/images/products/WheatIcon.svg",
    description: "Loading...",
    frequency: "Loading",
    quantity_required: 0,
    unit: "Loading...",
    timeframe: "Loading...",
    applicants: [{}],
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const params = useParams();
  const snackbarCtx = useContext(SnackbarContext);

  const reduxDemand = useSelector((state) => state.demand);

  const loadDemand = () => {

    dispatch(
      demandShow({
        demand_id: params.demand_id,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      }
    });
  }
  const loadDemandApplications = () => {
    dispatch(
      demandApplications({
        demand_id: params.demand_id,
        page: 1
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      }
    });
  }

  const [isShowPopSuccesAct, setIsShowPopSuccesAct] = useState(false);

  useEffect(() => {
    loadDemand()
    loadDemandApplications()
  }, []);

  return (
    <PageLayout>
      {isShowPopSuccesAct && <PopupSuccesAcc />}
      <div className="w-full flex flex-col p-4 laptop:p-6 wide:p-6 mb-5">
        <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
          {" Go Back"}
        </label>

        <Row className="mt-4">
          <Col md={12} sm={12}>
            <div className="title-content mb-3">
              {`Demand #${reduxDemand.demand.id} - ${reduxDemand.demand.name}`}
            </div>
          </Col>
        </Row>
        <Row >
          <Col md={12}>
            <div className="flex flex-col rounded-lg shadow bg-card-white-new">
              <Row className="items-center">
                <Col md={2} className="text-center">
                  <img
                    // src={orderDetail.product.photo ? info.photo : "/assets/images/products/WheatIcon.svg"}
                    src={reduxDemand.demand.photo_default}
                    alt={reduxDemand.demand.name}
                    style={{ margin: "auto", display: "block" }}
                    className="max-w-[9rem] max-h-[9rem] laptop:max-w-[18rem] laptop:max-h-[18rem] w-full h-full object-cover aspect-square rounded-full "
                  />

                </Col>
                <Col md={10} >
                  <div className="laptop:pl-10 flex flex-col">
                    <label className="text-2md font-semibold">{`Demand #${reduxDemand.demand.id} - ${reduxDemand.demand.name}`} </label>
                    <label>{reduxDemand.demand.quantity_required}
                      {reduxDemand?.demand?.grade?.unit?.name}/{reduxDemand.demand.frequncy_title}</label>
                    <label>
                      Status: {reduxDemand.demand.status_text}
                    </label>
                    <label>
                      {reduxDemand.demand.frequency_label}
                    </label>
                    {/*<label>{`${orderDetail.quantity} ${$orderDeta.grade.price_unit_symbol} / ${info.frequency}`}</label>*/}
                  </div>
                </Col>

              </Row>

            </div>
          </Col>

        </Row>

        <Row className="mt-4">
          <Col md={12} sm={12}>
            <div className="title-sidebar mb-3">
              Applicant List
            </div>
          </Col>
        </Row>
        <DemandApplicantList userDemandApplications={reduxDemand.user_demand_applications} setShowPopSuccesAct={setIsShowPopSuccesAct} />
      </div>
    </PageLayout>
  );
};

export default DemandShow;
