import clsx from "clsx";
import { Col, Row } from "react-bootstrap";
import CatalogControls from "./CatalogControls";
import ProductWidget from "./ProductWidget";
import PlotWidget from "./PlotWidget";
import PlotPageWidget from "./PlotPageWidget";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const CatalogList = ({ search, products, plots, searchByHandler, inputHandler, showSideBar, searchPlotByHandler, handleChangePage }) => {
  return (
    <>
      <div className="p-3 relative" style={{height: '88vh'}}>
        <Row className="mb-2">
          <Col md={6} sm={12}>
            <span className="title-content mb-1">
              Farmers Produce
            </span>
          </Col>
          <Col md={6} sm={12} className="text-right">
            <CatalogControls search={search} searchByHandler={searchByHandler} inputHandler={inputHandler} />

          </Col>
        </Row>
        <div className="ml-1 mb-2">
          <section className="flex gap-8">
            <label
              className={
                search.searchBy === "Product"
                  ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                  : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
              }
              onClick={() => searchByHandler("Product")}
            >
              Search by Product
            </label>
            <label
              className={
                search.searchBy === "Farmer"
                  ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                  : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
              }
              onClick={() => searchByHandler("Farmer")}
            >
              Search by Farms
            </label>
          </section>
        </div>
        {/* Actual Catalog */}
        {(search.searchBy == 'Product') ?
          <>
            <div className={clsx("w-full grid place-items-center tablet:grid-cols-3 mb-3 gap-4", !showSideBar ? "laptop:grid-cols-6 wide:grid-cols-7" : "laptop:grid-cols-3 wide:grid-cols-6")}>

              {products && products.data?.map((product, index) => {
                return <ProductWidget product={product} key={index} />;
              })}
            </div>
            {products && products.data.length == 0 && (<>
              <div className="flex lg:justify-center justify-center">
                <div>
                  Empty data
                </div>
              </div>
            </>)}
            {products && products.data.length > 0 && (<>
              <div className="flex lg:absolute lg:bottom-0 lg:right-0 lg:justify-end justify-center lg:pb-3">
                <Stack spacing={2} >
                  <Pagination count={products.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={search.page} />
                </Stack>
              </div>
            </>)}

          </>

          :
          <>
            <PlotPageWidget
              search={search}
              products={products}
              plots={plots}
              searchByHandler={searchByHandler}
              inputHandler={inputHandler}
              showSideBar={showSideBar}
              searchPlotByHandler={searchPlotByHandler}
              handleChangePage={handleChangePage}
            />
          </>
        }

      </div>

    </>

  );
};

export default CatalogList;
