import { useState } from "react";
import { useLocation } from "react-router-dom";

const RegisterProgress = () => {
  const location = useLocation();

  let progress = 1;
  if (location.pathname === "/register/details") {
    progress = 1;
  } else if (location.pathname === "/register/company") {
    progress = 2;
  } else if (location.pathname === "/register/type") {
    progress = 3;
  } else if (location.pathname === "/register/subscribe") {
    progress = 4;
  } else {
    progress = 5;
  }

  const progressBar = {
    width: `${(progress / 5) * 100}%`,
    height: "17px"
  };

  const activeTab = "text-green-800";

  return (
    <div className="flex flex-col gap-4 w-full" style={{ height: "17px" }}>
      {/* <div className="flex justify-between gap-x-4 text-xs laptop:text-lg">
        <label className={progress === 1 ? activeTab : ""}>Account Details</label>
        <label className={progress === 2 ? activeTab : ""}>Account Type</label>
        <label className={progress === 3 ? activeTab : ""}>Company Details</label>
      </div> */}
      <div className="bg-white flex rounded-lg" style={{ height: "17px" }}>
        <div className="bg-green-500 flex rounded-lg justify-center h-3" style={progressBar}></div>
      </div>
    </div>
  );
};

export default RegisterProgress;
