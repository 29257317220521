import { useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import * as statusConst from 'constants/advanceBooking.constants';
import { current } from "@reduxjs/toolkit";
const AuctionBidding = ({ auctionPlot, currentUser }) => {
    const navigate = useNavigate();


    const detailHandler = () => {
        navigate(`/manage-auction-bidding/detail/${auctionPlot.auction_id}`);
    };

    // let bookingStatus = statusConst.bookingPlotStatus[auctionPlot.status];

    return (
        <div data-testid="order-card" className="hover:ring-2 hover:ring-formDark bg-white rounded-lg w-full" style={{ borderBottomWidth: "1px" }}>
            <div className="hidden desktop:flex flex-row items-center justify-center py-3" style={{ borderBottomWidth: "1px" }}>
                <div className="basis-1/2 flex items-center justify-center font-medium">
                    <img
                        // src={orderDetail.product.photo ? info.photo : }
                        src={auctionPlot.plot ? auctionPlot.plot.photo_default : "/assets/images/products/WheatIcon.svg"}
                        alt={auctionPlot.plot ? auctionPlot.plot.name : "-"}
                        className="  orderLarge:inline min-w-[6rem] max-w-[6rem] min-h-[6rem] max-h-[6rem] object-cover aspect-square rounded-full"
                    />
                    <div className="hidden lg:flex flex-col ml-3">
                        <label className="text-2md font-semibold">{(auctionPlot.auction_id) ? auctionPlot.auction_id : "-"} </label>
                        <label>{`${auctionPlot.plot.name}`}</label>
                        {/*<label>{`${orderDetail.quantity} ${$orderDeta.grade.price_unit_symbol} / ${info.frequency}`}</label>*/}
                    </div>
                </div>
                <div className="basis-1/4 flex items-center justify-center font-medium"> {auctionPlot.bidding_start_date}</div>
                <div className="basis-1/4 flex items-center justify-center font-medium">{auctionPlot.bidding_end_date} </div>
                <div className="basis-1/4 flex items-center justify-center font-medium">
                    <div className="w-[7rem] text-center">
                        {(auctionPlot.status_current == "Ended" || auctionPlot.status_current == "Order Created" || auctionPlot.status_current == "Canceled") ? (
                            <>
                                -
                            </>
                        ) : (
                            <>
                                {`${auctionPlot.time_left.days} Days - ${auctionPlot.time_left.hours} Hours - ${auctionPlot.time_left.minutes} Minutes`}
                            </>
                        )}
                    </div>

                </div>
                <div className="basis-1/4 flex items-center justify-center font-medium">
                    {(auctionPlot.status_current == "New") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#7EDBF8"} style={{backgroundColor: "#7EDBF8"}}>
                                {auctionPlot.status_current}</Badge>
                        </>
                    )}
                    {(auctionPlot.status_current == "In Progress") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#FFC107"} style={{backgroundColor: "#FFC107"}}>
                                {auctionPlot.status_current}</Badge>
                        </>
                    )}
                    {(auctionPlot.status_current == "Ended") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#E54C4C"} style={{backgroundColor: "#E54C4C"}}>
                                {auctionPlot.status_current}</Badge>
                        </>
                    )}
                    {(auctionPlot.status_current == "Canceled") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#E54C4C"} style={{backgroundColor: "#E54C4C"}}>
                                {auctionPlot.status_current}</Badge>
                        </>
                    )}
                    {(auctionPlot.status_current == "Order Created") && (
                        <>
                            <Badge className="ml-2 text-center" bg={"#00C960" } style={{backgroundColor: "#00C960"}}>
                                {auctionPlot.status_current}</Badge>
                        </>
                    )}
                </div>
                <div className="basis-1/4 flex items-center justify-center font-medium">
                    {(auctionPlot.high_bid_price_current != null) ? (
                        <>
                            RM {auctionPlot.high_bid_price_current}
                        </>
                    ) : "-"}
                </div>
                <div className="basis-1/4 flex items-center justify-center font-medium">
                    {auctionPlot.status_current != "New" && (auctionPlot.status_current != "Canceled") && (
                        <>
                            <button
                                className="btn-border-green w-[120px] mr-auto ml-auto mb-3"
                                onClick={detailHandler}
                            >{(auctionPlot.status_current == "In Progress" || auctionPlot.status_current == "Order Created") ? 'View Bidders' : 'Create Booking'}</button>
                        </>
                    )}
                </div>
                {/* <div className="basis-1/4 flex items-center justify-center font-medium"></div> */}
            </div>
            <div className="desktop:hidden w-full flex flex-row p-2 gap-4 items-center justify-center">
                <section className="items-center gap-x-8 text-center laptop:flex laptop:flex-row">
                    <div>
                        <label className="lg:hidden text-md font-semibold mt-2">{(auctionPlot.auction_id) ? auctionPlot.auction_id : "-"} </label>
                        <div className="flex justify-center">
                        <img
                            // src={orderDetail.product.photo ? info.photo : "/assets/images/products/WheatIcon.svg"}
                            src={auctionPlot.plot ? auctionPlot.plot.photo_default : "/assets/images/products/WheatIcon.svg"}
                            alt={auctionPlot.plot ? auctionPlot.plot.name : "-"}
                            className="min-w-[4rem] max-w-[4rem] min-h-[4rem] max-h-[4rem] object-cover aspect-square rounded-full"
                        />
                        </div>
                        <label className="lg:hidden text-sm font-semibold">{`${auctionPlot.plot.name}`} </label>  <br/>
                    </div>
                    <div>
                        <label className="text-sm font-semibold">Status:</label>
                        <label className="mt-[-0.5rem]" style={{ whiteSpace: 'normal' }}>
                            {(auctionPlot.status_current == "New") && (
                            <>
                                <Badge className="ml-2 text-center" bg={"#7EDBF8"} style={{ padding: "4px", backgroundColor: "#7EDBF8"}}>
                                    {auctionPlot.status_current}</Badge>
                            </>
                            )}
                            {(auctionPlot.status_current == "In Progress") && (
                                <>
                                    <Badge className="ml-2 text-center" bg={"#FFC107"} style={{ padding: "4px", backgroundColor: "#FFC107"}}>
                                    {auctionPlot.status_current}</Badge>
                                </>
                            )}
                            {(auctionPlot.status_current == "Ended") && (
                                <>
                                    <Badge className="ml-2 text-center" bg={"#E54C4C"} style={{ padding: "4px", backgroundColor: "#E54C4C"}}>
                                    {auctionPlot.status_current}</Badge>
                                </>
                            )}
                            {(auctionPlot.status_current == "Canceled") && (
                                <>
                                    <Badge className="ml-2 text-center" bg={"#E54C4C"} style={{ padding: "4px", backgroundColor: "#E54C4C"}}>
                                    {auctionPlot.status_current}</Badge>
                                </>
                            )}
                            {(auctionPlot.status_current == "Order Created") && (
                                <>
                                    <Badge className="ml-2 text-center" bg={"#00C960" } style={{ padding: "4px", backgroundColor: "#00C960"}}>
                                    {auctionPlot.status_current}</Badge>
                                </>
                            )}</label>
                    </div>
                    <div>
                        <label className="lg:hidden">
                            <span className="text-sm font-semibold mr-2">Start Date:</span>
                        <label className="mt-[-0.5rem]">{auctionPlot.bidding_start_date}</label> </label>
                        <label className="lg:hidden" style={{textAlign:"center"}}>
                            <span className="text-sm font-semibold mr-2">End Date:</span>
                            <label className="mt-[-0.5rem]">{auctionPlot.bidding_end_date} </label> </label>
                        <label className="lg:hidden" style={{textAlign:"center"}}>
                        <span className="text-sm font-semibold mr-2">Time left:</span>
                        <label className="mt-[-0.5rem]">{(auctionPlot.status_current == "Ended" || auctionPlot.status_current == "Order Created" || auctionPlot.status_current == "Canceled") ? (
                            <>
                            -
                            </>
                        ) : (
                            <>
                                {`${auctionPlot.time_left.days} Days - ${auctionPlot.time_left.hours} Hours - ${auctionPlot.time_left.minutes} Minutes`}
                            </>
                    )}</label> </label>
                    </div>
                    <div>
                        <label className="lg:hidden" style={{textAlign:"center"}}>
                            <span className="text-sm font-semibold">Current Highest Bid:</span>
                            <label className="ml-2">{(auctionPlot.high_bid_price_current != null) ? (
                            <>
                                RM {auctionPlot.high_bid_price_current}
                            </>
                        ) : "-"}</label> </label>
                    </div>
                  </section>
            </div>
            <div className="desktop:hidden basis-1/4 flex items-center justify-center font-medium">
                    {(auctionPlot.status_current != "New") && (auctionPlot.status_current != "Canceled") && (
                        <>
                            <button
                                className="btn-border-green w-[150px] mr-auto ml-auto mb-3 mt-2 flex justify-center"
                                onClick={detailHandler}
                            >{(auctionPlot.status_current == "In Progress" || auctionPlot.status_current == "Order Created") ? 'View Bidders' : 'Create Booking'}</button>
                        </>
                    )}
                </div>
        </div>
    );
};

export default AuctionBidding;
