import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import registerService from "./registerService";


export  const  registerDetail = createAsyncThunk(
    "auth/users/register-detail",
    async (user, thunkAPI) => {
        try {
            return await registerService.registerDetail(user);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const registerType = createAsyncThunk(
    "users/register-type",
    async (dataRequest, thunkAPI) => {

        try {
            return await registerService.registerType(dataRequest);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const registerCompany = createAsyncThunk(
    "users/register-company",
    async (company, thunkAPI) => {
        try {
            return await registerService.registerCompany(company);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)
const initialState = {
    data: {},
    loading: false,
    isSuccess: false,
    test:'aaa',
    authenticated:false
}

export const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        //
    },
    extraReducers : {
        [registerDetail.pending]:(state, {}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [registerDetail.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [registerDetail.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

export default registerSlice;