import { useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import noPhoto from "images/no_photo.png";
import * as statusConst from "constants/normalOrder.constants";

const OrderFarmer = ({ orderFarmer }) => {
    const navigate = useNavigate();

    const editHandler = () => {
        navigate(`/customer/order-farmers/${orderFarmer.id}/tracking`);
    };

    return (
        <div data-testid="order-card" className="hover:ring-2 hover:ring-formDark bg-white w-full hover:rounded-lg mb-1 border-b-2">
            <div className=" w-full flex flex-row p-2 gap-4 items-center ">
                <section className="basis-1/4 desktop:flex items-center gap-x-8 ">
                    <img
                        src={orderFarmer?.detail?.grade_product?.photo ?? noPhoto}
                        alt={orderFarmer?.detail?.product?.name}
                        className="  orderLarge:inline min-w-[8rem] max-w-[8rem] min-h-[8rem] max-h-[8rem] object-cover aspect-square rounded-full"
                    />
                    <div className="hidden lg:flex flex-col">
                        <label className="text-2md font-semibold">#{orderFarmer?.order?.order_number} </label>
                        <label>{`${orderFarmer?.detail?.product?.name}`}</label>
                        <label>{`${orderFarmer?.detail?.grade_product?.name}`}</label>
                        <label>{`${orderFarmer?.detail?.product?.demand ? orderFarmer?.detail?.product?.demand.frequency_label : ""}`}</label>
                        <label>{`${orderFarmer?.detail?.quantity} ${orderFarmer?.detail?.grade_product?.unit?.name}`}</label>
                    </div>
                </section>
                <section className="basis-1/4 flex gap-4 items-center justify-center productSmall:flex-col">
                    <div className="flex flex-col gap-1 items-center ">
                        <label className="lg:hidden text-2md font-semibold text-center">Order #{orderFarmer?.order?.order_number} </label>
                        <label className="lg:hidden text-center">
                            <span className="text-sm font-semibold">Quantity:</span>
                            <label className="ml-2">{`${orderFarmer?.detail?.quantity} ${orderFarmer?.detail?.grade_product && orderFarmer?.detail?.grade_product?.unit?.name}`}</label> </label>
                        <label className="lg:hidden" style={{ textAlign: "center" }}>
                            <span className="text-sm font-semibold">Grade:</span>
                            <span className="ml-2">{`${orderFarmer?.detail?.grade_product?.name}`}</span> </label>
                        <label className=" text-center w-[7rem]">
                            <span className="text-sm font-semibold">Price:</span>
                            <label className="ml-2">{orderFarmer?.price_unit} {orderFarmer.amount_total_overall}</label> </label>
                    </div>
                </section>
                <section className="basis-1/4 flex gap-4 items-center justify-center">
                    <div className="hidden orderSmall:flex gap-3 items-center">
                        <label className="text-center w-[7rem]">
                            <Badge bg={statusConst.normalOrderStatus[orderFarmer.status]?.bg_color.slice(3)} className={(orderFarmer.status === -1) ? 'text-black' : ''}>{statusConst.normalOrderStatus[orderFarmer.status]?.label}</Badge>
                        </label>
                    </div>
                </section>
                <section className="basis-1/4 flex gap-4 items-center justify-end">
                    <button className="hidden laptop:flex btn-border-green w-[120px] items-center justify-center text-center" onClick={editHandler}>Details</button>
                </section>
            </div>
            <div className="laptop:hidden wide:hidden flex w-full space-x-2 text-center mb-2">
                <button className="btn-border-green w-[150px] mr-auto ml-auto mb-3" onClick={editHandler}>Details</button>
            </div>
        </div>
    );
};

export default OrderFarmer;
