
import "./CompanyDetails.css";

const CompanyDetailsHome = ({ company }) => {
    return (
        <>
            <div className="mx-4 mb-2 text-2xl font-extrabold text-greenGray">{company?.name}</div>
            <div className="flex flex-col rounded-lg shadow bg-card-white-farm-show">
                <p className="text-lg"><b>Company Registration Number:</b> {company?.registration_number}</p>
                <p className="text-lg"><b>Contact Number:</b> {company?.business_contact_number}</p>
                <p className="text-lg"><b>Address:</b> {company?.billing_address}</p>
            </div>
        </>
    );
};

export default CompanyDetailsHome;


