import { routesPaths } from "constants/routes.constants";
import { Link } from "react-router-dom";
import CartProgress from "./CartProgress";
// import { routesPaths } from "constants/routes.constants";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useState, useEffect} from "react";
import {getCurrentUserInfo} from "../../features/auth/authSlice";
const CartDone = () => {
    const { currentUser, token, currentCompany } = useSelector((state) => state.auth);

    const navigate = useNavigate();
    const [productAdded, setProductAdded] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();

  const trackHandler = () => {
      if(currentUser.role_active ===  "customer") {
          navigate(routesPaths.CUSTOMER_ORDER_FARMERS)
      } else {
          navigate(routesPaths.ORDER_FARMERS)
      }
  };

    useEffect(() => {
        dispatch(getCurrentUserInfo());
    }, []);


    return (
    <div data-testid="cart-done" className="bg-gray-50 min-h-screen p-4 grid gap-4 grid-cols-1 tablet:grid-cols-4 laptop:grid-cols-4 desktop:grid-cols-6">
      <Link to={routesPaths.CATALOG} className="font-medium text-center">
        {"< Go Back To Catalog"}
      </Link>
      <div className="max-w-[60rem] w-full justify-self-center flex flex-col gap-4 col-span-1 tablet:col-span-2 laptop:col-span-3 desktop:col-span-4 desktop:col-start-2">
        <CartProgress />
        <section className="bg-white flex flex-col rounded-lg shadow">
          <div className="bg-white w-full flex flex-col items-center rounded-t-lg p-8">
            <img src="/assets/images/CheckmarkIcon.png" alt="checkmark icon" className="w-[7rem] h-[7rem]" />
          </div>
          <div className="self-center w-full items-center tablet:flex flex-col p-6 gap-6 laptop:self-center w-1/2 flex flex-col text-center p-12 gap-12 items-center ">
            <p className="text-2xl font-semibold">Everything went well. Soon your delivery will arrive.</p>
            <p className="text-xl font-semibold">You can track its status here</p>
            <button className="saveButton w-fit rounded-md w-[15rem]" onClick={trackHandler}>
              Track Delivery
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CartDone;
