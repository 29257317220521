import { routesPaths } from "constants/routes.constants";
import PopupLayout from "pages/PopupLayout";
import { useNavigate } from "react-router-dom";
import success from "../../images/newSucess.png";

const PopupSuccesAcc = () => {
  const navigate = useNavigate();



  const demandsHandler = () => {
    navigate(routesPaths.DEMANDS);
  };

  return (
    <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
      <section
        data-testid="popup-layout"
        className="absolute bg-formLight w-[20rem] flex flex-col rounded-xl shadow"
      >

        <div className="self-center w-full flex flex-col p-8 gap-8 text-center items-center">
          <div className="flex flex-col h-80 self-center rounded-md space-y-8">
            <div className=" flex justify-center h-28 rounded-t-md">
              <img src={success} style={{ width: "100px" }} className="self-center" />
            </div>
            <div className="flex flex-col items-center text-center space-y-4">
              <h1 className="text-xl" style={{ fontSize: "18px", fontWeight: "bold", color: "#395B50" }}>Done!</h1>
              <span style={{ color: "#545556", fontSize: "14px", fontWeight: "600" }}>Farmer application accepted succesfully</span>
            </div>
            <button style={{ backgroundColor: "#00AF54", color: "white", fontWeight: "600" }} type="button" className="w-1/2 border-[.09rem] border-buttonBorder self-center hover:bg-green-500 hover:text-white" onClick={demandsHandler}>
              {/*<Link to={routesPaths.ACCOUNT} className="hover:text-green-500">*/}
              Go Back
              {/*</Link>*/}
            </button>
          </div>
        </div>
      </section>
    </div>

  );
};

export default PopupSuccesAcc;
