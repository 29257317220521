import { Spinner } from "react-bootstrap";

const LoadingWidget = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
          <section data-testid="popup-layout" className="absolute w-4/5 tablet:w-4/5 tablet:h-1/4 desktop:w-1/3 laptop:h-1/3 desktop:h-1/4 flex flex-col" style={{ alignItems: "center", justifyContent: "center" }}>
            <Spinner animation="border" variant="light" size="lg" >
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
            <span className="mt-2" style={{ fontSize: "15px", color: "white" }}>
              Loading...
            </span>
          </section>
        </div>
      )}
    </>
  );
};

export default LoadingWidget;
