import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { plotStore } from "../../../features/plot/plotSlice";
import AccountLayout from "pages/AccountLayout";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { companyCropIndex } from "features/company-crop/companyCropSlice";
import { productIndex } from "features/product/productSlice";
import NewPhotoSlider from "components/files/NewSlider/NewPhotoSlider";
import { fileUpload } from "features/file/fileSlice";
import { unitOfMeasureIndex } from "features/unit-of-measure/unitOfMeasureSlice";

const PlotCreate = () => {
  const { updating } = useSelector((state) => state.plot);
  const snackbarCtx = useContext(SnackbarContext);
  const [name, setName] = useState("");
  const [plantingStartDate, setPlantingStartDate] = useState("");
  const [harvestStartDate, setHarvestStartDate] = useState("");
  const [harvestEndDate, setHarvestEndDate] = useState("");
  const [harvestExpectedQuantity, setHarvestExpectedQuantity] = useState("");
  const [cropId, setCropId] = useState("");
  const [cropStage, setCropStage] = useState("")
  const [photos, setPhotos] = useState([]);
  const [formDatas, setFormDatas] = useState([]);
  const [inputElementPhoto, setInputElementPhoto] = useState("");
  const [productId, setProductId] = useState("");
  const { unitOfMeasures } = useSelector((state) => state.unitOfMeasure);
  const { products } = useSelector((state) => state.product);
  const { companyCrops } = useSelector((state) => state.companyCrop);
  const [productList, setProductList] = useState([]);
  const [unit, setUnit] = useState(unitOfMeasures?.data[0]?.name || "");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const save = (event) => {
    event.preventDefault();

    let farmId = params.farm_id;

    if (parseInt(harvestExpectedQuantity) < 0 || harvestExpectedQuantity === "") {
      snackbarCtx.displayMsg("Quantity cannot be zero.", "failure");
      return;
    }

    if (productId === "" || productId === null) {
      snackbarCtx.displayMsg("Product is not selected", "failure");
      return;
    }

    dispatch(
      plotStore({
        farm_id: params.farm_id,
        name: name,
        planting_start_date: plantingStartDate,
        harvest_start_date: harvestStartDate,
        harvest_end_date: harvestEndDate,
        harvest_expected_quantity: harvestExpectedQuantity,
        unit: unit,
        crop_id: cropId,
        crop_stage: cropStage,
        product_id: productId
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        let plot = response.payload.data.plot;

        if (formDatas.length > 0) {
          for (let index = 0; index < formDatas.length; index++) {
            const formData = new FormData();

            formData.append("file", formDatas[index], formDatas[index].name);
            formData.append("store", "multiple");
            formData.append("model", "plot");
            formData.append("type", "photo");
            formData.append("model_id", plot.id);

            dispatch(fileUpload(formData)).then(function (response) {
              if (response.error && response.error.message === "Rejected") {
                alert(response.payload);
              } else {
                let photos = response.payload.data.plot.photos.data;

                setPhotos(photos);
              }
            });
          }
        }

        navigate(`/farms/${farmId}/edit`);
      }
    });

  };

  const uploadPhotos = (event) => {
    let length = event.target.files.length;
    let newArr = [];
    let newFormDataArr = [];

    for (let i = 0; i < length; i++) {
      let obj = {};
      let imageUrl = URL.createObjectURL(event.target.files[i]);

      obj['src'] = imageUrl;
      newArr.push(obj)
      newFormDataArr.push(event.target.files[i]);
    }

    setFormDatas(newFormDataArr);
    setPhotos(newArr);
  };

  const selectCropVariety = cropId => {
    setCropId(cropId);

    let product = products?.data.filter(product => product?.company_crop?.id === cropId);
    setProductList(product);
  }

  const loadProducts = () => {
    dispatch(
      productIndex()
    );
  };

  const loadUnitOfMeasure = () => {
    dispatch(
      unitOfMeasureIndex()
    )
  }

  const loadCompanyCrops = () => {
    dispatch(
      companyCropIndex()
    )
  }

  useEffect(() => {
    loadProducts();
    loadCompanyCrops();
    loadUnitOfMeasure();
  }, []);

  return (
    <AccountLayout>
      <div className="w-full flex flex-col p-4 laptop:p-6 wide:p-6 mb-5">
        <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
          {" Go Back"}
        </label>
        <div className="title-content mb-3">
          Create Plot Details
        </div>
        <div className="flex flex-col rounded-lg shadow bg-card-white-new">
          <Row>
            <Col md={6}>
              <NewPhotoSlider name={""} address={""} photos={photos} reloadData={null} />
              <button type="button" className="btn-border-green w-full mt-2 mb-2 justify-center" onClick={() => {
                inputElementPhoto.click();
              }}>
                <label
                  className="cursor-pointer text-sm"
                >
                  Upload Photos
                </label>
                <input
                  className={"hidden"}
                  type="file"
                  accept="image/png, image/jpeg"
                  multiple
                  onChange={uploadPhotos}
                  ref={(input) => setInputElementPhoto(input)}
                />
              </button>
            </Col>
            <Col md={1}>
            </Col>
            <Col md={5}>
              <form className="flex flex-col gap-4" onSubmit={save}>
                <div className="flex flex-col gap-2">
                  <label>Plot Name:</label>
                  <input
                    type="text"
                    className="input-search"
                    placeholder="Plot Name"
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label>Crop & Variety:</label>
                  <select className="input-search" defaultValue={"DEFAULT"} onChange={({ target }) => selectCropVariety(parseInt(target.value))}>
                    <option value="DEFAULT" disabled>
                      Select crop & variety
                    </option>
                    {companyCrops && companyCrops?.data.map((crop, index) => {
                      return <option value={parseInt(crop?.id)} key={index}>{crop.master_crop?.name} - {crop.variety}</option>;
                    })}
                  </select>
                </div>
                <div className="flex flex-col">
                  <label>Product Name:</label>
                  <select className={productList.length > 0 ? "input-search" : "bg-gray-300 input-search"} defaultValue={"DEFAULT"} disabled={productList.length === 0} onChange={({ target }) => setProductId(parseInt(target.value))}>
                    <option value="DEFAULT" disabled>
                      {productList.length > 0 ? "Select product" : "No product available under this crop"}
                    </option>
                    {productList && productList.map((product, index) => {
                      return <option value={parseInt(product?.id)} key={index}>{product?.name}</option>;
                    })}
                  </select>
                </div>
                <div className="flex flex-col">
                  <label>Stage of the crop:</label>
                  <input
                    className="input-search"
                    type="text"
                    placeholder="Stage of the crop"
                    value={cropStage}
                    onChange={({ target }) => setCropStage(target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label>Planting Start Date:</label>
                  <input
                    className="input-search"
                    type="date"
                    placeholder="Planting Start Date"
                    value={plantingStartDate}
                    onChange={({ target }) => setPlantingStartDate(target.value)}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-1/2 flex flex-col gap-2">
                    <label>Harvesting Start Date:</label>
                    <input
                      type="date"
                      className="input-search"
                      placeholder="Harvesting Start Date"
                      value={harvestStartDate}
                      onChange={({ target }) => setHarvestStartDate(target.value)}
                    />
                  </div>
                  <div className="w-1/2 flex flex-col gap-2">
                    <label>Harvesting End Date:</label>
                    <input
                      type="date"
                      className="input-search"
                      placeholder="Harvesting End Date"
                      value={harvestEndDate}
                      onChange={({ target }) => setHarvestEndDate(target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <label>Expected Harvest Quantity:</label>
                  <div className="flex gap-4">
                    <input
                      type="number"
                      min="0"
                      className="input-search"
                      placeholder="Quantity"
                      value={harvestExpectedQuantity}
                      onChange={({ target }) => setHarvestExpectedQuantity(target.value)}
                    />
                    <select className="input-search w-1/3" defaultValue={unitOfMeasures?.data[0]?.name} onChange={({ target }) => setUnit(target.value)} value={unit}>
                      <option value="DEFAULT" disabled>
                        Select Unit
                      </option>
                      {unitOfMeasures.data.map((unit, index) => (
                        <option value={unit.name} key={index}>{unit.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className=" mt-4">
                  <button type="submit" className="saveButton">
                    {updating ? "Processing, please wait.." : "Save"}
                  </button>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    </AccountLayout>
  );
};

export default PlotCreate;
