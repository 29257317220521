import AuthLayout from "pages/AuthLayout";
import ForgotPasswordForm from "../../components/forgot-password/ForgotPasswordForm"

const ForgotPasswordPage = () => {
  return (
    <AuthLayout>
      <div className="flex flex-col items-center">
        <ForgotPasswordForm />
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
