import PageLayout from "pages/AccountLayout";
import UserDetail from "./NotificationDetail";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountAsc } from '@fortawesome/free-solid-svg-icons';

import { useState, useEffect, useContext } from "react";
import { notificationIndex, userIndex } from "../../features/user/userSlice";

import { useDispatch } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { useSelector } from "react-redux/es/index";
import MessageEmpty from "../ui/message/empty/MessageEmpty";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import NotificationDetail from "./NotificationDetail";
import { updateNotifFirebase } from "features/auth/authSlice";

const NotificationList = () => {
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);

  const [userList, setUserList] = useState({
    data: []
  })
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const loadUser = (pageNumber) => {
    setIsLoading(true);
    dispatch(
      notificationIndex({
        limit: 10,
        page: pageNumber
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
        setIsLoading(false);
      } else {
        let users = response.payload.data.notifications;
        setUserList(users);
        setIsLoading(false);
      }
    });
  }

  const handleChangePage = (event, pageNumber) => {
    // if (pageNumber > currentPage) {
    // } else if (pageNumber < currentPage) {
    // }
    setCurrentPage(pageNumber);
    loadUser(pageNumber);
  };

  useEffect(() => {
    loadUser();
    dispatch(updateNotifFirebase(1));
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      // Send a message to the service worker to get the variable
      navigator.serviceWorker.controller.postMessage({ type: 'clearNotif' });
    }
  }, []);

  return (
    <>
      <PageLayout>
        <div data-testid="user-list" className="flex flex-col items-start gap-6 p-4">
          <h1 className="text-3xl text-left font-semibold font-regular" style={{ color: "#395B50" }}>Notifications</h1>
          <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg font-regular">
            <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
              <div className="flex items-center tablet:flex desktop:w-full justify-end">
                <div style={{ display: "inline" }} className="mr-6">
                  <div style={{ display: "inline" }} className="mr-3">
                    <img
                      // src={orderDetail.product.photo ? info.photo : }
                      src={"/assets/images/notification/cross.png"}
                      className="  orderLarge:inline min-w-[1rem] max-w-[1rem] min-h-[1rem] max-h-[1rem] object-cover aspect-square rounded-full"
                    />
                  </div>
                  <div style={{ display: "inline" }}>
                    Clear All Notification
                  </div>
                </div>

                <div style={{ display: "inline" }}>
                  <div style={{ display: "inline" }} className="mr-3">
                    <img
                      // src={orderDetail.product.photo ? info.photo : }
                      src={"/assets/images/notification/setting.png"}
                      className="  orderLarge:inline min-w-[1rem] max-w-[1rem] min-h-[1rem] max-h-[1rem] object-cover aspect-square rounded-full"
                    />
                  </div>
                  <div style={{ display: "inline" }}>
                    Notification settings
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="p-2 w-full max-w" style={{backgroundColor:"#00AF54", color:"white"}}>
            <div className="hidden laptop:flex flex-row items-center justify-center text-sm text-semibold font-regular">
              <div className="flex items-center justify-center font-medium"></div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Description</div>
            </div>
          </div> */}
            <div className="p-2 w-full max-w mt-1">
              {userList.data && userList.data.map((users, index) => (
                <NotificationDetail users={users} loadUser={loadUser} key={index} />
              ))}
              {!isLoading && userList && userList.data.length == 0 && (<>
                <div className="flex lg:justify-center justify-center">
                  <div>
                    Empty data
                  </div>
                </div>
              </>)}
              {/* <UserDetail/> */}
            </div>
          </div>
          <Stack spacing={2}>
            <Pagination count={userList.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={currentPage} />
          </Stack>
        </div>

      </PageLayout >
    </>
  );
};
export default NotificationList;