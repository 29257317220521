import api from "../../support/support_api";

const packageIndex = async (params) => {
    const response  = await api.axiosGet(`/package`, params);

    return response.data;
}

const packageService = {
    packageIndex
}

export default packageService;