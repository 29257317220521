import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";
// import { useState } from "react";
import * as url from "../../../support/support_url";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SnackbarContext from "_helpers/snackbar-context";
import {
  demandFarmerApply, demandSearch, demandShow, demandShowGuest,
  demandStore
} from "../../../features/demand/demandSlice";
import { useSelector } from "react-redux";
import { getCurrentUserInfo } from "../../../features/auth/authSlice";
import { cropAuth } from "../../../features/crop/cropSlice";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faCalendar, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
// import { routesPaths } from "../../../constants/routes.constants";
import "./Market.css";
import CustomImageDemands from "../CustomImageDemands";
import PopupImageSlider from "components/catalogs/PopupImageSlider";
import Footer from "components/footer/footer";

const DemandMarketApply = () => {
  // const navigate = useNavigate();
  const default_product_photo = "/assets/images/products/WheatIcon.svg";

  const [info, setInfo] = useState({
    id: 1,
    customer_id: 1,
    product_photo: "",
    product_required: "",
    grade_required: "",
    quantity_required: 0,
    unit: "",
    frequency: "",
    time: "",
    requirement_open_date: "",
    requirement_close_date: "",
    status: "",
    location: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const { isLoggedIn } = useSelector((state) => state.auth);

  const reduxDemand = useSelector((state) => state.demand);
  const snackbarCtx = useContext(SnackbarContext);

  const [gradeId, setGradeId] = useState(reduxDemand.demand.grade_id);

  const reduxCrop = useSelector((state) => state.crop);
  const [isShowPopupImage, setIsShowPopupImage] = useState(false);

  const getDemand = () => {
    dispatch(
      demandShowGuest({
        demand_id: params.demand_id,
      })
    );
  }
  const applyDemand = (event) => {
    if (isLoggedIn) {
      dispatch(
        demandFarmerApply({
          grade_id: gradeId,
          demand_id: params.demand_id,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure");
        } else {
          snackbarCtx.displayMsg(response.payload.message, "success");
          navigate(routesPaths.MARKETDEMANDS)
        }
      });
    } else {
      navigate(`${routesPaths.LOGIN}?redirect=/demands/market/apply/${params.demand_id}`)
    }

    event.preventDefault();
  }

  const loadCropsAuth = () => {

    dispatch(cropAuth()).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        // snackbarCtx.displayMsg(response.payload, "failure")
      } else {

      }
    });
  };

  useEffect(() => {
    getDemand()
    loadCropsAuth()
  }, []);

  const getGradeValue = (crop, Cindex) => {
    return crop.grades.data.map((grade, index) => {
      return (
        <option key={index} value={grade.id}>Crop: {crop.name} - {grade.name} - Price: {grade.price_unit}{grade.price} - Quantity: {grade.quantity}{grade.quantity_unit}</option>
      );
    })
  }


  return (
    // Column Filler
    <div
      data-testid="market-demand-apply"
      className="flex flex-col col-span-1 tablet:col-span-2 laptop:col-span-3 desktop:col-span-4 desktop:col-start-2 gap-8 bg-content-new"
    >
      {(isShowPopupImage && reduxDemand.demand.photos) && <PopupImageSlider defaultPhoto={reduxDemand.demand.photo_default} photos={reduxDemand.demand.photos} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} />}

      {/* Actual Component */}
      <div className="self-center w-10/12 mt-2 mb-20">
        <div className="head-bar-back">
          <section>
            <label className="cursor-pointer head-bar-nav" onClick={() => navigate(routesPaths.MARKETDEMANDS)}>
              <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
              {" Go Back"}
            </label>
          </section>
          <div className="mb-2">
            <label className="title-content">
              Demand for {reduxDemand.demand.name}
            </label>
          </div>
        </div>

        <div className="flex flex-col rounded-lg shadow bg-card-white-new">
          <form onSubmit={applyDemand}>

            <Row>
              {/* image  */}
              <Col md={6}>
                {reduxDemand.demand.photos && <CustomImageDemands defaultPhoto={reduxDemand.demand.photo_default} photos={reduxDemand.demand.photos} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} />}
              </Col>

              <Col md={6} className="padding-form-market">
                <div className="self-center w-full flex flex-col gap-4">
                  <div className="desktop-respo">
                    <table style={{ width: "100%" }}>
                      <tbody>

                        <tr>
                          <td>
                            <img
                              src={reduxDemand.company?.photo_default?.url}
                              alt="company logo"
                              style={{ border: "1px solid #787676" }}
                              className="w-[6rem] h-[6rem] inline rounded-circle"
                            />
                          </td>
                          <td>
                            <div>
                              <div className="label-company-name">
                                {reduxDemand.company.name}
                              </div>
                              <div>
                                <div className="flex gap-1">
                                  <img src="/assets/images/StarIcon.svg" alt="star icon" className="w-[0.9rem] h-[0.9rem]" />
                                  <img src="/assets/images/StarIcon.svg" alt="star icon" className="w-[0.9rem] h-[0.9rem]" />
                                  <img src="/assets/images/StarIcon.svg" alt="star icon" className="w-[0.9rem] h-[0.9rem]" />
                                  <img src="/assets/images/StarIcon.svg" alt="star icon" className="w-[0.9rem] h-[0.9rem]" />
                                  <img src="/assets/images/StarIcon.svg" alt="star icon" className="w-[0.9rem] h-[0.9rem]" />
                                </div>
                              </div>
                              <div>
                                <span className="label-status-market">Status :</span> <span className="text-status-market">{reduxDemand.demand.status_text_1}</span>
                              </div>
                            </div>

                          </td>
                          <td>
                            <button className="borderedButton btn-farm-details" onClick={(event) => {
                              event.preventDefault()

                              // open customer profile
                              if (reduxDemand.owner.profile_url) {
                                window.open(reduxDemand.owner.profile_url, '_blank');
                              }
                            }}>
                              View Profile
                            </button>
                          </td>
                        </tr>
                      </tbody>

                    </table>

                  </div>
                  <div className="mobile-respo">
                    <Row>
                      <Col md={12} className="text-center">
                        <img
                          src={reduxDemand.company?.photo_default?.url}
                          alt="company logo"
                          style={{ border: "1px solid #787676" }}
                          className="w-[6rem] h-[6rem] inline rounded-circle"
                        />
                      </Col>
                      <Col md={12} className="text-center mb-2">
                        <div>
                          <div className="label-company-name">
                            {reduxDemand.company.name}
                          </div>
                          <div>
                            <div className="flex gap-1" style={{ placeContent: "center" }}>
                              <img src="/assets/images/StarIcon.svg" alt="star icon" className="w-[0.9rem] h-[0.9rem]" />
                              <img src="/assets/images/StarIcon.svg" alt="star icon" className="w-[0.9rem] h-[0.9rem]" />
                              <img src="/assets/images/StarIcon.svg" alt="star icon" className="w-[0.9rem] h-[0.9rem]" />
                              <img src="/assets/images/StarIcon.svg" alt="star icon" className="w-[0.9rem] h-[0.9rem]" />
                              <img src="/assets/images/StarIcon.svg" alt="star icon" className="w-[0.9rem] h-[0.9rem]" />
                            </div>
                          </div>
                          <div>
                            <span className="label-status-market">Status :</span> <span className="text-status-market">{reduxDemand.demand.status_text_1}</span>
                          </div>
                        </div>
                      </Col>
                      <Col md={12} className="text-center">
                        <button className="borderedButton btn-farm-details" onClick={(event) => {
                          event.preventDefault()

                          // open customer profile
                          if (reduxDemand.owner.profile_url) {
                            window.open(reduxDemand.owner.profile_url, '_blank');
                          }
                        }}>
                          View Profile
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <div className="w-full flex flex-col gap-4">
                    {/* <Row style={{ placeItems: "center" }}>
                      <Col md={4} className="head-bar-nav">
                        Crop Grade
                      </Col>
                      <Col md={8}>
                      <input type="text" className="input-search" value={reduxDemand.demand?.grade?.name} readOnly />
                        <select className={"select-grade"}
                          value={gradeId || ""} onChange={({ target }) => {
                            setGradeId(target.value)
                          }}
                        >
                          <option value={""}>Select Grade..</option>
                          {
                            // <option value={reduxDemand.demand.name}>Crop: {reduxDemand.demand?.crop?.name} - {reduxDemand.demand?.grade?.name} - Price: {reduxDemand.demand?.grade?.price_unit}{reduxDemand.demand?.grade?.price} - Quantity: {reduxDemand.demand?.grade?.quantity}{reduxDemand.demand?.grade?.price.quantity_unit}</option>
                            // reduxCrop.crops.data && reduxCrop.crops.data.map((crop, indexCrop) => {
                            //   return (
                            //     getGradeValue(crop, indexCrop)
                            //   )
                            // })
                          }
                        </select>
                      </Col>
                    </Row> */}
                    {/* <Row style={{ placeItems: "center" }}>
                      <Col md={4} className="head-bar-nav">
                        Price
                      </Col>
                      <Col md={8}>
                      <input type="text" className="input-search" value={`${reduxDemand.demand?.grade?.price_unit}${reduxDemand.demand?.grade?.price}/${reduxDemand.demand.unit || ''}`} readOnly />
                      </Col>
                    </Row> */}
                    <Row style={{ placeItems: "center" }}>
                      <Col md={4} className="head-bar-nav">
                        Posted By
                      </Col>
                      <Col md={8}>
                        <input type="text" className="input-search" value={reduxDemand.owner.name || ''} readOnly />
                      </Col>
                    </Row>
                    <Row style={{ placeItems: "center" }}>
                      <Col md={4} className="head-bar-nav">
                        Company Name
                      </Col>
                      <Col md={8}>
                        <input type="text" className="input-search" value={reduxDemand.company.name || ''} readOnly />
                      </Col>
                    </Row>
                    <Row style={{ placeItems: "center" }}>
                      <Col md={4} className="head-bar-nav">
                        Delivery Address
                      </Col>
                      <Col md={8}>
                        <input type="text" className="input-search" value={reduxDemand.company.billing_address ? reduxDemand.company.billing_address : "N/A"} readOnly />
                      </Col>
                    </Row>
                    <Row style={{ placeItems: "center" }}>
                      <Col md={4} className="head-bar-nav">
                        Amount Required
                      </Col>
                      <Col md={8}>
                        <input type="text" className="input-search w-1/3" value={`${reduxDemand.demand.quantity_required || ''} ${reduxDemand.demand.unit || ''}`} readOnly />
                      </Col>
                    </Row>
                    <Row style={{ placeItems: "center" }}>
                      <Col md={4} className="head-bar-nav">
                        Frequency
                      </Col>
                      <Col md={8}>
                        <input type="text" className="input-search" value={`${reduxDemand.demand.frequency_camel_case || ''}`} readOnly />
                      </Col>
                    </Row>
                    <Row style={{ placeItems: "center" }}>
                      <Col md={4} className="head-bar-nav">
                        Demand Period
                      </Col>
                      <Col md={8}>
                        <Row style={{ placeItems: "center" }}>
                          <Col md={6} sm={6} className="mb-2">
                            <input type="text" className="input-search w-2/3 text-center" value={reduxDemand.demand.requirement_open_date ? reduxDemand.demand.requirement_open_date : "No Open Date"} readOnly />
                            <FontAwesomeIcon icon={faCalendarAlt} color="#787676" style={{ fontSize: "25px", paddingLeft: "10px" }} />
                          </Col>
                          <Col md={6} sm={6}>
                            <input type="text" className="input-search w-2/3 text-center" value={reduxDemand.demand.requirement_close_date ? reduxDemand.demand.requirement_close_date : "No Close Date"} readOnly />
                            <FontAwesomeIcon icon={faCalendarAlt} color="#787676" style={{ fontSize: "25px", paddingLeft: "10px" }} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* <Row style={{ placeItems: "center" }}>
                      <Col md={4} className="head-bar-nav">
                        Total Price
                      </Col>
                      <Col md={8}>
                        <input type="text" className="input-search" value={`${reduxDemand.demand?.grade?.price_unit}${reduxDemand.demand.quantity_required * (reduxDemand.demand?.grade?.price || '')}`} readOnly />
                      </Col>
                    </Row> */}
                  </div>

                </div>
              </Col>

            </Row>
            <Row className="mt-4">
              <Col md={4}>
              </Col>
              <Col md={3} className="text-center">
                <button type="submit" className="saveButton">
                  <b>{reduxDemand.updating ? "Applying demand, please wait..." : "Apply"}</b>
                </button>
              </Col>
              <Col md={4}>
              </Col>
            </Row>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default DemandMarketApply;
