
import SnackbarContext from "_helpers/snackbar-context";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';
import { DateTime } from "luxon";
import md5 from 'md5';
import cartStyle from "../../cart/CartSidebar.module.scss";
import clsx from "clsx";
import { authGetUser } from "features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { downloadBill } from "features/billing/billingSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBills, faFileLines } from '@fortawesome/free-solid-svg-icons';

const Billing = () => {
  const navigate = useNavigate();
  const { currentCompany, currentUser } = useSelector((state) => state.auth);
  const [vcode, setVCode] = useState(null);
  const [selectedBill, setSelectedBill] = useState({})
  const [currentBills, setCurrentBills] = useState([]);
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);

  useEffect(() => {
    dispatch(authGetUser());

    if (currentCompany?.bills?.data) {
      setCurrentBills(currentCompany?.bills?.data);
    }
  }, [dispatch]);

  const payBill = bill => {
    setSelectedBill({ ...bill })
    setVCode(md5((parseFloat(bill.total_amount)) + process.env.REACT_APP_RAZER_MERCHANT_ID + "BILLING_" + bill?.invoice_number + process.env.REACT_APP_RAZER_VERIFY_KEY));

    setTimeout(() => {
      if (window.confirm("Are you sure want to pay for this bill?")) {
        document.getElementById("razerForm").submit();
      }
    }, 1000);
  };

  const downloadBillHandler = bill => {
    dispatch(
      downloadBill({
        id: bill.invoice_number
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
      } else {
        let result = response.payload;
        const file = result;
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;

        snackbarCtx.displayMsg("Bill has been generated.", "success");
      }
    });
  };

  return (
    <>
      {currentBills.map((bill, key) =>
        <tr className="text-center text-sm bg-white rounded-b-lg" style={{ borderBottomWidth: "1px" }}>
          <td className="py-3">
            {bill?.company_package?.package?.package_name}
          </td>
          <td>
            <span>{bill.invoice_number}</span>
          </td>
          <td className="py-3">
            {DateTime.fromSQL(bill.invoice_date).toFormat('dd/LL/yyyy hh:mm a')}
          </td>
          <td>
            <span>RM {(parseFloat(bill.current_charge)).toFixed(2)}</span>
          </td>
          <td className={(bill?.status === 1 ? "text-green" : "text-red")}>
            <span>{bill?.status === 1 ? 'Paid' : 'Unpaid'}</span>
          </td>
          <td>
            <span>Online</span>
          </td>
          <td>
            <div className="flex justify-center space-x-3">
              <FontAwesomeIcon title="View Invoice" icon={faFileLines} onClick={() => { navigate(`/account/bill/${bill?.id}/edit`); }} className="text-main h-[20px] cursor-pointer" />
              <FontAwesomeIcon title="Pay Invoice" icon={faMoneyBills} onClick={() => bill?.status === 0 && payBill(bill)} disabled={bill?.status === 1 || key !== 0} className={(bill?.status === 1 || key !== 0) ? "text-gray h-[20px]" : "text-main h-[20px] cursor-pointer"} />
            </div>
          </td>
        </tr>)}
      <form id="razerForm" action={process.env.REACT_APP_RAZER_PAYMENT_URL} method="POST">
        <input type="hidden" name="merchant_id" value={process.env.REACT_APP_RAZER_MERCHANT_ID} />
        <input type="hidden" name="amount" value={parseFloat(selectedBill.total_amount)} />
        <input type="hidden" name="orderid" value={'BILLING_' + selectedBill?.invoice_number} />
        <input type="hidden" name="bill_name" value={currentUser?.first_name} />
        <input type="hidden" name="bill_email" value={currentUser?.email} />
        <input type="hidden" name="bill_mobile" value={currentUser?.phone_number} />
        <input type="hidden" name="bill_desc" value={'BILLING_' + selectedBill?.invoice_number} />
        <input type="hidden" name="returnurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-billing-return"} />
        <input type="hidden" name="callbackurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-billing-callback"} />
        <input type="hidden" name="vcode" value={vcode} />
        <input type="submit" className={clsx(cartStyle.hideVisibility)} />
      </form>
      {/* {currentCompany?.company_packages === null && <span>No package available.</span>} */}
    </>
  );
};
export default Billing;
