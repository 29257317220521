import  api from "../../support/support_api";
import storage from "../../support/support_storage";

const registerDetail = async (userData) => {
    const response = await api.axiosPost(`/users/register`, userData);

    if(response.data) {
        storage.setUser(response.data)
    }

    return response.data;
}

const registerType = async (dataRequest) => {
    const response = await api.axiosPost(`/users/register-type`,  {
        'roles' : dataRequest.roles,
        'verification_code' : dataRequest.verification_code
    });

    return response.data;
}

const registerCompany = async (company) => {
    const response = await api.axiosPost(`/companies/register`, company);

    if(response.data) {
        storage.setUser(response.data)
    }

    return response.data;
}

const registerService = {
    registerDetail,
    registerType,
    registerCompany
}

export default registerService;