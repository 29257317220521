import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  delyvaIntegrationService from "./delyvaIntegrationsService";

export const delyvaIntegrationIndex = createAsyncThunk(
    "delyvaIntegration/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await delyvaIntegrationService.delyvaIntegrationIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const delyvaIntegrationGuest = createAsyncThunk(
    "delyvaIntegration/index",
    async (args, thunkAPI) => {
        try {
            return await delyvaIntegrationService.delyvaIntegrationGuest(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const delyvaIntegrationAuth = createAsyncThunk(
    "delyvaIntegration/index",
    async (args, thunkAPI) => {
        try {
            return await delyvaIntegrationService.delyvaIntegrationAuth(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const delyvaIntegrationStore = createAsyncThunk(
    "delyvaIntegration/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await delyvaIntegrationService.delyvaIntegrationStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const delyvaIntegrationUpdate = createAsyncThunk(
    "delyvaIntegration/update",
    async (args, thunkAPI) => {
        try {
            return await delyvaIntegrationService.delyvaIntegrationUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const delyvaIntegrationDelete = createAsyncThunk(
    "delyvaIntegration/delete",
    async (args, thunkAPI) => {
        try {
            return await delyvaIntegrationService.delyvaIntegrationDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const delyvaIntegrationShow = createAsyncThunk(
    "delyvaIntegration/:id/show",
    async (args, thunkAPI) => {
        try {
            return await delyvaIntegrationService.delyvaIntegrationShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    delyvaIntegration: {
        grades: {
            data: []
        }
    },
    delyvaIntegrations: {
        data: []
    },
    updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const delyvaIntegrationSlice = createSlice({
    name: 'delyvaIntegration',
    initialState,
    reducers: {
    },
    extraReducers : {
        [delyvaIntegrationStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [delyvaIntegrationStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.delyvaIntegration = payload.data.delyvaIntegration;
            state.loading=false;
            state.isSuccess = true;
        },
        [delyvaIntegrationStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [delyvaIntegrationUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [delyvaIntegrationUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [delyvaIntegrationUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [delyvaIntegrationDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [delyvaIntegrationDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [delyvaIntegrationDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [delyvaIntegrationShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [delyvaIntegrationShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.delyvaIntegration = payload.data.delyvaIntegration;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [delyvaIntegrationShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [delyvaIntegrationIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [delyvaIntegrationIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.delyvaIntegrations = payload.data.delyvaIntegrations;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [delyvaIntegrationIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export default delyvaIntegrationSlice;