import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/support_api";
import  catalogPlotsService from "./catalogPlotsService";
import catalogService from '../catalogService';

export const plotDetail = createAsyncThunk(
    "plots/:id/show",
    async (params, thunkAPI) => {
        try {
            return await catalogPlotsService.plotDetail(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const bookingPlot = createAsyncThunk(
    "booking-plots",
    async (params, thunkAPI) => {
        try {
            return await catalogPlotsService.bookingPlot(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const bidAucPlot = createAsyncThunk(
    "auction-plots",
    async (params, thunkAPI) => {
        try {
            return await catalogPlotsService.aucBidPlot(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const forefeitBid = createAsyncThunk(
    "auction-plots/:plot_id/forfeitByUser",
    async (params, thunkAPI) => {
        try {
            return await catalogPlotsService.forefeitBid(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getBookingPlotByUser = createAsyncThunk(
    "booking-plots/:plot_id/getByUser/:user_id",
    async (params, thunkAPI) => {
        try {
            return await catalogPlotsService.getBookingPlotByUser(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getBidPriceLast = createAsyncThunk(
    "auction-plots/:plot_id/last",
    async (params, thunkAPI) => {
        try {
            return await catalogPlotsService.getBidPriceLast(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getDataBidders = createAsyncThunk(
    "auction-plots/:plot_id/getDataBidders",
    async (params, thunkAPI) => {
        try {
            return await catalogPlotsService.getDataBidders(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getBidStatusUser = createAsyncThunk(
    "auction-plots/:plot_id/lastByUser/:user_id",
    async (params, thunkAPI) => {
        try {
            return await catalogPlotsService.getBidStatusUser(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getStatesList = createAsyncThunk(
    "states/list",
    async (params, thunkAPI) => {
        try {
            return await catalogPlotsService.getStatesList(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getAddress = createAsyncThunk(
    "users/get/userAddress",
    async (params, thunkAPI) => {
        try {
            return await catalogPlotsService.getAddress(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    plot: {},
    plots: [],
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const catalogPlotsSlice = createSlice({
    name: 'catalogPlots',
    initialState,
    reducers: {
        //

    },
    extraReducers : {
        [plotDetail.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [plotDetail.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.isSuccess = true;
        },
        [plotDetail.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },
        [bookingPlot.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [bookingPlot.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [bookingPlot.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating=false;
            state.isSuccess = true;
        },
        [getBookingPlotByUser.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [getBookingPlotByUser.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [getBookingPlotByUser.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating=false;
            state.isSuccess = true;
        },

        [getStatesList.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [getStatesList.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [getStatesList.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating=false;
            state.isSuccess = true;
        },

        [getAddress.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [getAddress.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [getAddress.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating=false;
            state.isSuccess = true;
        },
    }
})

export default catalogPlotsSlice;