
import { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';
import { DateTime } from "luxon";
import { authGetUser } from "features/auth/authSlice";
import { updatePackage } from "features/package-subscription/companyPackageSlice";
import SnackbarContext from "_helpers/snackbar-context";
import PopupPay from "components/ui/popup/PopupPay";

const Package = () => {
  const { currentCompany } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const [subsStartDateString, setSubsStartDateString] = useState("");
  const [selectedPackage, setSelectedPackage] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const calculateRange = (package_period_format, package_period) => {
    let totalDays = 0
    switch (package_period_format) {
      case 'Days':
        totalDays = package_period;
        break;
      case 'Months':
        totalDays = package_period * 30;
        break;
      case 'Years':
        totalDays = package_period * 30 * 12;
        break;
      default:
        totalDays = package_period;
    }
    return totalDays;
  }

  const renewPackage = () => {
    let newStartSubsDate = new Date(selectedPackage.subs_end_date);
    let newEndSubsDate = new Date(selectedPackage.subs_end_date);

    newStartSubsDate.setDate(newStartSubsDate.getDate() + 2);
    newStartSubsDate = newStartSubsDate.toISOString().substr(0, 10);

    newEndSubsDate.setDate(newEndSubsDate.getDate() + calculateRange(selectedPackage?.package?.package_period_format, selectedPackage?.package?.package_period));
    newEndSubsDate = newEndSubsDate.toISOString().substr(0, 10);

    dispatch(
      updatePackage({
        id: selectedPackage?.id,
        package_id: selectedPackage?.package?.id,
        company_id: selectedPackage?.company_id,
        service_charge_type: selectedPackage?.service_charge_type,
        service_charge: selectedPackage?.service_charge,
        subs_start_date: newStartSubsDate,
        subs_end_date: newEndSubsDate,
        payment_date: selectedPackage?.payment_date,
        status: selectedPackage?.status,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        loadUser();
      }

      setIsModalOpen(false);
    });
  }

  const openRenewModal = company => {
    let newStartSubsDate = new Date(company.subs_end_date);

    newStartSubsDate.setDate(newStartSubsDate.getDate() + 1); 

    setSubsStartDateString(newStartSubsDate.toLocaleDateString(undefined, dateOptions));

    setSelectedPackage(company);
    setIsModalOpen(true);
  }

  const loadUser = () => {
    dispatch(authGetUser());
  }

  useEffect(() => {
    dispatch(authGetUser());
  }, []);

  return (
    <>
      {currentCompany?.company_packages?.data.map((company, key) =>
        <tr className="text-center text-sm bg-white rounded-b-lg" style={{ borderBottomWidth: "1px" }} key={key}>
          <td className="p-3">{company?.package?.package_name}</td>
          <td>
            RM {company?.package?.subscription_fee}
          </td>
          <td>
            <span>{company?.service_charge}% / transaction</span>
          </td>
          <td>
            {DateTime.fromSQL(company?.subs_start_date).toFormat('dd/LL/yyyy')} - {DateTime.fromSQL(company?.subs_end_date).toFormat('dd/LL/yyyy')}
          </td>
          <td>
            <div className="flex justify-center">
              <button className="btn-border-green w-[100px] items-center" onClick={() => openRenewModal(company)} >Renew</button>
            </div>
          </td>
        </tr>)}
      {currentCompany?.company_packages?.data.length === 0 && <span className="pl-2">No package available.</span>}
      {isModalOpen && <PopupPay title={`You will renewing this package: ` + selectedPackage?.package?.package_name + ` which will start at ` + subsStartDateString + `. Do you wish to renew this this package ` + selectedPackage?.package?.package_name + ` ?`} successButtonTitle="Renew" cancelButtonTitle="Cancel" onSuccess={() => { renewPackage()}} onCancel={() => { setIsModalOpen(false) }} />}
    </>
  );
};
export default Package;
