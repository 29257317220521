import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import farmIcon from "../../../src/images/actype/buyer.png";
import cartIcon from "../../../src/images/actype/farmer.svg";
import bothIcon from "../../../src/images/actype/both.svg";
import { useDispatch, useSelector } from "react-redux";
import { registerType } from "../../features/auth/registerSlice";
import SnackbarContext from "../../_helpers/snackbar-context";
import styles from './RegisterType.module.scss';
import clsx from "clsx";
import { getCurrentUserInfo, } from "../../features/auth/authSlice";

const RegisterType = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const [roles, setRoles] = useState("");
  const [verificationcode, setVerificationcode] = useState("");
  const [loading, setLoading] = useState(false);
  const snackbarCtx = useContext(SnackbarContext);

  const submitUserType = (roles, verificationcode) => {
    dispatch(registerType({ roles: roles, verification_code: verificationcode })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        setLoading(false);
        snackbarCtx.displayMsg(response.payload, "failure");
      }
      else {
        snackbarCtx.displayMsg(response.payload.message, "success");
        navigate("/register/subscribe");
      }
    })
  }

  const typeHandler = ({ target }, roles) => {
    setLoading(true);

    if (currentUser?.social_provider_name === null && verificationcode) {
      submitUserType(roles, verificationcode)
    } else if (currentUser?.social_provider_name !== null) {
      submitUserType(roles, verificationcode)
    }
    else {
      snackbarCtx.displayMsg("Failed! Verification code is required.", "failure");
    }
    setLoading(false);
  };

  useEffect(() => {
    const reloadUser = () => {
      dispatch(getCurrentUserInfo());
    };
    reloadUser();
  }, [currentUser.username]);

  return (
    <>
      {currentUser?.social_provider_name === null && <>
        <div className="flex justify-center text-center">
          <h1 className={clsx(styles.titleActType, "self-center")}>Please enter verification code send to your email</h1>
        </div>
        <div className="flex flex-col space-y-4">
          {/* <div className="row text-center">
          <div className="col-md-2 col-sm-12 mb-3"> */}
          <input
            type="text"
            className={clsx("", styles.inputUserPass)}
            placeholder="Verification Code*"
            value={verificationcode}
            onChange={({ target }) => setVerificationcode(target.value)}
          />
          {/* </div>
        </div> */}
        </div>
      </>}
      <div className="flex justify-center text-center">
        <h1 className={clsx(styles.titleActType, "self-center")}>Who do you want to register as?</h1>
      </div>
      <div className="flex justify-center">
        <div className="row text-center">
          <div className="col-md-4 col-sm-12 mb-3">
            <button
              className={clsx(styles.bgWhiteCard, "items-center justify-center space-y-2 w-27 h-45 rounded-lg p-10 shadow")}
              value={"Buyer"}
              onClick={(event) => {
                typeHandler(event, ["customer"]);
              }}
            >
              <img className="w-17" src={cartIcon} alt="farm icon" />
              <label className={clsx(styles.titleCard)}>Buyer</label>

            </button>
          </div>
          <div className="col-md-4 col-sm-12 mb-3 ">
            <button
              className={clsx(styles.bgWhiteCard, "items-center justify-center space-y-2 w-27 h-45 rounded-lg p-10 shadow")}
              value={"Farmer"}
              onClick={(event) => {
                typeHandler(event, ["farmer"]);
              }}
            >
              <img className="w-13" src={farmIcon} alt="farm icon" />
              <label className={clsx(styles.titleCard)}>Farmer</label>
            </button>
          </div>
          <div className="col-md-4 col-sm-12 mb-3 ">

            <button
              className={clsx(styles.bgWhiteCard, "items-center justify-center space-y-2 w-27 h-48 rounded-lg p-10 shadow")}
              value={"Both"}
              disabled={loading}
              onClick={(event) => {
                typeHandler(event, ["customer", "farmer"]);
              }}
            >
              <img className="w-23" src={bothIcon} alt="farm icon" />
              <label className={clsx(styles.titleCard)}>Both</label>
            </button>
          </div>
        </div>



      </div>

    </>

  );
};

export default RegisterType;
