import api from "../../support/support_api";

const getStatesList = async (params) => {
    const response  = await api.axiosGet(`/states/list`);

    return response.data;
}

const orderDetail = async (params) => {
    const response  = await api.axiosShow(`/order-farmers/${params.order_farmer_id}`, params);

    return response.data;
}

const getCourier = async (params) => {
    const response  = await api.axiosPost(`/order-farmers/getCourier`, params);

    return response.data;
}

const storeAddress = async (params) => {
    const response  = await api.axiosPost(`/users/store/userAddress`, params);

    return response.data;
}

const orderFarmerDeliveryStart = async (params) => {
    const response  = await api.axiosPatch(`/order-farmers/${params.order_farmer_id}/delivery-start`, params);

    return response.data;
}


const orderFarmerDeliveryService = {
    getStatesList,
    orderDetail,
    getCourier,
    storeAddress,
    orderFarmerDeliveryStart,
}


export default orderFarmerDeliveryService;