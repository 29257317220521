import { routesPaths } from "constants/routes.constants";
import { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ProductAdded from "./ProductAdded";
import { catalogShow } from "../../features/catalog/catalogSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { gradeProductShow } from "../../features/grade-product/gradeProductSlice";
import { authGetUser } from "../../features/auth/authSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { Col, Container, Row } from "react-bootstrap";
import "./Catalog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCircleXmark, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import PopupImageSlider from "./PopupImageSlider";
import NewPhotoSlider from "components/files/NewSlider/NewPhotoSlider";
import Footer from "components/footer/footer";
import { updateActiveRoomChat, updateInActiveRoomChat } from "features/room-chat/roomChatSlice";
import ChatRoomFloating from "components/chat-room/ChatRoomFloating";
import { cartStore } from "features/cart/cartSlice";

const CatalogShow = () => {
    const navigate = useNavigate();
    const [productAdded, setProductAdded] = useState(false);
    const snackbarCtx = useContext(SnackbarContext);
    const currentLocation = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const reduxCatalog = useSelector((state) => state.catalog);
    const reduxOrderDetail = useSelector((state) => state.orderDetail);
    const { isLoggedIn, currentUser } = useSelector((state) => state.auth);
    const [isShowPopupImage, setIsShowPopupImage] = useState(false);
    const [showFLoatChat, setShowFLoatChat] = useState(false);
    const [unit, setUnitName] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [available_quantity, setAvailableQuantity] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);
    const [sellingPrice, setSellingPrice] = useState(0);
    const [grade, setGrade] = useState("");

    const farmDetailsHandler = (event) => {
        event.preventDefault();
        navigate(`/company-details/${reduxCatalog?.product?.user?.company?.id}`)
    };

    const setGradeSelected = (event) => {
        setGrade(parseInt(typeof event === 'number' ? event : event?.target?.value));
        setQuantity(1)
        dispatch(
            gradeProductShow({
                id: parseInt(typeof event === 'number' ? event : event?.target?.value)
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure");
            } else {
                setUnitName(response.payload.data.gradeProduct.unit?.name)
                if (response.payload.data.gradeProduct.selling_price) {
                    setSellingPrice(response.payload.data.gradeProduct.selling_price)
                    setTotalAmount(response.payload.data.gradeProduct.selling_price)
                }
                else {
                    setSellingPrice(response.payload.data.gradeProduct.base_price)
                    setTotalAmount(response.payload.data.gradeProduct.base_price)
                }
                setAvailableQuantity(response.payload.data.gradeProduct.available_qty - 1)
            }
        });

        if (event?.target?.value) {
            event.preventDefault();
        }
    }

    const loadCatalog = () => {
        dispatch(
            catalogShow({
                catalog_id: params.catalog_id
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                alert(response.payload);
            } else {
                setGradeSelected(response.payload?.data?.product?.grade_products[0]?.id)
            }
        });
    }

    const addToCartHandler = (event) => {
        if (!isLoggedIn) {
            navigate('/login', { state: { from: currentLocation } });
            return;
        }

        dispatch(
            cartStore(
                {
                    product_id: reduxCatalog.product.id,
                    quantity: quantity,
                    grade_id: grade || reduxCatalog?.grade_products[0]?.id,
                }
            )
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                setProductAdded(true);
            }
        });

        event.preventDefault();
    };

    function incrementCount() {
        setQuantity(quantity + 1);
        setAvailableQuantity(available_quantity - 1)
        setTotalAmount(sellingPrice * (quantity + 1));
    }
    function decrementCount() {
        if (((quantity - 1) >= 1) && ((quantity - 1) <= available_quantity)) {
            setTotalAmount(sellingPrice * (quantity - 1));
            setAvailableQuantity(available_quantity + 1)
            setQuantity(quantity - 1);
        }
    }

    useEffect(() => {
        loadCatalog();
        dispatch(authGetUser());
    }, []);

    return (
        <Container className="margin-content">
            {productAdded && <ProductAdded />}
            {(isShowPopupImage && reduxCatalog.product.photos) && <PopupImageSlider defaultPhoto={reduxCatalog.product.thumbnail_photo.length > 0 ? reduxCatalog.product.thumbnail_photo[0].photo : reduxCatalog.product.photo_default} photos={reduxCatalog.product.photos} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} />}
            <Row className="mb-2 mt-2">
                <Col md={12} className="head-bar-nav">
                    <Link to={routesPaths.HOME} className="head-menu">
                        <span>Home</span>
                    </Link>
                    <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "13px", paddingLeft: "4px", paddingRight: "4px" }} />
                    <Link to={routesPaths.CATALOG} className="head-menu">
                        <span>Farmers Produce</span>
                    </Link>
                    <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "13px", paddingLeft: "4px", paddingRight: "4px" }} />
                    <span className="tag-current-bar">{reduxCatalog.product.name} {reduxCatalog.product.scientific_name && <span>({reduxCatalog.product.scientific_name})</span>}</span>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col md={12} className="title-content">
                    <span>Product Details</span>
                </Col>
            </Row>
            <Row>
                <Col md={7} className="title-content">
                    {/* {reduxCatalog.product.photos && <CustomImageProduct defaultPhoto={reduxCatalog.product.photo_default} photos={reduxCatalog.product.photos} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} />} */}
                    {/* {reduxCatalog.product.photos && <CustomImageProduct defaultPhoto={reduxCatalog.product.photo_default} photos={reduxCatalog.product.photos} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} />} */}
                    {reduxCatalog?.product?.photo && <NewPhotoSlider photos={reduxCatalog?.product?.photo[0]?.photo} reloadData={loadCatalog} disableEdit={true} />}
                </Col>
                <Col md={5} className="title-content">
                    <div className="bg-form-content" style={{ minHeight: "547px" }}>
                        <form className="">
                            <span className="title-content">{reduxCatalog.product.name} {reduxCatalog.product.scientific_name && <span>({reduxCatalog.product.scientific_name})</span>}</span>

                            <div className="row mt-2">
                                {/* <div className="col-md-7">
                                    <div className="title-farm">{reduxCatalog.farm && reduxCatalog.farm.name}</div>
                                    <div className="title-farm-sub-address">{reduxCatalog.farm && reduxCatalog.farm.address}</div>
                                </div> */}
                                <div className="col-md-7">
                                    <div className="title-farm">{reduxCatalog?.data?.data?.product?.user?.company?.name}</div>
                                </div>
                                <div className="col-md-5" style={{ alignSelf: "end" }}>
                                    <button className="borderedButton btn-farm-details" onClick={farmDetailsHandler}>Company Details</button>
                                </div>

                            </div>
                            <div className="mt-4">
                                <div className="title-farm">Grade</div>
                                <div className="flex">
                                    {reduxCatalog.product.grade_products && reduxCatalog.product.grade_products.map((cropGrade, index) => (
                                        <button className={(grade == cropGrade.id) ? "borderedwhiteButton btn-catalog-grade-selected mx-1" : "borderedwhiteButton btn-catalog-grade mx-1"} onClick={(e) => setGradeSelected(e)} value={cropGrade.id} key={index}>
                                            {cropGrade.name}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-4">
                                {grade || reduxCatalog?.grade_products[0]?.id ?
                                    <>
                                        <div>
                                            <span className="price-sp-label mr-2">
                                                RM
                                            </span>
                                            <span className="price-sp-label">

                                                {sellingPrice || reduxCatalog?.grade_products[0]?.selling_price}
                                            </span>
                                            <span className="price-sp-label">
                                                /{unit || reduxCatalog?.grade_products[0]?.unit?.name}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="total-sp-label mr-2">
                                                Total
                                            </span>
                                            <span className="total-sp-label mr-2">
                                                RM
                                            </span>
                                            <span className="total-sp-label">
                                                {totalAmount.toFixed(2) || reduxCatalog?.grade_products[0]?.selling_price}
                                            </span>

                                        </div>


                                    </>
                                    : <span className="price-sp-label w-1/2" >Please choose grade</span>
                                }

                            </div>

                            <div className="mt-4">
                                <div className="title-farm">Quantity</div>
                                <Row>
                                    <Col md={5}>
                                        <div className="btn-quantity flex justify-between">
                                            <span onClick={decrementCount} className="cursor-pointer">-</span>
                                            <span>{quantity}</span>
                                            <span onClick={incrementCount} className="cursor-pointer">+</span>
                                        </div>
                                    </Col>
                                    <Col md={9}>
                                        <span className="avai-detail">Available: {available_quantity || reduxCatalog?.grade_products[0]?.available_qty - 1} {unit || reduxCatalog?.grade_products[0]?.unit?.name}</span>
                                    </Col>
                                </Row>
                            </div>
                            <div className="flex items-center gap-6 mt-4">

                                {/* <button className="borderedButton btn-farm-details" onClick={futureOrderHandler}>
                                    Future Order
                                </button> */}
                                {isLoggedIn && (
                                    <>
                                        {(currentUser?.id != reduxCatalog.catalog.user_id && currentUser?.role_active !== 'super-admin') ? (
                                            <>
                                                <button className={grade == "" ? "btn-add-carts-disabled" : "btn-add-carts"} onClick={addToCartHandler} disabled={grade == ""}>
                                                    {reduxOrderDetail.loading ? "Processing, please wait.." : "Add To Cart"}
                                                </button>
                                                <div
                                                    style={{ cursor: "pointer" }}
                                                    className="borderedButton btn-farm-details"
                                                    onClick={() => {
                                                        if (window.innerWidth <= 500) {
                                                            navigate(`/room-chat/live/${reduxCatalog.catalog.user_id}/product/${reduxCatalog.product.id}/${reduxCatalog.catalog.id}`);
                                                        } else {
                                                            dispatch(updateActiveRoomChat());
                                                            setShowFLoatChat(true);
                                                        }

                                                    }}
                                                >
                                                    Chat with us
                                                </div>

                                            </>
                                        ) : <>
                                            <span className="price-sp-label" >{currentUser?.role_active !== 'super-admin' && 'You are not eligible to buy because you are the farmer'}</span>
                                        </>}
                                    </>
                                )}
                            </div>


                        </form>
                        {isLoggedIn && (
                            <>
                                {showFLoatChat && (
                                    <>
                                        <div className="chat-popup-float z-10">
                                            <div className="container-chat">
                                                <div className="flex flex-row" style={{ backgroundColor: "#77b06e", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                                    <div className="basis-1/2 p-1">
                                                        <FontAwesomeIcon style={{ cursor: "pointer", color: "white" }} icon={faUpRightAndDownLeftFromCenter} className={"text-gray-400"} onClick={() => {
                                                            navigate(`/room-chat/live/${reduxCatalog.catalog.user_id}`);
                                                        }} />
                                                    </div>
                                                    <div className="basis-1/2 text-right p-1">
                                                        <FontAwesomeIcon style={{ cursor: "pointer", color: "white" }} icon={faCircleXmark} className={"text-gray-400"} onClick={() => {
                                                            dispatch(updateInActiveRoomChat());
                                                            setShowFLoatChat(false);
                                                        }} />
                                                    </div>
                                                </div>
                                                <ChatRoomFloating id_catalog={reduxCatalog.catalog.id} to_id={reduxCatalog.catalog.user_id} type_chat="product" type_chat_id={reduxCatalog.product.id} />
                                            </div>
                                        </div>
                                    </>
                                )}

                            </>
                        )}
                    </div>

                </Col>
            </Row>
            {/* <div className="self-center flex flex-col px-8 py-6 gap-6">
                <span className={'cursor-pointer'} onClick={() => navigate(-1)}> {"< Go Back "}</span>

                {productAdded && <ProductAdded />}
                <label className="self-center buyProductFull:self-start text-3xl">{reduxCatalog.product.name}</label>
                <div className="flex flex-col buyProductFull:flex-row gap-6">
                    <section className="flex flex-col gap-4">
                        {reduxCatalog.product.photos && <CustomImageProduct defaultPhoto={reduxCatalog.product.photo_default} photos={reduxCatalog.product.photos} />}
                    </section>



                </div>
            </div> */}
            <Footer />
        </Container>

    );
};

export default CatalogShow;
