import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  companyRatingService from "./companyRatingService";

export const companyRatingStore = createAsyncThunk(
    "company-rating/store",
    async (args, thunkAPI) => {
        try {
            return await companyRatingService.companyRatingStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyRatingUpdate = createAsyncThunk(
    "company-rating/update",
    async (args, thunkAPI) => {
        try {
            return await companyRatingService.companyRatingUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyRatingDelete = createAsyncThunk(
    "company-rating/delete",
    async (args, thunkAPI) => {
        try {
            return await companyRatingService.companyRatingDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyRatingGetAvg = createAsyncThunk(
    "company-rating/:id/show",
    async (args, thunkAPI) => {
        try {
            return await companyRatingService.companyRatingGetAvg(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    companyRating: {},
    avgCompanyRating:0,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const companyRatingSlice = createSlice({
    name: 'company-rating',
    initialState,
    reducers: {
    },
    extraReducers : {
        [companyRatingStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [companyRatingStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.companyRating = payload.data.companyRating;
            state.loading=false;
            state.isSuccess = true;
        },
        [companyRatingStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [companyRatingUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyRatingUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyRatingUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [companyRatingDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyRatingDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyRatingDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [companyRatingGetAvg.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyRatingGetAvg.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.avgCompanyRating = payload.data.avgCompanyRating;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyRatingGetAvg.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = ratingSlice.actions

export default companyRatingSlice;