import AccountLayout from "pages/AccountLayout";

import { useState, useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import { userShow, userUpdate } from "../../features/user/userSlice";
import { useParams } from "react-router-dom";
import authSlice, {
  authUpdatePhoto,
} from "../../features/auth/authSlice";

const UserDetailEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [photo, setPhoto] = useState(`${process.env.REACT_APP_BACKEND_ROOT}/assets/images/ProfileIcon.svg`);
  const snackbarCtx = useContext(SnackbarContext);
  const { updating } = useSelector((state) => state.auth);
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [photo, setPhoto] = useState("");
  const [inputElement, setInputElement] = useState("");
  const reduxUser = useSelector((state) => state.user);
  const params = useParams();

  const loadUser = () => {
    let userId = params.user_id;

    dispatch(
      userShow({
        user_id: userId,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        let user = response.payload.data.user;

        setUser(user);
        setName(user.name);
        setUsername(user.username);
        setPhoneNumber(user.phone_number);
        setEmail(user.email);
        // setPassword(user.password);
        // setConfirmPassword(user.password);
        let photo = user.photo.src || user.photo;
        setPhoto(photo);
      }
    });
  };

  const onFileChange = (event) => {
    let selectedFile = event.target.files[0];

    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);
    formData.append("store", "single");
    formData.append("model", "adminUserUpdate");
    formData.append("type", "photo");
    formData.append("user_id", user.id);

    dispatch(authUpdatePhoto(formData)).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
        window.location.reload();
      }
    });
  };

  const update = (event) => {
    let userId = params.user_id;

    dispatch(
      userUpdate({
        name: name,
        username: username,
        email: email,
        phone_number: phone_number,
        password: password,
        password_confirmation: confirmPassword,
        user_id: userId,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });

    event.preventDefault();
  };

  useEffect(() => {
    loadUser()
  }, []);
  return (
    <AccountLayout>
      <div className="flex flex-col items-start gap-2 p-4">
        <label className="cursor-pointer head-bar-nav font-regular font-semibold text-sm" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
          {" Go Back"}
        </label>
        <h1 className="text-3xl font-regular font font-semibold pb-2" style={{ color: "#395B50" }}>Edit User</h1>
        <div className="w-full max-w bg-white flex flex-col items-start rounded-lg shadow gap-4 p-4">
          <h1 className="text-xl font-regular font font-semibold" style={{ color: "#395B50" }}>User Detail</h1>
          <div className="row font-regular text-sm" style={{ width: "100%" }}>
            <form className="self-start flex flex-col gap-4 col-md-5" onSubmit={update}>

              <div className="flex items-start ">
                <img
                  src={photo}
                  alt="profile"
                  onClick={() => inputElement.click()}
                  className="bg-white w-25 h-25 cursor-pointer object-cover aspect-square border-[.01rem] border-green-400 rounded-md"
                />
                <input className={"hidden"} type="file" value="" onChange={onFileChange} ref={(input) => setInputElement(input)} accept="image/png, image/jpeg" />
              </div>

              <div className="flex flex-col space-y-6">
                <section className="flex flex-col space-y-1">
                  <label>Name:</label>
                  <input
                    type="text"
                    className="input-search"
                    placeholder={name}
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                  />
                </section>

                <section className="flex flex-col space-y-1">
                  <label>Username:</label>
                  <input
                    type="text"
                    className="input-search"
                    placeholder={username}
                    value={username}
                    onChange={({ target }) => setUsername(target.value)}
                  />
                </section>

                <section className="flex flex-col space-y-1">
                  <label>Contact No:</label>
                  <input
                    type="text"
                    className="input-search"
                    placeholder={phone_number}
                    value={phone_number}
                    onChange={({ target }) => setPhoneNumber(target.value)}
                  />
                </section>

                <section className="flex flex-col space-y-1">
                  <label>Email Address: </label>
                  <input
                    type="email"
                    className="input-search"
                    placeholder={email}
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                  />
                </section>

                <section className="flex flex-col space-y-1">
                  <label>Password: </label>
                  <input
                    type="password"
                    className="input-search"
                    placeholder={password}
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                  />
                  <small className={"text-danger"}>(Leave empty to update the rest of the fields.)</small>

                </section>
                <section className="flex flex-col space-y-1">
                  <label>Confirm Password:  </label>
                  <input
                    type="password"
                    className="input-search"
                    placeholder={confirmPassword}
                    value={confirmPassword}
                    onChange={({ target }) => setConfirmPassword(target.value)}
                  />
                </section>

                <div className="row">
                  <div className="col-md-4">
                    <button type="submit" className="saveButton">
                      {updating ? "Processing, please wait.." : "Edit and Save"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};

export default UserDetailEdit;
