import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  userService from "./userService";

export const userFarmerFarms = createAsyncThunk(
    "users/farmer-farms",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await userService.userFarmerFarms(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// export const userAll = createAsyncThunk(
//     "users/users_all",
//     async (args, thunkAPI) => {
//         try {
//             // alert("test");
//             return await userService.userAll(args);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )

export const userIndex = createAsyncThunk(
    "users/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await userService.userIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const userShow = createAsyncThunk(
    "users/:id/show",
    async (args, thunkAPI) => {
        try {
            return await userService.userShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)


export const userUpdate = createAsyncThunk(
    "users/userUpdate",
    async (args, thunkAPI) => {
        try {
            return await userService.userUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const userDelete = createAsyncThunk(
    "users/:id/delete",
    async (args, thunkAPI) => {
        try {
            return await userService.userDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const userStatusUpdate = createAsyncThunk(
    "users/:id/userStatusUpdate",
    async (args, thunkAPI) => {
        try {
            return await userService.userStatusUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)
//
// export const farmStore = createAsyncThunk(
//     "farms/store",
//     async (args, thunkAPI) => {
//         try {
//             // alert("test");
//             return await farmService.farmStore(args);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )
//
// export const farmUpdate = createAsyncThunk(
//     "farms/update",
//     async (args, thunkAPI) => {
//         try {
//             return await farmService.farmUpdate(args);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )
//
// export const farmUploadPhoto = createAsyncThunk(
//     "farms/upload-photo",
//     async (args, thunkAPI) => {
//         try {
//             return await farmService.farmUploadPhotos(args);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )
//
// export const farmShow = createAsyncThunk(
//     "farms/:id/show",
//     async (args, thunkAPI) => {
//         try {
//             return await farmService.farmShow(args);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )

export const notificationIndex = createAsyncThunk(
    "notifications/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await userService.notificationIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    user: {},
    users:  {
        data:[]
    },
    // farm: {},
    farms: {
        data:[]
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers : {
        [userFarmerFarms.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [userFarmerFarms.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.farms = payload.data.farms;
            state.message = payload.message;
            state.loading=false;
            state.isSuccess = true;
        },
        [userFarmerFarms.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        // [userAll.pending]:(state, {payload}) => {
        //     state.data = {};
        //     state.updating=true;
        //     state.loading=true;
        //     state.isSuccess = false;
        // },
        // [userAll.fulfilled]:(state, {payload}) => {
        //     state.data = payload;
        //     state.updating=false;
        //     state.user  = payload.data.user;
        //     state.message = payload.message;
        //     state.loading=false;
        //     state.isSuccess = true;
        // },
        // [userAll.rejected]:(state, {payload}) => {
        //     state.data = payload;
        //     state.updating = false;
        //     state.loading = false;
        //     state.isSuccess = true;
        // },

        [userIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [userIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.users = payload.data.users;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [userIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [userShow.pending]:(state, {payload}) => {
            state.data = {};
            state.product = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [userShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.user = payload.data.user;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [userShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [userUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [userUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [userUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [userDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.user = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [userDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [userDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [userStatusUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [userStatusUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [userStatusUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        // [farmStore.pending]:(state, {payload}) => {
        //     state.data = {};
        //     state.updating=true;
        //     state.loading=true;
        //     state.isSuccess = false;
        // },
        // [farmStore.fulfilled]:(state, {payload}) => {
        //     state.data = payload;
        //     state.updating=false;
        //     state.farm = payload.data.farm;
        //     state.loading=false;
        //     state.isSuccess = true;
        // },
        // [farmStore.rejected]:(state, {payload}) => {
        //     state.data = payload;
        //     state.updating = false;
        //     state.loading = false;
        //     state.isSuccess = true;
        // },
        //
        // [farmUpdate.pending]:(state, {payload}) => {
        //     state.data = {};
        //     state.loading=true;
        //     state.updating=true;
        //     state.isSuccess = false;
        // },
        // [farmUpdate.fulfilled]:(state, {payload}) => {
        //     state.data = payload;
        //     state.loading=false;
        //     state.updating=false;
        //     state.isSuccess = true;
        // },
        // [farmUpdate.rejected]:(state, {payload}) => {
        //     state.data = payload;
        //     state.loading = false;
        //     state.updating = false;
        //     state.isSuccess = true;
        // },
        //
        // [farmShow.pending]:(state, {payload}) => {
        //     state.data = {};
        //     state.loading=true;
        //     state.updating=true;
        //     state.isSuccess = false;
        // },
        // [farmShow.fulfilled]:(state, {payload}) => {
        //     state.data = payload;
        //     state.farm = payload.data.farm;
        //     state.loading=false;
        //     state.updating=false;
        //     state.isSuccess = true;
        // },
        // [farmShow.rejected]:(state, {payload}) => {
        //     state.data = payload;
        //     state.loading = false;
        //     state.updating = false;
        //     state.isSuccess = true;
        // },
        //
        // [farmIndex.pending]:(state, {payload}) => {
        //     state.data = {};
        //     state.loading=true;
        //     state.updating=true;
        //     state.isSuccess = false;
        // },
        // [farmIndex.fulfilled]:(state, {payload}) => {
        //     state.data = payload;
        //     state.farms = payload.data.farms;
        //     state.loading=false;
        //     state.updating=false;
        //     state.isSuccess = true;
        // },
        // [farmIndex.rejected]:(state, {payload}) => {
        //     state.data = payload;
        //     state.loading = false;
        //     state.updating = false;
        //     state.isSuccess = true;
        // },
    }
})

// export const {  } = farmSlice.actions

export default userSlice;