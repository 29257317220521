import api from "../../support/support_api";

const farmStore = async (params) => {
    const response  = await  api.axiosPost('/farms', params);

    return response.data;
}

const farmUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/farms/${params.farm_id}`, params);

    return response.data;
}

const farmUploadPhotos = async (params) => {
    const response  = await  api.axiosPatch(`/farms/${params.farm_id}`, params);

    return response.data;
}

const farmShow = async (params) => {
    const response  = await api.axiosShow(`/farms/${params.farm_id}`, params);

    return response.data;
}

const farmIndex = async (params) => {
    const response  = await api.axiosGet(`/farms`, params);

    return response.data;
}

const farmDelete = async (params) => {
    const response  = await api.axiosDelete(`/farms/${params.farm_id}`, params);

    return response.data;
}

const farmService = {
    farmStore, farmUpdate, farmUploadPhotos, farmShow, farmIndex, farmDelete
}

export default farmService;