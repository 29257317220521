import {useTranslation} from "react-i18next";

const Text = ({ id }) => {
    const { t } = useTranslation();

    return (
        <>{t(id)}</>
    )

}

export default Text
