import React from 'react'
import { useEffect } from 'react'
import Chat from './Chat';
import './css/ChatList.css'
import { createRef } from 'react';
import { useSelector } from "react-redux";

export default function ChatList({ chats }) {
    const refBottom = createRef();
    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        // console.log("cek ke sini");
        setTimeout(() => {
            refBottom.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }, 1000);

    }, [chats])

    return (
        <>
            <div className='all-chats' id='allChats'>
                {
                    chats.map((e, i) => {
                        if (e.from_user.id == currentUser.id) {
                            return <Chat data={e} key={i} loggedIn={true} />
                        } else {
                            return <Chat data={e} key={i} loggedIn={false} />
                        }

                    })
                }
                <div ref={refBottom} className='mt-40'>

                </div>
            </div>
        </>
    )
}
