import { useSelector } from "react-redux";;

const CartSidebar = ({ shippingCharge, totalAmount, productPrice }) => {
  const reduxOrderDetail = useSelector((state) => state.orderDetail);

  return (
    <>
      <section className="flex justify-between">
        <label className="font-semibold">Product Price:</label>
        <label className="font-semibold">
          {reduxOrderDetail.order?.price_unit} {productPrice.toFixed(2)}
        </label>
      </section>
      {reduxOrderDetail?.order_detail?.product?.delivery_charge !== 0 && <section className="flex justify-between">
        <label className="font-semibold">Delivery:</label>
        <label className="font-semibold">
          {reduxOrderDetail.order?.price_unit}  {shippingCharge}
        </label>
      </section>}
      <hr className="bg-black text-black w-full h-[0.1rem]" />
      <section className="flex justify-between">
        <label className="font-semibold">Total:</label>
        <label className="font-semibold">
          {reduxOrderDetail.order?.price_unit}  {totalAmount.toFixed(2)}
        </label>
      </section>
    </>
  );
};

export default CartSidebar;