import AuthLayout from "pages/AuthLayout";
import PlotsDetailForm from "../../../components/catalogs/plots/PlotsDetailForm"

const PlotsDetailPage = () => {
  return (
    // <AuthLayout>
    //   <div className="flex flex-col items-center">
        <PlotsDetailForm />
    //   </div>
    // </AuthLayout>
  );
};

export default PlotsDetailPage;
