import React from 'react'
import ChatList from './ChatList';
import './float-css/ChatRoom.css'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roomChatList, sendMsg, updateRoomMsgChat } from 'features/room-chat/roomChatSlice';
import { useParams } from 'react-router-dom';
import LoadingWidget from 'components/loading/LoadingWidget';
import ChatListFloating from './ChatListFloating';
import { Spinner } from 'react-bootstrap';
export default function ChatRoomFloating({ to_id, type_chat, type_chat_id, id_catalog }) {
    const dispatch = useDispatch();
    const { room_chat_count } = useSelector((state) => state.roomChat);

    const [input, setInput] = useState("");
    const [chats, setChats] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadChats = () => {
        dispatch(
            roomChatList({
                limit: 30,
                page: 1,
                to_id: to_id
            })
        ).then(function (response) {
            console.log("done", response);

            if (response.error && response.error.message === "Rejected") {
                setIsLoading(false);
            } else {
                let room_chats = response.payload.data.room_chats.data;
                console.log(room_chats, "cek data room chats");
                const dumDataList = room_chats.slice().reverse();
                setChats(dumDataList);
            }
        });
    }

    const loadChatsFirst = () => {
        setIsLoading(true);
        dispatch(
            roomChatList({
                limit: 30,
                page: 1,
                to_id: to_id
            })
        ).then(function (response) {
            console.log("done", response);

            if (response.error && response.error.message === "Rejected") {
                setIsLoading(false);
            } else {
                let room_chats = response.payload.data.room_chats.data;
                console.log(room_chats, "cek data room chats");
                const dumDataList = room_chats.slice().reverse();
                setChats(dumDataList);
                setIsLoading(false);
            }
        });
    }

    const sendMsgGo = () => {
        let formData = {
            msg: input,
            type: "TEXT",
            product_id: null,
            plot_id: null,
        }
        setIsLoading(true);
        dispatch(
            sendMsg({
                to_id: to_id,
                limit: 30,
                body: JSON.stringify(formData)
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                console.log(response.payload);
                setIsLoading(false);
            } else {
                let room_chats = response.payload.data.room_chats.data;
                const dumDataList = room_chats.slice().reverse();
                setChats(dumDataList);
                setInput("");
                setIsLoading(false);
            }
        });
    };


    useEffect(() => {
        console.log(room_chat_count);
        loadChats();
    }, [room_chat_count]);

    useEffect(() => {
        loadChatsFirst();
        dispatch(updateRoomMsgChat(1));
    }, []);

    useEffect(() => {
        console.log(to_id);
        if (type_chat !== undefined && type_chat_id !== undefined) {
            var titleSend = "";
            var url = "";
            var plot_id = null;
            var product_id = null;
            if (type_chat == 'product') {
                titleSend = "Product";
                url = `/products/catalogs/${id_catalog}/show`;
                product_id = type_chat_id;
            }

            if (type_chat == 'plot') {
                titleSend = "Plot";
                url = `/products/catalogs/plot/${type_chat_id}`;
                plot_id = type_chat_id;
            }

            let formData = {
                msg: "",
                url: url,
                type: "TAG",
                title: titleSend,
                product_id: product_id,
                plot_id: plot_id,
            }
            setIsLoading(true);
            dispatch(
                sendMsg({
                    to_id: to_id,
                    limit: 30,
                    body: JSON.stringify(formData)
                })
            ).then(function (response) {
                if (response.error && response.error.message == "Rejected") {
                    console.log(response.payload);
                    setIsLoading(false);
                } else {
                    let room_chats = response.payload.data.room_chats.data;
                    const dumDataList = room_chats.slice().reverse();
                    setChats(dumDataList);
                    setInput("");
                    setIsLoading(false);
                }
            });
        }

    }, []);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            // Call your function here when the Enter key is pressed
            sendMsgGo();
        }
    }

    return (
        <>
            <div className='chatroom-float'>
                <ChatListFloating chats={chats} />
                <div className='form-input-box'>
                    <input value={input} onChange={(e) => setInput(e.target.value)} placeholder='type a message ...' id='msgBox' type="text" style={{ borderRadius: "0px" }} onKeyDown={handleKeyPress} />
                    <button className='send-btn' onClick={() => {
                        sendMsgGo();
                    }}>⇨</button>
                </div>
                {isLoading && (
                    <>
                        <div className="bg-black/50 w-full h-full justify-center items-center z-10 absolute top-0">
                            <section data-testid="popup-layout" className="absolute w-full h-full flex flex-col" style={{ alignItems: "center", justifyContent: "center" }}>
                                <Spinner animation="border" variant="light" size="lg" >
                                    <span className='visually-hidden'>Loading...</span>
                                </Spinner>
                                <span className="mt-2" style={{ fontSize: "15px", color: "white" }}>
                                    Loading...
                                </span>
                            </section>
                        </div>
                    </>
                )}

            </div>
        </>
    )
}
