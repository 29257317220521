import AccountLayout from "pages/AccountLayout";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { settingDepositSave, settingDepositGet, settingRegistrationLimit } from "features/settingDeposit/settingDepositSlice";
import Toggle from "react-styled-toggle";
import { Col, Row } from "react-bootstrap";
import { categoryDelete, categoryIndex, categoryStore, categoryUpdate } from "features/category/categorySlice";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from "../account/Account.module.scss";
import clsx from "clsx";
import { faTrashCan, faPenToSquare, } from '@fortawesome/free-solid-svg-icons';
import UnitOfMeasureSetting from "./UnitOfMeasureSetting";
import { masterCropDelete, masterCropIndex, masterCropStore, masterCropUpdate } from "features/master-crop/masterCropSlice";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 2,
};

const SettingPage = () => {
  const { t } = useTranslation();
  const reduxcategory = useSelector((state) => state.category);
  const { masterCrops } = useSelector((state) => state.masterCrop);
  const [isEnabledDeposit, setIsEnabledDeposit] = useState(0);
  const [isEnabledRegistration, setIsEnabledRegistration] = useState(0);
  const [startDateBlock, setStartDateBlock] = useState("");
  const [endDateBlock, setEndDateBlock] = useState("");
  const [blockText, setBlockText] = useState("");
  const [category, setCategory] = useState("");
  const [crop, setCrop] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedCrop, setSelectedCrop] = useState({});
  const [toDeleteCategory, setToDeleteCategory] = useState(false);
  const [toEditCategory, setToEditCategory] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [selectedCropCategory, setSelectedCropCategory] = useState(crop.category);

  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);

  const loadSetting = () => {
    dispatch(
      settingDepositGet()
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        let result = response.payload.data.setting;
        if (result) {
          setIsEnabledDeposit(result.is_deposit_payment);
          setIsEnabledRegistration(result.is_registration_closed);
          setStartDateBlock(result.start_block_date);
          setEndDateBlock(result.end_block_date);
          setBlockText(result.regis_block_text);
        }
      }
    });
  }

  const submitHandler = (data) => {
    dispatch(
      settingDepositSave({
        is_deposit_payment: data.is_deposit_payment,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });
  };

  const limitRegistrationtHandler = (data) => {
    if (data.is_registration_closed === "0") {
      setStartDateBlock("")
      setEndDateBlock("")
      setBlockText("")
    }
    dispatch(
      settingRegistrationLimit({
        is_registration_closed: data.is_registration_closed,
        start_block_date: data.is_registration_closed === "1" ? startDateBlock : null,
        end_block_date: data.is_registration_closed === "1" ? endDateBlock : null,
        regis_block_text: data.is_registration_closed === "1" ? blockText : null
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {

        snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });
  };

  const handleBlockRegistration = (target) => {
    if (startDateBlock === "" && endDateBlock === "" && blockText === "") {
      snackbarCtx.displayMsg("The fields are empty", "failure");
      setIsEnabledDeposit(0);
    } else {
      setIsEnabledRegistration((isEnabledRegistration === 1) ? 0 : 1);
      limitRegistrationtHandler({ is_registration_closed: target.value });
    }
  }

  const addCategory = () => {
    dispatch(categoryStore({
      name: category,
    })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        loadCategories();
        snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });
  }

  const deleteCategory = category => {
    setSelectedCategory(category);
    setToDeleteCategory(true);
    setOpenDeletePopup(true);
  }

  const editCategory = category => {
    setSelectedCategory(category);
    setToEditCategory(true);
    setOpenEditPopup(true);
  }

  const editCrop = crop => {
    setSelectedCrop(crop);
    setToEditCategory(false);
    setOpenEditPopup(true);
  }

  const loadCategories = () => {
    dispatch(
      categoryIndex({
        page: 1,
        limit: 100
      })
    );
  }

  const loadCrops = () => {
    dispatch(masterCropIndex())
  }

  const handleDeleteModal = () => {
    setOpenDeletePopup(!openDeletePopup)
  };

  const handleEditModal = () => {
    setOpenEditPopup(!openEditPopup)
  };

  const submitDeleteCategory = () => {
    dispatch(categoryDelete({
      id: selectedCategory.id,
    })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        loadCategories();
        setToDeleteCategory(false);
        snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });

    setOpenDeletePopup(false)
  }

  const submitDeleteCrop = () => {
    dispatch(masterCropDelete({
      id: selectedCrop.id
    })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
        loadCrops()
      }
    });

    setOpenDeletePopup(false)
  }

  const submitEditCategory = () => {
    dispatch(categoryUpdate({
      category_id: selectedCategory.id,
      name: category || selectedCategory.name
    })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        loadCategories();
        setToDeleteCategory(false);
        snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });

    setOpenEditPopup(false)
  }

  const submitEditCrop = () => {
    dispatch(masterCropUpdate({
      id: selectedCrop.id,
      name: selectedCrop?.name,
      category_id: selectedCrop?.category?.id,
    })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        setToEditCategory(false);
        snackbarCtx.displayMsg(response.payload.message, "success")
        loadCrops();
      }
    });

    setOpenEditPopup(false)
  }

  const deleteCrop = crop => {
    setSelectedCrop(crop);
    setToDeleteCategory(false);
    setOpenDeletePopup(true);
  }

  const addCrop = () => {
    dispatch(masterCropStore({
      name: crop,
      category_id: selectedCropCategory,
    })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        loadCrops();
      }
    });
  }

  useEffect(() => {
    loadSetting();
    loadCategories();
    loadCrops();
  }, []);

  return (
    <AccountLayout>
      <div className="flex flex-col items-start gap-2 p-4">
        <h1 className="text-3xl font-regular font-semibold pb-2" style={{ color: "#395B50" }}>Setting</h1>
        <div className="w-full max-w bg-white flex flex-col shadow rounded-lg p-4 space-y-4">
          <Row>
            <p className="underline text-green mb-3 font-bold">Enable Settings</p>
            <Col md={2}>Deposit payment:</Col>
            <Col md={2}>
              <div style={{ display: "inline", float: "left" }}>
                <Toggle backgroundColorChecked="#00AF54" width={50} height={20} sliderHeight={12} sliderWidth={12} labelRight={isEnabledDeposit === 1 ? t('Enable') : t('Disable')} checked={isEnabledDeposit} value={(isEnabledDeposit === 1) ? 0 : 1} onChange={({ target }) => { setIsEnabledDeposit((isEnabledDeposit === 1) ? 0 : 1); submitHandler({ is_deposit_payment: target.value }); }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={2}>Block registration:</Col>
            <Col md={2} lg={1.5}>
              <div style={{ display: "inline", float: "left" }}>
                <Toggle backgroundColorChecked="#00AF54" width={50} height={20} sliderHeight={12} sliderWidth={12} labelRight={isEnabledRegistration === 1 ? t('Enable') : t('Disable')} checked={isEnabledRegistration} value={(isEnabledRegistration === 1) ? 0 : 1} onChange={({ target }) => { handleBlockRegistration(target) }} />
              </div>
            </Col>
            <Col md={2} lg={2}>
              <div style={{ display: "inline", float: "left" }}>
                <label>Start block date: </label>
                <input
                  style={{ width: '80%' }}
                  className="input-search"
                  type="datetime-local"
                  placeholder="Bidding Start Date"
                  value={startDateBlock}
                  onChange={({ target }) => setStartDateBlock(target.value)}
                />
              </div>
            </Col>
            <Col md={2} lg={2}>
              <div style={{ display: "inline", float: "left" }}>
                <label>End block date: </label>
                <input
                  style={{ width: '80%' }}
                  className="input-search"
                  type="datetime-local"
                  placeholder="Bidding Start Date"
                  value={endDateBlock}
                  onChange={({ target }) => setEndDateBlock(target.value)}
                />
              </div>
            </Col>
            <Col md={3} lg={3}>
              <div style={{ display: "inline", float: "left" }}>
                <label>Block text: </label>
                <textarea
                  // type="text"
                  className="input-search"
                  name="block_text"
                  value={blockText}
                  onChange={({ target }) => setBlockText(target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <p className="underline text-green mb-3 font-bold">Crop/Stock and Category Settings</p>
            <Col md={2}>Add Category:</Col>
            <Col>
              <div className="flex">
                <input
                  type="text"
                  placeholder="Category Name"
                  value={category}
                  onChange={({ target }) => setCategory(target.value)}
                  className="input-search lg:w-2/12"
                />
                <button className="saveButton ml-4 lg:w-2/12" onClick={addCategory}>Save</button>
              </div>
              <div className={clsx(styles.scroll, "lg:w-6/12 sm:w-full md:w-full mt-2 shadow rounded-lg",)}>
                <table className="rounded-t-lg" style={{ width: "100%" }}>
                  <tr className="text-center p-4 text-sm rounded-t-lg" style={{ backgroundColor: "#00AF54", color: "white" }}>
                    <th className="lg:w-6/12 sm:w-full md:w-full p-2 rounded-tl-lg">
                      Name
                    </th>
                    <th className="lg:w-4/12 sm:w-full md:w-full p-2 rounded-tr-lg">
                      Options
                    </th>
                  </tr>
                  <>
                    {reduxcategory?.categories?.data.map((category, key) =>
                      <tr className="text-center text-sm bg-white rounded-b-lg" style={{ borderBottomWidth: "1px" }}>
                        <td className="p-2">
                          {category.name}
                        </td>
                        <td className="p-2">
                          <div className="flex justify-center">
                            <FontAwesomeIcon icon={faPenToSquare} className={"ml-6 text-lg cursor-pointer"} onClick={() => editCategory(category)} />
                            <FontAwesomeIcon icon={faTrashCan} className=" ml-6 text-lg cursor-pointer" onClick={() => deleteCategory(category)} />
                          </div>

                        </td>
                      </tr>)}
                    {reduxcategory?.categories?.data.length === 0 && <span>No category available.</span>}
                  </>
                </table>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={2}>Add Master Crop:</Col>
            <Col>
              <div className="flex">
                <input
                  type="text"
                  placeholder="Crop Name"
                  value={crop}
                  onChange={({ target }) => setCrop(target.value)}
                  className="input-search lg:w-2/12"
                />
                <select
                  className="input-search ml-3 lg:w-2/12"
                  defaultValue={""}
                  value={selectedCropCategory}
                  onChange={({ target }) => {
                    setSelectedCropCategory(target.value)
                  }}>
                  <option value="">
                    Select Category
                  </option>
                  {reduxcategory.categories.data.map((category, index) => {
                    return (
                      <option value={category.id} key={index}>{category.name}</option>
                    )
                  })}
                </select>
                <button className="saveButton ml-4 lg:w-2/12" onClick={addCrop}>Save</button>
              </div>
              <div className="flex flex-wrap mt-2">
                <div className={clsx(styles.scroll, "lg:w-7/12 md:w-full sm:w-full mt-2 shadow rounded-lg", masterCrops?.data.length > 9 ? "h-96" : "")}>
                  <table className="rounded-t-lg" style={{ width: "100%" }}>
                    <tr className="sticky top-0 text-center p-2 text-sm rounded-t-lg" style={{ backgroundColor: "#00AF54", color: "white" }}>
                      <th className="lg:w-4/12 sm:w-full md:w-full p-2 rounded-tl-lg">
                        Crop Name
                      </th>
                      <th className="lg:w-4/12 sm:w-full md:w-full p-2">
                        Category Name
                      </th>
                      <th className="lg:w-4/12 sm:w-full md:w-full p-2 rounded-tr-lg">
                        Options
                      </th>
                    </tr>
                    <>
                      {masterCrops?.data.map((crop, key) =>
                        <tr className="text-center text-sm bg-white rounded-b-lg" style={{ borderBottomWidth: "1px" }}>
                          <td className="p-2">
                            {crop.name}
                          </td>
                          <td className="p-2">
                            {crop.category?.name}
                          </td>
                          <td className="p-2">
                            <div className="flex justify-center">
                              <FontAwesomeIcon icon={faPenToSquare} className="ml-6 text-lg cursor-pointer" onClick={() => editCrop(crop)} />
                              <FontAwesomeIcon icon={faTrashCan} className=" ml-6 text-lg cursor-pointer" onClick={() => deleteCrop(crop)} />
                            </div>
                          </td>
                        </tr>)}
                      {masterCrops?.data.length === 0 && <span>No master crop available.</span>}
                    </>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
          <UnitOfMeasureSetting />
        </div>
      </div>
      <Modal
        open={openEditPopup}
        onClose={handleEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                pb: 1,
                mb: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <div style={{ color: '#00AF54' }}>{toEditCategory ? "Edit Category" : "Edit Crop"}</div>
              <button onClick={handleEditModal}>
                <FontAwesomeIcon icon={faXmark} style={{ fontSize: "15px", }} />
              </button>
            </Box>
          </Typography>
          <Typography id="modal-modal-description" >
            <Box
              sx={{
                pb: 2,
                mb: 2,
                bgcolor: 'background.paper',
              }}
            >
              <input
                type="text"
                placeholder={toEditCategory ? selectedCategory.name : selectedCrop.name}
                value={toEditCategory ? selectedCategory.name : selectedCrop.name}
                onChange={({ target }) => toEditCategory ? setSelectedCategory({ ...selectedCategory, name: target.value }) : setSelectedCrop({ ...selectedCrop, name: target.value })}
                className="input-search lg:w-5/12"
              />
              {!toEditCategory && <select
                className="input-search ml-3 lg:w-5/12"
                value={selectedCrop?.category?.id}
                onChange={({ target }) => {

                  setSelectedCrop({ ...selectedCrop, category: { ...category, id: target.value } })
                }}>
                <option value="">
                  Select Category
                </option>
                {reduxcategory.categories.data.map((category, index) => {
                  return (
                    <option value={category.id} key={index}>{category.name}</option>
                  )
                })}
              </select>}
            </Box>
            <div className="flex justify-end">
              <button onClick={(toEditCategory ? submitEditCategory : submitEditCrop)} className="border-none borderedwhiteButton btn-add-carts w-full desktop:w-1/4 text-center borderedInactiveButton">
                Save
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={openDeletePopup}
        onClose={handleDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                pb: 1,
                mb: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <div style={{ color: '#00AF54' }}>{toDeleteCategory ? "Delete Category" : "Delete Crop"}</div>
              <button onClick={handleDeleteModal}>
                <FontAwesomeIcon icon={faXmark} style={{ fontSize: "15px", }} />
              </button>
            </Box>
          </Typography>
          <Typography id="modal-modal-description" >
            <Box
              sx={{
                pb: 2,
                mb: 2,
                bgcolor: 'background.paper',
              }}
            >
              <div>{"Are you sure to delete this " + (toDeleteCategory ? "category" : "crop") + ": " + (toDeleteCategory ? selectedCategory?.name : selectedCrop?.name) + " ?"}</div>
            </Box>
            <div className="flex justify-end">
              <button onClick={(toDeleteCategory ? submitDeleteCategory : submitDeleteCrop)} className="border-none borderedwhiteButton btn-add-carts w-full desktop:w-1/4 text-center borderedInactiveButton">
                Delete
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </AccountLayout >
  );
};

export default SettingPage;
