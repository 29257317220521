import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import CatalogSidebar from "../components/catalogs/CatalogSidebar";
import DemandMarketList from "../components/demands/market/DemandMarketList";
import DemandMarketApply from "../components/demands/market/DemandMarketApply";
import * as url from "../support/support_url";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { demandSearch } from "../features/demand/demandSlice";
import SnackbarContext from "_helpers/snackbar-context";
import "./Pages.css";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Footer from "components/footer/footer";
import FooterStyles from "components/footer/footer.module.scss";
import clsx from "clsx";

const DemandMarketPage = () => {
  const location = useLocation();
  const snackbarCtx = useContext(SnackbarContext);
  const [showSideBar, setShowSideBar] = useState(false);

  // const [search, setSearch] = useState({
  //   input: "",
  //   searchBy: "Product",
  //   filters: {
  //     fruits: ["Bananas", "Grapes", "Citrus"],
  //     vegetables: ["Tomatoes", "Potatoes", "Salads"],
  //   },
  //   selectedFilter: "None",
  // });

  // const inputHandler = (newInput) => {
  //   setSearch({ ...search, input: newInput });
  // };

  // const filterHandler = (newFilter) => {
  //   setSearch({ ...search, selectedFilter: newFilter });
  // };
  //
  // const searchByHandler = (newSearchBy) => {
  //   setSearch({ ...search, searchBy: newSearchBy });
  // };

  const dummyDemands = [
    {
      info: {
        id: 1,
        customer_id: 1,
        product_photo: "/assets/images/products/BananasPhoto.svg",
        product_required: "Bananas",
        grade_required: "U.S. No.1",
        quantity_required: 10,
        unit: "KG",
        frequency: "Week",
        time: "Delivery Every Thursday",
        requirement_open_date: "",
        requirement_close_date: "",
        status: "New",
        location: "California, US",
      },
    },
    {
      info: {
        id: 2,
        customer_id: 1,
        product_photo: "/assets/images/products/GrapesPhoto.svg",
        product_required: "Grapes",
        grade_required: "U.S. No.1",
        quantity_required: 40,
        unit: "KG",
        frequency: "Week",
        time: "Delivery Every Thursday",
        requirement_open_date: "",
        requirement_close_date: "",
        status: "Closed",
        location: "Italy",
      },
    },
    {
      info: {
        id: 3,
        customer_id: 1,
        product_photo: "/assets/images/products/OrangesPhoto.svg",
        product_required: "Oranges",
        grade_required: "U.S. No.1",
        quantity_required: 150,
        unit: "KG",
        frequency: "Week",
        time: "Delivery Every Friday",
        requirement_open_date: "",
        requirement_close_date: "",
        status: "New",
        location: "Florida, US",
      },
    },
    {
      info: {
        id: 4,
        customer_id: 1,
        product_photo: "/assets/images/products/DuriansPhoto.svg",
        product_required: "Durians",
        grade_required: "U.S. No.1",
        quantity_required: 130,
        unit: "KG",
        frequency: "Month",
        time: "Delivery Every 1st Thursday",
        requirement_open_date: "",
        requirement_close_date: "",
        status: "New",
        location: "Indonesia",
      },
    },
    {
      info: {
        id: 5,
        customer_id: 1,
        product_photo: "",
        product_required: "Mangosteen",
        grade_required: "U.S. No.1",
        quantity_required: 0,
        unit: "KG",
        frequency: "Week",
        time: "Delivery Every Wednesday",
        requirement_open_date: "",
        requirement_close_date: "",
        status: "New",
        location: "Malaysia",
      },
    },
  ];


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState({
    input: "",
    searchBy: "Product",
    filters: {
      fruits: ["Bananas", "Grapes", "Citrus"],
      vegetables: ["Tomatoes", "Potatoes", "Salads"],
    },
    selectedFilter: "None",
  });

  const inputHandler = (newInput) => {
    setSearch({ ...search, input: newInput });
  };

  const filterHandler = (newFilter) => {
    setSearch({ ...search, selectedFilter: newFilter });
  };

  const searchByHandler = (newSearchBy) => {
    setSearch({ ...search, searchBy: newSearchBy });
  };

  // const catalog = useSelector((state) => state.catalog);

  // const [testProducts, setTestProducts] = useState([
  //     {
  //         photo: "/assets/images/products/BananasPhoto.svg",
  //         farm_id: 1,
  //         grade_id: 1,
  //         name: "Apple Bananas",
  //         category: "Fruits",
  //         sub_category: "Bananas",
  //         description: "",
  //         quantity_number: 10,
  //         quantity_target: 10,
  //     },
  //     {
  //         photo: "/assets/images/products/GrapesPhoto.svg",
  //         farm_id: 1,
  //         grade_id: 1,
  //         name: "Autumn Royal Grapes",
  //         category: "Fruits",
  //         sub_category: "Grapes",
  //         description: "",
  //         quantity_number: 10,
  //         quantity_target: 10,
  //     },
  //     {
  //         photo: "/assets/images/products/OrangesPhoto.svg",
  //         farm_id: 1,
  //         grade_id: 1,
  //         name: "Valencia Oranges",
  //         category: "Fruits",
  //         sub_category: "Citrus",
  //         description: "",
  //         quantity_number: 10,
  //         quantity_target: 10,
  //     },
  //     {
  //         photo: "/assets/images/products/OrangesPhoto.svg",
  //         farm_id: 1,
  //         grade_id: 1,
  //         name: "Seville Oranges",
  //         category: "Fruits",
  //         sub_category: "Citrus",
  //         description: "",
  //         quantity_number: 10,
  //         quantity_target: 10,
  //     },
  //     {
  //         photo: "/assets/images/products/DuriansPhoto.svg",
  //         farm_id: 1,
  //         grade_id: 1,
  //         name: "Tekka Durians",
  //         category: "Fruits",
  //         sub_category: "Durians",
  //         description: "",
  //         quantity_number: 10,
  //         quantity_target: 10,
  //     },
  //     {
  //         photo: "",
  //         farm_id: 1,
  //         grade_id: 1,
  //         name: "Alicante Tomatoes",
  //         category: "Vegetables",
  //         sub_category: "Tomatoes",
  //         description: "",
  //         quantity_number: 10,
  //         quantity_target: 10,
  //     },
  //     {
  //         photo: "",
  //         farm_id: 1,
  //         grade_id: 1,
  //         name: "Starchy Potatoes",
  //         category: "Vegetables",
  //         sub_category: "Potatoes",
  //         description: "",
  //         quantity_number: 10,
  //         quantity_target: 10,
  //     },
  //     {
  //         photo: "/assets/images/products/SpinachPhoto.png",
  //         farm_id: 1,
  //         grade_id: 1,
  //         name: "Savoy Spinach",
  //         category: "Vegetables",
  //         sub_category: "Salads",
  //         description: "",
  //         quantity_number: 10,
  //         quantity_target: 10,
  //     },
  // ]);
  const reduxDemand = useSelector((state) => state.demand);

  const [wait, setWait] = useState(false);

  const searchDemands = () => {
    let t = clearTimeout(wait);
    setWait(t);
    setWait(
      setTimeout(function () {
        dispatch(
          demandSearch({
            keyword: search.input,
            searchBy: search.searchBy,
            filter: search.selectedFilter,
            limit: 20,
          })
        ).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
            snackbarCtx.displayMsg(response.payload, "failure");
          }
        });
      }, 1000)
    );
  };

  useEffect(() => {
    let qs = url.get_current_query_string()

    setSearch({
      ...search,
      input: qs.search ? qs.search : '',
      selectedFilter: qs.filter ? qs.filter : '',
      searchBy: qs.searchBy ? qs.searchBy : 'Product'
    });
  }, []);

  useEffect(() => {
    searchDemands();

    let params = url.set_current_query_string_filter(search)
    navigate(`?${params.toString()}`);

  }, [search]);

  return (
    <Container className={clsx(FooterStyles.menuMobile)}>
      <Row>
        {showSideBar && (
          <>
            <Col md={3} className="margin-seidebar pb-10">
              <CatalogSidebar search={search} filterHandler={filterHandler} setShowSideBar={setShowSideBar} />
            </Col>
          </>
        )}
        <Col md={showSideBar ? 9 : 12} className="bg-content-new mr-0" style={{ height: '92vh' }}>
          {!showSideBar && (
            <>
              <div
                className="pl-4 pt-3 text-icon-color-green cursor-pointer"
                onClick={() => {
                  setShowSideBar(true);
                }}
              >
                <FontAwesomeIcon icon={faFilter} className={"text-icon-color-green"} /> Filter
              </div>
            </>
          )}
          <DemandMarketList
            search={search}
            demands={reduxDemand.demands}
            searchByHandler={searchByHandler}
            inputHandler={inputHandler}
            showSideBar={showSideBar}
          />
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default DemandMarketPage;
