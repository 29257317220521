import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import creditManagementHistoryService from './creditManagementHistoryService';

export const creditManagementHistoryIndex = createAsyncThunk(
    "creditManagementHistory/index",
    async (args, thunkAPI) => {
        try {
            return await creditManagementHistoryService.creditManagementHistoryIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const creditManagementHistoryStore = createAsyncThunk(
    "creditManagementHistory/store",
    async (args, thunkAPI) => {
        try {
            return await creditManagementHistoryService.creditManagementHistoryStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const creditManagementHistoryUpdate = createAsyncThunk(
    "creditManagementHistory/update",
    async (args, thunkAPI) => {
        try {
            return await creditManagementHistoryService.creditManagementHistoryUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const creditManagementHistoryDelete = createAsyncThunk(
    "creditManagementHistory/delete",
    async (args, thunkAPI) => {
        try {
            return await creditManagementHistoryService.creditManagementHistoryDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const creditManagementHistoryShow = createAsyncThunk(
    "creditManagementHistory/:id/show",
    async (args, thunkAPI) => {
        try {
            return await creditManagementHistoryService.creditManagementHistoryShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    creditManagementHistory: {},
    creditManagementHistories: {
        data: []
    },
    loading: false,
    isSuccess: false,
    updating: false,
}

export const creditManagementHistorySlice = createSlice({
    name: 'creditManagementHistory',
    initialState,
    reducers: {
    },
    extraReducers : {
        [creditManagementHistoryStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [creditManagementHistoryStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.creditManagementHistory = payload.data.creditManagementHistory;
            state.loading=false;
            state.isSuccess = true;
        },
        [creditManagementHistoryStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [creditManagementHistoryUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [creditManagementHistoryUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [creditManagementHistoryUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [creditManagementHistoryDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [creditManagementHistoryDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [creditManagementHistoryDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [creditManagementHistoryShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [creditManagementHistoryShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.creditManagementHistory = payload.data.creditManagementHistory;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [creditManagementHistoryShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [creditManagementHistoryIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [creditManagementHistoryIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.creditManagementHistories = payload.data.creditManagementHistories;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [creditManagementHistoryIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export default creditManagementHistorySlice;