// import React from "react";
import { useState, useEffect } from "react";

import styles from "./CustomSliderDemand.module.css";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { farmShow } from "../../../features/farm/farmSlice";
import { fileDelete } from "../../../features/file/fileSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { routesPaths } from "constants/routes.constants";
import { Col, Row } from "react-bootstrap";
import clsx from "clsx";

// TODO - All select has a delay on setting state hook, so this must be fixed.
const SliderDemand = ({ photos, reloadData, setIsShowPopupImage, uploadImg }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const reduxFile = useSelector((state) => state.file);


    const itemDelete = (photo) => {
        if (window.confirm("Are you sure you want to delete this Photo?")) {
            dispatch(
                fileDelete({
                    file_id: photo.id,
                })
            ).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                } else {
                    reloadData()
                }
            });
        }
    }

    return (
        <>
            <Row>
                <Col md={12} className="title-content mb-4">
                    {photos.length > 0 && (
                        <>
                            <div className={"flex flex-col relative rounded-lg"} key={0}>
                                <section className="absolute text-white flex flex-col left-0 top-0 rounded-t-lg w-full h-fit px-2 py-1 box-img-card-top">
                                    <section className="absolute top-2 right-2">
                                        <button className="absolute top-2 right-2 fosuc:pointer-events-auto" onClick={(e) => {
                                            e.preventDefault();
                                            itemDelete(photos[0])
                                        }}>
                                            <div>
                                                {/* <span className={'text-white mr-2'}>Delete</span> */}
                                                <FontAwesomeIcon icon={faTrashCan} className={"text-cyan-400"} />
                                            </div>
                                        </button>
                                    </section>
                                    <section className="absolute top-2 left-2">
                                        <button className="absolute top-2 left-2 fosuc:pointer-events-auto" onClick={(e) => {
                                            e.preventDefault();

                                            navigate(`${routesPaths.FILES}/${photos[0].id}/edit`);
                                        }}>
                                            <div>
                                                {/* <span className={'text-white mr-2'}>Edit</span> */}
                                                <FontAwesomeIcon icon={faPenToSquare} className={"text-rose-400"} />
                                            </div>
                                        </button>
                                    </section>
                                </section>
                                <img
                                    src={photos[0].src}
                                    alt="product"
                                    className={styles.sliderImageNew}
                                />
                            </div>
                        </>
                    )}

                </Col>

            </Row>
            <Row className="mb-3">

                {photos.length > 0 && photos.map((obj, index) => {
                    if (index > 0 && index <= 3) {
                        return (
                            <>
                                <Col md={3} className="title-content">
                                    <div className={"flex flex-col relative rounded-lg"} key={0}>
                                        <section className="absolute text-white flex flex-col left-0 top-0 rounded-t-lg w-full h-fit px-2 py-1 box-img-card-top">
                                            <section className="absolute top-1 right-1">
                                                <button className="absolute top-0 right-0 fosuc:pointer-events-auto" onClick={(e) => {
                                                    e.preventDefault();
                                                    itemDelete(photos[index])
                                                }}>
                                                    <div>
                                                        {/* <span className={'text-white mr-2'}>Delete</span> */}
                                                        <FontAwesomeIcon icon={faTrashCan} className={"text-cyan-400"} />
                                                    </div>
                                                </button>
                                            </section>
                                            <section className="absolute top-1 left-1">
                                                <button className="absolute top-0 left-0 fosuc:pointer-events-auto" onClick={(e) => {
                                                    e.preventDefault();

                                                    navigate(`${routesPaths.FILES}/${photos[index].id}/edit`);
                                                }}>
                                                    <div>
                                                        {/* <span className={'text-white mr-2'}>Edit</span> */}
                                                        <FontAwesomeIcon icon={faPenToSquare} className={"text-rose-400"} />
                                                    </div>
                                                </button>
                                            </section>
                                        </section>
                                        <div
                                            onClick={() => {
                                                if ((index == 3)) {
                                                    setIsShowPopupImage(true);
                                                }

                                            }}
                                            className={clsx((index == 3) ? styles.bgColorImageNewRel : "")}>
                                            <img
                                                src={obj.src}
                                                alt="product"
                                                className={styles.sliderImageSubNew}
                                            />
                                            <div className={clsx((index == 3) ? (styles.bgColorImageNewAbs) : "")}>
                                                {(index == 3) && (
                                                    <>
                                                        <div style={{ display: "table", height: "100%", width: "100%", overflow: "hidden" }}>
                                                            <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                                                                <div style={{ fontSize: "35px", color: "#FFFFFF" }}>
                                                                    +{photos.length - 4}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "20px" }}>

                                    </div>
                                </Col>
                            </>

                        )
                    }

                })}

                <Col md={3} className="title-content">
                    <div
                        className={clsx(styles.sliderImageSubNew, "cursor-pointer min-w-[4rem] w-full  flex flex-col justify-center items-center bg-white rounded-md border-[.01rem] border-green-500 space-y-2")}
                        onClick={() => {
                            uploadImg();
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} className={"change-icon-color-green"} />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default SliderDemand;
