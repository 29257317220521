import React from 'react'
import './float-css/ChatFloating.css'
import { useNavigate } from 'react-router-dom';
export default function ChatFloating({ data, loggedIn }) {
    const navigate = useNavigate();

    if (loggedIn) {
        return (
            <>
                {(data.body_parse.type === "TEXT") && (
                    <>
                        <div className='msg-block-logged-floating'>
                            <table style={{ width: "100%" }}>
                                <tr>

                                    <td style={{ width: "84%", textAlign: "right", verticalAlign: "top" }}>
                                        <p className='message-floating mt-2 text-base'>
                                            {data.body_parse.msg}
                                        </p>
                                    </td>
                                    <td style={{ width: "2%" }}>

                                    </td>
                                    <td style={{ width: "12%", textAlign: "-webkit-right", verticalAlign: "top" }}>
                                        <img
                                            src={data.fp_from_user ? data.fp_from_user : "/assets/images/products/WheatIcon.svg"}
                                            alt="profile icon"
                                            className="bg-white rounded-full w-11 object-cover aspect-square mt-2 ml-1"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='date-time-container-logged'>
                                            <h9 className='msg-time'>
                                                {data.date_formated}
                                            </h9>

                                        </div>
                                    </td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                </tr>

                            </table>
                        </div>
                    </>
                )}

                {(data.body_parse.type === "TAG") && (
                    <>
                        <div className='msg-block-logged-tag-floating' style={{ cursor: "pointer" }} onClick={() => {
                            navigate(data.body_parse.url);
                        }}>
                            <div className='chat-flex-logged-detail-floating'>
                                <div className='dp-container-floating'>
                                    <img
                                        src={data.data_tag.photo_default ? data.data_tag.photo_default : "/assets/images/products/WheatIcon.svg"}
                                        className="object-cover w-20 h-20 aspect-square rounded-lg"
                                    />
                                </div>
                                <div className='msg-container-floating' style={{ alignSelf: "center" }}>
                                    <div className='mt-1'>
                                        <b>
                                            {data.body_parse.title}
                                        </b>
                                    </div>
                                    <div className='text-base'>
                                        {data.data_tag.name}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                )}


            </>
        )
    } else {
        return (
            <>
                {(data.body_parse.type === "TEXT") && (
                    <>
                        <div className='msg-block-floating'>
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td style={{ width: "12%", textAlign: "-webkit-left", verticalAlign: "top" }}>
                                        <img
                                            src={data.fp_from_user ? data.fp_from_user : "/assets/images/products/WheatIcon.svg"}
                                            alt="profile icon"
                                            className="bg-white rounded-full w-11 object-cover aspect-square mt-2 mr-1"
                                        />
                                    </td>
                                    <td style={{ width: "2%" }}>

                                    </td>
                                    <td style={{ width: "84%", textAlign: "left", verticalAlign: "top" }}>
                                        <p className='message-floating mt-2 text-base'>
                                            {data.body_parse.msg}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td >
                                        <div className='date-time-container-floating'>
                                            <h9 className='msg-time-floating'>
                                                {data.date_formated}
                                            </h9>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </>
                )}

                {(data.body_parse.type === "TAG") && (
                    <>
                        <div className='msg-block-tag' style={{ cursor: "pointer" }} onClick={() => {
                            navigate(data.body_parse.url);
                        }}>
                            <div className='chat-flex'>
                                <div className='dp-container'>
                                    <img
                                        src={data.data_tag.photo_default ? data.data_tag.photo_default : "/assets/images/products/WheatIcon.svg"}
                                        className="object-cover w-20 h-20 aspect-square rounded-lg"
                                    />
                                </div>
                                <div className='msg-container' style={{ alignSelf: "center" }}>
                                    <div className='mt-1'>
                                        <b>
                                            {data.body_parse.title}
                                        </b>
                                    </div>
                                    <div className='text-base'>
                                        {data.data_tag.name}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                )}
            </>

        )
    }
}
