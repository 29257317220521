import { useEffect } from "react";
import { useSelector } from "react-redux";
import styles from './RegisterDetail.module.scss';
import clsx from "clsx";
import { useStateWithCallback } from "support/support_function";
import { DateTime } from "luxon";

const RegisterClosed = () => {
  const [startDateBlock, setStartDateBlock] = useStateWithCallback("");
  const [endDateBlock, setEndDateBlock] = useStateWithCallback("");
  const [blockText, setBlockText] = useStateWithCallback([]);
  let setting = useSelector((state) => state.setting);

  useEffect(() => {
    if (setting) {
      setStartDateBlock(setting?.setting?.start_block_date, () => {
      });
      setEndDateBlock(setting?.setting?.end_block_date, () => {
      });
      if (setting?.setting?.regis_block_text) {
        setBlockText(setting?.setting?.regis_block_text.split(/(?<=[.!?])\s+/), () => {
        });
      }
    }
  }, []);

  useEffect(() => {
    if (setting) {
      setStartDateBlock(setting?.setting?.start_block_date, () => {
      });
      setEndDateBlock(setting?.setting?.end_block_date, () => {
      });
      if (setting?.setting?.regis_block_text) {
        setBlockText(setting?.setting?.regis_block_text.split(/(?<=[.!?])\s+/), () => {
        });
      }
    }
  }, []);

  return (
    <div data-testid="register-detail">
      <div className={clsx(styles.box, "flex", "flex-col", "space-y-6")}>
        {/* User Input */}
        <div className={clsx("self-center", styles.headerRegister)}>
          <span>
            <label>{blockText[0]}</label>
          </span>
        </div>
        <div className={clsx("self-center", styles.headerBeBack)}>
          <span>
            {blockText.filter((item, key) => {
              let settingItem = item;
              if (key !== 0) {
                return settingItem;
              }
            }).map((item, key) => <label key={key}>{item}</label>)}
          </span>
        </div>
        <div className={clsx("self-center", styles.headerDate)}>
          <div className="flex">
            <div>From {DateTime.fromSQL(startDateBlock).toFormat('dd/LL/yyyy')}</div>
            <div className=" mx-1">until</div>
            <div>{DateTime.fromSQL(endDateBlock).toFormat('dd/LL/yyyy')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterClosed;
