import api from "../../support/support_api";
import storage from "../../support/support_storage";

const getPackages = async (credentials) => {

    let response = await api.axiosGet('/retrieve-packages')

    return response.data;
}

const getPackage = async (params) => {
    const response = await api.axiosGet(`/retrieve-package/${params.id}`, params);

    return response.data;
}

const addPackage = async (params) => {

    let response = await api.axiosPost('/add-package', params)

    return response.data;
}

const editPackage = async (params) => {
    const response = await api.axiosPut(`/edit-package/${params.id}`, params);

    return response.data;
}

const deletePackage = async (params) => {
    const response = await api.axiosDelete(`/delete-package/${params.id}`, params);

    return response.data;
}

const packageSubscriptionService = {
    getPackages, addPackage, getPackage, editPackage, deletePackage
}

export default packageSubscriptionService;