import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    orderDetailUpdateQuantity,
    orderDetailDelete
} from "../../features/order-detail/orderDetailSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { useNavigate, useParams } from "react-router-dom";
import noPhoto from "images/no_photo.png";
import { routesPaths } from "constants/routes.constants";
import {
    generateInvoiceNumber, updateStatus
} from "../../features/order-detail/orderDetailSlice";

const CartProductTable = ({ orderDetail, loadOrder, paymentType }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser, token, currentCompany } = useSelector((state) => state.auth);
    // useEffect(() => {
    // }, [orderDetail?.quantity]);

    const [wait, setWait] = useState(false);

    const [updating, setUpdating] = useState(false);

    const updateQuantity = (e) => {
        let t = clearTimeout(wait);
        setWait(t);

        setWait(setTimeout(function () {
            setUpdating(true);
            let quantity = parseInt(e.target.value);
            let orderDetailId = orderDetail.id;
            if (!quantity) return;
            dispatch(
                orderDetailUpdateQuantity(
                    {
                        order_detail_id: orderDetailId,
                        quantity: quantity
                    }
                )
            ).then(function (response) {
                setUpdating(false);

                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success")
                    loadOrder()
                }
            });
        }, 1000))

    }

    const updateQuantityCustom = (val) => {
        let t = clearTimeout(wait);
        setWait(t);

        setWait(setTimeout(function () {
            setUpdating(true);
            let quantity = parseInt(val);
            let orderDetailId = orderDetail.id;
            if (!quantity) return;
            dispatch(
                orderDetailUpdateQuantity(
                    {
                        order_detail_id: orderDetailId,
                        quantity: quantity
                    }
                )
            ).then(function (response) {
                setUpdating(false);

                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success")
                    loadOrder()
                    const el = document.getElementById("quantity");
                    el.value = quantity;
                }
            });
        }, 1000))

    }
    const addQuantity = (e) => {
        e.preventDefault();
        const el = document.getElementById("quantity");
        updateQuantityCustom(parseInt(el.value) + 1);

    }

    const subQuantity = (e) => {
        e.preventDefault();
        const el = document.getElementById("quantity");
        if (parseInt(el.value) > 0) {
            updateQuantityCustom(parseInt(el.value) - 1)
        }
    }

    const deleteHandler = () => {
        if (window.confirm(`Are you sure you want to delete product ${orderDetail?.product.name}? `)) {
            dispatch(
                orderDetailDelete({
                    order_detail_id: orderDetail.id
                })
            ).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success")
                    loadOrder();
                }
            });

        }
    }

    const payNow = () => {
        setIsLoading(true);
        if (paymentType == 0) {
            dispatch(
                generateInvoiceNumber({
                    id: orderDetail.id,
                    payment: paymentType,
                })).then(function (response) {
                    if (response.error && response.error.message == "Rejected") {
                        snackbarCtx.displayMsg(response.payload, "failure");
                    } else {
                        let result = response.payload.data.order_details;
                        setIsLoading(false);
                        navigate(`/cart/offline-payment/${orderDetail.id}`);
                        //   snackbarCtx.displayMsg(response.payload.message, "success");
                    }
                });
        }
    };

    const payLater = () => {
        setIsLoading(true);
        // if(paymentType == 0){
        //     dispatch(
        //         updateStatus({
        //             id: orderDetail.id,
        //       })).then(function (response) {
        //         if (response.error && response.error.message == "Rejected") {
        //           snackbarCtx.displayMsg(response.payload, "failure");
        //         } else {
        //           let result = response.payload.data.order_details;
        //           setIsLoading(false);
        //           snackbarCtx.displayMsg(response.payload.message, "success");
        //           trackHandler();
        //         //   navigate(`/cart/transfer/${orderDetail.id}`);

        //         }
        //       });
        // }
    };

    const trackHandler = () => {
        if (currentUser.role_active === "customer") {
            navigate(routesPaths.CUSTOMER_ORDER_FARMERS)
        } else {
            navigate(routesPaths.ORDER_FARMERS)
        }
    };

    const offlinePaymentTransfer = () => {
        navigate(`/cart/transfer/${orderDetail.id}`);
    }

    useEffect(() => {
        // loadOrder();
    }, []);

    return (
        <div className="w-full" style={{ borderBottomWidth: "1px" }}>
            <div data-testid="cart-product"
                className="bg-white w-full flex flex-row py-4 justify-between items-center rounded-lg" >
                <section className="flex items-center px-4 gap-4 laptop:basis-2/4">
                    <img
                        src={orderDetail?.product?.grade_products[0]?.photo ?? noPhoto}
                        alt="photo of product"
                        className="cartProductLarge:inline min-w-[5rem] max-w-[5rem] min-h-[5rem] max-h-[5rem] object-cover aspect-square rounded-full"
                    />

                    <span> <b>Name: {orderDetail?.product?.name}</b>  <br /> Available:  {orderDetail?.product?.grade_products[0]?.available_qty - orderDetail?.quantity}   <br />  </span>

                    {/*<span> Name: {orderDetail?.product.name}  / Available:  {orderDetail?.product.grade && orderDetail?.product.grade.quantity} / Price: $ {orderDetail?.product.grade && orderDetail?.product.grade.price_formatted}  </span>*/}
                    {/*<small className="text-2xl"> {orderDetail?.product.name}  </small>*/}

                </section>

                <section className="hidden items-center justify-center tablet:flex basis-1/3">
                    <div className="hidden items-center tablet:flex gap-2 ">
                        <div className="input-group" style={{ borderWidth: "1px" }}>
                            <div className="laptop:flex input-group-prepend">
                                <input type="button" value="-" className="button-minus" onClick={subQuantity} data-field="quantity" style={{ boxShadow: "none" }} />
                            </div>
                            <input type="text" id="quantity" defaultValue={orderDetail?.quantity} onChange={updateQuantity} name="quantity" className="form-control" style={{ borderRadius: "0px", boxShadow: "none", borderWidth: "1px", textAlign: "center", maxWidth: "100px" }} />
                            <div className="laptop:flex input-group-append">
                                <input type="button" value="+" className="button-plus" onClick={addQuantity} data-field="quantity" style={{ boxShadow: "none" }} />
                            </div>
                        </div>
                        {/* <input type="text" className="indentedText w-[7rem] input-search  flex justify-between items-center" defaultValue={orderDetail?.quantity} onChange={updateQuantity} /> */}

                    </div>
                </section>
                <section className="hidden laptop:flex items-center justify-center basis-1/4">
                    <div className="flex items-center">
                        <label className=" text-center">{orderDetail?.price_unit} {orderDetail?.subtotal_formatted}</label>
                    </div>
                </section>
                <section className="hidden flex flex-col items-center px-2 justify-end tablet:flex basis-2/6">

                    <button type="button" className="btn-border-green" onClick={() => {
                        payNow()
                    }}>
                        {/* {(isLoading) ? "Processing, please wait.." : "Download Invoice"} */}
                        Pay Now
                    </button>
                    <br />
                    {/* <button type="button" className="btn-border-gray" onClick={() => {
                    payLater()
                }}>
                    {(isLoading) ? "Processing, please wait.." : "Pay Later"}
                    Pay Later
                </button> */}
                </section>
                <section className="hidden items-center px-4 justify-end tablet:flex basis-1/6">

                    <img
                        src="/assets/images/TrashIcon.svg"
                        alt="trash icon"
                        className="w-[2rem] h-[2rem] aspect-square cursor-pointer"
                        onClick={() => deleteHandler()}
                    />
                </section>

            </div>
            <div className="tablet:hidden w-full flex flex-row p-4 pt-0 justify-between items-center">
                <div className="input-group w-[10rem]" style={{ borderWidth: "1px" }}>
                    <div className="input-group-prepend">
                        <input type="button" value="-" className="button-minus" onClick={subQuantity} data-field="quantity" style={{ boxShadow: "none" }} />
                    </div>
                    <input type="text" id="quantity" defaultValue={orderDetail?.quantity} onChange={updateQuantity} name="quantity" className="form-control" style={{ borderRadius: "0px", boxShadow: "none", borderWidth: "1px", textAlign: "center" }} />
                    <div className="input-group-append">
                        <input type="button" value="+" className="button-plus" onClick={addQuantity} data-field="quantity" style={{ boxShadow: "none" }} />
                    </div>
                </div>
                <img
                    src="/assets/images/TrashIcon.svg"
                    alt="trash icon"
                    className="w-[2rem] h-[2rem] aspect-square cursor-pointer mr-auto ml-auto"
                    onClick={() => deleteHandler()}
                />

            </div>
            {/* <button className="lg:hidden borderedButton w-[100px] mr-auto ml-auto mb-3" onClick={deleteHandler}>Delete</button> */}
        </div>
    );

}
export default CartProductTable;
