import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useContext, useState} from "react";
import SnackbarContext from "_helpers/snackbar-context";
import { demandApplicationDestroy } from "../../../features/demand-application/demandApplicationSlice";
import PopupConfirmation from "components/ui/popup/PopupConfirmation";
import Badge from 'react-bootstrap/Badge';

const Application = ({ demand, loadDemandFarmerApplications }) => {
  const default_product = "/assets/images/products/WheatIcon.svg";
  const default_customer = "/assets/images/BlankPFP.png";
  const snackbarCtx = useContext(SnackbarContext);
  let status = "";
  if(demand?.application?.status === 0){
      status = "primary";
  }else if(demand?.application?.status === 1){
      status = "success";
  }else{
      status = "warning";
  }
  const [isDelete, setDelete] = useState(false);
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const destroyDemandApplication = () => {
    setDelete(true);
    // if(window.confirm("Are you sure you want to delete this demand?")) {
    //   dispatch(demandApplicationDestroy({
    //       demand_application_id:demand.application.id
    //   })).then(function (response) {
    //       if (response.error && response.error.message === "Rejected") {
    //           snackbarCtx.displayMsg(response.payload, "failure")
    //       } else {
    //         snackbarCtx.displayMsg(response.payload.message, "success")

    //         loadDemandFarmerApplications()
    //       }
    //   })
    // }
  }
  
  const success = () =>{
    dispatch(demandApplicationDestroy({
        demand_application_id:demand.application.id
    })).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
            snackbarCtx.displayMsg(response.payload, "failure")
        } else {
          snackbarCtx.displayMsg(response.payload.message, "success")

          loadDemandFarmerApplications()
          setDelete(false);
        }
    })
  }
  return (
    <>
      {isDelete && <PopupConfirmation title="Are you sure want to delete this application?" successButtonTitle="Delete" cancelButtonTitle="Cancel" onSuccess={success} onCancel={setDelete}/>}
      <div
        data-testid="application-card"
        className="hover:ring-2 hover:ring-formDark bg-white rounded-lg w-full" style={{borderBottomWidth: "1px"}}
      >
        {/* <label className="text-xl">{props.title}</label>
      <div className="w-full flex gap-4">
        <button className="saveButton" onClick={props.onSuccess()}>
          {props.successButtonTitle}
        </button>
        <button className="borderedButton" onClick={props.onCancel()}>
          {props.cancelButtonTitle}
        </button>
      </div> */}
        {/* Application Information */}
        {/* <section className="flex w-full gap-4">
          <img
            // src={info.product_photo ? info.product_photo : default_product}
            src={demand.photo_default}
            alt="photo of product"
            className={`hidden applicationSmall:inline min-w-[10rem] max-w-[10rem] min-h-[10rem] max-h-[10rem] object-cover rounded-lg shadow-lg`}
          />
          <div className="max-w-[30rem] w-full flex flex-col justify-around gap-2">
            <label className="text-2xl font-medium">{`Demand #${demand.id}: ${demand.name}`}</label>
            <div className="w-full flex-flex-col space-y-4 text-xl">
              <div className="flex">
                <label className="w-full">Grade: </label>
                <label className="w-full">{`Location: ${demand.owner.company?.billing_address_short}`}   </label>
              </div>

                <div className="flex">
                <label className="w-full">
                    {demand.quantity_required}
                    {demand.unit}  /
                    { demand.frequncy_title } </label>
                  <label className="w-full">{demand.frequency_label} </label>
                </div>
            </div>

            <button onClick={destroyDemandApplication} className={"saveButton w-20 bg-red-500 hover:bg-red-600 hover:ring-red-600"}>Delete</button>
          </div>

        </section>

        <section className="w-fit text-xl hidden applicationLarge:flex flex-col items-center gap-4">
          <img
            src={demand.owner.photo_default}
            alt="photo of customer"
            className="self-center min-w-[6rem] max-w-[6rem] min-h-[6rem] max-h-[6rem] object-cover rounded-full shadow-lg"
          />
          <div className="flex w-full justify-center items-center gap-2">
            <label>Status</label>
            <label className="indentedText text-base w-[6.2rem] text-center">{demand.application && demand.application.status_text}</label>

          </div>
        </section> */}
        <div className =" w-full flex flex-row p-2 gap-4 items-center ">
            <section className="basis-1/4 desktop:flex items-center gap-x-8 ">
              <img
                  // src={orderDetail.product.photo ? info.photo : "/assets/images/products/WheatIcon.svg"}
                  src={demand.photo_default}
                  alt="photo of product"
                  className="  orderLarge:inline min-w-[8rem] max-w-[8rem] min-h-[8rem] max-h-[8rem] object-cover aspect-square rounded-full"
              />
              <div className="hidden lg:flex flex-col">
                  <label className="text-2md font-semibold">{`Demand #${demand.id}: ${demand.name}`} </label>
                  <label>{`${demand.quantity_required} ${demand.unit }`} / {`${demand.frequncy_title}`}</label>
                  {/* <label>{`${orderFarmer.detail.grade.name}`}</label>
                  <label>{`${orderFarmer.detail.product.demand ? orderFarmer.detail.product.demand.frequency_label : ""}`}</label> */}
                  {/*<label>{`${orderDetail.quantity} ${$orderDeta.grade.price_unit_symbol} / ${info.frequency}`}</label>*/}
              </div>
            </section>
            <section className="basis-1/4 flex gap-4 items-center justify-center productSmall:flex-col">
              <div className="flex flex-col gap-1 laptop:gap-3 items-center ">
                <label className="lg:hidden text-2md font-semibold text-center ">{`Demand #${demand.id}: ${demand.name}`} </label>
                  <label className="lg:hidden text-center">{`${demand.quantity_required} ${demand.unit }`} / {`${demand.frequncy_title} `}</label>
                  {/* <label className="lg:hidden" style={{textAlign:"center"}}>{`${orderFarmer.detail.grade.name}`}</label> */}
                  <label className=" text-center w-[7rem]">
                    {demand.frequency_label}
                      {/*{info.currency}{info.price.toFixed(2)}*/}

                      {/* {orderFarmer?.price_unit} {orderFarmer.amount_total_overall} */}
                  </label>
                  <label className="laptop:hidden"><Badge bg={status} style={{ whiteSpace:"normal" }}>
                      {demand.application && demand.application.status_text}</Badge></label>
                  <label className="hidden lg:flex w-full">{`Location: ${demand.owner.company?.billing_address}`}   </label>
              </div>
          </section>
          
            <section className="basis-1/4 flex gap-4 items-center justify-center">
                
              <div className="hidden orderSmall:flex gap-3 items-center">
                  
                  <label className="text-center w-[7rem]">
                    <Badge bg={status} style={{ whiteSpace:"normal" }}>
                      {demand.application && demand.application.status_text}</Badge>
                    
                      {/*{info.status ? info.status : "Loading..."}*/}
                  </label>
              </div>
            </section>
            <section className="basis-1/4 flex gap-4 items-center justify-center">
                <button className="hidden laptop:flex btn-border-red w-[120px] justify-center" onClick={destroyDemandApplication}>Delete</button> 
            </section>
        </div>
        <div className="inline laptop:hidden wide:hidden flex w-full space-x-2 text-center mb-2">
          <button className="lg:hidden btn-border-red w-[120px] mr-auto ml-auto mb-3" onClick={destroyDemandApplication}>Delete</button>
        </div>
      </div>
    </>
  );
};

export default Application;
