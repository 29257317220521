import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  companyCropService from "./companyCropService";

export const companyCropIndex = createAsyncThunk(
    "companyCrop/index",
    async (args, thunkAPI) => {
        try {
            return await companyCropService.companyCropIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyCropCheckCropExist = createAsyncThunk(
    "companyCrop/checkCropExist",
    async (args, thunkAPI) => {
        try {
            return await companyCropService.companyCropCheckCropExist(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyCropStore = createAsyncThunk(
    "companyCrop/store",
    async (args, thunkAPI) => {
        try {
            return await companyCropService.companyCropStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyCropUpdate = createAsyncThunk(
    "companyCrop/update",
    async (args, thunkAPI) => {
        try {
            return await companyCropService.companyCropUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyCropDelete = createAsyncThunk(
    "companyCrop/delete",
    async (args, thunkAPI) => {
        try {
            return await companyCropService.companyCropDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyCropShow = createAsyncThunk(
    "companyCrop/:id/show",
    async (args, thunkAPI) => {
        try {
            return await companyCropService.companyCropShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyCropAll= createAsyncThunk(
    "companyCrop/all",
    async (args, thunkAPI) => {
        try {
            return await companyCropService.companyCropAll(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    companyCrop: {},
    companyCrops: {
        data: []
    },
    allCompanyCrops: {
        data:[]
    },
    loading: false,
    isSuccess: false,
    updating: false,
}

export const companyCropSlice = createSlice({
    name: 'companyCrop',
    initialState,
    reducers: {
    },
    extraReducers : {
        [companyCropStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [companyCropStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.companyCrop = payload.data.companyCrop;
            state.loading=false;
            state.isSuccess = true;
        },
        [companyCropStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [companyCropUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyCropUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyCropUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [companyCropDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyCropDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyCropDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [companyCropShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyCropShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.companyCrop = payload.data.companyCrop;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyCropShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [companyCropIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyCropIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.companyCrops = payload.data.companyCrops;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyCropIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [companyCropAll.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyCropAll.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.allCompanyCrops = payload.data.allCompanyCrops;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyCropAll.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export default companyCropSlice;