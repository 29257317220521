import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fileUpdate, fileShow } from "../../features/file/fileSlice";
import SnackbarContext from "_helpers/snackbar-context";

import AccountLayout from "pages/AccountLayout";
import { productUpdate } from "../../features/product/productSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
const FileEdit = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);

    const [date, setDate] = useState("");
    const [description, setDescription] = useState("");

    const reduxFile = useSelector((state) => state.file);

    const params = useParams();
    const update = (event) => {
        dispatch(
            fileUpdate({
                date: date,
                description: description,
                file_id: params.file_id
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                snackbarCtx.displayMsg(response.payload.message, "success")
                navigate(-1);
            }
        });

        event.preventDefault();
    }

    const loadFile = () => {
        dispatch(
            fileShow({
                file_id: params.file_id
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                snackbarCtx.displayMsg(response.payload.message, "success")
                setDescription(response.payload.data.file.description);
                setDate(response.payload.data.file.date);
            }
        });
    }

    useEffect(() => {
        loadFile()
    }, []);

    return (
        <AccountLayout>
            <div className="w-full flex flex-col p-4 laptop:p-6 wide:p-6 mb-5">
                <label className="cursor-pointer head-bar-nav mb-3" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
                    {" Go Back"}
                </label>
                <div className="w-full laptop:flex-row gap-6 p-6 rounded-lg shadow bg-card-white-new">

                    <div className={"px-10 py-10"}>

                        <div >
                            <img src={reduxFile.file.src} className="sliderimg" alt="" key={1} />
                        </div>

                        <section className="w-full  space-y-4 mt-10">
                            <form className="w-full space-y-4" onSubmit={update}>
                                <div className=" grid grid-cols-1 justify-between space-x-4">
                                    <div>
                                        <label>Date:</label>
                                        <input className={"mt-3 input-search"} type="date" value={date} onChange={({ target }) => setDate(target.value)} />
                                    </div>
                                </div>

                                <div className=" grid grid-cols-1 justify-between space-x-4">
                                    <div>
                                        <label>Description:</label>
                                        <textarea value={description} className={"mt-3 input-search"} placeholder={"Add description here.."} onChange={({ target }) => setDescription(target.value)} ></textarea>
                                    </div>
                                </div>
                                <button type="submit" className="saveButton">
                                    {reduxFile.updating ? "Processing, please wait.." : "Save"}
                                </button>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col items-center gap-8">
                
            </div>
        </AccountLayout>
    );
};

export default FileEdit;
