import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import index from "axios-middleware";
import PopupConfirmation from "components/ui/popup/PopupConfirmation";
import { userDelete } from "features/user/userSlice";
import { useDispatch } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';

const NotificationDetail = ({ users, loadUser }) => {
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const [isDelete, setDelete] = useState(false);
  const navigate = useNavigate();

  const listDetail = JSON.parse(users.data_body);

  const doDelete = () => {
    let userID = users.id;
    dispatch(
      userDelete({
        user_id: userID,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        loadUser();
        setDelete(false);
      }
    });

  }

  const detailHandler = () => {
    navigate(listDetail.url);
  };

  return (
    <>
      {isDelete && <PopupConfirmation title="Are you sure want to delete this user?" successButtonTitle="Delete" cancelButtonTitle="Cancel" onSuccess={doDelete} onCancel={setDelete} />}
      <div data-testid="users" className="p-1" style={{ borderBottomWidth: "1px" }}>
        <div className="p-2 text-sm" key={index}>
          <section className="flex flex-row font-medium ">
            <div className="basis-9/12 flex font-medium" style={{ alignItems: "center" }}>
              {listDetail?.title == "Package Subscription Renewal" ? <FontAwesomeIcon className="text-greenGray orderLarge:inline min-w-[6rem] max-w-[6rem] min-h-[6rem] max-h-[6rem] object-cover aspect-square rounded-full" icon={faBullhorn} /> : <img
                src={users.image ? users.image : "/assets/images/products/WheatIcon.svg"}
                alt={users.image ? users.image : "-"}
                className="  orderLarge:inline min-w-[6rem] max-w-[6rem] min-h-[6rem] max-h-[6rem] object-cover aspect-square rounded-full"
              />}
              <div className="hidden lg:flex flex-col ml-3">
                <label className="text-2md font-semibold" style={{ color: "#CAC6C6" }}>{users.date_formated}</label>
                <label className="text-2md font-semibold">{listDetail['title']}</label>
                <label>{listDetail['body']}</label>
              </div>
            </div>
            <div className="basis-3/12 flex items-center justify-center font-medium">

              <>
                <button
                  className="btn-border-green w-[150px] mr-auto ml-auto mb-3 mt-2 flex justify-center"
                  onClick={detailHandler}
                >Detail</button>
              </>
            </div>
          </section>
          <div className="lg:hidden lg:flex flex-col ml-3 mt-2">
            <div>
              <label className="text-2md font-semibold" style={{ color: "#CAC6C6" }}>{users.date_formated}</label>
            </div>
            <div>
              <label className="text-2md font-semibold">{listDetail['title']}</label>
            </div>
            <div>
              <label>{listDetail['body']}</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationDetail;