import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  roomChatService from "./roomChatService";

export const roomChatPage = createAsyncThunk(
    "room-chat/page",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await roomChatService.roomChatPage(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const roomChatList = createAsyncThunk(
    "room-chat/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await roomChatService.roomChatList(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const sendMsg = createAsyncThunk(
    "room-chat/sendMsg",
    async (params, thunkAPI) => {
        try {
            return await roomChatService.sendMsg(params);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const getTotalMsgByUser = createAsyncThunk(
    "room-chat/total-msg-unread",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await roomChatService.getTotalMsgByUser(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getUserIdAdmin = createAsyncThunk(
    "room-chat/user-admin-id",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await roomChatService.getUserIdAdmin(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
);

const initialState = {
    data: {},
    booking_plot: {},
    booking_plots: {
        data: []
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
    room_chat_count: 0,
    room_chat_msg_count: 0,
    isAcitiveRoomChat: false
}

export const roomChatSlice = createSlice({
    name: 'roomChat',
    initialState,
    reducers: {
        updateRoomChat:(state, action) => {
            state.room_chat_count = parseInt(state.room_chat_count || 0) +  parseInt(action.payload || 0);
        },
        updateRoomMsgChat:(state, action) => {
            state.room_chat_msg_count = parseInt(state.room_chat_msg_count || 0) +  parseInt(action.payload || 0);
        },
        updateActiveRoomChat:(state, action) => {
            state.isAcitiveRoomChat = true;
        },
        updateInActiveRoomChat:(state, action) => {
            state.isAcitiveRoomChat = false;
        }
    },
    extraReducers : {
        [roomChatPage.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [roomChatPage.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.booking_plots = payload.data.booking_plots;
            state.loading=false;
            state.isSuccess = true;
        },
        [roomChatPage.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

export const { updateRoomChat, updateRoomMsgChat, updateActiveRoomChat, updateInActiveRoomChat } = roomChatSlice.actions;

export default roomChatSlice;